<script>
  import etBtnMini from './etBtnMini.vue'

  export default {
    name: "etBtnFindMini",
    extends: etBtnMini,
    props: {
      type: {default: "success"},
      title: {default: "查询"},
    }
  }
</script>
