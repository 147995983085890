<template>
  <div v-if="value&&conf">
    <!--    {{ value.ydFormShow }}-->
    <!--    {{ slotsList }}-->
    <!--    {{ slotsValList }}-->
    <el-form v-if="value.obj!=null" :model="value.obj" :rules="value.rules" ref="obj" @close="closeDialog()"
             label-suffix="" label-width="0" size="small" class="formBox descriptions " style="width: 99%">

      <el-descriptions class="margin-top" :column="formCol" :labelStyle="{width:lableWidth}" size="small"
                       border>
        <template v-for="c in value.config.fieldList" v-if="c.show!=false&&c.find!=false ">
          <template v-if="slotsList.indexOf(c.field)>-1">
            <slot v-if="slotsList.indexOf(c.field)>-1" :name="c.field" :c="c" :class="['col'+c.colspan]" :prop="c.field"
                  :label="c.name" :colspan="c.colspan "
                  :disabled="c.read"/>
          </template>
          <el-descriptions-item v-else :label="(c.label||c.name)" :span="c.colspan ">
            <el-form-item :prop="c.field" :_label="(c.label||c.name)" :disabled="c.read">
              <slot v-if="slotsValList.indexOf(c.field)>-1" :name="c.field+'$'" :c="c"/>
              <etSysDict v-else-if="c.type=='sysDict'" :sysMap="value.sysMap" v-model="value.obj[c.field]" radio
                         :dict_code="c.sysDictCode"/>
              <el-date-picker v-else-if="c.type=='date'" v-model="value.obj[c.field]"
                              type="date" value-format="yyyy-MM-dd" placeholder="选择日期"/>
              <el-date-picker v-else-if="c.type=='datetime'" v-model="value.obj[c.field]"
                              type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"/>
              <el-input type="number" v-else-if="c.type=='number'" v-model="value.obj[c.field]"/>
              <et-upload-file-list v-else-if="c.type=='file'" v-model="value.obj[c.field]"/>
              <el-input v-else-if="c.type=='textarea'" v-model="value.obj[c.field]" type="textarea"/>
              <el-input v-else-if="c.type=='password'" v-model="value.obj[c.field]" type="password"/>
              <textarea v-else-if="c.type=='html'" :name="c.field+newTimes"
                        style="width:100%;height:480px;display: none"></textarea>
              <el-input v-else v-model="value.obj[c.field]" maxlength="200" :disabled="c.findRead"/>
            </el-form-item>
          </el-descriptions-item>
        </template>
      </el-descriptions>

    </el-form>
    <div slot="footer" class="dialog-footer" v-if="showBtn ">
      <el-button type="primary" @click="addEdit()" native-type="submit" v-if="addBtn&&addEditStatus()">确定</el-button>
      <el-button @click="closeDialog()" type="info">取消</el-button>
    </div>
  </div>
</template>
<script>

export default {
  name: "ydForm",
  data() {
    return {
      formCol: 1,
      editorMap: {},
      conf: {
        colNum: 0,
      },
      ydFormShow: null,
      newTimes: new Date().getTime(),
      slotsList: [],
      slotsValList: [],
    }
  },
  props: {
    showBtn: {default: true},
    addBtn: {default: true},
    lableWidth: {default: "120px"},
    value: {},
  },
  methods: {
    initEditor(field) {
      var e = KindEditor.create('textarea[name="' + field + this.newTimes + '"]', {
        allowFileManager: true,
        uploadJson: this.axiosUrl(this.vueConfig.apiUrl) + '/sysFile/uploadKindEditorFile.do',
        items: [
          'source', '|', 'undo', 'redo', '|', 'preview', 'print', 'template', 'code', 'cut', 'copy', 'paste',
          'plainpaste', 'wordpaste', '|', 'justifyleft', 'justifycenter', 'justifyright',
          'justifyfull', 'insertorderedlist', 'insertunorderedlist', 'indent', 'outdent', 'subscript',
          'superscript', 'clearhtml', 'quickformat', 'selectall', '|', '/',
          'formatblock', 'fontname', 'fontsize', '|', 'forecolor', 'hilitecolor', 'bold',
          'italic', 'underline', 'strikethrough', 'lineheight', 'removeformat', '|', 'image',
          'flash', 'media', 'insertfile', 'table', 'hr', 'emoticons', 'baidumap', 'pagebreak',
          'anchor', 'link', 'unlink', '|', 'about'
        ],
      });
      return e;
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    addEdit() {/*新增修改*/
      this.value.config.fieldList.forEach(c => {
          if (c.type == 'html') {
            this.$set(this.value.obj, c.field, this.editorMap[c.field].html());
          }
        }
      )
      this.$refs['obj'].validate((valid, validateErrorFieldList) => {
        if (valid) {
          this.$emit('addEdit')
        } else {
          this.validScroll('obj', validateErrorFieldList)
        }
      })
    },
    init() {
      var $this = this;
      var formCol = $this.value.config.formCol
      this.$set(this, "formCol", formCol);
      this.value.config.fieldList.forEach(o => {
        if (!o.colspan || o.colspan > formCol) {
          o.colspan = formCol;
        }
        if (o.required) {
          this.$set(this.value.rules, o.field, [this.vRequired]);
        }
      })
      setTimeout(() => {
        this.value.config.fieldList.forEach(c => {
          if (c.type == 'html' && $this.value && $this.value.obj) {
            var editor = $this.initEditor(c.field).html("");
            var content = $this.value.obj[c.field] || "";
            $this.$set($this.value.obj, c.field, content)
            editor.html(content);
            $this.$set($this.editorMap, c.field, editor);
            // $this.$set($this.isLoad, "loading", false);
            // this.$set(this, "editor", this.initEditor(c.field).html(this.value.obj[c.field] === undefined ? '' : this.value.obj[c.field]));
          }
        })
      }, 100)
    }
  }, mounted() {
    var scopedSlots = this.mapKeyToList(this.$scopedSlots);
    var slotsList = []
    var slotsValList = []
    scopedSlots.forEach(k => {
      if (k.indexOf("$") > -1) {
        slotsValList.push(k.replace("$", ""))
      } else {
        slotsList.push(k)
      }
    })
    this.$set(this, "slotsValList", slotsValList);
    this.$set(this, "slotsList", slotsList);

  }, watch: { //值改变动作 valObj(val){}
    value() {
    }
  }, components: {
    /*加载组件*/
  },

}
</script>
