<template>
  <span class="etSysUserShow" >
    <span class="sysMapNull" v-if="(sysMap==null||sysMap.sysUser$==null)&&devModel()"> sysMap is null</span>
    <span v-if="value[filed] instanceof  Array">
      <span v-for="uid in value[filed]" style="display:-webkit-inline-box">
        <span v-if="sysMap.sysUser$[uid]!=null">{{sysMap.sysUser$[uid].name}}</span>
        <span v-else>{{uid}}</span>
        &nbsp;
      </span>
    </span>
    <span v-else>
      <span
        v-if="sysMap.sysUser$[value[filed]]!=null">{{$set(value, filed + "_name", sysMap.sysUser$[value[filed]].name)}}</span>
      <span v-else>{{value[filed]}}</span>
    </span>
  </span>
</template>
<script>
  export default {
    name: "etSysUserShow",
    data() {
      return {
        list: [],
        nowDate: new Date().getTime(),
      }
    }, props: {
      filed: {default: ""},
      sysMap: {
        type: Object,
        default: () => {
        }
      },
      value: {},
    }, methods: {
      find() {

      }
    }, mounted() {
      var $this = this;
      setTimeout(function () {
        $this.find();
      }, 100)
    }, watch: {
      value: {
        handler(newValue, oldValue) {
          this.find();
        }, deep: true
      },
      filed() {
        this.find();
      }
    }
  }
</script>
