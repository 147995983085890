const defaultObj = {}
import elTemp from './elTemp/_index.js';

for (var k in elTemp) {
    defaultObj[k] = elTemp[k]
}
import design from './design/_index.js';

for (var k in design) {
    defaultObj[k] = design[k]
}

export default defaultObj
