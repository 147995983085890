var ws$
var ws$open = false
export default {
    startWebSocket: function () {
        var $this = this;
        if (window.WebSocket) {
            var socketUrl = this.getWebSocketUrl() + "/mainWS";
            if (!this.dev$)
                socketUrl = this.getWebSocketUrl() + "/api/mainWS";
            // var socketUrl =  "ws://qj2.ydkj2019.com/api/onLineWs";
            var webSocket = new WebSocket(socketUrl);
            // console.log("webSocket",webSocket)
            ws$ = webSocket;
            // this.setSession("ws$", webSocket)
            //打开事件
            webSocket.onopen = function () {
                console.log("websocket已打开");
                ws$open = true;
                $this.wsLogin()
                //socket.send("这是来自客户端的消息" + location.href + new Date());
            };
            //获得消息事件
            webSocket.onmessage = function (msg) {
                // var serverMsg = "收到服务端信息：" + msg.data;

                try {
                    var data = JSON.parse(msg.data);
                    // console.log("wsMsg", data)
                    $this.setSession("wsMsg", data)
                    if(data.funName){
                        $this.thisRunFun({funName:data.funName,datas:data.datas})
                    }else{
                        console.log("wsMsg", data)
                    }
                }catch (e){
                    console.log("wsMsg", msg.data)
                    console.log(e)
                }
            };
            //关闭事件
            webSocket.onclose = function (msg) {
                console.log("websocket已关闭,稍后进行重连！");

                ws$open=false;
                setTimeout(()=>{
                    $this.startWebSocket();
                },1000*6)
            };
            //发生了错误事件
            webSocket.onerror = function (e) {
                console.log("websocket发生了错误",e);
            }

        }
    }
    , wsLogin: function () {
        var $this = this;
        var sessionId=this.getSessionId()
        if (ws$open && ws$) {
        //     var webSessionId = webSessionId;
            var sysUserId = this.getLoginUserId();
            if(sysUserId) {
                var data = {sysUserId: sysUserId, login$: true};
                ws$.send(JSON.stringify(data))
                return true;
            }else{
                setTimeout(()=>{
                    $this.wsLogin()
                },1000*60)
            }
        }

        return false
    }
    , httpStatus: {
        902: "微信会话超时，请重新登录！",
        901: "H5会话超时，请重新登录！",
        900: "会话超时，请重新登录！",
        500: "系统异常",
        400: "请求错误",
        200: "成功",
        // public final static int loginWxTimeoutCode = 902;
        // public final static String loginWxTimeoutMsg = "微信会话超时，请重新登录！";
        // public final static int loginH5TimeoutCode = 901;
        // public final static String loginH5TimeoutMsg = "H5会话超时，请重新登录！";
        // public final static int loginTimeoutCode = 900;
        // public final static String loginTimeoutMsg = "会话超时，请重新登录！";
        // public final static int successCode = 200;
        // public final static String successMsg = "操作成功！";
        // public final static int errorCode = 400;
        // public final static String errorMsg = "";
        // public final static int exceptionCode = 500;
        // public final static String exceptionMsg = "系统异常！";
    }
    , fileUrl: function (fileId) {
        return this.axiosUrl(this.vueConfig.apiUrl) + '/sysFile/findFile.do?id=' + fileId
    }
    , downFileUrl: function (fileId) {
        var url = this.axiosUrl(this.vueConfig.apiUrl) + '/sysFile/findFile.do?id=' + fileId
        window.open(url)
    }
    , downUrl: function (url) {
        var url = this.axiosUrl(this.vueConfig.apiUrl) + url
        window.open(url)
    }
    , axiosUrl: function (apiUrl) {
        if (!apiUrl) {
            apiUrl = this.vueConfig.apiUrl;
        }
        var url = window.location.protocol + '//' + document.domain
        if (apiUrl > 0) {
            url = url + ':' + apiUrl

        } else {
            url = apiUrl
        }
        return url
    }
    , getWebSocketUrl: function (apiUrl) {
        // console.log("protocol", document.location.protocol)
        var url = 'ws://' + document.domain
        if (document.location.protocol == 'https:') {
            url = 'wss://' + document.domain
        }

        if (!apiUrl) {
            apiUrl = this.vueConfig.socketUrl;
        }

        if (apiUrl > 0) {
            url = url + ':' + apiUrl

        } else if (!apiUrl) {
            url = url + ":" + location.port;
        }
        console.log("ws_url", url)
        return url
    }
    , addEditStatus: function ($data) {
        if (!$data) {
            $data = ("p$data" in this) ? this.p$data : this.$data;
        }
        var $parent = this.$parent;
        // console.log("this.$parent", this.$parent)
        var doUrl = ("doUrl" in $data) ? $data.doUrl : ("doUrl" in $parent) ? $parent.doUrl : null;
        var baseUrl = (("baseUrl" in $data)) ? $data.baseUrl : ("baseUrl" in $parent) ? $parent.baseUrl : null;
        if (doUrl != null && doUrl) {
            // alert(1)
            return true
        }
        // console.log("baseUrl", baseUrl)
        return this.funStatus((baseUrl) + '/addEdit.action')
    }
    , exportStatus: function ($data) {
        if (!$data)
            $data = this.p$data || this.$data;
        var $parent = this.$parent;
        // console.log("this.$parent", this.$parent)
        var doUrl = ("doUrl" in $data) ? $data.doUrl : ("doUrl" in $parent) ? $parent.doUrl : null;
        var baseUrl = (("baseUrl" in $data)) ? $data.baseUrl : ("baseUrl" in $parent) ? $parent.baseUrl : null;
        if (doUrl != null && doUrl) {
            // alert(1)
            return true
        }
        return this.funStatus((baseUrl) + '/exportTable.action')
    }
    , getMenuName: function () {
        var menu = null
        if (this.author)
            menu = this.author.menu
        if (menu != null) {
            return menu.name
        } else {
            return ''
        }
    }
    , getAuthor: function () {
        var menu = null
        if (this.author)
            menu = this.author.menu
        if (menu != null) {
            return menu
        } else {
            return null
        }
    }
    , getDevAuthor: function () {
        // console.log("p",this.$parent.author)
        var menu = null
        if (this.$parent.author)
            menu = this.$parent.author.menu
        if (menu != null) {
            return menu
        } else {
            return null
        }
    }
    , funStatus: function (url) {
        var $data = ("p$data" in this) ? this.p$data : this.$data;
        var $parent = this.$parent;
        var author = ("author" in $data) ? $data.author : ("author" in $parent) ? $parent.author : null;
        var status = false
        if (author != null && author[url] != null && author[url].status == 1) {
            status = true
        }
        return status
    }
    , delStatus: function () {
        var $this = ("p$data" in this) ? this.p$data : this;
        var doUrl = ("doUrl" in $this) ? $this.doUrl : ("value" in $this) ? $this.value.doUrl : null;
        var baseUrl = ("baseUrl" in $this) ? $this.baseUrl : ("value" in $this) ? $this.value.baseUrl : null;
        return this.funStatus(baseUrl + '/delete.action')
    }
    , axiosAuthor: function (fullPath) {
        // this.axiosPost("/sysAdmin/findAuthor.do", {menu_fullPath: this.$route.fullPath}, (resData)=> {
        //   this.$set(this, "author", resData.author);
        //   this.runCallbackFn(callbackFn, resData);
        // });
        var findAuthor = this.getSession('findAuthor')
        if (findAuthor != null) {
            if (this.isNullStr(this.fullPath)) {
                this.$set(this, 'author', findAuthor[this.fullPath])
            } else {
                this.$set(this, 'author', findAuthor[this.$route.fullPath])
            }
        }
    }
    , setHeaders: function (key, value) {
        var requestHeaders = this.getSession('requestHeaders')
        if (!requestHeaders) {
            requestHeaders = {}
        }
        requestHeaders[key] = value
        this.setSession('requestHeaders', requestHeaders)
    }
    , getHeaders: function () {
        var requestHeaders = this.getSession('requestHeaders')
        if (!requestHeaders) {
            requestHeaders = {}
        }
        return requestHeaders
    }

    , axiosGet: function (url, data, callbackFn, errorFn) {
        return this.axiosBase('GET', null, url, data, callbackFn, errorFn)
    }
    , axiosPost: function (url, data, callbackFn, errorFn) {
        return this.axiosBase('POST', null, url, data, callbackFn, errorFn)
    }, postUrl: function (url, data, callbackFn, errorFn) {
        return this.axiosBase('POST', null, url, data || {}, (res) => {
            this.showMsgSuccess(data.tip$ || url + "执行成功", 1000 * 60)
            this.axiosThen({data: res}, callbackFn, errorFn)
        }, (res) => {
            console.error(res)
            this.axiosThen(res, callbackFn, errorFn)
        })
    }

    , axiosUploadFileData: function (url, formData, callbackFn, errorFn) {
        // let formData = new FormData()
        // formData.append('file', file)
        try {
            if (url == null) {
                url = this.vueConfig.uploadFileApi
            }
            var headers = {'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()}

            this.axiosBase('post', headers, url, formData, callbackFn, errorFn)
        } catch (err) {
            //console.log(err)
            this.showMsgExcept(err)
        }
    }
    , axiosUploadFile: function (url, file, callbackFn, errorFn) {
        try {
            if (url == null) {
                url = this.vueConfig.uploadFileApi
            }
            var headers = {'Content-Type': 'multipart/form-data;boundary=' + new Date().getTime()}
            let formData = new FormData()
            formData.append('file', file)
            this.axiosBase('post', headers, url, formData, callbackFn, errorFn)
        } catch (err) {
            //console.log(err)
            this.showMsgExcept(err)
        }
    }
    , axiosCatch: async function (err, callbackFn, errorFn) {
        if (err.response && err.response.data) {
            var res = err.response;
            if (res.data['$']) {
                var str = this.aesDecrypt(res.data['$']);
                var data = JSON.parse(str);
                for (var k in data) {
                    res.data[k] = data[k];
                }
                delete res.data["$"];
                this.setSession("apiEncrypt", true);
            } else {
                this.setSession("apiEncrypt", false);
            }
            this.judgeStatus(res.data)
            this.runCallbackFn(errorFn, err.response.data)
        } else if (err) {
            console.log(err)
            this.showMsgExcept(err)
        }
    }
    , axiosThen: async function (res, callbackFn, errorFn) {
        if (res.data['$']) {
            var str = this.aesDecrypt(res.data['$']);
            var data = JSON.parse(str);
            for (var k in data) {
                res.data[k] = data[k];
            }
            delete res.data["$"];
            this.setSession("apiEncrypt", true);
        } else {
            this.setSession("apiEncrypt", false);
        }
        // this.nprogress$.done()
        if (this.judgeStatus(res.data)) {
            this.runCallbackFn(callbackFn, res.data)
        } else {
            this.runCallbackFn(errorFn, res.data)
        }
        // this.nprogress$.done() // 设置加载进度条(结束..)
    }
    , axiosBase: async function (method, headers, url, data, callbackFn, errorFn, isAwait) {
        try {
            // this.nprogress$.start()
            // data.route = this.getRouteJson()
            if (!headers) {
                headers = {};
            }
            if (!this.headersKeyNo) {
                if (this.headersKey) {
                    headers["Key"] = this.headersKey;
                } else {
                    headers["Key"] = window.location.pathname + window.location.search;
                }
            }
            if (this.getSession("apiEncrypt") && data.apiEncrypt != false && !(data instanceof FormData)) {
                data = {'$': this.aseEncrypt(JSON.stringify(data))}
            }
            if (!isAwait) {
                this.axios({
                    method: method, url: url, data: data, headers: headers,
                }).then(res => {
                    this.axiosThen(res, callbackFn, errorFn)
                }).catch(err => {
                    this.axiosCatch(err, callbackFn, errorFn)
                })
            } else {

                await this.axios({
                    method: method, url: url, data: data, headers: headers,
                }).then((res) => {
                    this.axiosThen(res, callbackFn, errorFn)
                }).catch((err) => {
                    this.axiosCatch(err, callbackFn, errorFn)
                })
            }
        } catch (ex) {
            console.error(ex)
            this.runCallbackFn(errorFn, ex)
            this.showMsgExcept(ex)
            // this.nprogress$.done() // 设置加载进度条(结束..)
        }
        return 999
    },

    /**
     * 初始化请求参数
     * @param params
     * @returns {*}
     */
    getRouteJson: function () {

        var route = {}
// ////console.log(this.$route)
//   route.fullPath = this.$route.fullPath;
//   if (this.isNullStr(this.fullPath)) {
//     route.fullPath = this.fullPath;
//   }
//   route.path = this.$route.path;
//   for (var item in this.$route.query) {
//     route["query." + item] = this.$route.query[item];
//   }
        // ////console.log("route",route)
        return route
    }
    /**
     * 分页查询
     * @param obj {pageNumber:当前页码,pageSize:页大小,其他查询条件}
     * @param callbackFn
     */
    , axiosPage: function (reqData, callbackFn, showResultMsg) {
        // ////console.log(this.initParams())

        if (!this.baseUrl) {
            console.error("this.baseUrl", this.baseUrl)
        }
        this.loading = true
        var url = this.baseUrl + '/findPage.action'
        if (this.xhtmlUrl) {
            url = this.baseUrl + '/findPage.xhtml'
        }
        if (this.doUrl) {
            url = this.baseUrl + '/findPage.do'
        }
        if (this.customUrl) {
            url = this.baseUrl + '/findPageCustom.do'
        }
        if (this.findPageUrl != null) {
            url = this.findPageUrl
        }
        if (this.page) {
            // this.$set(this.page, "list", []);
        }
        this.axiosPost(url, reqData, (resData) => {
            if (showResultMsg == true) {
                this.showMsg(resData.$message)
            }

            this.runCallbackFn(callbackFn, resData)
            this.loading = false
            this.jsHeight()
        })

    }
    , axiosUpdate: function (reqData, callbackFn) {
        var url = this.baseUrl + '/addEdit.action'
        this.axiosPost(url, reqData, (resData) => {
            if (this.showResultMsg == true) {
                this.showMsg("修改成功")
            }
            this.runCallbackFn(callbackFn, resData)
        })

    }

    , axiosAddDataUpdate: function (tableName, dataJson, callbackFn, errorFn) {
        this.axiosPost('/sysDataUpdate/addEdit.do', {
            obj: {
                tableName: tableName,
                dataJson: dataJson,
                tableId: dataJson.id,
                sysUserId: this.getLoginUserId(),
            },
        }, function (resData) {
            this.runCallbackFn(callbackFn, resData)
        })
    }

    /**
     * 表单验证错误，滚动到错误位置
     * @param refsName  表单的ref
     * @param validateErrorFieldList 表单验证失败的字段来源：this.$refs["obj"].validate((valid, noFieldList)
     */
    , validScroll: function (refsName, validateErrorFieldList) {
        var dom = null
        for (let i in validateErrorFieldList) {
            if (dom != null) {
                break
            }
            console.log(this.$refs[refsName])
            var fields = this.$refs[refsName].fields
            fields.forEach(f => {
                if (dom == null && i == f._props.prop) {
                    dom = f
                }
            })
        }
        var fieldName = null;
        var validateMessage = null;
        if (dom != null) {
            fieldName = dom.label || dom.$attrs._label
            validateMessage = dom.validateMessage;
            if (!validateMessage) {
                validateMessage = "填写错误";
            }
            dom.$el.scrollIntoView({　　//滚动到指定节点
                block: 'center',　　　　　//值有start,center,end，nearest，当前显示在视图区域中间
                behavior: 'smooth'　　　　//值有auto、instant,smooth，缓动动画（当前是慢速的）
            })
        } else {
            console.error('prop不存在，无法滚动定位！')
        }
        if (!fieldName || fieldName == 'null') {
            this.showMsgError('内容填写不完整，请检查后再提交')
        } else {
            this.showMsgError("【 " + fieldName + ' 】 ' + validateMessage)
        }
    }

    , axiosAddEditRow: function (reqData, callbackFn, errorFn, noObj) {
        this.loading = true;
        var url = this.baseUrl + '/addEdit.action'
        if (this.doUrl != null && this.doUrl == true) {
            url = this.baseUrl + '/addEdit.do'
        }
        if (this.xhtmlUrl != null && this.xhtmlUrl == true) {
            url = this.baseUrl + '/addEdit.xhtml'
        }
        if (this.isNullStr(this.addEditUrl)) {
            url = this.addEditUrl
        }
        var obj = reqData.obj;
        var obj2 = reqData.obj2;
        // reqData.obj.oldObj$ = this.addSysDataUpdate(null, obj2 || obj, true);
        this.axiosPost(url, reqData, (resData) => {
            if (this.showResultMsg == true) {
                if (reqData.obj.id > 0) {
                    this.showMsg("修改成功")
                } else {
                    this.showMsg("新增成功")
                }
            }
            obj.id = resData.obj.id
            if (obj2) {
                obj2.id = resData.obj.id
            }
            this.addSysDataUpdate(null, obj2 || obj);//开启数据修改记录
            this.runCallbackFn(callbackFn, resData)
            this.loading = false
        }, function (resData) {
            // this.runCallbackFn(errorFn, resData)
            this.loading = false
        })

    }
    , axiosAddEdit: function (reqData, callbackFn, errorFn, noObj) {
        this.$refs['obj'].validate((valid, validateErrorFieldList) => {
            // ////console.log("valid==" + valid)alert(
            if (valid || reqData.$isValidate == true) {
                this.loading = true;
                var url = this.baseUrl + '/addEdit.action'
                if (this.doUrl != null && this.doUrl == true) {
                    url = this.baseUrl + '/addEdit.do'
                }
                if (this.xhtmlUrl != null && this.xhtmlUrl == true) {
                    url = this.baseUrl + '/addEdit.xhtml'
                }
                if (this.isNullStr(this.addEditUrl)) {
                    url = this.addEditUrl
                }
                var obj = reqData.obj;
                if (!reqData.obj.id) {
                    delete reqData.obj.id
                }
                var obj2 = reqData.obj2;
                // reqData.obj.oldObj$ = this.addSysDataUpdate(null, obj2 || obj, true);
                this.axiosPost(url, reqData, (resData) => {
                    if (this.showResultMsg == true) {
                        if (reqData.obj.id > 0) {
                            this.showMsg("修改成功")
                        } else {
                            this.showMsg("新增成功")
                        }
                    }
                    obj.id = resData.obj.id
                    if (obj2) {
                        obj2.id = resData.obj.id
                    }
                    this.addSysDataUpdate(null, obj2 || obj);//开启数据修改记录
                    this.runCallbackFn(callbackFn, resData)
                    this.loading = false
                }, function (resData) {
                    // this.runCallbackFn(errorFn, resData)
                    this.loading = false
                })
            } else {
                this.showMsgWarn("表单验证失败")
                // this.validScroll('obj', validateErrorFieldList)
            }
        })
    }
    , axiosAddEdit2: function (reqData, callbackFn, errorFn, noObj) {

        this.loading = true;
        var url = this.baseUrl + '/addEdit.action'
        if (this.doUrl != null && this.doUrl == true) {
            url = this.baseUrl + '/addEdit.do'
        }
        if (this.xhtmlUrl != null && this.xhtmlUrl == true) {
            url = this.baseUrl + '/addEdit.xhtml'
        }
        if (this.isNullStr(this.addEditUrl)) {
            url = this.addEditUrl
        }
        var obj = reqData.obj;
        var obj2 = reqData.obj2;
        // reqData.obj.oldObj$ = this.addSysDataUpdate(null, obj2 || obj, true);
        this.axiosPost(url, reqData, (resData) => {
            if (this.showResultMsg == true) {
                if (reqData.obj.id > 0) {
                    this.showMsg("修改成功")
                } else {
                    this.showMsg("新增成功")
                }
            }

            this.$set(obj, 'id', resData.obj.id)
            if (obj2) {
                this.$set(obj2, 'id', resData.obj.id)
            }
            this.addSysDataUpdate(null, obj2 || obj);//开启数据修改记录
            this.runCallbackFn(callbackFn, resData)
            this.loading = false
        }, function (resData) {
            // this.runCallbackFn(errorFn, resData)
            this.loading = false
        })

    }
    /**
     * 新增修改
     * @param obj {obj:{对象属性}}
     * @param callbackFn
     */
    , axiosBatchImport: function (reqData, callbackFn, errorFn) {
        this.loading = true
        var url = this.baseUrl + '/batchImport.action'
        if (this.doUrl != null && this.doUrl == true) {
            url = this.baseUrl + '/batchImport.do'
        }
        if (this.isNullStr(this.addEditUrl)) {
            url = this.addEditUrl
        }
        this.axiosPost(url, reqData, (resData) => {
            if (resData.error == 0) {
                this.showMsgSuccess('成功导入' + resData.success + '条')
                this.runCallbackFn(callbackFn, resData)
            } else {
                this.showMsgError('成功导入' + resData.success + '条，失败' + resData.error + '条')
            }
            this.loading = false
        }, errorFn)
    }
    , compareJson: function (newJson, oldJson) {
        if (!newJson) {
            newJson = this.copyObj(this.obj);
        }
        if (!oldJson) {
            oldJson = this.copyObj(this.oldObj$);
        }
        var json = {};
        for (var key in oldJson) {
            var oldVal = oldJson[key]
            var newVal = newJson[key]
            if (this.jsonToStr(oldVal) != this.jsonToStr(newVal)) {
                json[key] = newVal;
            }
        }
        for (var key in newJson) {
            var oldVal = oldJson[key]
            var newVal = newJson[key]
            if (oldVal == null) {
                json[key] = newVal;
            }
        }
        return json;
    }
    , listToName: function (objVal, list, listId, valKey) {

        if (objVal && list && valKey) {
            var valList = "";
            var listMap = this.listJsonToMap(list, listId);
            // console.log(objVal, listMap)
            if (objVal instanceof Array) {
                objVal.forEach(o => {
                    if (listMap[o]) {
                        valList = +listMap[o][valKey] + ",";
                    } else {
                        valList = +o.toString() + ",";
                    }
                })
            } else {
                if (listMap[objVal]) {
                    valList = listMap[objVal][valKey];
                } else {
                    valList = objVal.toString();
                }

            }
            return valList;
        }
    }

    , addSysDataUpdate: function (tableName, newObj, get) {
        if (!tableName) {
            if (this.tableName) {
                tableName = this.tableName;
            } else if (this.baseUrl) {
                tableName = this.baseUrl.substring(1);
            }
        }
        if (!newObj)
            newObj = this.obj

        var oldObj = this.oldObj$
        if (oldObj == null) {
            oldObj = {}
        }
        var list = {}
        var keyListMap = []
        for (var key in oldObj) {
            keyListMap[key] = "";
        }
        for (var key in newObj) {
            keyListMap[key] = "";
        }
        for (var key in keyListMap) {
            var oldVal = oldObj[key]
            var newVal = newObj[key]
            if (get != true && key.indexOf("$List") > -1 && newVal != null) {
                key = newVal.key + "$";
                oldVal = this.listToName(oldObj[newVal.key], newVal.list, newVal.id, newVal.name)
                newVal = this.listToName(newObj[newVal.key], newVal.list, newVal.id, newVal.name)
            }
            if (oldVal == null) {
                var upObj = {oldVal: oldVal, newVal: newVal, type: 'add'}
                list[key] = upObj
            } else {
                if (this.jsonToStr(oldVal) != this.jsonToStr(newVal)) {
                    var upObj = {oldVal: oldVal, newVal: newVal, type: 'update'}
                    list[key] = upObj
                }
            }
        }
        if (this.design && this.design.colMap) {
            // var fieldMap = this.design.colMap;
            // list.forEach(o => {
            //     var fieldObj = fieldMap[o.field];
            //     if (fieldObj != null) {
            //         o.fieldName = fieldObj.name;
            //     }
            // })
        } else if (this.flatConfig[tableName]) {
            // var fieldMap = this.listJsonToMap(this.flatConfig[tableName].fieldList, "field");
            // list.forEach(o => {
            //     var fieldObj = fieldMap[o.field];
            //     if (fieldObj != null) {
            //         o.fieldName = fieldObj.name;
            //     }
            // })
        }
        var comObj = {table_name: tableName, change_list: list, type: oldObj.id ? 'update' : 'add', obj_id: newObj.id}
        if (this.mapLength(list) > 0 && get != true) {
            comObj.sys_users_id = this.getLoginUserId();
            // this.axiosPost('/sysAdmin/addSysDataUpdate.do', {obj: comObj, newObj: newObj}, function (resData) {
            // })
        }
        if (get == true) {
            return comObj;
        }
    }
    /**
     * 通用-查询单个对象
     * @param reqData {id:1}
     * @param callbackFn
     * @param showResultMsg
     */
    , axiosFind: function (reqData, callbackFn, errorFn) {
        this.loading = true
        this.$set(this, 'obj', {})
        this.$set(this, 'oldObj$', {})
        var url = this.baseUrl + '/find.action'
        if (this.doUrl != null && this.doUrl == true) {
            url = this.baseUrl + '/find.do'
        }

        if (this.xhtmlUrl != null && this.xhtmlUrl == true) {
            url = this.baseUrl + '/find.xhtml'
        }

        if (this.isNullStr(this.findUrl)) {
            url = this.findUrl
        }
        this.axiosPost(url, reqData, (resData) => {
            if (resData.obj) {
                this.obj=this.copyObj(resData.obj)
                // this.$set(this, 'obj', this.copyObj(resData.obj))
                this.oldObj$ = this.copyObj(resData.obj)
                if (resData.pidTree != null) {
                    this.pidTree=this.copyObj(resData.pidTree)
                    // this.$set(this, 'pidTree', resData.pidTree)
                }
            } else {
                this.obj.id = 0
            }
            this.obj.$times$=new Date().getTime();

            this.loading = false
            if (this.$refs && this.$refs.obj) {
                this.$refs.obj.clearValidate();
            }
            this.runCallbackFn(callbackFn, resData)

        }, errorFn)

    }
    /**
     * 通用隐藏数据栏
     * @param ids id数组、json数组、单个ID
     * @param key
     * @param code
     * @param callbackFn
     * @param tipMsg
     * @param showResultMsg
     */
    , axiosUpdateCell: function (params, callbackFn, tipMsg, showResultMsg, cancelCallback) {
        this.axiosBaseUpdateCel(null, params, callbackFn, tipMsg, showResultMsg, cancelCallback)
    }
    , axiosBaseUpdateCel: function (url, params, callbackFn, tipMsg, showResultMsg, cancelCallback) {
        try {
            var confimMsg = '您确定要修改【' + tipMsg + '】吗？'
            if (tipMsg == undefined) {
                confimMsg = '您确定要修改该信息吗？'
            }
            this.$confirm(confimMsg, '修改提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true,
                confirmButtonClass: 'confirmBtnOk',
            }).then((e) => {
                if (url == null) {
                    // url = this.baseUrl + '/setIsShow.do'
                    url = '/devicePorts' + '/setIsShow.do'
                }
                // if (this.doUrl != null && this.doUrl == true) {
                //   url = this.baseUrl + '/setIsShow.do'
                // }
                this.axiosPost(url, params, (resData) => {
                    if (showResultMsg != false) {
                        this.showMsg("修改成功")
                    }
                    this.runCallbackFn(callbackFn, resData)
                })
            }).catch((action) => {
                if (action === 'cancel') {
                    this.runCallbackFn(cancelCallback)
                }
            })
        } catch (err) {
            //console.log(err)
            this.showMsgExcept(err)
        }
    }
    /**
     * 通用批量删除
     * @param ids id数组、json数组、单个ID
     * @param key
     * @param code
     * @param callbackFn
     * @param tipMsg
     * @param showResultMsg
     */
    , axiosDel: function (ids, key, code, callbackFn, tipMsg, showResultMsg) {
        this.axiosBaseDel(null, ids, key, code, callbackFn, tipMsg, showResultMsg)
    }
    , axiosBaseDel: function (url, ids, key, code, callbackFn, tipMsg, showResultMsg) {
        try {
            if (typeof ids != 'object') {//判断是不是数组，不是数组进行组装
                ids = [ids];
            }
            if (ids.length == 0) {
                this.showMsgWarn("请选择要删除的数据")
                return
            } else {
                if (ids[0].id != undefined) {
                    ids = this.arrayJoinArray(ids, 'id')
                }
                var confimMsg = '您确定要删除【' + tipMsg + '】吗？'
                if (tipMsg == undefined) {
                    confimMsg = '您确定要删除该信息吗？'
                }
                this.$confirm(confimMsg, '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true,
                    confirmButtonClass: 'confirmBtnOk',
                }).then((e) => {
                    var params = {}
                    params.ids = ids
                    params.key = key
                    params.code = code
                    if (url == null) {
                        url = this.baseUrl + '/delete.action'
                    }
                    if (this.doUrl != null && this.doUrl == true) {
                        url = this.baseUrl + '/delete.do'
                    }
                    this.axiosPost(url, params, (resData) => {
                        if (this.judgeStatus(resData)) {
                            var dvt = resData.dvt
                            if (dvt != null) {
                                //data.js中定义tableNameMap
                                var tableName = this.tableNameMap[dvt.tableName]
                                if (tableName == null) {
                                    tableName = dvt.tableName
                                }
                                this.showMsgError('数据被【' + tableName + '】占用' + dvt.total + '次，无法删除')
                            } else {
                                if (showResultMsg != false) {
                                    this.showMsg("删除成功")
                                }
                                this.runCallbackFn(callbackFn, resData)
                            }
                        }
                    })

                }).catch(() => {
                })
            }
        } catch (err) {
            //console.log(err)
            this.showMsgExcept(err)
        }
    }
    , callbackFn: function () {
    }
    /**
     * 执行回调函数，
     */
    , runCallbackFn: function (callbackFn, resData) {
        try {
            if (callbackFn != undefined) {
                var $this = this
                $this.callbackFn = callbackFn
                ////console.log(callbackFn)
                $this.callbackFn(resData)
            }
        } catch (ex) {
            //console.log(ex)
            this.showMsgExcept(ex)
        }
    }
    /**
     * 验证data状态
     */
    , dimission: function (id) {
        this.$confirm('', '确定【离职】？', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
            confirmButtonClass: 'confirmBtnOk',
        }).then((e) => {
            this.axiosPost('/sysUserMe/dimission.do', {id: id}, (resData) => {
                this.showMsgSuccess('操作成功')
                this.refresh()
            })
        }).catch(() => {
        })
    }
    , judgeVersion: function (resData) {//更新操作时,用于版本控制
        var $this = this
        $this.setSession('testVersion', new Date())
    }

    , judgeStatus: function (resData) {

        var $this = this
        try {
            if (typeof resData == 'string') {
                $this.showMsgExcept(resData)
            } else if (resData instanceof Object) {
                var $code = resData.$code || resData.code
                var $message = resData.$message || resData.message
                this.judgeVersion(resData)
                if ($code != null) {
                    if ($code == 900 || $code == 901) {
                        // alert($code)
                        if (this.getLoginUser() != null) {
                            this.showMsgExcept($message)
                        }
                        if (("loading" in this)) {
                            try {
                                this.loading = false
                            } catch (e) {

                            }
                        }

                        this.setLoginUser(null)
                        // if (this.sessionOuttimeTarget) {
                        //   this.$router.push(this.vueConfig.loginUrl + '?url=' + this.$route.fullPath);
                        // }
                        return false
                    } else if ($code == 400) {
                        this.showMsgError($message)
                        this.loading = false
                        return false
                    } else if ($code == 500) {
                        if ($message.indexOf("OAuthCodeThread") > -1) {
                            this.showMsgExcept("许可证无效！" + resData.$exMsg)
                        } else {
                            this.showMsgExcept($message)
                        }
                        this.loading = false
                        return false
                    } else if ($code != 200) {
                        // console.log("judgeStatus=" + $code, resData)
                        this.showMsgExcept($message)
                        return false
                    }
                }
            } else {
                console.log(resData, typeof resData)
                $this.showMsgExcept(resData)
            }
            return true
        } catch (ex) {
            //console.log($this)
            $this.showMsgExcept(ex)
            //console.log(ex)
            return false
        }
    }
}
