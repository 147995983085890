import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
//#自动adminPath#
        {path: adminPath + "/nxUserBlacklist", component: () => import("./nxUserBlacklist_index.vue")},
        {path: adminPath + "/nxMasterGroupRule", component: () => import("./nxMasterGroupRule_index.vue")},
        {path: adminPath + "/nxUserBalance", component: () => import("./nxUserBalance_index.vue")},
        {path: adminPath + "/nxAdminUser", component: () => import("./nxAdminUser_index.vue")},
        {path: adminPath + "/nxAdminUserLogin", component: () => import("./nxAdminUserLogin_index.vue")},
        {path: adminPath + "/nxUser", component: () => import("./nxUser_index.vue")},
        {path: adminPath + "/nxNewsInfo", component: () => import("./nxNewsInfo_index.vue")},
        {path: adminPath + "/nxInviteRule", component: () => import("./nxInviteRule_index.vue")},
        {path: adminPath + "/nxInvite", component: () => import("./nxInvite_index.vue")},
        {path: adminPath + "/nxCreditRule", component: () => import("./nxCreditRule_index.vue")},
        {path: adminPath + "/nxCredit", component: () => import("./nxCredit_index.vue")},
        {path: adminPath + "/nxCollect", component: () => import("./nxCollect_index.vue")},
        {path: adminPath + "/nxArea", component: () => import("./nxArea_index.vue")},
        {path: adminPath + "/nxUserAddr", component: () => import("./nxUserAddr_index.vue")},
        {path: adminPath + "/nxVipInfo", component: () => import("./nxVipInfo_index.vue")},
        {path: adminPath + "/nxVipOrder", component: () => import("./nxVipOrder_index.vue")},
        {path: adminPath + "/nxUserMoney", component: () => import("./nxUserMoney_index.vue")},
        {path: adminPath + "/nxUserStatistics", component: () => import("./nxUserStatistics_index.vue")},


    ],
    pages: []
}
