<template>
  <div class="etUploadFile">
    <el-upload
        :disabled="readonly"
        class="avatar-uploader_file"
        :action="getUploadUrl"
        :with-credentials="true"
        :on-success="uploadSuccess"
        :on-remove="fileRemove"
        :on-preview="clickFile"
        :before-upload="uploadFile"
        :file-list="value"
        :limit="limit"
        :list-type="listType"
        :before-remove="beforeRemove"
        :on-exceed="outLimit">
      <el-button size="mini" v-if="listType!='picture-card'&&!readonly&&value.length<limit" type="primary">
        {{ btnName }}
      </el-button>
      <div class="el-upload__tip" v-if="tip!=''"
      >
        <i class="iconfont-tixing"></i>
      </div>
      <div v-if="tempFile!=null&&tempFile.length>0">资料下载：
        <a :href="item.url" target="_blank" style="color: royalblue" v-for="(item,ii) in tempFile">
          <span v-if="ii>0">、</span>《{{ item.name }}》</a>
      </div>
    </el-upload>
  </div>
</template>

<script type="text/javascript">

export default {
  name: "etUploadFile",
  data() {
    return {
      btnDisabled: false
    };
  },
  computed: {
    getUploadUrl() {
      return this.axiosUrl(this.vueConfig.apiUrl) + this.uploadUrl;
    }
  },
  mounted() {
  },
  props: {
    uploadUrl: {default: "/sysFile/uploadFileDo.do"},
    btnName: {default: "点击上传"}//按钮名称
    , tip: {default: ""}//文件说明,空字符串不显示
    , fileNameValidate: {type: String}
    , delConfirm: {default: false}
    , fileType: {default: "all"}//上传文件类型,或后缀名逗号分隔
    , maxFileSize: {default: 1024 * 1024 * 10}//文件大小限制
    , value: {default: Array}//默认现在的文件列表{name,url}
    , tempFile: {default: Array}//默认现在的文件列表{name,url}
    , limit: {default: 6}
    , readonly: {default: false}
    , listType: {default: "picture"}//文件列表的类型text/picture/picture-card
  },
  methods: {
    beforeRemove(file, fileList) {
      if (!this.delConfirm) {
        return true;
      } else {
        return this.$confirm("确定移除该文件？")
      }
    },
    uploadSuccess(resData, file, fileList) {//文件上传成功时的钩子res=返回结果，file文件内容
      try {
        if (this.value == null) {
          this.$set(this, "value", new Array())
        }
        var fileObj = resData.obj;
//          fileObj.id = resData.obj.id;
//          fileObj.name = resData.obj.name;
//          fileObj.url = resData.obj.url;
        this.value.push(fileObj)
        this.$emit('input', this.value);
      } catch (e) {
        this.showMsgError("上传文件失败：" + e)
      }
    },
    fileRemove(file, fileList) {//文件列表移除文件时的钩子
      this.delArrItem(this.value, file);
      this.$emit('input', this.value);
    },
    outLimit(files, fileList) {
      this.$message.error("最多上传文件数量：" + this.limit);
    },
    clickFile(file) {//点击文件列表中已上传的文件时的钩子
      var url = file.url;
      if (file.response != null && file.response.url != null) {
        url = file.response.url
      }
      window.open(url);
    },
    //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    uploadFile(file) {
      if (this.fileNameValidate != null && this.fileNameValidate.trim().length > 0
          && file.name != null && file.name.indexOf(this.fileNameValidate) == -1) {
        this.showMsgError("文件名必须包含：" + this.fileNameValidate)
        return false;
      }
      if (file.size == 0) {
        this.showMsgError("文件大小不能为空")
        return false;
      }
      var imageType = "jpg,jpeg,png,bmp,gif";
      var flashType = "swf,flv";
      var mediaType = "swf,flv,mp3,wav,wma,wmv,mid,avi,mpg,asf,rm,rmvb,mp4";
      var fileType = "doc,docx,xls,xlsx,ppt,txt,zip,rar,gz,bz2,pdf";
      var fileList2 = "jpg,jpeg,png,bmp,gif,pdf";
      var typeMap = {
        "image": imageType
        , "flash": flashType
        , "media": mediaType
        , "file": fileType
        , "rfFile": fileList2
        , "all": imageType + "," + flashType + "," + mediaType + "," + fileType
      };
      var suffixList = "";
      if (typeMap[this.fileType] != undefined) {
        suffixList = typeMap[this.fileType];
      } else {
        suffixList = this.fileType;
      }
      suffixList = suffixList.toLocaleLowerCase();
      var suffix = file.name.substr(file.name.lastIndexOf(".") + 1).toLocaleLowerCase();
      var suffixListTemp = "," + suffixList + ",";
      if (suffixListTemp.indexOf("," + suffix + ",") == -1) {
        this.$message.error('上传的文件类型为：' + suffixList);
        return false;
      }
      // if (file.size > this.maxFileSize) {
      //   this.$message.error('上传的文件大小不能超过：' + this.maxFileSize / 1024 + " KB");
      //   return false;
      // }
      return true;
    }
  }, watch: { //值改变动作 valObj(val){}
    value: {
      handler(newObj, oldObj) {
        var $this = this;
        this.value.forEach(o => {
          if (o.name == null || o.name.trim().length == 0) {
            var url = o.url;
            if (url != null && url.lastIndexOf("=") > 0) {

              url = url.substring(url.lastIndexOf("=") + 1)
              $this.$set(o, "name", url)
            }
          }
        })
      }, deep: true
    }

  },
}
</script>

