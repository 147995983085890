<template>
  <span class="sysMapNull" v-if="(sysMap==null||sysMap.sysUser==null) &&devModel()"> sysMap is null={{sysMap}}</span>
  <el-select v-else v-model="value2" :placeholder="name" :clearable="clearable" @change="change" :multiple="multiple "
             style="width: 120px" :disabled="disabled" filterable>
    <el-option v-if="!(sysMap==null||sysMap.sysUser==null)" v-for="(item,key) in sysMap.sysUser" :key="item.id"
               :label="item.name"
               :value="item.id" :disabled="item.status==0&&optionDisabled">
      <span :title="item.status==0?'账号停用':''" :style="item.status==0?'color:red;':''"> {{item.name}}</span>
    </el-option>
  </el-select>
</template>
<script>

  export default {
    name: "sysUserTemp",
    data() {
      return {
        value2: this.value,
      }
    },
    props: {
      optionDisabled: {default: true},
      disabled: {default: false},
      multiple : {default: false},
      clearable: {default: true},
      name: {default: "系统人员"},
      value: {},
      sysMap: {
        default: () => {}
      },
      list: {type: Array, default: () => []},
    },
    methods: {
      change() {

      }
    }, mounted() {

    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, components: {}, mounted: function () {/*页面加载初始化*/
    }
  }
</script>
