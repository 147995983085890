<style lang="less">
@import "../../../assets/css/param";
@width: 100px;
.etUploadImgList {
  padding: 10px 10px 0 10px;

  .el-upload-list__item-actions {
    i {
      font-size: 16px;
    }

    align-items: start;
  }

  .is-disabled, &.noShowAdd {
    .el-upload {
      display: none;
    }
  }

  .el-upload-list__item, .el-upload--picture-card {
    //width: @width;
    //height: @width;
    background-color: @readBg;
  }

  .el-icon svg {
    height: 100% !important;
    width: 100% !important;

  }

  .el-upload {
    margin: 0 8px 12px 0;
  }
}

.widthgen(@count) when (@count > 0) {
  .widthgen((@count - 1));
  .width_@{count} {
    .el-upload-list .el-upload-list__item, .el-upload {
      width: auto;
      min-width: @count * 1px;
      height: @count * 1px;
    }
  }
}

.widthgen(400);
</style>
<template>
  <el-dialog class="flex" v-model="showEdit" title="编辑文件" width="600px" top="300px">
    <el-form :model="file" class="formBox" label-suffix="" label-width="100px" ref="file">
      <el-form-item label="文件名" prop="name">
        <el-input maxlength="200" size="small" v-model="file.file_name"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="url">
        <el-input type="textarea" autosize maxlength="200" size="small" v-model="file.url"></el-input>
      </el-form-item>
      <div style="text-align: center">
        <el-button @click="showEdit=false" type="primary">
          确定
        </el-button>
        <el-button @click="showEdit=false" type="info">取消</el-button>
      </div>
    </el-form>

  </el-dialog>
  <div class="etUploadImgList  " :class="{'noShowAdd':(limit==(value2&&value2.length)||readonly||disabled)}"
       style="">

    <el-upload :class="'width_'+imgWidth"
               :action="uploadUrl"
               :before-upload="uploadFileBefore"
               :data="paraData"
               :disabled="readonly||disabled "
               v-model:file-list="value2"
               :limit="limit"
               :on-exceed="outLimit"
               :on-progress="onProgress"
               :on-preview="clickFile"
               :on-remove="fileRemove"
               :on-success="uploadSuccess"
               :with-credentials="true"
               list-type="picture-card">
      <template #default="o">
        <el-icon>
          <Plus :style="{width: imgWidth+'px',height:imgHeight+'px',lineHeight:imgHeight+'px'}"/>
        </el-icon>
      </template>
      <template #file="o">
        <img :src="getImgUrl(o.file)" :style="{ height:imgWidth+'px',lineHeight:imgWidth+'px'}"/>
        <span class="el-upload-list__item-actions">
            <span @click="editFileInfo(o.file,1)" class="el-upload-list__item-edit"><i class="iconfont-bianji"/></span>
            <span @click="clickFile(o.file)" class="el-upload-list__item-preview"><i class="iconfont-chakan"/></span>
            <span @click="fileRemove(o.file)" class="el-upload-list__item-delete"><i class="iconfont-shanchu"/></span>
            <span @click="moveIndex(value2,'left',o.file)" style="position: absolute;left: 0;bottom: -5px"
                  v-if="value2.length>1"><i
                class="iconfont-zuoyi-04"/></span>
            <span @click="moveIndex(value2,'right',o.file)" style="position: absolute;right: 16px;bottom: -5px"
                  v-if="value2.length>1"><i
                class="iconfont-youyi-04"/></span>
        </span>

      </template>
    </el-upload>
  </div>
</template>
<style lang="less">
</style>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etUploadImgList",
  data() {
    return {
      showEdit: false,
      value2: [], file: {},
    };
  },
  computed: {

    uploadUrl() {
      var url = this.axiosUrl(this.vueConfig.apiUrl) + this.url;
      url += url.indexOf("?") > -1 ? "&" : "?";
      url += "&compressWidth=" + this.compressWidth;
      url += "&loginVisit=" + this.loginVisit;
      if (this.userDir) {
        url += "&userDir=" + this.userDir;
      } else if (this.userDirLoginUserId) {
        url += "&userDir=" + this.getLoginUserId();
      }
      return url;
    }
  },
  props: {
    userDir: {default: ""},//自定义文件夹
    userDirLoginUserId: {default: true},//自定义文件夹
    loginVisit: {default: false},//登录访问
    paraData: {},
    showSizeTip: {type: Boolean, default: false},
    imgWidth: {default: 160},
    imgHeight: {type: Number, default: 120},
    title: {default: ""},
    tip: {type: String},//文件说明,空字符串不显示
    limit: {type: Number, default: 6},
    fileNameValidate: {type: String},
    readonly: {type: Boolean},
    disabled: {type: Boolean},
    compressWidth:{default:0},
    maxFileSize: {default: 1024 * 1024 * 100},//文件大小限制
    modelValue: {type: Array, default: () => []},//默认现在的文件列表{name,url}
    fileType: {default: ""},//上传文件类型,或后缀名逗号分隔
    tempFile: {type: Array, default: []},//默认现在的文件列表{name,url}
    url: {default: "/sysFile/uploadFile.do", type: String}
  },
  methods: {
    getImgUrl(file) {

      return file.url
    },

    clickFile(file, type) {//点击文件列表中已上传的文件时的钩子
      // console.log(file, type);
      var url = this.getImgUrl(file);
      if (file.response != null && file.response.url != null) {
        url = file.response.url
      }
      window.open(url);
    },
    editFileInfo(file) {
      this.file = file;
      this.showEdit = true
    },
    uploadSuccess() {//文件上传成功时的钩子res=返回结果，file文件内容
      var list = [];
      this.value2.forEach(o => {
        if (o.response && o.response.obj) {
          list.push(o.response.obj);
        } else {
          list.push(o)
        }
      });
      this.$set(this, "value2", list);
    },
    outLimit() {
      this.$message.error("最多上传文件数量：" + this.limit);
    },
    fileRemove(file) {//文件列表移除文件时的钩子
      this.delArrItem(this.value2, file);
    },
    moveIndex(list, flag, file) {
      list.forEach((o, index) => {
        if (o.uuid == file.uuid) {
          file.index = index;
        }
      });
      let toIndex;
      if (flag == 'left') {
        // if (file.index == 0) {
        //   this.showMsgError("已经在最前面");
        //   return;
        // }
        toIndex = file.index - 1;
      }
      if (flag == 'right') {
        // if (file.index == list.length - 1) {
        //   this.showMsgError("已经在最后面");
        //   return;
        // }
        toIndex = 0;
      }
      let currItem = list.splice(file.index, 1);
      list.splice(toIndex, 0, ...currItem);
    },

    //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    uploadFileBefore(file) {
      // this.getBase64(file).then((res) => {
      //   this.dealImage(res, 720, (base64) => {
      //
      //   });
      // })

      if (this.fileNameValidate != null && this.fileNameValidate.trim().length > 0
          && file.name.indexOf(this.fileNameValidate) == -1) {
        this.showMsgError("文件名必须包含：" + this.fileNameValidate);
        return false;
      }
      var imageType = "jpg,jpeg,png,bmp,gif";
      var suffixList = "";
      if (this.fileType == undefined || this.fileType == "") {
        suffixList = imageType;
      } else {
        suffixList = this.fileType;
      }
      suffixList = suffixList.toLocaleLowerCase();
      var suffix = file.name.substr(file.name.lastIndexOf(".") + 1).toLocaleLowerCase();
      var suffixListTemp = "," + suffixList + ",";
      if (suffixListTemp.indexOf("," + suffix + ",") == -1) {
        this.$message.error('上传的文件类型为：' + suffixList);
        return false;
      }
      if (file.size > this.maxFileSize) {
        this.$message.error('上传的文件大小不能超过：' + this.maxFileSize / 1024 + " KB");
        return false;
      }
      file = null;
      return true;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        ///FileReader类就是专门⽤来读⽂件的
        const reader = new FileReader();
        //开始读⽂件
        //readAsDataURL: dataurl它的本质就是图⽚的⼆进制数据，进⾏base64加密后形成的⼀个字符串，
        reader.readAsDataURL(file);
        // 成功和失败返回对应的信息，reader.result⼀个base64，可以直接使⽤
        reader.onload = () => resolve(reader.result);
        // 失败返回失败的信息
        reader.onerror = (error) => reject(error);
      })
    },

    /**
     * @param {path,w,callback}
     * @returns {callback} // 通过canvas压缩base64图片 并压缩
     */
    dealImage(path, w = 720, callback) {
      const newImage = new Image();


      newImage.src = path;
      newImage.setAttribute("crossOrigin", 'Anonymous');    // url为外域时需要
      let imgWidth;
      let imgHeight;
      newImage.onload = function () {
        imgWidth = this.width;
        imgHeight = this.height;
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = w * imgHeight / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = w * imgWidth / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
        const newBase64 = canvas.toDataURL("image/jpeg", 0.7);
        console.log(newBase64)
        callback(newBase64);
      }
    }
  },
  setup(props, {emit}) {
    const value2 = ref(props.modelValue);
    watch(
        () => value2.value,
        (data) => emit('update:modelValue', data)
    );
    return {
      value2: value2,
    }
  },
  watch: {
    modelValue() {
      this.$set(this, "value2", this.modelValue);
    },
  },
}
</script>
