<template>
  <span>
     <span v-if="value==1" style="color: green">已兑换</span>
     <span v-else style="color: red">待兑换</span>
  </span>
</template>
<script>

    export default {
        data() {
            return {};
        },
        props: {
            value: null,
        }
    }
</script>
