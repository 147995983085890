<template>
  <i :class="'inputAddBtn '+icon" v-if="show"
     @click="click"></i>
</template>
<script>
  export default {
    name: "etInputAddBtn",
    data() {
      return {}
    }, props: {
      show: {default: true},
      disabled: {default: false},
      icon: {default: "iconfont-xinzengmian"},
    }, methods: {
      click() {
        if (!this.disabled)
          this.$emit('click');
      }
    }, mounted() {

    }, watch: {}
  }
</script>

