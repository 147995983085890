<!--Breadcrumb 面包屑 -->
<template>
    <template v-if="page">
        <el-form ref="tableForm" :model="page" label-width="0">
            <el-table ref="table"
                      :class="{tableInput:design.table.edit_table,verticalAlignTop:design.table.verticalAlignTop}"
                      :height="tableHeight||getHeight(tHeight+(design.table.noShowPage?-40:0))" :data="page.list"
                      :border="design.table.border!=null?design.table.border:true" stripe
                      :tree-props="design.table.tree?{children: 'children' }:{}"
                      :row-key="design.table.tree?'value':'id'"
                      :default-expand-all="design.table.treeExpandAll" :show-summary="design.table.showSummary"
                      :summary-method="this.$parent[this.design.table.summaryMethod]"
                      :header-cell-class-name="headerCellClass"
                      :cell-class-name="cellClass" :row-class-name="rowClassNameGet"
                      @selection-change="selectionChange" @row-dblclick="rowDblClick" @header-contextmenu="colRight"
                      @header-dragend="headerDragend" :cell-style="cellStyle"
                      @sort-change="sortChange"
                      :default-sort="{prop: design.table.sort_col, order: design.table.sort_order}"
                      v-loading="loading" :style="{width:design.table.width,verticalAlign:'top'}">
                <el-table-column v-if="design.table.expand" type="expand" width="40px" :fixed="findTableFixed(design)">
                    <template #default="o">
                        <div :class="{'el-table__row--striped__div':o.$index%2==1}">
                            <slot name="expand$" :row="o.row" :$index="o.$index"></slot>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column v-if="design.table.indexShow" type="index" :label="design.table.index_label||'#'"
                                 align="center" :fixed="findTableFixed(design)"
                                 :width="indexWidth()"/>

                <el-table-column v-if="design.table.selectDel" type="selection" align="center" width="32"
                                 :selectable="selectable"></el-table-column>
                <template v-for="c in design.tableCols">
                    <template v-if="design.colMap[c]&&design.colMap[c].show&&showTableItem(design.colMap[c])">
                        <template v-if="$slots['column-'+design.colMap[c].col]&&design.colMap[c].close_solt_col!=true">
                            <slot :name="'column-'+design.colMap[c].col" class="1"></slot>
                        </template>
                        <template v-else>
                            <el-table-column :prop="design.colMap[c].col" label=""
                                             :align="design.colMap[c].align"
                                             :sortable="design.colMap[c].sortable||false"
                                             :width="design.colMap[c].width " :fixed="design.colMap[c].operFixed||false"
                                             :show-overflow-tooltip="design.colMap[c].tooltip||false">
                                <!--              <template v-if="design.colMap[c].col=='create_time'" #default="o">-->
                                <!--                <span style="color:green">{{ o.row.create_time }}</span>-->
                                <!--              </template>-->
                                <template #header>
                  <span class="label" :class="{noField:design.colMap[c].status==0}" :field="c"
                        v-html="toHtmlBr(tableColLabel(design.colMap[c]))">
                  </span>
                                </template>
                                <template #default="o">
                                    <template
                                            v-if="$slots[design.colMap[c].col]&&design.colMap[c].close_solt_col!=true">
                                        <slot :name="design.colMap[c].col" :row="o.row"></slot>
                                        <!--                  <span class="custom-value" title="值插槽">值</span>-->
                                    </template>
                                    <el-form-item v-else-if="o.row.edit$" :prop="'list.' + o.$index + '.'+c"
                                                  :rules="getRules(design.colMap[c])">
                                        <etDesignInput :design="design" v-model="o.row" :sysMap="sysMap"
                                                       :selectList="selectList"
                                                       :selectMap="selectMap" :ptreeList="ptreeList"
                                                       :c="c" type="txt"
                                                       @remoteMethod="remoteMethod"/>
                                    </el-form-item>
                                    <etDesignColVal v-else :row="o.row" :c="design.colMap[c]" :design="design"
                                                    :selectMap="selectMap" :sysMap="sysMap"></etDesignColVal>
                                </template>
                            </el-table-column>
                        </template>
                    </template>
                </template>
                <slot name="default"></slot>
                <el-table-column v-if="design.oper.show" :label="design.oper.name" :align="design.oper.align"
                                 :fixed="design.oper.fixed||false" :width="design.oper.width||80">
                    <template #default="o">
                        <slot name="oper_left" :row="o.row" :$index="o.$index"></slot>
                        <etBtnCopyMini v-if="design.oper.copy&&addEditStatus()" @click="findObj(o.row,true)">复制
                        </etBtnCopyMini>
                        <etBtnEditMini v-if="o.row.edit$&&addEditStatus()" @click="saveRow(o.row)">保存</etBtnEditMini>
                        <etBtnEditMini @click="findObj(o.row,false,o.row)" v-if="operEditShow(o.row)&&addEditStatus()">
                            {{ o.row.edit$ ? '关闭' : '编辑' }}
                        </etBtnEditMini>
                        <etBtnEditMini v-else @click="findObj(o.row)">查看</etBtnEditMini>
                        <etBtnDelMini
                                v-if="operDelShow(o.row)&&delStatus()&&(!noDelIds||noDelIds.indexOf(o.row.id)==-1)"
                                @click="del( o.row.id)"/>
                        <slot name="oper_right" :row="o.row" :$index="o.$index"></slot>
                    </template>
                </el-table-column>

            </el-table>
        </el-form>
        <etPage :page="page" @refresh="refresh" @setVal="setVal" v-if="!design.table.noShowPage"
                :pageSizes="(design.table.pageSizes||'10,20,50,100,200,500').split(',')"/>
    </template>
</template>
<script>
    import {ref, watch} from 'vue'

    export default {
        name: "etDesignTable",
        components: {},
        data() {
            return {

                selectList: {},
                selectMap: {},
            };
        },
        emits: "setVal,refresh,selectionChange,rowDblClick,colRight,headerDragend,saveRow,sortChange,findObj,del".split(','),
        props: {
            modelValue: null,
            loading: {},
            tableHeight: {},
            border: {default: true},
            sunTbale: {default: false},
            ptreeList: {},
            tHeight: {},
            page: {},
            row: {},
            col: {},
            noDelIds: {},
            selectable: {},
            c: {},
            design: {},
            sysMap: {},
            p$data: {},
            runFun: {
                type: Function,
                default: null
            },


        },
        setup(props, {emit}) {
            const search = ref(props.modelValue); // 初始化数据
            watch(
                () => search.value,
                (data) => emit('update:modelValue', data)
            );

            return {
                search: search,
            }
        },
        methods: {
            cellStyle({row, column, rowIndex, columnIndex}) {
                // console.log(column)
                // :style="[{lineHeight:design.colMap[c].lineHeight+'px',fontSize:design.colMap[c].fontSize?design.colMap[c].fontSize+'px':'',color:design.colMap[c].color,fontWeight:design.colMap[c].fontWeight},design.colMap[c].style]"
                var style = {};
                var c = column.property;
                if (this.design && this.design.colMap[c]) {
                    if (this.design.colMap[c].lineHeight) {
                        style.lineHeight = this.design.colMap[c].lineHeight + "px"
                    }
                    if (this.design.colMap[c].color) {
                        style.color = this.design.colMap[c].color
                    }
                    if (this.design.colMap[c].fontSize) {
                        style.fontSize = this.design.colMap[c].fontSize + "px"
                    }
                    if (this.design.colMap[c].fontWeight) {
                        style.fontWeight = this.design.colMap[c].fontWeight
                    }
                    if (this.design.colMap[c].style_map) {
                        try {
                            var style_map = JSON.parse(this.design.colMap[c].style_map);
                            for (var k in style_map) {
                                style[k] = style_map[k];
                            }
                        } catch (e) {
                            var styles = this.design.colMap[c].style_map.split(";");
                            styles.forEach(o => {
                                if (o) {
                                    var vk = o.split(":");
                                    style[this.toMidLineCamel(vk[0])] = vk[1];
                                }

                            })
                            // console.error(e)
                            // this.showMsgError("样式Map错误：" + this.design.colMap[c].style_map)
                        }
                    }
                    return style;
                }
                return {}
            },
            // show(col, query) {
            //   var flag = true;
            //   if (col && col.show_checked) {
            //     col.show_checked.forEach(o => {
            //       if (query && query instanceof Object) {
            //         for (var k in query) {
            //           if (o == query[k]) {
            //             flag = false;
            //           }
            //         }
            //       }
            //     })
            //   }
            //   return flag;
            // },
            rowClassNameGet({row, rowIndex}) {
                if (row.edit$) {
                    return "editRow"
                }
                return "";

            },
            headerCellClass({row, column, rowIndex, columnIndex}) {
                var c = column.property;
                var className = "field-" + c;
                for (var s in this.$slots) {
                    if (s == ("column-" + c) && this.design.colMap[c] && this.design.colMap[c].close_solt_col != true) {
                        className += " custom-column";
                    }
                }
                if (this.design.selectTableCol && this.design.selectTableCol.col == c) {
                    className += " design-select-column";
                }
                // if(c){
                //   $slots['column-'+design.colMap[c].col]
                // }
                return className;
            },
            cellClass({row, column, rowIndex, columnIndex}) {
                var c = column.property;
                for (var s in this.$slots) {
                    if (s == (c) && this.design.colMap[c].close_solt_col != true) {
                        return "custom-value"
                    }
                }
                // if(c){
                //   $slots['column-'+design.colMap[c].col]
                // }
                return ""
            },
            selectionChange(val) {
                this.$emit("selectionChange", val)
            },
            setVal(key, value) {
                console.log(key, value)
                this.$emit("setVal", key, value)
            },
            refresh() {
                // console.log("this.$emit",this)
                // console.log("this.$parent2",this.$parent)
                this.$emit("refresh")
            },
            del(id) {
                this.$emit("del", id)
            },
            findObj(row, copy, test) {
                this.$emit("findObj", row, copy, test)
            }, saveRow(row) {
                this.$refs['tableForm'].validate((valid, validateErrorFieldList) => {
                    if (valid) {
                        this.$emit("saveRow", row)
                    } else {
                        for (var ek in validateErrorFieldList) {
                            var fields = ek.split(".");
                            var field = fields[fields.length - 1];
                            this.showMsgError(this.design.colMap[field].show_name + " - 验证失败")
                        }
                    }
                })
            }, rowDblClick(row, column, event) {
                this.$emit("rowDblClick", row, column, event)
            }, sortChange(sort) {
                this.$emit("sortChange", sort)
            }, colRight(column, event) {
                this.$emit("colRight", column, event)
            }, headerDragend(newWidth, oldWidth, column, event) {
                this.$emit("headerDragend", newWidth, oldWidth, column, event)
            },
        }, mounted: function () {

        }, watch: { //值改变动作 valObj(val){}

            "page.$times$": {
                handler(newName, oldName) {

                    if (newName && newName != oldName) {
                        // console.log("watch.etDesignTable.page")
                        this.initSelect('table');
                    }
                }, deep: true
            }
        }
    }
</script>
