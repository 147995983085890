import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    index: [
        //#自动adminPath#
{path: adminPath + "/sysUseRemark", component: () => import("./sysUseRemark_index.vue")},
{path: adminPath + "/sysUseRemark", component: () => import("./sysUseRemark_index.vue")},
{path: adminPath + "/sysFiles", component: () => import("./sysFile_index.vue")},
{path: adminPath + "/sysServerNodeRun", component: () => import("./sysServerNodeRun_index.vue")},
{path: adminPath + "/sysServerNode", component: () => import("./sysServerNode_index.vue")},
{path: adminPath + "/sysThreadRun", component: () => import("./sysThreadRun_index.vue")},
{path: adminPath + "/sysApiVisit", component: () => import("./sysApiVisit_index.vue")},
        {path: adminPath + '/sysConfigs', component: () => import("./sysConfigs_index.vue"),},
        {path: adminPath + '/sysMenu', component: () => import("./sysMenuIndex.vue"),},
        {path: adminPath + '/sysRole', component: () => import("./sysRole_index.vue"),},
        {path: adminPath + '/sysDevDb', component: () => import("./sysDevDb_index.vue"),},
        {path: adminPath + '/sysDictInfo', component: () => import("./sysDictInfo_index.vue"),},
        {path: adminPath + '/sysUsers', component: () => import("./sysUsers_index.vue"),},
        {path: adminPath + '/sysException', component: () => import("./sysException_index.vue"),},
        {path: adminPath + '/sysLog', component: () => import("./sysLog_index.vue"),},
        {path: adminPath + '/sysThreadConfig', component: () => import("./sysThreadConfig_index.vue"),},
        {path: adminPath + '/pgsqlCompare', component: () => import("./pgsqlCompare.vue"),},
        {path: adminPath + '/sysDevDbTable', component: () => import("./sysDevDbTable_index.vue"),},
        {path: adminPath + '/sysDevTest', component: () => import("./sysDevTest_index.vue"),},
        {path: adminPath + '/sysDevDesignLog', component: () => import("./sysDevDesignLog_index.vue"),},
        {path: adminPath + '/sysDevNeed', component: () => import("./sysDevNeed_index.vue"),},
        {path: adminPath + '/sysDevDesign', component: () => import("./sysDevDesign_index.vue"),},
        {path: adminPath + '/sysDevDesignApi', component: () => import("./sysDevDesignApi_index.vue"),},
        {path: adminPath + '/sysServerNode', component: () => import("./sysServerNode_index.vue"),},
        {path: adminPath + '/sysServerNodeRun', component: () => import("./sysServerNodeRun_index.vue"),},
    ],
}
