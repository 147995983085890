<style lang="less">
.ydFromItem {

}
</style>
<template>
   <td>
     <slot></slot>
   </td>
</template>
<script>

export default {
  name: "ydFromItem",
  data() {
    return {
      value2: this.value,
    }
  },
  props: {
    value:{},
    rules:{},
  },
  created() {
  },
  methods: {},
  watch: {
    value2() {
    },
    value() {
    }

  }
  ,
  components: {}
  ,
  mounted: function () {/*页面加载初始化*/
    console.log(this.$slots)
  }
}
</script>
