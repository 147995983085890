<template>
  <el-form-item  prop="sysTenantCode"  label="租戶" v-if="isSaasAdmin()">
    <sysTenantTemp v-model="value2.sysTenantCode" />
  </el-form-item>
</template>
<script>

  export default {
    name: "sysTenantForm",
    data() {
      return {
        value2: this.value,
      }
    },
    props: {

      label: {default: "租户"},
      value: {},
      sysMap: {
        default: () => {
        }
      },
    },
    created() {
    },
    methods: {

      change() {

      }
    }, mounted() {

    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, components: {}
  }
</script>
