<!--Breadcrumb 面包屑 -->
<template>
  <div v-if="col&&selectMap&&selectList&&selectMap[col.col]">
  <span class="txt" :style="contentStyle(col.col)" v-if="(col.module_type_form)=='select_text'||showType=='text'">
    <etDesignApiShow :sysMap="sysMap" type="table" :selectMap="selectMap" :c="col" :row="obj"/>
  </span>
    <el-cascader
        v-else-if="selectMap[col.col].api$.type=='cascader'"
        :change-on-select="true" :options="selectList[col.col]"
        @change="changeFun($event,col)" clearable expand-trigger="hover" filterable
        :disabled="disabled"     :placeholder="placeholder||col.placeholder"
        v-model="obj[col.col_data||col.col]"
        separator=">" style="width: 100%;" :style="contentStyle(col.col)"
        :props="{checkStrictly:true,multiple:false,emitPath:false}"/>

    <el-select v-else-if="selectList[col.col]&&col.api_form_hide!=true"
               :style="contentStyle(col.col)"
               :class="{rrrr:selectMap[col.col].api$.remote}"
               :multiple="multiple||col.module_type_form=='select_multiple'||col.module_type_form=='select_remote_multiple'"
               :placeholder="placeholder||col.placeholder"
               :disabled="disabled" @change="changeFun($event,col)"
               :remote="selectMap[col.col].api$.remote"
               :remote-method="(query)=>{remoteMethod(query,col)}"

               clearable
               filterable remote-show-suffix
               :popper-class="selectMap[col.col].api$&&selectMap[col.col].api$.headTitle?'headTitle':''"
               v-model="obj[col.col_data||col.col]"
               value-key="value">
      <el-option
          :style="{minWidth: (selectMap[col.col].api$.width||320)+'px'}"
          class="apiSelectTitle" disabled
          key="#1" label=""
          v-if="selectMap[col.col].api$&&selectMap[col.col].api$.headTitle"
          value="#">
        <el-row class="header-panel">
          <template v-for="(k,ki) in selectMap[col.col].api$.headTitle">
            <el-col :style="{maxWidth:((selectMap[col.col].api$.labelWidth[ki]||120)+8+'px')}">
              {{ k }}
            </el-col>
          </template>
        </el-row>
      </el-option>
      <template v-for="item in selectList[col.col]">
        <el-option :key="item.value"
                   :label="item.label"
                   v-if="selectMap[col.col].api$&&selectOptionShow(item,col)"
                   :style="{minWidth: (selectMap[col.col].api$.width||320)+'px'}"
                   :value="item.value" :disabled="selectOptionRead(item,col)"
                   class="selectApi"
        >
          <el-row>
            <el-col class="apiSelectItem"
                    :style="{maxWidth: ((selectMap[col.col].api$.labelWidth[ki]||120)+8+'px')}"
                    v-for="(k,ki) in  selectMap[col.col].api$.options|| selectMap[col.col].api$.labels"
            >
                  <span v-if="selectMap[col.col].api$.labelDict[k]">
                        <etSysDict :sysMap="sysMap" :modelValue="objKeyVal(item,k)" text
                                   :dict_code="selectMap[col.col].api$.labelDict[k]"></etSysDict>
                      </span>
              <img v-else-if="k&k.indexOf('img#')==0" :src="item[k.replace('img#','')]"
                   style="height: 36px;vertical-align: middle"/>
              <span v-else :title="objKeyVal(item,k)">
                      {{ objKeyVal(item, k) }}
                    </span>
            </el-col>
          </el-row>
        </el-option>
      </template>
      <el-option v-if="selectList[col.col].length==0" :value="0" disabled>
        <div style="text-align: center;color: #999999">
          无数据
        </div>
      </el-option>
    </el-select>
    <div v-if="obj[col.col_data||col.col]&&col.api_form_table"
         style="padding: 0px;border-top:0px #ccc solid;background-color: #ffffff">
      <etDesignApiShow :style="contentStyle(col.col)" :sysMap="sysMap" type="descriptions" :selectMap="selectMap"
                       :c="col"
                       :row="obj"/>
    </div>
  </div>
  <span v-else-if="show_error" style="color:red;">
<!--    selectApiTemp-->
    <!--    {{col.col}}=-->
    <!--    c={{col!=null}}-->
    <!--    m={{selectMap!=null}}-->
    <!--    l={{selectList!=null}}-->
    <!--    mv={{selectMap[col.col]}}-->
  </span>
</template>
<script>
import {ref, watch} from 'vue'
import EtJsonViewer from "../elTemp/etJsonViewer";

export default {
  name: "selectApiTemp",
  components: {EtJsonViewer},
  data() {
    return {
      show_error: false,
    };
  },
  emits: "remoteMethod,formItemRight,changeFun,update:modelValue".split(","),
  props: {
    // selectMap: {},
    placeholder:{},
    colKey: {},
    showType: {},
    type: {default: "form"},
    multiple: {default: false},
    disabled: {default: false},
    oldObj$: {},
    // selectList: {},
    modelValue: {},
    design: {},
    sysMap: {},
    col: {},
    selectMap: {},
    selectList: {},
    apiCode: {},
  },
  setup(props, {emit}) {
    const obj = ref(props.modelValue); // 初始化数据
    const selectMap = ref(props.selectMap); // 初始化数据
    const selectList = ref(props.selectList); // 初始化数据
    const design = ref(props.design); // 初始化数据
    const col = ref(props.col); // 初始化数据
    // console.log("col",col)
    watch(
        () => obj.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动

    return {
      obj: obj,
      col: col,
      selectMap: selectMap,
      selectList: selectList,
      design: design,
    }
  },
  methods: {
    contentStyle(c) {
      if (this.design && this.design.colMap && this.design.colMap[c]) {
        return this.design.colMap[c].form_style
      } else {
        return '';
      }
    },
    objVal(obj, c) {
      console.log(c, obj)
      return this.objKeyVal(obj, c.col_data || c.api_field || c.col);
    },

    changeFun(val) {
      this.$emit("changeFun", val)
    },
    selectOptionRead(o, col) {
      if (col.api_read) {
        return eval(col.api_read)
      }
      return false;
    },
    selectOptionShow(o, col) {
      if (col.api_item_no_show) {
        return !eval(col.api_item_no_show)
      }
      return true;
    },
    remoteMethod($event, c) {
      if (this.selectMap[c.col].api$.remote) {
        // console.log("selectApiTemp",this.type,c)
        if (this.type == 'search') {
          var col = (this.design.colMap[c.col]);
          this.initSelectR(this.type, col, $event)
        } else {
          this.initSelectR(this.type, c, $event)
        }
      } else {
        console.log("非远程")
      }
      // this.$emit("remoteMethod", $event || "", c)

    },
    getApi() {
      if (!this.selectMap) {
        this.selectMap = {}
      }
      if (!this.selectList) {
        this.selectList = {}
      }
      var col = this.col || {};
      col.col = this.colKey || col.col;
      if (this.apiCode) {
        // console.log("selectApiTemp.getApi.",this.apiCode)
        col.api = this.apiCode;

        // console.log(this.apiCode, col)
        this.col = col;
        // console.log(col)
        this.initSelectR(this.type, col, '')
      } else {
        this.col = col;
      }
    }
  }, mounted: function () {
    setTimeout(() => {
      this.show_error = true;
    }, 3000)
    // console.log("selectApiTemp", this.apiCode, this.col)
    this.getApi();

  }, watch: { //值改变动作 valObj(val){}
    modelValue() {
      this.$set(this, "obj", this.modelValue);
      this.getApi();
    },
  }
}
</script>

