<template>
   <div :class="{dev:dev$}">
  <router-view></router-view>
   </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      headersKeyNo: true,
    }
  },
  mounted() {/*页面加载初始化*/
    document.querySelector('body').setAttribute('class', 'webAdmin '+(this.dev$?'dev':''))
    this.initWebVersionCheck();
    this.setIconfontUrl("https://at.alicdn.com/t/c/font_4142240_etg2y9t6z28.css")//更新修改
  }
}
</script>

<style lang="less">
/*本项目iconfont图片地址*/
@import "https://at.alicdn.com/t/c/font_4142240_etg2y9t6z28.css";//更新修改
/*@import "assets/iconfont/icon/iconfont.css";*/

</style>
