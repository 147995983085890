// import Vue from 'vue'
// import button from './button/_button'
//
// Vue.use(button);
import etSearch from './etSearch.vue'
import helpHint from './other/HelpHint.vue'
// import deleleHint from './DeleleHint.vue'
import KcTags from './other/KcTags.vue'
import etBreadcrumb from './etBreadcrumb.vue'
import etPage from './etPage.vue'


import etUploadFile from './upload/etUploadFile.vue'
import etUploadImgList from './upload/etUploadImgList.vue'
import etUploadExcelRead from './upload/etUploadExcelRead.vue'
import etUploadExcelReadTable from './upload/etUploadExcelReadTable.vue'
import etUploadExcelReadTableInfo from './upload/etUploadExcelReadTableInfo.vue'

import etStatusTag from './status/etStatusTag.vue'
import etUploadFileList from './upload/etUploadFileList.vue'
import etRichTextBox from './etRichTextBox.vue'
import etJsonViewer from './etJsonViewer.vue'
// import etUploadFileList1 from './upload/etUploadFileList1.vue'


import etStatusSearch from './status/etStatusSearch.vue'
import etStatusYnEn from './status/etStatusYnEn.vue'
import etStatusQT from './status/etStatusQT.vue'
import etStatusStop from './status/etStatusStop.vue'
import etStatusVerify from './status/etStatusVerify.vue'
import etImgView from './img/etImgView.vue'
import etStatusEdit from './status/etStatusEdit.vue'
import etStatusQTIcon from './status/etStatusQTIcon.vue'
import etRadioSex from './status/etRadioSex.vue'
import etSysUserShow from './etSysUserShow.vue'
import etTabPaneTemp from './etTabPaneTemp.vue'
import etSearchItem from './etSearchItem.vue'
import etJsonShow from './etJsonShow.vue'
import etStatusSubmit from "./status/etStatusSubmit";
import ydForm from "./ydForm";
import ydTable from "./ydTable";
import ydTableConf from "./ydTableConf";
import etBytes from "./etBytes";
import etUploadVideo from "./upload/etUploadVideo";

import searchOption from "./searchOption";
import etImgsView from "./etImgsView";
import select_temp from "./select_temp.vue";
const  defaultJson={
  // "kcTable",kcTable);
  select_temp:select_temp,

  "etImgsView":etImgsView,
  "etJsonViewer":etJsonViewer,
  "searchOption":searchOption,
  "etBytes":etBytes,
  "ydTableConf":ydTableConf,
  "ydForm":ydForm,
  "ydTable":ydTable,
  "etSearchItem":etSearchItem,
  "etTabPaneTemp":etTabPaneTemp,
  "etSysUserShow":etSysUserShow,
  "etRadioSex":etRadioSex,
  "etStatusQTIcon":etStatusQTIcon,
  "etStatusEdit":etStatusEdit,
  "etStatusSubmit":etStatusSubmit,
  "etStatusSearch":etStatusSearch,
  "KcTags":KcTags,
  "etStatusTag":etStatusTag,
  "etSearch":etSearch,
  "helpHint":helpHint,
  "etBreadcrumb":etBreadcrumb,
  "etPage":etPage,


  "etUploadFile":etUploadFile,
  "etUploadImgList":etUploadImgList,
  "etUploadVideo":etUploadVideo,
  "etUploadExcelRead":etUploadExcelRead,
  "etUploadExcelReadTable":etUploadExcelReadTable,
  "etUploadExcelReadTableInfo":etUploadExcelReadTableInfo,
  // "kcTable",kcTable,

  "etStatusYnEn":etStatusYnEn,
  "etStatusQT":etStatusQT,
  "etStatusStop":etStatusStop,
  "etStatusVerify":etStatusVerify,
  "etImgView":etImgView,
  "etUploadFileList":etUploadFileList,
  "etRichTextBox":etRichTextBox,
  // "etUploadFileList1":etUploadFileList1,
  /*json美化输出*/
  "etJsonShow":etJsonShow,
}
import _button from "./button/_button";

for(var k in _button){
  defaultJson[k]=_button[k]
}
import sysTemp from "@/views/sys/sysTemp/index";
for(var k in sysTemp){
  defaultJson[k]=sysTemp[k]
}import temp from "@/views/_temp/_index";
for(var k in temp){
  defaultJson[k]=temp[k]
}

export default defaultJson
