import vueConfig from '../../config/vueConfig'

export default {
    adminPages: [

        // {path: "/admin", component: resolve => require(['./admin.vue'], resolve)},
        {path: vueConfig.adminUrl,  component: ()=>import("./admin.vue")},
    ],
    pages: [
        {path: '/error404', component: () => import("./error404.vue")},
        {path: '/error403', component: () => import("./error403.vue")},
        // {path: vueConfig.loginUrl, component: resolve => require(['./login.vue'], resolve)},
    ]
}
