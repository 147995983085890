<!--Breadcrumb 面包屑 -->
<template>
  <template v-if="c">
    <template v-if="design">
      <div v-if="$slots['item']&&design.colMap[c].close_solt_form!=true"
           class="custom-form-item" :field="c"
           @contextmenu.native="formItemRight($event,design.colMap[c])">
        <slot :name="'item'"></slot>
      </div>
      <el-form-item :prop="design.colMap[c].col_data||design.colMap[c].col"
                    v-else-if="obj&&design.colMap[c]&&showFormItem(design.colMap[c])"
                    :class="{
                      hideLabel:design.colMap[c].form_label_hide,
                            col2:design.colMap[c].col2,col2Right:design.colMap[c].col2Right,
                          disabled:!formSaveBtnShow(design)|| formItemRead(design.colMap[c]),
                          noField:design.colMap[c].status==0,
                          'custom-form-content':$slots[design.colMap[c].col]&&design.colMap[c].close_solt_form!=true
                          }"
                    :style="{textAlign:design.colMap[c].align_form}"
                    @contextmenu.native="formItemRight($event,design.colMap[c])"
                    :field="c" :rules="getRules(design.colMap[c])">
        <template #label>
          <div :field="c" :style="design.colMap[c].form_label_style">
            {{ formItemLabel(design.colMap[c]) }}
          </div>
        </template>
        <template v-if="obj">

          <slot :name="design.colMap[c].col"></slot>
          <template v-if="$slots['content']&&design.colMap[c].close_solt_form!=true">
            <slot name="content"></slot>
          </template>
          <div v-else  style="display: flex;width: 100%;" :class="{txt:design.colMap[c].module_type_form=='text'||design.colMap[c].module_type_form=='text_dict'}">
          <etDesignInput :c="c" :design="design" :selectList="selectList" :selectMap="selectMap"
                        :root$="root$" :sysMap="sysMap" @remoteMethod="remoteMethod" :ptreeList="ptreeList"
                         @changeFun="changeFun" :oldObj$="oldObj$" type="form" v-model="obj"/>
          </div>
        </template>
      </el-form-item>
    </template>
  </template>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etDesignFormItemContent",
  data() {
    return {};
  },
  emits: "remoteMethod,formItemRight,changeFun,update:modelValue".split(","),
  props: {
    modelValue: null,
    col: {},
    selectMap: {},
    selectList: {},
    sunTbale: {},
    c: {},
    // c: {},
    design: {},
    oldObj$: {},
    sysMap: {},
    root$: {},
    ptreeList: {},
  },
  setup(props, {emit}) {
    const obj = ref(props.modelValue); // 初始化数据
    const design = ref(props.design); // 初始化数据
    watch(
        () => obj.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      obj: obj,
      design: design,
    }
  },
  methods: {
    changeFun(fun_name, val) {
      this.$emit("changeFun", fun_name, val)
    },

    remoteMethod($event, c) {
      this.initSelectR('form', c, $event)
    },
    formItemRight($event, c) {
      this.$emit('formItemRight', $event, c)
    },

  }, mounted: function () {
  }, watch: { //值改变动作 valObj(val){}
    modelValue() {
      this.$set(this, "obj", this.modelValue);
    },

    "modelValue.id"() {
      this.$set(this, "obj", this.modelValue);
    },
  }
}
</script>
<style>
.el-select-dropdown__list {
  margin: 0 !important;
}
</style>
