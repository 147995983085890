<template>
  <el-cascader v-if="sysMap&&sysMap.sysArea" size="small" style="width: 100%;" v-model="value2"
               expand-trigger="hover" :clearable="clearable" :disabled="disabled" placeholder="试试搜索：昆明" separator=" > "
               :options="sysMap.sysArea" filterable :change-on-select="true"></el-cascader>
</template>
<script>

  export default {
    name: "sysAreaCascaderTemp",
    data() {
      return {
        value2: this.value,
        sysTypeMap: {},
      }
    },
    props: {
      name: {default: "类别"},
      typeCode: {default: "typeCode"},
      value: {},
      sysMap: {},
      clearable: {default: true},
      disabled: {default: false},
    },
    methods: {
      refresh(obj) {
        if (obj != null) {
          var ids = obj.pids;
          ids.push(obj.id);
          ids.splice(0, 1);
          this.$set(this, "value2", ids);

        }
      }
    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, mounted: function () {/*页面加载初始化*/
    }, components: {},
  }
</script>
