<template>
  <el-button :type="type" :loading="loading">
    <i v-if="icon&&!loading" :class="icon" style="font-size: 16px;"></i>
   <span v-if="this.$slots.default||title" :style="{marginLeft:icon?'4px':0}">
      <slot v-if="this.$slots.default"></slot>
       <span v-else-if="title">{{ title }}</span>
   </span>
  </el-button>
</template>
<script>

export default {
  name: 'etBtnAdd',
  props: {
    icon: {String,default: 'iconfont-jia'},
    type: {default: 'success'},
    title: {String,default: '新增'},
    loading: {default: false},
    plain: {default: false},
  },
}
</script>
