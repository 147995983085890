<template>
  <el-upload :class="{etUploadFileListDisabled:disabled,etUploadFileListReadShow:readShow,etUploadFileList:true}"
             :action="uploadUrl"
             :before-upload="uploadFileBefore"
             :data="paraData"
             :disabled="disabled"
             :limit="limit"
             :on-exceed="outLimit"
             :on-preview="clickFile"
             :on-remove="fileRemove"
             :on-success="uploadSuccess"
             :with-credentials="true"
             multiple
             v-model:file-list="value2">

    <el-button size="small" type="primary" v-if="!disabled">
      <i v-if="btnIcon" class=" btnIcon " :class="btnIcon"/>
      <span v-if="btnName" style="margin-left: 4px;">{{ btnName }}</span>
    </el-button>
  </el-upload>
</template>
<style lang="less">
.el-upload-list__item-name {
  font-size: inherit;
}

.etUploadFileList {
  .el-upload {
    margin-left: 12px;

    .el-button {
      padding: 6px 8px;
      height: 26px;

    }
  }

  .btnIcon {
    font-size: 16px;
    color: #ffffff;
  }

  .el-upload-list {
    margin-top: 4px;
  }

  .el-upload-list__item {
    line-height: 1.2;
    margin-bottom: 4px;
    font-size: inherit;
  }

  .el-upload-list__item-name {
    padding-left: 8px;
    font-size: inherit;
  }
}

.etUploadFileListReadShow {
  .el-upload-list {
    margin-top: 0;

  }

  .el-upload-list__item {
    margin-bottom: 0px;
    line-height: 20px;

    .el-upload-list__item-info {
      margin-left: 0;
    }
  }

  .el-upload-list__item-name {
    padding: 0;

    .el-icon {
      margin-right: 2px;
    }
  }
}

.etUploadFileListDisabled, etUploadFileListReadShow {
  .el-upload {
    display: none;

  }

  .el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: none !important;
  }
}
</style>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etUploadFileList",
  data() {
    return {
      value2: []
    };
  },
  computed: {
    uploadUrl() {
      var url = this.axiosUrl(this.vueConfig.apiUrl) + this.url;
      url += url.indexOf("?") > -1 ? "&" : "?";
      url += "&loginVisit=" + this.loginVisit;
      if (this.userDir) {
        url += "&userDir=" + this.userDir;
      } else if (this.userDirLoginUserId) {
        url += "&userDir=" + this.getLoginUserId();
      }
      return url;
    }
  },
  props: {
    btnName: {default: "上传"},//自定义文件夹
    userDir: {default: ""},//自定义文件夹
    btnIcon: {default: "iconfont-wenjianshangchuan"},//自定义文件夹
    userDirLoginUserId: {default: true},//自定义文件夹
    loginVisit: {default: false},//登录访问
    readShow: {default: false},//用于表格显示等
    paraData: {},
    showSizeTip: {type: Boolean, default: false},
    imgWidth: {type: Number, default: 160},
    imgHeight: {type: Number, default: 120},
    title: {default: ""},
    tip: {type: String},//文件说明,空字符串不显示
    limit: {type: Number, default: 6},
    fileNameValidate: {type: String},
    disabled: {type: Boolean},
    maxFileSize: {default: 1024 * 1024 * 10},//文件大小限制
    modelValue: {type: Array, default: () => []},//默认现在的文件列表{name,url}
    fileType: {default: ""},//上传文件类型,或后缀名逗号分隔
    tempFile: {type: Array, default: []},//默认现在的文件列表{name,url}
    url: {default: "/sysFile/uploadFile.do", type: String}
  },
  methods: {
    uploadSuccess() {//文件上传成功时的钩子res=返回结果，file文件内容
      var list = [];
      this.value2.forEach(o => {
        if (o.response && o.response.obj) {
          list.push(o.response.obj);
        } else {
          list.push(o)
        }
      });
      this.$set(this, "value2", list);
    },
    outLimit() {
      this.$message.error("最多上传文件数量：" + this.limit);
    },
    fileRemove(file) {//文件列表移除文件时的钩子
      this.delArrItem(this.value2, file);
    },
    clickFile(file) {//点击文件列表中已上传的文件时的钩子
      var url = file.url;
      if (file.response != null && file.response.url != null) {
        url = file.response.url
      }
      window.open(url);
    },
    //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    uploadFileBefore(file) {
      if (this.fileNameValidate != null && this.fileNameValidate.trim().length > 0
          && file.name.indexOf(this.fileNameValidate) == -1) {
        this.showMsgError("文件名必须包含：" + this.fileNameValidate)
        return false;
      }
      if (file.size > this.maxFileSize) {
        this.$message.error('上传的文件大小不能超过：' + this.maxFileSize / 1024 + " KB");
        return false;
      }
      file = null;
      return true;
    }
  },
  setup(props, {emit}) {
    const value2 = ref(props.modelValue);
    watch(
        () => value2.value,
        (data) => emit('update:modelValue', data)
    );
    return {
      value2: value2,
    }
  },
  watch: {
    modelValue() {
      this.$set(this, "value2", this.modelValue);
    },
  },
}
</script>
