import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
    //#自动adminPath#
    {path: adminPath + "/aliSmsSend",  component: ()=>import("./aliSmsSend_index.vue")},
    {path: adminPath + "/aliSmsTemp",  component: ()=>import("./aliSmsTemp_index.vue")},
  ]
}
