import Vue from 'vue'

var validateUpload = (rule, value, callback) => {
    if (JSON.stringify(value) == '{}') {
        callback(new Error('必填'))
    } else {
        callback()
    }
}
var validateUsername = (rule, value, callback) => {
    if (!/^[a-zA-Z0-9_]{2,16}$/.test(value)) {
        callback(new Error('必须是（字母，数字，下划线），4到16位'))
    } else {
        callback()
    }
}
var validatePassword = (rule, value, callback) => {
    if (value == null || value.length == 0) {
        callback()
        return
    }
    if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]+)/.test(value)) {
        callback(new Error('密码必须包含（大写字母、小写字母、数字）'))
    } else {
        callback()
    }
}
var validateFload = (rule, value, callback) => {
    if (!/^(-?\d+)(\.\d+)?$/.test(value)) {
        callback(new Error('必须是浮点数'))
    } else {
        callback()
    }
}
var validatePlusFload = (rule, value, callback) => {
    if (value == null || value == '') {
        callback()
    }
    if (!/^\d+(\.\d+)?$/.test(value)) {
        callback(new Error('必须是正浮点数'))
    } else {
        callback()
    }
}
var validateNumber = (rule, value, callback) => {
    if (!/^-?\d+$/.test(value)) {
        callback(new Error('必须是数字'))
    } else {
        callback()
    }
}
var validatePlusNumber = (rule, value, callback) => {
    if (!/^[0-9]*$$/.test(value)) {
        callback(new Error('必须是正数'))
    } else {
        callback()
    }
}

var validatePlusIdCard = (rule, value, callback) => {
    if (value != null && value != '' && value != undefined) {
        if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
            callback(new Error('身份证格式不正确'))
        } else {
            callback()
        }
    } else {
        callback(new Error('必填'))
    }
}

var required = function (rule, value, callback) {
    var errorMsg = null
    if (value != null && value.toString().trim().length > 0) {
    } else {
        errorMsg = ('必填')
        callback(new Error(errorMsg))
    }
    callback()
}
var validatePlusPhone = function (rule, value, callback) {
    console.log(rule, value, callback)
    var errorMsg = ""
    if (value != null && value != '' && value != undefined) {
        if (!/^(1[3-9])[0-9]{9}$/.test(value)) {
            errorMsg = '手机号码格式不正确'
            callback(new Error(errorMsg))
        }
    }
    callback()
    // console.log(callback,errorMsg)
    // runCallback(callback, errorMsg)
    // return errorMsg
}
var validatePlusTxlPhone = function (rule, value, callback) {
    if (value != null && value != '' && value != undefined) {
        if (!/^(^(\d{3,4}-)?\d{7,8})$|(1[3-9][0-9]{9})$/.test(value)) {
            callback(new Error('手机或座机号码格式不正确'))
        } else {
            callback()
        }
    } else {
        callback(new Error('必填'))
    }
}

var runCallback = function (callback, errorMsg) {
    if (callback != null) {
        var error = null
        if (errorMsg != null) {
            error = new Error(errorMsg)
            callback(error)
        } else {
            callback()
        }

    }
}
var validateJzfmTime = function (rule, value, callback) {
    if (value == null || value == undefined || value == '') {
        callback(new Error('必填'))
        return
    }
    var newDate = new Date()
    var newDate2 = new Date(newDate.getTime() + 24 * 60 * 60 * 1000)
    var date = new Date(value)
    if (new Date().getHours() < 9) {//必须大于当天14点
        if (date.getTime() <= new Date(newDate.getFullYear() + '-' + (newDate.getMonth() + 1) + '-' + newDate.getDate() + ' 14:00:00')) {
            callback(new Error('必须超过当天14点'))
        }
    } else if (new Date().getHours() >= 18) {//必须大于第二天14点
        if (date.getTime() <= new Date(newDate2.getFullYear() + '-' + (newDate2.getMonth() + 1) + '-' + newDate2.getDate() + ' 14:00:00')) {
            callback(new Error('必须超过第二天14点'))
        }
    } else if (date.getTime() <= new Date(newDate2.getFullYear() + '-' + (newDate2.getMonth() + 1) + '-' + newDate2.getDate() + ' 9:00:00')) {
        callback(new Error('必须超过第二天9点'))

    } else {
        callback()
    }

}

var validateNumberScope2 = (rule, value, callback) => {
    var valNum = parseFloat(value, 10)
    if (!valNum && valNum != '') {
        // callback(new Error('必须是数字'))
        callback()
        return
    }
    var errorMsg = null;
    if (rule.min != null && rule.max != null) {
        errorMsg = "输入范围：" + rule.min + " - " + rule.max;
    }
    if (rule.min != null && valNum < rule.min) {
        if (errorMsg == null) {
            errorMsg = '最小值' + rule.min
        }
        callback(new Error(errorMsg))
    } else if (rule.max != null && valNum > rule.max) {
        if (errorMsg == null) {
            errorMsg = '最大值' + rule.max
        }
        callback(new Error(errorMsg))
        // callback(new Error('输入范围${rule.min} - ${rule.max}'));
    } else {
        callback()
    }
//   if (!/^-?\d+$/.test(value)) {
//     callback(new Error('必须是数字'));
//   } else {
//     callback();
//   }
}
export default {
    validateNumberScope: validateNumberScope2
    , vNumber_test: {type: 'number', message: '必须是整数', trigger: 'blur'}
    , vRequired: {required: true, message: '*必填', trigger: 'change'}
    , vRequired$: {required: true, message: '*', trigger: 'change'}
    , vRequiredBlur: {required: true, message: '*必填', trigger: 'blur'}
    , vRequiredChange: {required: true, message: '*必填', trigger: 'change'}
    , vUsername: {min: 2, max: 16, validator: validateUsername, trigger: 'blur'}
    , vPassword: {validator: validatePassword, trigger: 'blur'}
    , vFload: {validator: validateFload, trigger: 'blur'}
    , vPlusFload: {validator: validatePlusFload, trigger: 'blur'}
    , vNumber: {validator: validateNumber, trigger: 'blur'}
    , vJzfmTime: {validator: validateJzfmTime, trigger: 'blur'}
    , vPlusNumber: {validator: validatePlusNumber, trigger: 'blur'}
    , vPhone: {validator: validatePlusPhone, trigger: 'blur'}
    , vTxlPhone: {validator: validatePlusTxlPhone, trigger: 'blur'}
    , vIdCard: {validator: validatePlusIdCard, trigger: 'change'}
    , vUpload: {validator: validateUpload, trigger: 'change'},
    validateMap: {
        'vPhone': {name: '手机号码验证', validate: {validator: validatePlusPhone, trigger: 'change'}},
        // "vTextPhone":{name: "手机号码和电话号码验证", validate: {validator: validatePlusTxlPhone, trigger: "change"}},
        'vIdCard': {name: '身份证号码验证', validate: {validator: validatePlusIdCard, trigger: 'change'}},
        'vJzfmTime': {name: '预计浇筑或封膜时间验证', validate: {validator: validateJzfmTime, trigger: 'change'}},
    }, rulesMap: {
        vRequired: {name: "必填"},
        vPlusNumber: {name: "整数"},
        vNumber: {name: "数字"},
        vPhone: {name: "手机"},
        vTextPhone: {name: "手机或座机"},
        vIdCard: {name: "身份证"},
        vPassword: {name: "密码规则"},
    },

}
var trule = function (el, binding, vnode) {
    /** binding指的是一个对象，一般不用 **/
    /** vnode 是 Vue 编译生成的虚拟节点 **/
    /** el可以获取当前dom节点，并且进行编译，也可以操作事件 **/
        // el.style.border = "1px solid red";  //操作style所有样式
    var rule = binding.value
    if (rule == null) {
        rule = 'required'
        return
    }
    var ruleList = rule.split(',')
    var value = null
    var table = el.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
    if (vnode.data.model != null) {
        value = vnode.data.model.value
    }
    el = el.parentElement
    var error = null
    table.className = table.className.replace(/ tiError/g, '')
    table.parentElement.className = table.parentElement.className.replace(/ tiError/g, '')
    el.className = el.className.replace(/ tiError/g, '')
    el.attributes.errorMsg = null
    ruleList.forEach(r => {
        if (error == null && r == 'required') {
            error = required(null, value)
        }
        if (error == null && r == 'validatePlusPhone') {
            error = validatePlusPhone(null, value)
        }
        if (error == null && r == 'min0') {
            // error = validatePlusPhone(null, value)
        }
        if (error != null) {
            table.className = table.className + ' tiError'
            table.parentElement.className = table.parentElement.className + ' tiError'
            el.className = el.className + ' tiError'
            el.title = error
        }
    })
}
// Vue.directive('trule', {
//     vue: this,
//     // 注意： 在每个 函数中，第一个参数，永远是 el ，表示 被绑定了指令的那个元素，这个 el 参数，是一个原生的JS对象
//     inserted: function (el, binding, vnode) {
//         trule(el, binding, vnode)
//     },
//     update: function (el, binding, vnode) {
//         trule(el, binding, vnode)
//
//     },
// })
// Vue.directive('tablewidth', {
//     //更新tablewidt调整样式正常
//     componentUpdated: function (el, binding, vnode) {
//         el.style.width = 'auto'  //操作style所有样式
//         el.style.width = binding.value  //操作style所有样式
//     },
// })

