<!--npm install -S file-saver xlsx（这里其实安装了2个依赖）-->
<!--npm install -D script-loader-->
<style>
.etUploadExcelReadTable .el-upload--text {
  width: auto;
  height: auto;
  border: 0;
}

.uertSpan {
  color: #cccccc;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.etUploadExcelReadTable .el-upload-list {
  display: none;
}

.webAdmin .el-table th .el-select.excel_title_select .el-input--suffix .el-input__inner {
  font-weight: bold;
  /*color: red !important;*/
  /*background-color: red;*/
}

.webAdmin .el-table th .el-select.excel_title_select .el-input--suffix .el-input__inner::-webkit-input-placeholder {
  color: #ebe9e9;
  font-weight: normal;
  /*color: red !important;*/
  /*background-color: red;*/
}
</style>
<template>
  <span>
    <el-button :size="btnSize" type="primary" :loading="loading" icon="iconfont-daoru" @click="show=true"
               style="margin-left: 10px">
              {{ btnName }}
            </el-button>
  <el-dialog class="etUploadExcelReadTable" :title="title" width="1200px"
             :top="top" append-to-body :close-on-click-modal="false"
             @close="closeDialog"
             :visible="show">
    <slot></slot>
    <div v-loading="loading">
      <el-upload style="float: left;margin-right: 20px"
                 action=""
                 :on-change="handleChange"
                 :on-remove="handleRemove"
                 :file-list="fileListUpload"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                 :auto-upload="false">
        <etBtnAdd type="primary" icon="iconfont-shangchuan3">上传Excel文件</etBtnAdd>

      </el-upload>
      <etBtnAdd @click="submit({dataFormat:false})" type="success" :loading="loading"
                v-if="importBtn" icon="iconfont-daoru">开始导入</etBtnAdd>
      <etBtnAdd v-if="dataFormatShow" @click="submit({dataFormat:true})" type="success"
                :loading="loading" icon="iconfont-daoru">数据格式化</etBtnAdd>
      <span style="color: red;line-height: 24px;" v-if="importBtn">{{ importTip }}</span>
    </div>
    <el-table v-loading="loading" height="350"  :data="outJson"
              :class="colType=='input'?'tableInput':''"
              style="margin-top: 10px" border>

      <el-table-column label="" width="60" fixed="left" align="center">
        <template slot-scope="o">
          <etBtnDelMini @click="del(o.row)"/>
        </template>
      </el-table-column>
      <el-table-column type="index" label="#" class="index" fixed="left" align="center" width="40"/>
      <el-table-column v-for="(head,k) in headList" :key="k" align="center" width="120"
                       :show-overflow-tooltip="!isNullStr(selectFiled[head])" show-overflow-tooltip>
        <template slot="header" slot-scope="scope">
          <el-select v-model="selectFiled[head]" class="excel_title_select" style="color: #409EFF" placeholder="请选择"
                     clearable>
            <el-option v-for="(item,key) in  filedMap" :key="key"
                       :label="filedKey==null?item:item[filedKey]" :value="key"
                       :disabled="mapValToKey(selectFiled)[key]!=null||key.indexOf('$read')>-1"
            >
            </el-option>
          </el-select>
        </template>
        <template slot-scope="o">
          <div v-if="colType=='input'">
             <el-input v-if="isNullStr(selectFiled[head])" maxlength="200" style="width: 100%;" :controls="false"
                       size="mini"
                       v-model="o.row[head]"/>
          <span v-else class="uertSpan">{{ o.row[head] }}</span>
          </div>
          <div v-else>{{ o.row[head] }}</div>

        </template>
      </el-table-column>
    </el-table>

     <el-pagination @current-change="current"
                    :total="dataList.length"
                    :page-size="pageSize"
                    layout="slot,prev, pager, next">
<span v-if="dataList!=null" style="font-weight: bold">{{ dataList.length }}行</span>
          </el-pagination>

  </el-dialog>
     </span>
</template>
<script>

export default {
  name: "etUploadExcelReadTable",
  data() {
    return {
      fileTemp: null,
      pageIndex: 1,
      show: false,
      loading: false,
      fileListUpload: [],
      dataList: [],
      outJson: [],
      outFiledList: [],
      outJsonFiled: [],
      outFiledLength: 0,
      selectFiled: {},
      headList: {},
      price: {
        txt: "张飞"
      },
      top: '10vh'
    };
  },
  props: {
    filedKey: {default: null},
    pageSize: {default: 10},
    colType: {default: "text"},
    dataFormatShow: {default: false},
    importBtn: {default: true},
    disabled: {default: false},
    btnSize: {default: "small"},
    btnName: {default: "数据导入"},
    importTip: {default: "数据导入从第1行开始。表头识别完后，删除第1行"},
    title: {default: "数据导入"},
    filedMap: {
      default: () => {
        return {};
      }
    },
    filedValueDefaultMap: {
      default: () => {
        return {};
      }
    },
  },
  computed: {},
  components: {
    /*加载组件*/
  },
  methods: {
    del(row) {
      this.current(this.pageIndex)
      this.delArrItem(this.outJson, row);
      this.delArrItem(this.dataList, row);
    },
    open() {
      this.$set(this, "show", true);
    },
    closeDialog() {
      this.$set(this, "show", false);
      Object.assign(this.$data, this.$options.data());
    },
    getDataList() {
      var selectFiled = this.mapValToKey(this.selectFiled);
      var list = []
      this.dataList.forEach(o => {
        var map = {}
        for (var key in selectFiled) {
          map[key] = o[selectFiled[key]];
        }
        for (var key in this.filedValueDefaultMap) {
          if (!this.isNullStr(map[key])) {
            map[key] = this.filedValueDefaultMap[key];
          }
        }
        list.push(map);
      });
      return list;
    },
    submit({dataFormat = false}) {
      var $this = this;
      if (this.outJson.length == 0) {
        this.showMsgError("请上传Excel");
        return;
      }
      if (this.mapLength(this.selectFiled) == 0) {
        this.showMsgError("请选择对应数据列");
        return;
      }
      if (dataFormat) {
        this.$set(this, "loading", true);
        this.$emit('dataFormat', this.getDataList(), function (list) {

          // console.log("dataFormat-fn", list)
// $this.current(1)
          // $this.$set($this, "headList", $this.mapKeyToList(list[0]));
          $this.$set($this, "selectFiled", {});
          $this.importfxx({
            dataList: list,
            headList: $this.mapKeyToList(list[0])
          })
          setTimeout(()=>{
            $this.importfxx({
              dataList: list,
              headList: $this.mapKeyToList(list[0])
            })
            $this.$set($this, "loading", false);
          },100)

        }, function () {
          $this.$set($this, "loading", false);
        });
      } else {
        this.$confirm("确定批量导入当前数据吗？", '批量导入提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning",
          center: true,
          confirmButtonClass: "confirmBtnOk"
        }).then((e) => {
          this.$set(this, "loading", true);
          this.$emit('uploadExcelJson', this.getDataList(), function () {
            $this.$set($this, "show", false);
            $this.$emit('refresh');
            $this.$set($this, "loading", false);
          }, function () {
            $this.$emit('refresh');
            $this.$set($this, "loading", false);
          });

//          this.loading=false
        });
      }
    },
    // excel表上传
    handleChange(file, fileList) {
      this.fileTemp = file.raw
      // 判断上传文件格式
      if (this.fileTemp) {
        if ((this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (this.fileTemp.type == 'application/vnd.ms-excel')) {
//            this.importfxx(this.fileTemp)
          this.axiosUploadFile('/sysFile/uploadFile.do?readExcel=true', this.fileTemp, function (resData) {
            this.importfxx(resData.excelObj)
            // this.axiosPost("/sysFile/readExcelJson.do", {file: resData.obj.file_path}, function (resData2) {
            //   this.$set(this, "loading", true);
            //   this.importfxx(resData2.obj)
            //   this.importfxx(resData.excelObj)
            //   ;this.$set(this, "loading", false)
            // });
          })
        } else {
          this.$message({
            type: 'warning',
            message: '附件格式错误，请删除后重新上传！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请上传附件！'
        })
      }
    },
    // 移除excel表
    handleRemove(file, fileList) {
      this.fileTemp = null
    },
    current(i) {
      this.$set(this, "pageIndex", i)
      this.$set(this, "outJson", this.dataList.slice((i - 1) * this.pageSize, i * this.pageSize));
    },
    importfxx(obj) {
      // console.log(obj)
      let $this = this;
      $this.$set($this, "selectFiled", {});
      var dataList = obj.dataList;
      var headList = obj.headList;
      for (var k in this.filedMap) {
        if (k.indexOf("$read") > -1 && headList.indexOf(k) == -1) {
          headList.unshift(k)
          if (dataList.length > 0)
            dataList[0][k] = this.filedMap[k];
        }
      }
      this.$set(this, "headList", headList);
      var fmap = {};
      headList.forEach(h => {
        fmap[h] = dataList[0][h];
      })
      $this.$set($this, "dataList", dataList);
      $this.$set($this, "outFiledLength", headList.length);
      $this.$set($this, "outJsonFiled", this.listToMap(headList));
      $this.$set($this, "selectFiled", {});
      for (var k in fmap) {
        var v = (fmap[k] || '').trim();
        for (var fk in $this.filedMap) {
          var fv = ($this.filedMap[fk] || '').trim();
          k = (k || '').trim();
          fk = (fk || '').trim();
          var fvList = fv.split(",");
          if (fvList.indexOf(k) > -1 || k == fv || fvList.indexOf(v) > -1 || v == fv) {
            $this.$set($this.selectFiled, k, fk);
          }
        }
      }
      // console.log("selectFiled", $this.selectFiled)
      this.current(1);
    },
  }, watch: { //值改变动作 valObj(val){}
    selectFiled: {
      handler(newObj, oldObj) {
      }, deep: true
    }

  },
}
;
</script>
