<!--Breadcrumb 面包屑 -->
<template>
  <template v-for="c in design.wheres">
    <etSearchItem :keyName="c.keyName=c.key||c.col"
                  :label="c.label||(design.colMap&&design.colMap[c.col]?design.colMap[c.col].name:'')"
                  :class="{selected:design.selectWhere&&design.selectWhere.id==c.id}"
                  @contextmenu.native="whereRight($event,c)">
<!--      {{search[c.keyName]}}-->
      <template v-if="$slots[c.keyName]">
        <slot :name="c.keyName"></slot>
      </template>
      <template v-else>

        <el-input v-if="!c.moduleType||c.moduleType=='input'" :placeholder="c.placeholder" size="small"
                  :style="{width:c.width||'160px'}"
                  v-model="search[c.keyName]"
                  @keyup.enter.native="change(c)" @change="change(c)" clearable/>
        <template v-else-if="c.moduleType">
          <el-input-number v-if="c.moduleType=='inputNumber'" :controls="false" :precision="c.precision"
                           :style="{width:c.width||'160px'}"
                           v-model="search[c.keyName]"/>
          <template v-else-if="c.moduleType.indexOf('_dict')>0||design.colMap[c.col]&&design.colMap[c.col].dict_code">
            <span v-if="!(c.dict_code||(design.colMap[c.col]&&design.colMap[c.col].dict_code))" style="color:red;">
              先选择字典编码
            </span>
            <etSysDict v-else :sysMap="sysMap"
                       :dict_code="c.dict_code||design.colMap[c.col].dict_code"
                       :placeholder="c.placeholder" :radioTitle="c.radioTitle" v-model="search[c.keyName]"
                       @change="change(c)" :style="{width:c.width||'160px'}"
                       :radio="dictTypeMap[c.moduleType].radio"
                       :tree="dictTypeMap[c.moduleType].tree"
                       :select="dictTypeMap[c.moduleType].select"
                       :multiple="dictTypeMap[c.moduleType].multiple"
            />
          </template>
          <el-date-picker v-else-if="c.moduleType.indexOf('date')>-1" :placeholder="c.placeholder"
                          v-model="search[c.keyName]"
                          :type="c.moduleType" :format="c.timeFormat||timeFormatMap[c.moduleType]"
                          :value-format="c.timeFormat||timeFormatMap[c.moduleType]" @change="change(c)" clearable
                          :style="{width:c.width||'160px'}"/>
        </template>


      </template>
    </etSearchItem>

  </template>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etDesignTableSearch",
  components: {},
  data() {

    return {

    };
  },
  props: {
    modelValue: null,
    design: {},
    sysMap: {},

  },
  setup(props, {emit}) {
    const search = ref(props.modelValue); // 初始化数据
    watch(
        () => search.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      search: search,
    }
  },
  methods: {
    change(c) {
      if (c.changeFind)
        this.findPage()
    },
    findPage() {
      this.$emit('findPage', this.search)
    },
    whereRight($event, c) {
      this.$emit('whereRight', $event, c)
    },
  }, mounted: function () {
    console.log("$slots", this.$slots)
  }, watch: { //值改变动作 valObj(val){}
  }
}
</script>