<template>
  <!--<span>{{value}}</span>-->
  <el-radio-group v-model="value2" class="etStatusSearch" :size="size" @change="change">
    <el-radio-button v-if="title">{{title}}</el-radio-button>
    <el-radio-button v-for="(o,k) in list" :label="o.val" :key="k"
                     :style="{'background-color': o.color, 'border-color': o.color}">{{o.name}}
    </el-radio-button>
  </el-radio-group>
</template>
<script>
  export default {
    name: 'etStatusSearch',
    props: {
      value: {},
      title: {default: "全部"},
      list: {type: Array, default: () => [{val: 1, name: '正常'}, {val: 0, name: '停用'}]},
      size: {default: "small"},
//      value: {},

    },
    data() {
      return {
        value2: this.value
      }
    }, methods: {
      change() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }
    }, watch: {
      value() {
        this.value2 = this.value;
      }
    }
  }
</script>

