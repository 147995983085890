<script>
  import elButton from './elButtonTemp.vue'

  export default {
    name: "etBtnBatchImport",
    extends: elButton,
    props: {
      icon: {default: "small iconfont-wa_add2"},
      type: {default: "primary"},
      title: {default: "新增"},
    }
  }
</script>
