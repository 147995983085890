<template>
  <div class="etSearch" >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "etSearch",
  data() {
    return {
      type: "etSearch",
    }
  }, props: {
    design: {},

    zf: {default: false},
  }, methods: {},
  mounted() {/*页面加载初始化*/
    // console.log("this.$parent",this.$parent)
    // console.log("this",this)
    // console.log("this.data$",this.data$)
  }
}
</script>
