<template>

  <el-table-column v-if="isSaasAdmin() " prop="sysTenantCode" label="租户" align="left" width="160" sortable>
    <template slot-scope="o">
      <sysTenantTemp   :value="value2" showName/>
    </template>
  </el-table-column>
</template>
<script>

  export default {
    name: "sysTenantTCol",
    data() {
      return {
        value2: this.value,
      }
    },
    props: {

      label: {default: "租户"},
      value: {},
      sysMap: {
        default: () => {
        }
      },
    },
    created() {
    },
    methods: {

      change() {

      }
    }, mounted() {

    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, components: {}
  }
</script>
