import etBtnCancel from './etBtnCancel.vue'
import selectSearchTemp from './selectSearchTemp.vue'
import vueVideoPlayer from "./vueVideoPlayer.vue";
import etSysTypeSelect from "./etSysTypeSelect.vue";
import alarmIcon from "./alarmIcon.vue";
import statusIcon01 from "./statusIcon01.vue";
import simpleRow from "./simpleRow"
import ydFromTemp from "./ydFromTemp"
import ydFromItem from "./ydFromItem.vue"
import nxUserShow from "./nxUserShow.vue"
import firmManagerShow from "./firmManagerShow.vue"
import staffInfoShow from "./staffInfoShow.vue"
import nxAreaSelect from "./nxAreaSelect.vue"
import firm_temp from "./firm_temp.vue"
import orderServeTmp from "@/views/_temp/orderServeTmp";
import orderServeItem from "@/views/order/orderServeItem";
import goodsServerItemTmp from "@/views/_temp/goodsServerItemTmp";


export default {
    "firm_temp": firm_temp,
    "nxAreaSelect": nxAreaSelect,
    "nxUserShow": nxUserShow,
    "firmManagerShow": firmManagerShow,
    "staffInfoShow": staffInfoShow,
    "ydFromItem": ydFromItem,
    "ydFromTemp": ydFromTemp,
    "statusIcon01": statusIcon01,
    "alarmIcon": alarmIcon,
    "etBtnCancel": etBtnCancel,
    "selectSearchTemp": selectSearchTemp,
    "vueVideoPlayer": vueVideoPlayer,
    "etSysTypeSelect": etSysTypeSelect,
    "simpleRow": simpleRow,
    "orderServeTmp": orderServeTmp,
    "orderServeItem": orderServeItem,
    "goodsServerItemTmp": goodsServerItemTmp,
}
