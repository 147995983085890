/**
 *   分页页面
 */
const page = {
    data() {
        return {
            scrollMap$: {},
            fullPath$: "",
            sysMap: {},
            showNameMap: {},
            sysTypeAll: [],
            sysTypeMap: {},
            sysUserList: [],
            userAllMap: {},
            findPageUrl: null,//重定向分页查询接口
            funType: 'page',//tree为树形结构
            tHeight: 130,
            dHeight: 650,
            tHeight2: null,
            title: '功能标题',
            treeList: [{children: []}],
            author: {},//权限
            loading: false,//加载效果
            baseUrl: null,//默认访问地址,必须继承修改,如【/sys_User】
            findObjDialog: '继承修改',//查询单个对象组件名，如【sysUserFind】
            page: {pageNumber: 1, pageSize: 20},//分页数据集，包含页码、页大小
            sort: {prop: 'createTime', order: 'descending'},//排序数据集
            search: {},//查询参数
            selectionVals: [],//table选项值
        }
    }, props: {//扩展，重写
        doUrl: {default: false},
    },
    methods: {
        getSummaries(param) {
            var $this = this;
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                if ($this.page && $this.page.result && $this.page.result.total) {
                    if ($this.page.result.total[column.property]) {
                        var val = $this.page.result.total[column.property];
                        if (typeof val == 'number') {
                            val = this.numFmt(val)
                        }
                        sums[index] = val;
                    } else {
                        sums[index] = "";
                    }
                }
            });
            return sums;
        },
        uploadExcelJson(jsonList, fn, erFn) {

            this.axiosBatchImport({list: jsonList}, fn, erFn)

        },
        setVal(key,val) {
            this.baseSetVal(key,val)
        },
        sortChange(sort) { /*排序变化操作*/
            this.psortChange(sort)
        },
        psortChange(sort) { /*排序变化操作*/
            this.$set(this, 'sort', sort)
            this.findPage('search')
        },
        updateCell(param, callbackFn, showResultMsg, cancelCallback) {//隐藏数据栏
            this.axiosUpdateCell(param, callbackFn, null, null, cancelCallback);
        },

        findPage(type) {
            this.pfindPage(type)
        },
        pfindPage(type, callbackFn, showResultMsg) {/*查询分页*/
            if (type == 'search') {//判断是否查询
                this.page.pageNumber = 1;
            }
            var reqData = this.copyObj(this.search)
            if (this.config && this.config.searchMap) {
                for (var k in reqData) {
                    if (this.config.searchMap[k] == false) {
                        // reqData[k]=null
                        delete reqData[k];
                    }
                }
            }
            if (this.isScrollTable == true) {
                if (this.loading == true || this.page.pageNumber == -1) {
                    return
                }
                // this.search.isCount$ = false
                if (type == 'search') {
                    this.$set(this, 'pageList', [])
                } else {
                    this.page.pageNumber = this.page.pageNumber + 1
                }
            }

            if (this.sort) {
                reqData.prop = this.sort.prop
                reqData.order = this.sort.order
            }
            if (this.page) {
                reqData.pageNumber = this.page.pageNumber
                reqData.pageSize = this.page.pageSize
            }
            if (this.config && this.config.searchList) {
                reqData.searchList = this.config.searchList;
            }
            // alert(this)

            if (this.funType == 'tree') {
                this.axiosPost(this.baseUrl + '/findTree.action', reqData, (resData) => {
                    if (showResultMsg == true) {
                        this.$message({message: resData.$message, type: 'success'})
                    }
                    this.$set(this, 'treeList', resData.treeList)
                    this.runCallbackFn(callbackFn, resData)
                    this.loading = false
                    this.initTableHeight();
                    this.jsHeight();
                })
            } else {
                var page = this.copyObj(this.page);
                this.axiosPage(reqData, function (resData) {
                    if (this.isScrollTable == true) {
                        if (resData.page.totalRow >= 0) {
                            // this.totalRow = this.page.totalRow
                        } else {

                            // this.page.totalRow = page.totalRow || this.totalRow
                        }
                        if (this.page.list.length != this.page.pageSize) {
                            this.page.pageNumber = -1;
                        }
                        this.page.list.forEach(o => {
                            this.pageList.push(o)
                        })
                    } else {
                        this.page=resData.page;

                        // if (resData.page.totalRow >= 0) {
                        //     this.$set(this.page, "totalRow", page.totalRow);
                        // } else {
                        //     this.$set(this.page, "totalRow", page.totalRow);
                        // }
                    }
                    // this.loading = false
                    this.loading=false
                    this.runCallbackFn(callbackFn, resData)
                    this.initTableHeight();
                    this.jsHeight();
                }, showResultMsg)
            }
        },
        pexportPage(callbackFn) {/*导出数据*/
            var reqData = this.search
            reqData.prop = this.sort.prop
            reqData.order = this.sort.order
            reqData.pageNumber = 1
            reqData.pageSize = 10000
            var url = this.baseUrl + '/exportXls.do'
            this.axiosPost(url, reqData, callbackFn)
        },
        refresh(type) {/*刷新*/
            this.findPage(type)
        },

        selectionChange(val) { /*多选操作*/
            this.pselectionChange(val)
        },
        pselectionChange(val) { /*多选操作*/
            this.$set(this, 'selectionVals', val)
        },
        del(ids) {/*删除数据*/
            this.pdel(ids)
        },
        pdel(ids, key, code) {/*删除数据*/
            if (ids == undefined) {
                ids = this.selectionVals
            }

            this.axiosDel(ids, key, code, function () {
                this.refresh()
            })
        },

        selectable(row, index) { /*多选框是否可操作判断*/
            return true
        },
        rowDblClick(row) {/*双击行编辑*/
            this.findObj(row.id)
        },
        findObj(id, title) { /*查询表单对象obj*/
            this.$refs[this.findObjDialog].findObj(id)
        },
        initTableHeight() {
            var $this = this;
            if (!$this.tHeight2)
                $this.tHeight2 = $this.tHeight
            if ($this.$refs.etSearch && $this.$refs.etSearch.$el.clientHeight > 0) {
                $this.$set($this, "tHeight", $this.tHeight2 + $this.$refs.etSearch.$el.clientHeight);
                // console.log("tHeight", $this.tHeight)
            }
        }
    }, watch: { //值改变动作 valObj(val){}
    }, mounted() {/*页面加载初始化*/
        this.axiosAuthor();

        this.scrollMonitor("table");
        var $this = this;
        setTimeout(() => {
            this.initTableHeight();
            this.jsHeight();
        }, 10)
    }, created() {
        //页面刚进入时
    },
    destroyed: function () {
        //页面销毁时
    }, beforeRouteLeave(to, from, next) {
        //Vue离开
        next()
    },
    activated() {
        //初始化及激活时
        this.initScrollMonitor("table");
    }
}

/**
 *  查询单个对象-对话框
 */
const findObjDialog = {
    data() {
        return {
            tHeight: 170,
            top: "50px",
            tableName: '',
            formConfigItemMap: null,
            formItemRead: false,
            sysMap: {},
            useVMap: {},
            dataMap: {},
            showNameMap: {},
            copy$: false,
            sysUserList: [],
            sysUserMap: [],
            selectMap: {},
            selectList: [],
            userAllMap: {},
            sysTypeAll: [],
            sysTypeMap: {},
            pidTree: [],//上级类别
            author: {},//权限
            dialogVisible: false,//是否显示对话框
            loading: false,//是否显示加载效果
            baseUrl: null,//默认访问地址
            obj: {},//表单值
            oldObj$: {},//表单值
            rules: {},//表单校验
            // login_id: localStorage.getItem('login_id'),//当前登录用户ID
            showResultMsg: true,//新增修改成功后是否提示
        }
    },
    props: {//扩展，重写
        title: String,
        doUrl: {default: false},
        xhtmlUrl: {default: false},
        fullPath: {default: ''},
    },
    methods: {
        setVal(key,val) {
            this.baseSetVal(key,val)
        },
        close() {//关闭对话框
            this.dialogVisible = false
        },
        refresh() {//刷新父组件
            this.$emit('refresh')
        },
        findObj(id) { /*初始化-查询表单对象obj*/
            this.dialogVisible = true
            this.axiosFind({id: id}, (resData) => {
                //重写init
            })
        },
        addEdit() {/*新增修改*/
            var obj = this.copyObj(this.obj)
            this.axiosAddEdit({obj: obj}, function () {
                this.close()
                this.refresh()
            })
        },
    }, watch: { //值改变动作 valObj(val){}
    }, mounted() {/*页面加载初始化*/
        this.axiosAuthor()
    }, created() {
        //页面刚进入时
    },
    destroyed: function () {
        //页面销毁时
    }, beforeRouteLeave(to, from, next) {
        //Vue离开
        next()
    },
    activated() {
        //初始化及激活时
    }
}
export default {
    page: page,
    findObjDialog: findObjDialog,
}

