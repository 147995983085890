<style lang="less">
.showTipDialog {
  margin-top:12px;
  float: left;
  margin-left: 10px;
  width:330px;
  border: 1px #dddddd solid;
  padding: 0 8px 0 8px;
  height: calc(100vh - 160px);
  overflow: auto;

  img {
    width: 100%;
    height: auto;
  }
}
</style>
<template>
  <div class="showTipDialog">
    <div style="font-weight: bold;margin-bottom: 8px;text-align: center">使用说明</div>
    <div v-if="showTipDialog">
      <div style="border:1px #ccc solid">
        <etRichTextBox col="content" :height=" '800px'" v-model:modelValue="remark"></etRichTextBox>
      </div>
      <div style="text-align: center;margin-top: 16px;">
        <el-button type="success" @click="saveRemark()">保存</el-button>
        <el-button @click="showTipDialog=false">关闭</el-button>
        <sysDataUpdateListDialog :tableName="remarkDesign.table_name"
                                 :design="remarkDesign" :objId="remark.id"></sysDataUpdateListDialog>
      </div>
    </div>
    <div v-else>
      <div class="remarkContent" v-html="remark.content"></div>
      <div v-if="!showTipDialog" style="margin-top: 16px;text-align: center">
        <el-button @click="findRemark(true)" type="success">编辑</el-button>
      </div>
    </div>
  </div>
  <!--    <template #footer>-->
  <!--      <div v-if="!showTipDialog" style="margin-top: 16px;text-align: center">-->
  <!--        <el-button @click="findRemark(true)" type="success">编辑</el-button>-->
  <!--      </div>-->
  <!--    </template>-->
</template>
<script>
import {ref, watch} from 'vue'
import sysUseRemark from '../../views/sys/sysUseRemark.js';

export default {
  name: "showTipDialog",
  components: {},
  data() {
    return {
      remark: {},
      remarkDesign: sysUseRemark.design,
      showTipDialog: false,

    };
  },
  props: {},
  setup(props, {emit}) {
    const design = ref(props.modelValue); // 初始化数据
    watch(
        () => design.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      design: design,
    }
  },
  methods: {
    findRemark(show) {
      this.axiosPost("/sysUseRemark/findByUrl.do", {url: this.$route.path}, res => {
        this.remark = res.obj
        if (show) {
          this.showTipDialog = true
        }
      })
    },
    saveRemark() {
      this.axiosPost("/sysUseRemark/save.do", {obj: this.remark}, res => {
        this.showMsgSuccess("保存成功")
        this.showTipDialog = false
      })
    },
  }, mounted: function () {
    this.findRemark(false)
  }, watch: { //值改变动作 valObj(val){}

  }
}
</script>

