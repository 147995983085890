import etDesign from './etDesign.vue'
import etDesignBtn from './etDesignBtn.vue'
import etDesignTableSearch from './etDesignTableSearch.vue'
import etDesignColVal from './etDesignColVal.vue'
import etDesignFormItem from './etDesignFormItem.vue'
import etDesignCol from './etDesignTable.vue'
import etDesignTable from './etDesignTable.vue'
import etDesignSearch from './etDesignSearch.vue'
import etDesignIndexPage from './etDesignIndexPage.vue'
import etDesignIndex from './etDesignIndex.vue'
import etDesignInput from './etDesignInput.vue'
import etDesignApiShow from './etDesignApiShow.vue'
import selectApiTemp from './selectApiTemp.vue'
import showTipDialog from './showTipDialog.vue'


const defaultJson = {


    "showTipDialog": showTipDialog,
    "selectApiTemp": selectApiTemp,
    "etDesignApiShow": etDesignApiShow,
    "etDesignInput": etDesignInput,
    "etDesignIndex": etDesignIndex,
    "etDesignIndexPage": etDesignIndexPage,
    "etDesignSearch": etDesignSearch,
    "etDesignTable": etDesignTable,
    "etDesignCol": etDesignCol,
    "etDesignFormItem": etDesignFormItem,
    "etDesignColVal": etDesignColVal,
    "etDesign": etDesign,
    "etDesignBtn": etDesignBtn,
    "etDesignTableSearch": etDesignTableSearch,

}
export default defaultJson
