<script>
  import etBtn from './etBtn.vue'

  export default {
    name: "etBtnMini",
    extends: etBtn,
    props: {
      size: {default: "mini"},
      fontColor: {default: ""},
      icon: {default: "small"},
      type: {default: "primary"},
      title: {default: "默认"},
      disabled: Boolean,

    }
  }
</script>
