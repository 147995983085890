<style lang="less">

</style>
<template>
  <div style="display: contents;width:100%">
    <el-cascader :change-on-select="true" :options="nxAreaTree" expand-trigger="hover" filterable
                 :disabled="disabled"
                 :separator="separator" style="width: 100%;" v-model:modelValue="value2"
                 @change="handleChange"
                 :props="{checkStrictly:checkStrictly,multiple:multiple,emitPath:emitPath}"/>
  </div>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "nxAreaSelect",
  data() {
    return {
      value2: [],
      nxAreaTree: [],
    }
  },
  props: {
    modelValue: null,
    is_sales: {type: Number, default: 1},
    status: {type: Number, default: 1},
    label: null,
    topPid: {type: Number, default: 1},
    showPid: {type: Boolean, default: false},
    checkStrictly: {type: Boolean, default: false},
    emitPath: {type: Boolean, default: false},//在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值
    separator: {type: String, default: '>'},
    currentVal: {type: Boolean, default: false},
    level: {type: Number, default: 3},
    disabled: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
  },
  created() {

  },
  methods: {
    handleChange(val) {
      this.$emit("handleChange", val);
    }
  },
  setup(props, {attrs, emit}) {
    const value = ref(props.modelValue); // 初始化数据
    const currentVal = ref(props.currentVal).value
    watch(
        () => value.value,
        (data) => {
          if (data && currentVal && data.length > 0) {
            data = data[data.length - 1]
          }
          emit('update:modelValue', data)
          emit('change', data)
        }
    ) // 监听输入值变动
    return {
      value2: value,
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue != this.value2) {
        this.$set(this, "value2", this.modelValue);
      }
    },
    value2(val) {
      let pa = val
      if (this.currentVal && val) {
        pa = val[0]
      }
      // this.$emit('change', this.value2);
    },
  },
  components: {},
  mounted: function () {/*页面加载初始化*/
    // alert(this.label)
    this.getAreaTree({
      status: this.status,
      is_sales: this.is_sales,
      dataKey: "nxAreaTree",
      pid: this.topPid || 1,
      showPid: this.showPid,
      level: this.level
    });
  }
}
</script>
