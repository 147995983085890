<template>
<!--  <el-tooltip :effect="effect" :content="value" :placement="placement">-->
    <div class="singleRow" :title="value">
      <slot></slot>
    </div>
<!--  </el-tooltip>-->
</template>

<script>
  export default {
    name: "simpleRow",
    props: {
      value: {default: ''},
      placement: {default: 'bottom-start'},
      effect: {default: 'dark'},
    }
  }
</script>

<style scoped>
  .singleRow {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
