<script>
  import etBtn from './etBtn.vue'

  export default {
    name: "etBtnRefresh",
    extends: etBtn,
    props: {
      icon: {default: "small iconfont-wa_refresh2"},
      type: {default: "success"},
      title: {default: "刷新"},
      disabled: Boolean,
      loading: Boolean,
    }
  }
</script>
