<style lang="less"></style>
<template>

  <div v-if="value&&tableFieldList&&tableFieldList.length>0">
    <!--    {{ slotsList }}-->
    <!--    {{ slotsValList }}-->
    <el-table ref="table" :height="getHeight(value.tHeight)" :data="value.page.list" border stripe
              @selection-change="selectionChange" @row-dblclick="rowDblClick"
              @sort-change="sortChange" :default-sort="value.sort"
              v-loading="value.loading" style="width: 100%">
      <el-table-column type="index" align="center" :width="indexWidth()"/>
      <el-table-column v-if="value.config.tableSelection" type="selection" align="center" width="44"></el-table-column>
      <template v-for="c in tableFieldList" v-if="c.show!=false">
        <template v-if="slotsList.indexOf(c.field)>-1">
          <slot :name="c.field" :c="c" :prop="c.field" :label="c.name" :align="c.align||'left'"
                :width="c.twidth||flatConfDef.twidth" :sortable="c.isSort==null?flatConfDef.isSort:c.isSort"
                :tooltip="c.tooltip==null?flatConfDef.tooltip:tooltip"/>

        </template>
        <template v-else>

          <!--            非自定义字段-->
          <el-table-column :prop="c.field" :label="c.name" :align="c.align||'left'"
                           :sortable="c.isSort==null?flatConfDef.isSort:c.isSort"
                           :width="c.twidth"
                           :show-overflow-tooltip="c.tooltip==null?flatConfDef.tooltip:tooltip">
            <template slot-scope="o">
              <slot :name="c.field+'$'" v-if="slotsValList.indexOf(c.field)>-1" :o="o" :row="o.row"/>

              <span v-else-if="c.type=='datetime'||c.type=='date'">
                  {{
                  o.row[c.field] | moment(c.type == 'datetime' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD')
                }}
                </span>
              <et-img-view v-else-if="c.type=='file'" :img-list="o.row[c.field]"></et-img-view>
              <etSysDict v-else-if="c.type=='sysDict'" :sysMap="value.sysMap" :dict_code="c.sysDictCode" text
                         :value="o.row[c.field]"/>
              <span v-else> {{ o.row[c.field] }}</span>
            </template>
          </el-table-column>
        </template>
      </template>
      <el-table-column label="操作" align="center" :fixed="fixed" :width="fun_width">
        <template slot="header" slot-scope="scope">
          <ydTableConf :config="value.config" :tableFieldList="tableFieldList" @recoverTable="recoverTable"
                       @setTableFieldList="setTableFieldList"
                       @initTable="initScrollMonitor('table',true)"/>
        </template>

        <template slot-scope="o">
          <slot name="fn$" v-if="slotsValList.indexOf('fn')>-1" :o="o" :row="o.row"/>
          <template v-else>
            <slot name="fun" :o="o" :row="o.row"></slot>
            <etBtnEditMini v-if="addEditStatus()&&showCopyBtn" @click="findObj(o.row.id,true)">复制</etBtnEditMini>
            <etBtnEditMini v-if="addEditStatus()" @click="findObj(o.row.id)"/>
            <etBtnEditMini v-else @click="findObj(o.row.id)">查看</etBtnEditMini>
            <etBtnDelMini v-if="delStatus()" @click="del(o.row.id)"/>
            <slot name="fun_right" :o="o" :row="o.row"></slot>
          </template>
        </template>

      </el-table-column>
    </el-table>
    <etPage :page="value.page" @refresh="refresh" @setVal="setVal"/>

  </div>
</template>
<script>
  import baseAdmin from '../../ydVue/baseAdmin'

  export default {
    name: "ydTable",
    data() {
      return {
        scrollMap$: {},
        tableFieldList: [],
        slotsList: [],
        slotsValList: [],
      }
    }, props: {
      value: {},
      fixed: {},
      showCopyBtn: {default: false},//是否显示复制按钮
      fun_width: {default: 120},//功能列的宽度
    }
    , methods: {
      selectionChange(val) { /*多选操作*/
        this.$emit('selectionChange', val)
      },
      rowDblClick(row) {/*双击行编辑*/
        this.$emit('rowDblClick', row)
      },
      findObj(id, row) {/*双击行编辑*/
        this.$emit('findObj', id, row)
      },
      del(id, row) {/*双击行编辑*/
        this.$emit('del', id, row)
      },
      sortChange(sort) { /*排序变化操作*/
        this.$emit('sortChange', sort)
      },
      refresh() {
        this.$emit('refresh')
      },
      recoverTable() {
        this.init(true)
      },
      setVal(key,val) {
        this.$emit("setVal", key,val)
      },
      setTableFieldList(tableFieldList) {
        this.$set(this, "tableFieldList", tableFieldList);
      },
      init: function (recoverTable) {
        var config = this.value.config
        var vueConfigMap = this.getSession("vueConfigMap");
        if (!vueConfigMap) {
          vueConfigMap = {};
        }
        var vueConfig = vueConfigMap[config.vueKey];
        var tableFieldListVueMap = {}
        if (vueConfig && vueConfig.config && vueConfig.config.tableFieldList) {
          tableFieldListVueMap = this.listJsonToMap(vueConfig.config.tableFieldList, "field");
        }
        this.$set(this, "tableFieldList", []);
        var tableList = config.tableList;
        var fieldList = config.fieldList;
        var fieldMap = this.listJsonToMap(fieldList, "field");
        if (tableList && tableList.length > 0) {
          var tableFieldList = []
          var tableLists = tableList.split(",");
          tableLists.forEach((k, ki) => {
            var o = fieldMap[k];
            if (o) {
              o.$i$ = ki;
              var ov = tableFieldListVueMap[k];
              if (recoverTable)
                if (ov != null && !recoverTable) {
                  o.$i$ = ov.$i$;
                  o.show = ov.show;
                }
              tableFieldList.push(o);
            }
          })
          this.$set(this, "tableFieldList", this.bubblingSort(tableFieldList, "$i$"));

        }
        // if (vueConfig && vueConfig.config) {
        //   var configV= vueConfig.config;
        //   var tableFieldListV = this.listJsonToMap(configV.tableFieldList, "field");
        //   var newFieldListTable2 = this.copyObj(this.fieldListCompa(this.tableFieldList, tableFieldListV))
        //   console.log(newFieldListTable2)
        // }
        var scopedSlots = this.mapKeyToList(this.$scopedSlots);
        var slotsList = []
        var slotsValList = []
        scopedSlots.forEach(k => {
          if (k.indexOf("$") > -1) {
            slotsValList.push(k.replace("$", ""))
          } else {
            slotsList.push(k)
          }
        })
        this.$set(this, "slotsValList", slotsValList);
        this.$set(this, "slotsList", slotsList);
      }
    }
    , watch: {
      value() {
      },
      /*值改变动作 valObj(val){}*/
      "value.config"() {
      }
    }, components: {}
    , mounted() {/*页面加载初始化*/
      var si = setInterval(() => {
        if (this.value.config.fieldList) {
          clearInterval(si)
          this.init();

        }
      }, 10)

    }
  }
</script>

