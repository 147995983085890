<style lang="less">
.showTableFieldTempDraggable {
  .el-button {
    font-size: 14px;
    min-width: 80px;
    margin: 0 12px 6px 0;
    padding: 9px 0;
    /*display: block;*/
  }
}
</style>
<template>
  <el-popover placement="bottom-start" :width="width" trigger="click" v-model="showSelectField" @show="show()" @hide="hide()">
    <draggable
        v-model="fieldListTemp"
        group="people"
        @start="drag=true"
        @end="drag=false"
        item-key="id">
      <template #item="{element}">
        <div>{{element.name}}</div>
      </template>
    </draggable>
    <template #reference>
      <el-button   type="success"  >{{ btnName }}</el-button>
    </template>
<!--    <span v-if="icon" slot="reference" style="cursor: pointer;">-->
<!--        <span style="margin-right: 5px;" v-if="txt">{{ txt }}</span>-->
<!--      <i :class="icon" style="color: #ffffff;"></i>-->
<!--       </span>-->
<!--    <el-button v-else type="success" slot="reference">{{ btnName }}</el-button>-->
    <div v-if="showSelectField">
      <div class="tip" v-if="showSelectField">
        请选择您需要{{ tipMap[type] }}的信息，拖拽进行排列。
      </div>
      <div style="text-align: center">
        <el-button style="margin-left:20px;" type="primary" @click="confirm()">{{ confirmName }}</el-button>
        <el-button style="margin-left:20px;" type="info" @click="confirm()">取消</el-button>
      </div>
    </div>
  </el-popover>
<!--    <span  >{{txt}}</span>-->
</template>
<style>
.tip {
  text-align: center;
  margin: 6px 0;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px #C3C3C3 solid;
  padding-bottom: 6px;
}

.el-button.sortable-chosen {
  color: red;
  background-color: #ffffffd9;
}
</style>
<script>
import draggable from 'vuedraggable'

export default {
  name: 'showTableFieldTemp',
  data() {
    return {
      tipMap: {'Table': '显示', 'Export': '导出'},
      fieldListTemp: [{people:'cn',id:10,name:'www.itxst.com'},
        {people:'cn',id:20,name:'www.baidu.com'},
        {people:'cn',id:30,name:'www.taobao.com'},
        {people:'us',id:40,name:'www.yahoo.com'}],
      showSelectField: true,
    }
  },
  props: {
    width: {default: 600},
//      icon: {default: "ifweb-shezhi2"},
//      icon: {default: "ifweb-setting"},
    icon: {default: "ifweb-shezhi1"},
    txt: {default: '操作'},
    confirmName: {default: '确认'},
    btnName: {default: '显示内容'},
    type: {default: 'Table'},
    disabled: {default: false},
    config: {},
    list: {},
//        value: {},
  },
  methods: {
    hide() {
//        if (this.type == 'Table') {
//          this.confirm()
//        }
    },
    show() {
      // this.$set(this, 'fieldListTemp', this.copyObj(this.config['fieldList' + this.type]));
    },
    confirm() {
      var $this = this;
      this.$set(this, "showSelectField", false);
//        this.showSelectField = false;
      var $this = this
      if ($this.type == 'Table' && this.jsonToStr($this.fieldListTemp) == this.jsonToStr($this.config['fieldList' + $this.type])) {
        return
      }
      this.$set(this.config, 'fieldList' + this.type, [])
      setTimeout(function () {
        var vv = $this.copyObj($this.fieldListTemp);
        $this.$set($this.config, 'fieldList' + $this.type, vv);
        $this.axiosPost("/sysVueConfig/addEdit.do", $this.config, function (res) {
          var vueConfigMap = this.getSession("vueConfigMap");
          if (!vueConfigMap) {
            vueConfigMap = {};
          }

          vueConfigMap[this.config.vueKey] = {config: this.config};
          this.setSession("vueConfigMap", vueConfigMap)
        })
      }, 1);
      if (this.type == 'Export') {
        if (this._events.exportFn) {
          this.$emit('exportFn')
        } else {
          this.exportLocal()
        }
      } else {
        setTimeout(function () {
          $this.$emit("initTable");//initScrollMonitor('table',true)
        }, 500)

      }


//          this.$emit('input', valueCopy);
//          this.$emit('click', valueCopy);

    },
    datadragEnd(evt) {
      var $this = this
      console.log("evt",evt)
      evt.preventDefault()
    },
    upField(c, ci) {
      c.show = !(c.show != false)
      this.$set(this.fieldListTemp, ci, c)
    },
    exportLocal() {
      var headMap = {}
      this.fieldListTemp.forEach(o => {
        if (o.show != false) {
          headMap[o.field] = o.name
        }
        if (o.exportField) {
          delete headMap[o.field];
          headMap[o.exportField] = o.name
        }
      })

      this.exportExcel(this.confirmName + '_' + this.dateFmt(new Date(), 'yyyyMMddhhmmss'), headMap, this.list)
    },
  },
  watch: { //值改变动作 valObj(val){}
  },
  components: {
    /*加载组件*/
    draggable
    // VueDraggableNext,
  },
  mounted() {/*页面加载初始化*/

  },
}
</script>

