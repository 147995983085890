export default {
    getTiError: (o, name) => {
        if (o.row.$error == null) {
            o.row.$error = {}
        }
        return o.row.$error[name] == false ? true : false;
    },
    vtiRequired: (o, name) => {
        if (o.row.$error == null) {
            o.row.$error = {}
        }
        var value = o.row[name];
        if (value == null || value.trim().length == 0) {
            Vue.set(o.row.$error, name, true);
        } else {
            Vue.set(o.row.$error, name, false);
        }
        alert(o.row.$error[name])
    },
}
