import Watermark from '@/assets/js/watermark.js';
import CryptoJS from 'crypto-js/crypto-js'
import list from "../../../yd_housekeep_v2_app/ptest/list";
import setOauth from "@/views/admin/setOauth";

export default {
    initWebVersionCheck() {
        if (this.dev$) {
            console.log("开发环境不验证版本！")
            return
        }
        this.webVersionCheck()
        setInterval(() => {
            this.webVersionCheck()
        }, 1000 * 5)

    },
    webVersionCheck() {
        var testVersion = this.getSession('testVersion')//判断是否存在操作
        if (testVersion != 'ok') {
            this.setSession('testVersion', 'ok')
            var url = this.vueConfig.versionApi + '?id_=' + new Date().getTime();
            this.axios({
                method: "GET", url: url, data: {}, headers: {},
            }).then(res => {
                var resData = res.data
                this.setSession('testVersion', 'ok')
                var newVersion = resData
                var vueVersion = this.vueConfig.versionCode
                if (vueVersion != newVersion) {
                    var title =

                        '当前版本：' + vueVersion + '<br/>' +
                        "<span style='color:red;'>最新版本：" + newVersion + "</span><br/>" +
                        '点击【升级】按钮自动更新'
                    // if (this.dev$) {
                    //     console.log("开发环境不验证版本！", title)
                    // } else {
                    this.$confirm(title, {
                        confirmButtonText: '升级',
                        // center: true,
                        dangerouslyUseHTMLString: true,
                    }).then(_ => {
                        window.location.reload(true)
                        // this.$router.push({path: this.$route.fullPath,query:{"_":new Date().getTime()} });
                    }).catch(_ => {

                    })
                    // }
                }
            }).catch(err => {

            })

        }
    },
    $set: function (obj, key, val) {
        if (obj)
            obj[key] = val;
    },
    setThis: function (key, val) {
        this[key] = val;
    },
    replaceAll(text, repstr, newstr) {
        if (!text) {
            return text
        }
        if (typeof text == 'number') {
            return text
        }
        var s = text.replace(new RegExp(repstr, "gm"), newstr);
        return s;
    },
    showWatermark: function (ref, uploadUrl) {
        if (this.getSession("ip")) {
            var loginUser = this.getLoginUser();
            var watermarkStr = "";
            if (loginUser) {
                watermarkStr += loginUser.name;
                // console.log("loginData",this.getSession("loginData").loginSysUserPhone)
                watermarkStr += this.phoneHide(this.getSession("loginData").loginSysUserPhone);
            } else {
                watermarkStr += "IP:" + (this.getSession("ip") + "").repeat("10.100.61.2", "");
            }
            Watermark.set(watermarkStr)
        }
    }
    ,
    copyText: function (val) {
        // createElement() 方法通过指定名称创建一个元素
        var copyInput = document.createElement("input");
        //val是要复制的内容
        copyInput.setAttribute("value", val);
        document.body.appendChild(copyInput);
        copyInput.select();
        try {
            var copyed = document.execCommand("copy");
            if (copyed) {
                document.body.removeChild(copyInput);
                this.showMsgSuccess("复制成功");
            }
        } catch (e) {
            this.showMsgError("复制失败，请检查浏览器兼容");
        }
    }

    ,
    getTreeObj: function (list, id) {
        var val = null;
        if (list && list.length > 0) {
            list.forEach(item => {
                if (val == null) {
                    if (val == null && item.id == id) {
                        val = item;
                    } else {
                        val = this.getTreeObj(item.children, id);
                    }
                }
            })
        }
        return val;
    }
    ,
    treeToList: function (list) {
        var val = [];
        if (list && list.length > 0) {
            list.forEach(item => {
                val.push(item);
                var val2 = this.treeToList(item.children);
                val2.forEach(v2 => {
                    val.push(v2)
                })
            })
        }
        return val;
    }
    ,
    getListLast: function (list) {
        var val = list;
        if (list instanceof Array) {
            if (list.length > 0) {
                val = list[list.length - 1];
            } else {
                val = null;
            }
        }
        return val;
    }
    ,
    randomString: function (num) {
        num = num || 32;
        var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
            a = t.length,
            n = "";
        for (var i = 0; i < num; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n
    },
    strHide: function (str, startIndex, length) {
        if (!str || !startIndex) {
            return str;
        }
        var strTemp = "";
        for (var i = 0; i < str.length; i++) {

            if (i >= (startIndex - 1) && (!length || i <= (startIndex - 2 + length))) {
                strTemp += "*";
            } else {
                strTemp += str[i];
            }
        }
        return strTemp
    }
    ,
    strHidePhone: function (startIndex, length) {
        var str = this.phone;
        var strTemp = "";
        for (var i = 0; i < str.length; i++) {

            if (i >= (startIndex - 1) && (!length || i <= (startIndex - 2 + length))) {
                strTemp += "*";
            } else {
                strTemp += str[i];
            }
        }
        return strTemp
    }
    ,
    random: function (maxNum) {

        return Math.ceil(Math.random() * maxNum);
    }
    ,
    aseEncrypt: function (word, keyStr, ivStr) {
        keyStr = this.randomString(16);
        var center = this.randomString(16);
        ivStr = this.randomString(16);
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);


        let srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return keyStr + center + ivStr + CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    }
    ,
    aseEncryptITM: function (word) {
        var keyStr = "Ydkj@2023.134729";
        // var center = this.randomString(16);
        var ivStr = "Ydkj@2023.134729";
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);


        let srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    }
    ,
    aesDecrypt: function (word, keyStr, ivStr) {
        keyStr = word.substring(0, 16);
        ivStr = word.substring(32, 48);
        word = word.substring(48);
        let key = CryptoJS.enc.Utf8.parse(keyStr);
        let iv = CryptoJS.enc.Utf8.parse(ivStr);

        let base64 = CryptoJS.enc.Base64.parse(word);
        let src = CryptoJS.enc.Base64.stringify(base64);

        var decrypt = CryptoJS.AES.decrypt(src, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        });
        var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }

    ,
    listToTree: function ({
                              list,
                              pid,
                              pidKey = "pid",
                              idKey = "id",
                              showPid = false,
                              noId = [],
                              fieldMap = {name: "label", id: "value"}
                          }) {
        var result = [];
        for (var i = 0; i < list.length; i++) {
            var o = list[i];
            if (o[pidKey] == pid && showPid == false || showPid == true && o[idKey] == pid) {
                var children = this.listToTree({
                    list: list,
                    pid: o[idKey],
                    pidKey: pidKey,
                    showPid: false,
                    noId: noId,
                    fieldMap: fieldMap
                });
                o.hasChildren = false;
                if (children.length > 0) {
                    o.hasChildren = true;
                    o.children = children;
                }
                if (fieldMap) {
                    for (var k in fieldMap) {
                        o[fieldMap[k]] = o[k];
                    }
                }
                if (noId.length == 0 || noId.indexOf(o[idKey]) == -1) {
                    result.push(o);
                }
            }
        }
        result.sort(function (a, b) {
            return a.order_num - b.order_num
        });

        return result;
    }
    ,
    quillEditorPasteImg: function (ref, uploadUrl) {
        if (!uploadUrl) {
            uploadUrl = this.vueConfig.uploadFileApi;
        }
        let quill = this.$refs[ref].quill;
        this.$forceUpdate();
        quill.root.addEventListener('paste', evt => {
            if (evt.clipboardData && evt.clipboardData.files && evt.clipboardData.files.length) {
                evt.preventDefault();
                [].forEach.call(evt.clipboardData.files, file => {
                    if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
                        return
                    }
                    this.axiosUploadFile(uploadUrl, file, function (res) {
                        var range = quill.getSelection();
                        if (range) {
                            // 获取光标所在位置
                            let length = quill.getSelection().index;
                            // 插入图片  res.info为服务器返回的图片地址
                            quill.insertEmbed(length, 'image', res.obj.url);
                            quill.setSelection(length + 1);
                            //  将光标移动到图片后面
                            this.$refs[ref].quill.setSelection(range.index + 1)
                        }
                    })

                })
            }
        }, false)
    }
//截屏上传成功
    ,
    screenCapture: function () {
        var $this = this;
        var canvas2 = document.createElement("canvas");
        // let _canvas = document.getElementById('child');
        let _canvas = document.getElementById('html');
        var w = parseInt(window.getComputedStyle(_canvas).width);
        var h = parseInt(window.getComputedStyle(_canvas).height);
        //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
        // alert(w+"-"+h)
        var lv = 2;
        canvas2.width = w * lv;
        canvas2.height = h * lv;
        canvas2.style.width = w + "px";
        canvas2.style.height = w + "px";
        //可以按照自己的需求，对context的参数修改,translate指的是偏移量
        var context = canvas2.getContext("2d");
        context.scale(lv, lv);
        $this.$html2canvas(document.getElementById('html'), {
            canvas: canvas2
        }).then(function (canvas) {
            var data = canvas.toDataURL("image/jpeg", 1);
            var date = $this.dateFmt(new Date(), "yyyyMMddHHmmss");
            $this.axiosPost("/sysFile/uploadFile.base64?userDir=screenCapture", {
                base64: data,
                fileName: date + "_" + $this.getLoginUserId() + ".jpg"
            }, function (resData) {
                window.open(resData.obj.url);
            });
        });
    }

    ,
    treeFindVal: function (idVal, treeList, idKey, valKey, sign) {
        var str = '';
        var list = [];
        var objList = this.treeFindListObj(idVal, treeList, idKey);
        objList.forEach((o, i) => {
            if (i > 0 && sign) {
                str += sign
            }
            if (valKey) {
                str += o[valKey];
                list.push(o[valKey])
            } else {
                list.push(o)
            }

        });
        if (sign) {
            return str
        } else {
            return list
        }
    }


    ,
    numFmt: function (num) {
        if (typeof num == 'number') {
            var numStr = num.toFixed(8);
            num = Number.parseFloat(numStr);
        }
        return num;
    }
    ,
    daysBetween: function (date) {
        if (!date) {
            date = new Date();
        }
        var list = [];
        var btime = this.dateFmt(date, "yyyy-MM-dd");
        list.push(btime);
        list.push(btime);
        return list;
    }
    ,
    monthBetween: function (date) {
        if (!date) {
            date = new Date();
        }
        var list = [];
        list.push(this.dateFmt(date, "yyyy-MM") + "-01");
        var endTimeStr = this.dateFmt(new Date(date.getTime() + 24 * 60 * 60 * 1000 * 31), "yyyy-MM") + "-01";
        list.push(this.dateFmt(new Date(new Date(endTimeStr).getTime() - 60 * 60 * 24 * 1000), "yyyy-MM-dd"));
        return list;
    }
    ,
    treeFindListObj: function (idVal, treeList, idKey, getKey) {
        if (!idKey) {
            idKey = 'id'
        }
        var $this = this;
        var list = [];
        treeList.forEach(o => {
            if (list && list.length == 0) {
                if (o[idKey] == idVal) {
                    list.push(getKey ? o[getKey] : o)
                } else if (o.children != null) {
                    var list2 = $this.treeFindListObj(idVal, o.children, idKey, getKey);
                    if (list2.length > 0) {
                        var otemp = $this.copyObj(o);
                        delete otemp.children;
                        list.push((getKey ? otemp[getKey] : otemp));
                        list2.forEach(o2 => {
                            list.push(o2);


                        })
                    }
                }
            }
        });

        return list
    }
//手机号码中间部分隐藏
    ,
    phoneHide: function (str) {
        if (!str) {
            return "";
        } else if (str.length < 11) {
            return str;
        }
        return str.slice(0, 3) + '****' + str.slice(7, 11)
    }
//短信倒计时
    ,
    countDown: function (count, field) {
        if (field == null) {
            field = 'waitTime'
        }
        var $this = this;
        this.$set(this, field, count);
        var interval = window.setInterval(function () {
            $this.$set($this, field, $this[field] - 1);
            if ($this.waitTime <= 0) {
                $this.$set($this, field, $this[field]);
                window.clearInterval(interval)
            }
        }, 1000)
    }
    ,
    isNullArray: function (arrayList) {
        if (arrayList == null || undefined == arrayList || arrayList.length == 0) {
            return true
        }
        return false
    }
    ,
    //下划线转驼峰
    toCamel: function (str) {
        return str.replace(/([^_])(?:_+([^_]))/g, function ($0, $1, $2) {
            return $1 + $2.toUpperCase();
        });
    },
    //中线转驼峰
    toMidLineCamel: function (str) {
        return str.replace(/([^-])(?:-+([^-]))/g, function ($0, $1, $2) {
            return $1 + $2.toUpperCase();
        });
    },
    isListFiledNum: function (arrayList, filedStr, filedNameStr) {
        var status = null;
        if (arrayList == null || arrayList.length == 0) {
            status = ''
        } else {
            var filedList = filedStr.split(',');
            var filedNameList = filedNameStr.split(',');
            arrayList.forEach(o => {
                filedList.forEach((f, i) => {
                    var val = o[f];
                    if (val == null || (val + '').trim().length == 0) {
                        status = filedNameList[i];
                        this.$set(o, f + '$error', true)
                    } else if (val <= 0) {
                        status = filedNameList[i] + '不能为0';
                        this.$set(o, f + 'error', true)
                    }
                })
            })
        }
        return status
    }
    /**清除表单残留验证提示*/
    ,
    resetFieldsBase: function (name) {
        if (this.$refs[name] != undefined) {
            this.$refs[name].resetFields() //清除表单残留验证提示
        }
    }
    /**数据格式化,*/
    ,
    filedFormat: function (mapOrList, upMap, isDel, treeCode) {

        var $this = this;
        if (mapOrList instanceof Array) {
            mapOrList.forEach(o => {
                $this.filedFormat(o, upMap);
                var list2 = o[treeCode];
                if (list2 != null) {
                    $this.filedFormat(list2, upMap)
                }
            })
        } else {
            for (var key in mapOrList) {
                var key2 = upMap[key];
                if (key2 != null) {
                    if (key2 instanceof Object) {
                        var val = mapOrList[key];
                        if (key2 instanceof Array) {
                            val.forEach(o => {
                                $this.filedFormat(o, key2)
                            })
                        } else if (key2 instanceof Object) {
                            $this.filedFormat(val, key2)
                        }
                        if (key2['$'] != null) {
                            mapOrList[key2['$']] = mapOrList[key]
                        }
                    } else {
                        mapOrList[key2] = mapOrList[key]
                    }
                    if (isDel != false) {
                        delete mapOrList[key]
                    }
                }

            }
        }
        return mapOrList
    }
    ,
    //数组JSON排序，
    listSort: function (array, key) {
        array.sort((a, b) => {
            return a[key] - b[key];
        });
    }

    ,
    delArrItem: function (arr, item, key) {
        arr.forEach((o, i) => {
            if (key && (o[key] == item[key] || o[key] == item)) {
                arr.splice(i, 1);

            } else if (o == item) {

                arr.splice(i, 1);

            }
        })
    },
    getRules: function (c) {

        var rules = [];
        if ((c.read_form != true && this.design.form.read != true) && c.rules) {
            c.rules.forEach(r => {
                if (this[r])
                    rules.push(this[r])
            })
            if (c.required_rule) {

                if (this.evalRule(c.required_rule, {})) {
                    rules.push(this.vRequired$)
                } else {
                    this.delArrItem(rules, this.vRequired$)
                }
            }
        }
        return rules;
    },
    evalRule(rule, {row = {}, oldObj, obj, search}) {
        if (rule) {
            // console.log("evalRule", rule)
            // var ruleLog = "evalRule=" + rule;
            var $rq, $this, loginSys, sysConfig, sunTable, newDate, newDates, newTime, newTimes, newTimeMs;
            if (rule.indexOf("$rq") > -1) {
                $rq = this.$route.query;
            }
            if (rule.indexOf("$this") > -1) {
                $this = (("root$" in this) ? this.root$ || this : this)
            }

            if (rule.indexOf("loginSys") > -1 && !loginSys) {
                loginSys = this.getLoginUser();
                if (loginSys == null) {
                    return false;
                }
            }
            if (rule.indexOf("sysConfig") > -1) {
                sysConfig = this.getSysConfig();
                if (sysConfig == null) {
                    return false;
                }
            }
            if (rule.indexOf("sunTable") > -1 && !sunTable) {
                $this = $this || (("root$" in this) ? this.root$ || this : this)
                sunTable = this.sunTable || $this.sunTable;
            }
            if (rule.indexOf("newDate") > -1) {
                newDate = this.dateFmt(new Date(), "yyyy-MM-dd");
                newDates = [newDate, newDate];
            }
            if (rule.indexOf("newTime") > -1) {
                newDate = this.dateFmt(new Date(), "yyyy-MM-dd");
                newTime = this.dateFmt(new Date(), "yyyy-MM-dd HH:mm:ss");
                newTimeMs = new Date().getTime();
                newTimes = [newDate + " 00:00:00", newDate + " 23:59:59"];
            }
            if (rule.indexOf("obj") > -1 && !obj) {
                $this = $this || (("root$" in this) ? this.root$ || this : this)
                obj = this.obj || $this.obj;
                // console.log("obj",obj)
                if (obj == null) {
                    return false;
                }
            }
            if (rule.indexOf("search") > -1 && !search) {
                $this = $this || (("root$" in this) ? this.root$ || this : this)
                search = this.search || $this.search;
                if (search == null) {
                    return false;
                }
            }
            if (rule.indexOf("oldObj") > -1 && !oldObj) {
                $this = $this || (("root$" in this) ? this.root$ || this : this)
                oldObj = this.oldObj$ || $this.oldObj$;
                if (oldObj == null) {
                    return false;
                }
            }
            if (rule.indexOf("row") > -1 && !row) {
                $this = $this || (("root$" in this) ? this.root$ || this : this)
                row = this.row || $this.row;
                if (row == null) {
                    return false;
                }
            }


            // console.log("row",row)
            // console.log("evalRule=" + rule, $rq, $this, loginSys, sysConfig, sysConfig, obj, search, oldObj, sunTable, newDate, newDates, newTime)

            return eval(rule)
        }
        return eval(rule)
    },

    indexWidth: function (length) {

        var width = 30;
        if (length == undefined && this.page && this.page.list) {
            length = this.page.list.length
        }
        if (length != null) {
            if (length >= 1000) {
                width = width + 12
            } else if (length >= 100) {
                width = width + 6
            }
        }
        return width
    }
    /**
     *  数组字符串连接
     * @param arrayList 数组对象
     * @param sep 转换后的分隔符
     * @param col 取值的字段，不填则返回全值
     * @returns {string}
     */
    ,
    mapLength: function (map) {
        var length = 0;
        for (var k in map) {
            length += 1
        }
        return length
    }
    ,
    exportExcel: function (filename, headMap, jsonList) {
        var keyList = this.mapKeyToList(headMap);
        var headList = this.mapToList(headMap);
        var dataList = [];
        jsonList.forEach(o => {
            var list = [];
            keyList.forEach(f => {
                var val = o[f];
                if (typeof val == 'object' || typeof val == 'array') {
                    val = this.jsonToStr(val);
                }
                list.push(val)
            });

            dataList.push(list)
        });
        dataList.unshift(headList);
        const ws = this.$xlsx.utils.aoa_to_sheet(dataList);
        //	 ws['!cols'] = wscols
        const wb = this.$xlsx.utils.book_new();
        this.$xlsx.utils.book_append_sheet(wb, ws, filename);
        this.$xlsx.writeFile(wb, filename + '.xls')
    }

    ,
    isPc: function () {
        var sUserAgent = navigator.userAgent.toLowerCase();

        var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
        var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
        var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
        var bIsAndroid = sUserAgent.match(/android/i) == 'android';
        var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
        var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
        if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
            return true
        } else {
            return false
        }
    }
    ,
    arrayJoinStr: function (arrayList, sep, col) {
        var ids = '0';
        arrayList.forEach(function (o, i) {
            if (col) {
                ids += sep + o[col]
            } else {
                ids += sep + o
            }
        });
        if (ids.length <= 1) {
            ids = ''
        } else {
            ids += sep + '0'
        }
        return ids
    }

    ,
    listJsonToStr: function (list, sep, key) {
        var ids = '';
        list.forEach(function (o, i) {
            if (i > 0) {
                ids += sep
            }
            if (key) {
                ids += o[key]
            } else {
                ids += o
            }
        });

        return ids
    }
    ,
    arrayJoinArray: function (arrayList, col) {
        var ids = [];
        arrayList.forEach(function (o, i) {
            ids.push(o[col])

        });

        return ids
    }
    /**
     * 字符串转数组
     * @param str 字符串
     * @param sep 分隔符，默认逗号
     * @returns {Array}
     */
    ,
    strToArrayInt: function (str, sep) {
        if (sep == undefined) {
            sep = ','
        }
        var ids = [];
        if (str != null && str != undefined) {
            str.split(sep).forEach(function (o, i) {
                o = o.trim();
                if (o.length > 0 && o != '0') {
                    ids.push(Number(o))
                }
            })
        }
        return ids
    }
    ,
    strToArray: function (str, sep) {
        if (sep == undefined) {
            sep = ','
        }
        var ids = [];
        if (str != null && str != undefined) {
            str.split(sep).forEach(function (o, i) {
                o = o.trim();
                if (o.length > 0 && o != '0') {
                    ids.push(o)
                }
            })
        }
        return ids
    }
    ,
    getDialogWidth(design) {
        var def = "1000px";
        if (design && design.form) {
            return design.form.dialog_width || def;
        }
        return def
    },
    toHtmlBr: function (str) {
        if (str == undefined) {
            return null
        }
        var regs = new RegExp('\n', 'g');
        var regs2 = new RegExp(' ', 'g');
        var str1 = str.replace(regs, '<br/>');
        str1 = str1.replace(regs2, '&nbsp;');
        return str1
    }
    /**
     * 字符串分隔数组转map
     * @param str 字符串
     * @param sep 分隔符，默认逗号
     * @returns {Array}
     */
    ,
    strListToMap: function (str, sep) {
        if (sep == undefined) {
            sep = ','
        }
        var map = {};
        if (str != null && str != undefined) {
            str.split(sep).forEach(function (o, i) {
                o = o.trim();
                if (o.length > 0 && o != '0') {
                    // ids.push(o);
                    map[o] = o
                }
            })
        }
        return map
    }

    ,
    listFind: function (list, listOrVal) {
        var status = true;
        if (list != null && listOrVal != undefined) {
            if (listOrVal instanceof Array) {
                listOrVal.forEach(function (v2) {
                    status = status && (list.indexOf(v2) > -1)
                })
            } else {
                status = list.indexOf(listOrVal) > -1
            }
        } else {
            status = false
        }
        return status
    }
    ,
    listJsonToMapList: function (listJson, key) {
        var map = {};
        var $this = this;
        if (listJson != null && listJson != undefined) {
            listJson.forEach(function (o, i) {
                var kayVal = o[key];
                if (map[kayVal] == null) {
                    map[kayVal] = []
                }
                map[kayVal].push(o)
            })
        }
        return map
    }
    ,
    listJsonToGroupList: function (listJson, key) {
        var list = [];
        var map = {};
        if (listJson != null && listJson != undefined) {
            listJson.forEach(function (o, i) {

                if (key == null && map[o] == null) {
                    map[o] = o;
                    list.push(o)
                } else if (o[key] && map[o[key]] == null) {
                    map[o[key]] = o[key];
                    list.push(o[key])
                }
            })
        }
        return list
    }

    ,
    listJsonKeySwitch: function (listJson, key, newKey) {
        if (listJson != null && listJson != undefined) {
            listJson.forEach(function (o, i) {
                o[newKey] = o[key]
            })
        }
        return listJson
    }
    ,
    listJsonToList: function (listJson, key) {
        var list = [];
        if (listJson && listJson instanceof Array) {
            listJson.forEach(function (o, i) {
                if (key == null && o) {
                    list.push(o)
                } else if (o && o[key]) {
                    list.push(o[key])
                }
            })
        }
        return list
    },
    listJsonToKeyList: function (listJson, key) {
        var list = [];
        if (listJson && listJson instanceof Array) {
            listJson.forEach(function (o, i) {
                var list2 = o[key]
                if (list2) {
                    list2.forEach(function (o2, i2) {
                        list.push(o2)
                    })
                }
            })
        }
        return list
    },
    listJsonToListJson: function (listJson, map, seq) {
        var list = [];
        seq = seq || "-";
        if (listJson && listJson instanceof Array) {
            listJson.forEach((o, i) => {
                // if (key == null && o) {
                //     list.push(o)
                // } else if (o && o[key]) {
                var ov = this.copyObj(o);
                for (var mk in map) {
                    var kk = map[mk];
                    var kv = null;
                    if (kk) {
                        if (kk instanceof Array) {
                            kk = this.listJsonToStr(kk, ",");
                        }
                        kk.split(",").forEach((k, ki) => {
                            // if(")){
                            //     k.indexOf("img#
                            // }
                            k = k.replace("img#", "");
                            if (ki == 0) {
                                kv = o[k] || ''
                            } else {
                                kv += seq + (o[k] || '')
                            }
                            ov[k] = o[k]

                        });
                    }
                    ov[mk] = kv
                }
                list.push(ov);
                if (o.children && o.children.length > 0) {
                    var childrenList = this.listJsonToListJson(o.children, map, seq);
                    o.children = childrenList
                }
                // }
            })
        }
        return list
    }
    ,
    getJsonMinVal: function (listJson, key) {
        var val = null;
        if (listJson != null && listJson != undefined) {
            listJson.forEach(function (o, i) {
                var val2 = 0;
                if (key == null) {
                    val2 = o
                } else {
                    val2 = o[key]
                }
                if (val == null) {
                    val = val2
                } else if (val > val2) {
                    val = val2
                }
            })
        }
        return val
    }
    ,
    listJsonToMap: function (listJson, key, valueKey, isNumber) {
        var map = {};
        var $this = this;
        if (listJson != null && listJson != undefined) {
            listJson.forEach((o, i) => {
                // var okey=o[key];
                // if(isNumber){
                //   okey=parseInt[okey]
                // }
                //530127000000
                if (o) {


                    o.$i$ = i;
                    if (key == null) {
                        map[o] = o
                    } else if (valueKey != null) {
                        var v = "";
                        if (valueKey.split(",").length <= 1 && typeof o[valueKey] == 'object') {
                            v = {};
                            Object.assign(v, o[valueKey])
                        } else {
                            valueKey.split(",").forEach(k => {
                                v += o[k] + "-";
                            })
                        }
                        map[o[key]] = v
                    } else {
                        // console.log(o[key])
                        if (o[key] != null) {
                            map[o[key]] = o
                        }
                        // $this.$set(map, o[key], o);
                        // map[o[key]] = o
                    }
                    if (o.children && o.children.length > 0) {
                        var childrenList = this.listJsonToMap(o.children, key, valueKey, isNumber);
                        // console.log("childrenList", childrenList);
                        for (var k in childrenList) {
                            map[k] = childrenList[k]
                        }
                    }
                }
            })
        }
        return map
    }
    ,
    listToMap: function (list) {
        var map = {};
        if (typeof list == 'string') {
            list = list.split(',')
        }
        if (list != null && list != undefined) {
            list.forEach(function (o, i) {
                map[o] = o
            })
        }
        return map
    }
    ,
    mapToList: function (map) {
        var list = [];
        for (var key in map) {
            var o = map[key];
            // if (o) {
            //   o["key$"] = key;
            // }
            list.push(o)
        }
        return list
    }
    ,
    mapToListKey: function (map) {
        var list = [];
        for (var key in map) {
            list.push(key)
        }
        return list
    }
    ,
    mapValToKey: function (map) {
        var map2 = {};

        for (var key in map) {
            map2[map[key]] = key
        }
        return map2
    }
    ,
    mapKeyToList: function (map) {
        var list = [];
        for (var key in map) {
            list.push(key)
        }
        return list
    }

    /**
     * 字符串转Int数组
     * @param str 字符串
     * @param sep 分隔符，默认逗号
     * @returns {Array}
     */
    ,
    strToIntArray: function (str, sep) {
        if (sep == undefined) {
            sep = ','
        }
        var ids = [];
        if (str != null && str != undefined) {
            str.split(sep).forEach(function (o, i) {
                o = o.trim();
                if (o.length > 0) {
                    ids.push(o * 1)
                }
            })
        }
        return ids
    }
    /**
     * 字符串转JSON数组，
     * @param str 字符串
     * @returns {Array} 默认空数组
     */
    ,
    strToJsonArray: function (str) {
        try {
            if (str instanceof Array) {
                return str
            }
            if (!this.isNullStr(str)) {
                return []
            } else {
                return JSON.parse(str)
            }
        } catch (e) {
            return []
        }
    }
    /**
     * Map取值连接
     * @param ids key值，逗号分隔
     * @param map map对象
     * @param sep 转换后的分隔符
     * @param col 取值的字段，不填则返回全值
     * @returns {string}
     */
    ,
    mapJoin: function (ids, map, sep, col) {
        var vals = '';
        if (ids) {
            ids.split(',').forEach(function (o, i) {
                if (o.length > 0) {
                    var obj = map[o];
                    if (obj != undefined) {
                        if (col) {
                            vals += sep + obj[col]
                        } else {
                            vals += sep + obj
                        }
                    }
                }

            });
            if (vals != '') {
                vals = vals.substr(1)
            }
        }
        return vals
    }
    /*生成UUID*/
    ,
    getUuid: function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
        })
    }
//金额整数部分
    ,
    moneyHead: function (money) {
        if (money == null) {
            return '';
        }
        var str = money.toFixed(2);
        return str.substring(0, str.indexOf('.'))
    }
//金额小数部分
    ,
    moneyLast: function (money) {
        var str = money.toFixed(2);
        return str.substring(str.indexOf('.') + 1)
    }
    ,
    formatAmount: function (val) {
        let regex = /\S/;
        if (regex.test(val)) {
            return Number(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        } else {
            return '0'
        }
    }
    ,
    moneyStr: function (num2) {
        try {
            var num = parseFloat(num2);
            if (num == null) {
                return num2
            }
            if (num > 10000 * 10000) {
                num = num / (10000 * 10000);
                return num.toFixed(4) + '亿'
            } else if (num > 10000) {
                num = num / (10000);
                return num.toFixed(4) + '万'
            }

        } catch (e) {

        }
        return num2
    }
    /**固定元素高度*/
    ,
    jsHeight: function (val) { /*固化行高*/
        var val2 = this.tHeight;
        // console.log(val2)
        if (val == null) {
            val = val2 + 0.1
        }
        this.$set(this, 'tHeight', val);
        var $this = this;
        setTimeout(function () {
            $this.$set($this, 'tHeight', val2)
        }, 1)
    }
    ,
    scrollMonitor: function (ref) {
        var $this = this;
        setTimeout(function () {
            if ($this.$refs[ref]) {
                var tableDom = $this.$refs[ref].bodyWrapper;
                if (tableDom) {
                    tableDom.addEventListener('scroll', function () {
                        let scrollTop = tableDom.scrollTop;
                        let scrollLeft = tableDom.scrollLeft;
                        $this.scrollMap$[ref] = {scrollTop: scrollTop, scrollLeft: scrollLeft}
                    })
                }
            }
        }, 100)


    }
    ,
    initScrollMonitor: function (ref, monitor) {
        var $this = this;
        var si = setInterval(function () {
            if ($this.scrollMap$ && $this.scrollMap$[ref]) {
                clearInterval(si);
                var map = $this.scrollMap$[ref];
                if (map && $this.$refs[ref]) {
                    $this.$refs[ref].bodyWrapper.scrollBy(map.scrollLeft, map.scrollTop);
                    if (monitor == true) {
                        $this.scrollMonitor(ref)
                    }
                }
            }
        }, 1)
    }
    ,
    initScrollYdTable: function (ref, monitor) {
        var $this = this;
        var si = setInterval(function () {
            if ($this.scrollMap$ && $this.scrollMap$[ref]) {
                var map = $this.scrollMap$[ref];
                if (map && $this.$refs[ref]) {
                    $this.$refs[ref].bodyWrapper.scrollBy(map.scrollLeft, map.scrollTop);
                    if (monitor == true) {
                        $this.scrollMonitor(ref)
                    }
                }
                clearInterval(si)
            }
        }, 10)
    }
    /**固定元素高度*/
    ,
    getHeight: function (val) { /*固化行高*/
        // if (!this.isPc()) {
        //     val = val + this.tableHeight
        // } else {
        //     val = val + (this.tableHeight)
        // }
        return 'calc(100vh - ' + val + 'px)'
    }
    /**通用子组件返回值填充父组件*/
    ,
    baseSetVal2: function (vals) {
        if (vals.length == 1) {
            vals = vals[0]
        }
        var obj = this;
        for (var i = 0; i < vals.length - 2; i++) {
            obj = obj[vals[i]]
        }
        this.$set(obj, vals[vals.length - 2], vals[vals.length - 1]);
        try {
            this.$refs[vals[0]].validateField(vals[vals.length - 2])
        } catch (e) {

        }
        //    this.$set(obj,vals[vals.length - 2],vals[vals.length - 1])
    },
    baseSetVal: function (key, value) {
        // console.log("baseSetVal", key, value)
        //page.pageNumber 1
        var keyList = key.split(".");
        // console.log("baseSetVal",keyList, value)
        var obj = this;
        for (var i = 0; i < keyList.length; i++) {
            obj = obj[keyList[i]]
        }
        obj = value;
        // this.$set(obj, vals[vals.length - 2], vals[vals.length - 1]);
        try {
            if (keyList.length > 1)
                this.$refs[keyList[0]].validateField(keyList[1])
        } catch (e) {

        }
        //    this.$set(obj,vals[vals.length - 2],vals[vals.length - 1])
    },
    validateField: function (field) {
        this.$refs.obj.validateField(field)
    },
    validateRowField: function (field) {
        this.$refs.obj.validateField(field)
    }
    /**
     * 本地多字段搜索
     * @param key 关键字
     * @param data listJson数据
     * @param fieldNameListStr 查询的json字段，逗号[,]分隔
     * @param tempKey listJson全数据，filterDataMap$临时存储的key，
     * JS引用：  this.filterMethodBase(key, this.unitList, "unitName,unitCode", "unitList")
     * vue标签： :filter-method="filterUnitCode" @visible-change="filterUnitCode('')"
     */
    ,
    filterMethodBase: function (key, data, fieldNameListStr, tempKeyOrList, maxLength, selectList, disabledSelectList) {
        if (!tempKeyOrList) {
            return
        }
        if (key == null) {
            key = ''
        }
        key = key.trim();
        var d1 = Date.now();
        // if (maxLength.length > 0 && selectList != null) {
        //     maxLength += selectList.length;
        // }
        this.$set(this.filterDataMap$, 'loading', true);

        var tempKeyOrListTemp = [];
        if (tempKeyOrList instanceof Array) {
            tempKeyOrListTemp = tempKeyOrList
        } else {
            if (tempKeyOrList && this.filterDataMap$[tempKeyOrList] == null) {
                this.$set(this.filterDataMap$, tempKeyOrList, this.copyObj(data))
            }
            tempKeyOrListTemp = this.filterDataMap$[tempKeyOrList]
        }

        var dataSelect = [];
        var selectListMap = this.listToMap(selectList);
        if (selectList != null && selectList.length > 0) {
            var filterDataMapIdList = this.listJsonToMap(tempKeyOrListTemp, 'id');
            var disabledSelectMap = this.listToMap(disabledSelectList);
            selectList.forEach(id => {
                if (filterDataMapIdList[id] != null) {
                    var o2 = this.copyObj(filterDataMapIdList[id]);
                    if (disabledSelectMap[id] != null) {
                        o2.selectStatus = true
                        // alert(2)
                    }
                    dataSelect.push(o2)
                }
            })
        }
        data.splice(0, data.length);
        if (key != null && key.length > 0) {
            // var keyList = this.strToList(key);
            var keyList = key.split(' ');
            tempKeyOrListTemp.forEach(o => {
                // var fullValue = "";
                if (selectListMap[o.id] == null) {
                    if (maxLength == null || data.length < maxLength) {
                        var fullValue = [];
                        fieldNameListStr.split(',').forEach(k => {
                            if (k != null && o[k] != null) {
                                // fullValue += o[k];
                                fullValue.push(o[k])
                            }
                        });
                        // var fullValueList = this.strToList(fullValue)
                        if (this.listFindLike(fullValue, keyList)) {
                            data.push(o)
                        }
                    }
                }
            })
        } else {
            tempKeyOrListTemp.forEach(o => {
                if (selectListMap[o.id] == null) {
                    if (maxLength == null || data.length < maxLength) {
                        data.push(o)
                    }
                }
            })
        }
        dataSelect.forEach(o => {
            data.push(o)
        });
        this.$set(this.filterDataMap$, 'loading', false)
    }

    ,
    listFindLike: function (list, listOrVal) {
        var status = true;
        if (list != null && listOrVal != undefined) {
            if (listOrVal instanceof Array) {
                listOrVal.forEach(function (v2) {
                    var status2 = false;
                    list.forEach(o => {
                        if (o.indexOf(v2) > -1) {
                            status2 = true
                        }
                    });
                    status = status && status2
                })
            } else {
                var status2 = false;
                list.forEach(o => {
                    if (o.indexOf(listOrVal) > -1) {
                        status2 = true
                    }
                });
                status = status2
            }
        } else {
            status = false
        }
        return status
    }
    ,
    listAddList: function (...list) {
        var listTemp = [];
        if (list && list instanceof Array)
            list.forEach(l => {
                if (l && l instanceof Array) {
                    l.forEach(o => {
                        listTemp.push(o)
                    })
                }
            });

        return listTemp
    }
    ,
    validateTableInputChild: function (refs) {
        var status = true;
        refs.childNodes.forEach(o => {
            if (status && refs.className.indexOf('tiError') > -1) {
                status = false
            }
            if (status) {
                status = this.validateTableInputChild(o)
            }
        });
        return status
    }
    ,
    oldObjInit: function () {
        for (var k in this.oldObj$) {
        }
        this.oldObj$.sysUserId = this.getLoginUserId();
        return this.oldObj$

    }
    ,
    validateTableInput: function (ref, errorTip) {
        var refs = this.$refs[ref].$el;
        var status = this.validateTableInputChild(refs);
        return status

    }
    ,
    excelImport: function (excelData, list, fieldList) {
        // var flist = "name,brand,modelNum,config,unit,price,num".split(",");
        var flist = fieldList.split(',');
        excelData.forEach(o => {
            var val = {};
            flist.forEach((f, i) => {
                val[f] = o[i]
            });
            list.push(val)
        })
    }
    ,
    initOldObj: function (key, value, obj) {
        if (key == null && value != null) {
            this.$set(this, 'oldObj$', this.copyObj(value))
        } else if (value != null) {
            this.$set(this.oldObj$, key, this.copyObj(value))
        } else if (obj != null) {
            var oldObj = this.oldObj$;
            var objTemp = this.copyObj(obj);
            var oldObjTemp = {};

            for (var k in oldObj) {
                var oldVal = JSON.stringify(oldObj[k]);
                var objVal = JSON.stringify(objTemp[k]);
                if (oldObj[k] instanceof Array) {
                    var addStatus = false;
                    var oldMap = this.listJsonToMap(oldObj[k], 'id');
                    var list = [];
                    objTemp[k].forEach(o => {
                        if (o.id == null) {
                            list.push(o);
                            addStatus = true
                        } else if (oldMap[o.id] != null) {
                            var map = {};
                            for (var ok in oldMap[o.id]) {
                                if (JSON.stringify(o[ok]) != JSON.stringify(oldMap[o.id][ok])) {
                                    map[ok] = o[ok]
                                }
                            }
                            if (this.mapLength(map) > 0) {
                                addStatus = true
                            }
                            list.push(map)
                        }
                    });
                    if (addStatus) {
                        oldObjTemp[k] = list
                    }
                    // } else if (oldObj[k] instanceof Object) {
                    //   oldVal = JSON.stringify(oldObj[k])
                    //   objVal = JSON.stringify(objTemp[k])
                } else if (oldVal != objVal) {
                    oldObjTemp[k] = objTemp[k]
                }
            }
            if (this.mapLength(oldObjTemp) > 0 || addStatus) {
                oldObjTemp.updateStatus$ = true
            }
            if (obj.id == null) {
                oldObjTemp = this.copyObj(obj);
                oldObjTemp.updateStatus$ = true
            }
            oldObjTemp.id = obj.id;
            oldObjTemp.sysUserId$ = this.getLoginUserId();
            this.$set(obj, 'oldObj$', oldObjTemp)
        }
    },
    /** 全屏 **/
    openFullScreen(open) {

        let element = document.documentElement;
        if (open) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen();
            }
        }
        open = !open
    }
    /**复制对象*/

    ,
    copyObj: function (val) {
        if (val == null) {
            return null
        }
        try {
            return JSON.parse(JSON.stringify(val))
        } catch (e) {
            return null
        }
    }
    ,
    copyFormObj: function (copy, name, callbackFn) {
        if (copy == true || copy == 'true') {
            this.$set(this, 'copy$', true);
            delete this.obj.is_del;
            delete this.obj.del_user_id;
            delete this.obj.id;
            delete this.obj.create_time;
            delete this.obj.update_time;
            delete this.obj.del_time;
            if (name != null) {
                this.$set(this.obj, name, this.obj[name] + '【复制】')
            }
            this.runCallbackFn(callbackFn)
        }
    }

    ,
    listMultiToListVal: function (list) {
        var listTemp = [];
        list.forEach(o => {
            if (typeof (o) == 'object') {
                listTemp.push(o[o.length - 1]);
            } else {
                listTemp.push(o)
            }
        });
        return listTemp;
    }
    /**初始化参数*/

    ,
    listFindVal: function (list, idKey, valKey, idVal) {
        var map = this.listJsonToMap(list, idKey);
        var valObj = map[idVal];
        if (valObj != null) {
            return valObj[valKey]
        }
        return null
    }
    ,
    closeTag: function (str) {
        this.delTabMenu(this.$route.fullPath);
        this.$router.go(-1)
    }
    ,
    isNullStr: function (str) {
        if (str == null || str == undefined || str.toString().trim().length == 0 || str.length == 0) {
            return false
        }
        return true
    }
    ,
    initPara2: function (obj, key, defaultVal) {
        if (obj.id == undefined) {
            this.$set(obj, key, defaultVal)
        }
    }
    ,
    initPara: function (key, defaultVal, compel) {
        if (this.obj == null) {
            return
        }
        if (this.obj.id == undefined || (this.obj[key] == null && compel == true)) {
            this.obj[key] = defaultVal
        }
    }
    ,
    getListToVal: function (obj, key) {
        if (this.obj[key] != null && this.obj[key].length > 0) {
            this.$set(obj, key, this.obj[key][this.obj[key].length - 1]);
        }
    }

    ,
    devModel: function () {
        return this.getSession('devModel') == 'true'
    }

    /**初始化vue页面的数据*/
    ,
    initData: function () {
        Object.assign(this.$data, this.$options.data())
    },
    // parentRunFun: function (methodName, c1, c2, c3, c4, c5) {
    //     if (this.runFun) {
    //         var datas = [];
    //         c1 ? datas.push(c1) : '';
    //         c2 ? datas.push(c2) : '';
    //         c3 ? datas.push(c3) : '';
    //         c4 ? datas.push(c4) : '';
    //         c5 ? datas.push(c5) : '';
    //         console.log("runFun", methodName, datas)
    //         this.runFun(methodName, datas)
    //     } else {
    //         console.log("$emit", methodName, [c1, c2, c3, c4, c5])
    //         this.$emit(methodName, c1, c2, c3, c4, c5)
    //     }
    // },
    thisRunFun: function ({this_, funName, datas}) {

        if (datas && datas instanceof Array) {
            if (datas.length == 0) {
                return this[funName](datas)
            } else if (datas.length == 1) {
                console.log(this[funName](datas[0]));
                return this[funName](datas[0])
            } else if (datas.length == 2) {
                return this[funName](datas[0], datas[1])
            } else if (datas.length == 3) {
                return this[funName](datas[0], datas[1], datas[2])
            } else if (datas.length == 4) {
                return this[funName](datas[0], datas[1], datas[2], datas[3])
            } else if (datas.length == 5) {
                return this[funName](datas[0], datas[1], datas[2], datas[3], datas[4])
            }
        } else {
            return this[funName](datas)
        }
    },
    // parentRunFun: function (this_, methodName, c1, c2, c3, c4, c5) {
    //     if (this_ && this_.$parent && this_.$parent.runFun) {
    //         var datas = [];
    //         c1 ? datas.push(c1) : '';
    //         c2 ? datas.push(c2) : '';
    //         c3 ? datas.push(c3) : '';
    //         c4 ? datas.push(c4) : '';
    //         c5 ? datas.push(c5) : '';
    //         console.log("this_.$parent.runFun", methodName, datas,)
    //         this_.$parent.runFun(methodName, datas)
    //     } else {
    //         console.log("this_.$parent",this_.$parent)
    //         // console.log("$emit", methodName, [c1, c2, c3, c4, c5])
    //         this.$emit(methodName, c1, c2, c3, c4, c5)
    //     }
    // },
    /**验证权限*/
    btnDisabledValidate: function ($this) {
        var author = $this.parentData.author;
        var baseUrl = $this.parentData.baseUrl;
        if (author[baseUrl + $this.method] == true) {
            $this.btnDisabled = false

        }
    },
    areaSub(id, num) {
        var idStr = id + "";
        idStr = idStr.substring(0, 6);
        for (var i = 0; i < (12 - num); i++) {
            idStr += "0";
        }
        return parseInt(idStr);
    }
    ,
// remoteMethod: function (val) {
//     console.log(123)
//     console.log(val)
// },
    objKeyVal: function (obj, key) {

        if (!obj) {
            return '';
        }
        if (key.indexOf(".") >= 0) {
            try {
                if (key.indexOf("$list$")) {
                    var ks = key.split("$list$");

                    var v2 = eval("obj." + ks[0])

                    var list = [];
                    if (v2) {
                        // var kk = ks[1].split("+");
                       try{
                           v2.forEach(o => {
                               list.push(o[ks[1]]);
                               // console.log(key,ks[1],o,o[ks[1]])
                           })
                       }catch (e){

                           // console.error(v2,e)
                           return v2
                       }
                    }
                    // console.log(key, ks, v2,list)
                    return list
                } else {
                    var v = eval("obj." + key)
                    // console.log(key, v, obj)
                    return v;
                }
            } catch (e) {
                console.error(e)
                return '';
            }
        }
        return obj[key]
    },
    initSelect: function (type) {
        if (type == 'search') {
            this.design.wheres.forEach(o => {
                var c = (this.design.colMap[o.col]);
                if (o.api) {
                    this.initSelectR(type, o, '')
                } else if (c && c.api) {
                    this.initSelectR(type, c, '')
                }
            })
        } else if (type == 'form') {
            this.design.tableCols.forEach(o => {
                var c = (this.design.colMap[o]);
                if (c && c.api && c.show_form == true) {
                    // console.log(type,c)
                    this.initSelectR(type, c, '')
                }
            });
        } else if (type == 'table') {
            var apiColList = {}
            var apiIds = {}
            this.design.tableCols.forEach(o => {
                var c = (this.design.colMap[o]);

                if (c && (c.api || c.table_api) && c.show == true) {

                    var module_type = c.module_type_table || c.module_type_form || c.module_type
                    var module_type_form = module_type && (module_type == 'select' || module_type == 'radio' || module_type == 'tree' || module_type.indexOf('area') == 0 || module_type == 'checked' || module_type == 'select_multiple' || module_type == 'select_remote' || module_type == 'select_remote_multiple')
                    // console.log(c.show_name,module_type,module_type_form)
                    if (module_type_form) {
                        var api = c.api || c.table_api
                        var col = c.api_field || c.col;
                        var ids = []
                        if (!apiIds[api]) {
                            apiIds[api] = []
                        }
                        this.page.list.forEach(v => {
                            var v2 = v[col];
                            if (v2) {
                                if (v2 instanceof Array) {
                                    v2.forEach(o1 => {
                                        apiIds[api].push(o1);
                                    })
                                } else {
                                    apiIds[api].push(v2);
                                }
                            }
                        });
                        if (!apiColList[api]) {
                            apiColList[api] = []
                        }
                        apiColList[api].push(c);
                    }

                    this.initSelectR(type, c, '')
                }


            });
            // console.log(apiColList)
            // console.log(apiIds)
            for (var k in apiColList) {
                // console.log(k)
                // this.initSelectR(type, null, '', k, apiColList[k], apiIds[k])
            }
        }

        return "";
    }
    ,
    initSelectR: function (type, c, keystr, apiCode, colList, ids) {
        // console.error("initSelectR",type,c,keystr, apiCode)
        var col = null;
        var module_type = "select";
        if (c != null) {
            apiCode = c.api;
            col = c.keyName || c.col
            module_type = c.module_type_form || c.module_type || module_type
        }


        if (type == 'table') {
            apiCode = c.table_api || c.api;
            module_type = c.module_type_table || c.module_type_form || c.module_type || module_type
            // if (c.module_type_table) {
            //     module_type = c.module_type_table
            // }
            // if (c.table_api) {
            //     apiCode = c.table_api;
            // }
        }

        if (apiCode) {
            var module_type_form = module_type && (module_type == 'select' || module_type == 'radio' || module_type == 'tree' || module_type.indexOf('area') == 0 || module_type == 'checked' || module_type == 'select_multiple' || module_type == 'select_remote' || module_type == 'select_remote_multiple')
            var api = this.copyObj(this.designApiType[apiCode]);
            // console.log("designApiType", this.designApiType);
            // console.log(apiCode,module_type_form)
            if (!api) {
                api = {
                    name: apiCode,
                    url: "/" + apiCode + "/findSelectList.do",
                    value: "id",
                    label: "name",
                    labelLv: [24],
                    seq: "-",
                    headTitle: "名称".split(","),
                    remove: true
                }
            }
            api.module_type = module_type;
            // console.log(apiCode,module_type)
            if (api && module_type_form) {

                // console.log(type, c)
                var param = {apiCode$: apiCode};
                if (c && c.api_param_json) {
                    for (var k in c.api_param_json) {
                        param[k] = c.api_param_json[k]
                    }
                }
                var api_default_param = api.default_param;

                if (c.api_default_param) {
                    api_default_param = JSON.parse(c.api_default_param)
                }
                if (type == 'table' && c.api_default_param_table) {
                    api_default_param = JSON.parse(c.api_default_param_table)
                }
                if (api_default_param) {
                    for (var k in api_default_param) {
                        param[k] = api_default_param[k]
                    }
                }
                if (!ids)
                    ids = [];
                if (type == 'form') {
                    // console.log(col, this.obj[col])
                    if (this.obj[col] instanceof Array) {
                        ids = this.obj[col];
                    } else {
                        if (this.obj[col]) {
                            if (ids.indexOf(this.obj[col]) == -1) {
                                ids.push(this.obj[col])
                            }
                        }
                    }
                }
                if (type == 'table') {
                    // console.log("this.page.list",this.page.list)
                    // alert(type+"="+col)
                    this.page.list.forEach(v => {
                        var v2 = v[c.api_field || col];
                        if (v2) {
                            if (v2 instanceof Array) {
                                v2.forEach(o1 => {
                                    if (ids.indexOf(o1) == -1) {
                                        ids.push(o1);
                                    }
                                })
                            } else {
                                if (ids.indexOf(v2) == -1) {
                                    ids.push(v2);
                                }
                            }
                        }
                    });
                    param.limit = 0;
                    // console.log(c.api_field || col,ids)
                }

                if (!api.value) {
                    api.value = "id"
                }
                param.type$ = type
                param.sortField = api.sort_filed;
                param.sortRule = api.sort_rule;
                param.findMyFirmData = c.api_my_firm || false;
                param.findFirmAuth = c.api_is_firm || false;

                param.remote = api.remote
                param.valueField = api.value;
                if (param.remote) {
                    param.ids = ids;
                    // param.remote = true;
                    param.limit = 10;
                    param.keystr = keystr;
                    param.selectField = api.selectField || api.label;

                }


                if (this.$route.query) {
                    for (var k in this.$route.query) {
                        this.$set(param, k, this.$route.query[k]);
                    }
                }
                if (!colList) {
                    colList = [c];
                }
                var sessionKey = "apiCode#" + apiCode;
                var list = this.getSession(sessionKey);
                if (list == null) {
                    list = []
                }
                if (!param.remote) {
                    if (!list || list.length == 0) {
                        param.find = true;
                    } else {
                        this.selectFn(null, type, c, this.copyObj(list))
                    }
                }
                var sessList = []
                var sessionIdsKey = "apiCode#ids#" + apiCode;
                if (type == 'table') {

                    sessList = this.getSession(sessionIdsKey) || [];
                    if (sessList && sessList.length > 0) {
                        this.selectFn(null, type, c, sessList)
                    } else {
                        // param.find = true;
                    }

                    //
                }

                // console.log(type, apiCode, c)
                if(param.remote&&ids.length==0&&!param.keystr){
                    colList.forEach(c1 => {
                            this.selectFn(apiCode, type, c1, [])
                    })
                }else {
                    this.axiosPost(api.url, param, res => {
                        var listSess = [];
                        if (res.list && res.list.length >= 0) {
                            list = res.list;
                            listSess = this.sessionSaveIdsData(apiCode, api.value, list);
                        }
                        if (!param.remote) {
                            if (list.length > 0) {
                                this.setSession(sessionKey, list);
                                colList.forEach(c1 => {
                                    this.selectFn(apiCode, type, c1, list)
                                })
                            }
                        } else {
                            colList.forEach(c1 => {
                                if (type == 'table') {
                                    this.selectFn(apiCode, type, c1, listSess)
                                } else {
                                    this.selectFn(apiCode, type, c1, list)
                                }

                            })
                        }

                    })
                }
            }
        }
    }
    ,
    sessionSaveIdsData(apiCode, idKey, list) {
        var sessionIdsKey = "apiCode#ids#" + apiCode;
        var sessList = this.getSession(sessionIdsKey);
        if (sessList == null) {
            sessList = [];
        }

        var sessMap = this.listJsonToMap(sessList, idKey)
        var listMap = this.listJsonToMap(list, idKey)
        list.forEach(o => {
            var idVal = o[idKey];
            if (sessMap[idVal] == null) {
                sessList.push(o);
            }
        })
        var listAll = [];
        sessList.forEach(o => {
            var idVal = o[idKey];
            if (listMap[idVal] == null) {
                listAll.push(o);
            } else {
                listAll.push(listMap[idVal])
            }
        })
        this.setSession(sessionIdsKey, listAll);
        return listAll
    },
    selectFn(apiCode, type, c1, list) {
        // console.log(apiCode,type,list.length)
        var col1 = c1.keyName || c1.col;

        var api2 = this.copyObj(this.designApiType[c1.api || c1.table_api]);
        ;
        if (type == 'table') {
            api2 = this.copyObj(this.designApiType[c1.table_api || c1.api]);
        }

        // console.log("api2.value", api2.value)
        // if (apiCode) {
        //     this.sessionSaveIdsData(apiCode, api2.value, list);
        // }
        var table_label = (c1.api_show_field ? c1.api_show_field.split(",") : null) || (api2.table_label ? api2.table_label.split(",") : null) || api2.label;
        // console.log(apiCode,type,api2,table_label)
        this.selectList[col1] = this.listJsonToListJson(list, {
            value: api2.value,
            label: api2.label,
            table_label: table_label,
        }, api2.seq || this.selectSeqText);

        this.selectMap[col1] = this.listJsonToMap(this.selectList[col1], "value");
        this.selectMap[col1].api$ = api2;
        this.selectMap[col1].api$.labels = api2.label;
        // console.log(api2)
        if (table_label) {
            // console.log(col1,table_label)
            this.selectMap[col1].api$.table_label = table_label;
        }
        // this.selectMap[col].api$.titles = api.title.split(",");
        this.selectMap[col1].api$.label_size = this.selectMap[col1].api$.labels.length;
        // console.log(col1,(c1.api_show_field?c1.api_show_field.split(","):null) || api.table_label || api.label,this.selectList[col1],this.selectMap[col1])

    },
    selectSeqText: "◆",
    jsonToStr: function (json) {
        return JSON.stringify(json)
    }
    ,
    selectOptionRead(o, col) {
        if (col.api_read) {
            return eval(col.api_read)
        }
        return false;
    },
    selectOptionShow(o, col) {
        if (col.api_item_no_show) {
            return !eval(col.api_item_no_show)
        }
        return true;
    },
    strToJson: function (str) {
        if (!str) {
            return str;
        }
        return eval('(' + str + ')')
    }
    ,
    setSysConfig: function (data) {
        this.setSession('sysConfig', data)
    }
    ,
    setSysAreaId: function (data) {
        this.setSession('sysAreaId', data)
    }

    ,
    getSysAreaId: function () {
        return this.getSession('sysAreaId')
    }
    ,
    setSessionId: function (data) {
        this.setSession('sessionId', data)
    }
    ,
    getSessionId: function () {
        return this.getSession('sessionId')
    }
    ,
    getSysConfig: function () {
        var sysConfig = this.getSession('sysConfig');
        return sysConfig == null ? {} : sysConfig
    }
    ,
    callPhone: function (phoneNumber) {
        window.location.href = 'tel:' + phoneNumber
    }
    ,
    setTitle: function (title) {
        window.document.title = title
    }
    ,
    delTabMenu: function (path) {
        if (!path) {
            path = window.location.pathname + window.location.search;
        }
        this.setSession('delTabMenu', path)
    }
    ,
    getDelTabMenu: function () {
        var delTabMenu = this.getSession('delTabMenu');
        return delTabMenu
    }

    ,
    clearAllSession: function () {
        var noClearKeyStr = this.noClearKeyStr;
        if (!noClearKeyStr) {
            noClearKeyStr = ''
        }
        var noClearKeyList = noClearKeyStr.split(',');
        var noClearKeyMap = {};
        noClearKeyList.forEach((k) => {
            var val = localStorage.getItem(k);
            noClearKeyMap[k] = val
        });
        localStorage.clear();
        for (var k in noClearKeyMap) {
            localStorage.setItem(k, noClearKeyMap[k])
        }
    }
    ,
    setSession: function (key, jsonData) {
        try {
            if (jsonData) {
                var str=JSON.stringify(jsonData);
                // console.error(key,str)
                localStorage.setItem(key, str)
            } else {
                localStorage.removeItem(key)
            }
        } catch (oException) {
            localStorage.removeItem(key)
        }
    }
    ,
    getSession: function (key) {
        try {
            var val = localStorage.getItem(key);
            if (val) {
                var s = JSON.parse(localStorage.getItem(key));
                return s
            }
        } catch (e) {
            console.error(e);
            return localStorage.getItem(key)
        }
        return null
    }

    ,
    setLoginUser: function (data) {
        sessionStorage.setItem("loginAdminSession", new Date().getTime());
        this.setSession('loginSysUser', data);
        setTimeout(() => {
            this.wsLogin();
        }, 1000)
    }
    ,
    getLoginUser: function () {
        return this.getSession('loginSysUser')
    }

    ,
    setIconfontUrl(data) {
        this.setSession('iconfontUrl', data)
    },
    getIconfontUrl() {
        var se = this.getSession('iconfontUrl');
        if (!se) {
            se = ""
        }
        return se
    }
    ,

    getIconfontList: function (key) {
        this.axiosPost("/http/get.do", {url: this.getIconfontUrl()}, res => {
            var list = [];
            res.result.split("\n").forEach(o => {
                o = o.trim();
                if (o.indexOf(".iconfont-") == 0) {
                    var icon = o.substring(1, o.indexOf(":"));
                    list.push({icon: icon})
                }
            });
            if (key) {
                this[key] = list
            } else {
                this.iconList = list;
            }
        })
    },
    setLoginQjUser: function (data) {
        this.setSession('loginQjUser', data)
    }
    ,
    toUrl: function (url) {
        if (url.path) {
            this.$router.push(url.path)
        } else {
            this.$router.push(url)
        }

    }
    ,
    getLoginQjUser: function () {
        return this.getSession('loginQjUser')
    }

    ,
    getLoginUserId: function () {
        if (this.getLoginUser()) {
            return this.getLoginUser().id
        } else {
            return null;
        }
    }
    ,
    getLoginUsername: function () {
        if (this.getLoginUser()) {
            return this.getLoginUser().username
        } else {
            return null;
        }
    }
    ,
    getLoginStaff: function () {
        return this.getLoginUser().staff
    }

    ,
    setTagData: function (key, obj) {
        if (key == null) {
            return null
        }
        this.setSession('tagData' + key, obj)
    }
    ,
    saveTagData: function (to) {
        this.setTagData(to.fullPath, this.$data)
    }

    ,
    getTagData: function (key, str) {
        if (key == null) {
            return null
        }
        var data = this.getSession('tagData' + key);
        if (data == null) {
            return null
        }
        return this.getSession('tagData' + key)
    }
    ,
    byteStr: function (val) {
        if (val >= (1000 * 1024 * 1024)) {
            return (val / (1024 * 1024 * 1024)).toFixed(1) + "GB";
        } else if (val >= (1000 * 1024)) {
            return (val / (1024 * 1024)).toFixed(1) + "MB";
        } else if (val >= 1000) {
            return (val / 1024).toFixed(1) + "KB";
        } else {
            return val + "B";
        }
    }
    ,
    timesStr: function (val) {
        return this.dateFmt(new Date(1577808000000 + val), "HH:mm:ss")
    }
    ,
    lastTimeStr: function (date) {
        if (!date) {
            return "";
        }
        if (typeof date == 'string') {
            if (date.length >= 6 && date.length <= 7) {
                date = date + '-01'
            }
            date = new Date(date.replace(/-/g, '/'))
        } else if (typeof date == 'number') {
            date = new Date(date)
        }
        var num = new Date().getTime() - date.getTime();
        var yNew = this.dateFmt(new Date(), "yyyy");
        var y = this.dateFmt(date, "yyyy");
        if (num < 0) {
            return this.dateFmt(date, "yyyy-MM-dd HH:mm");
        } else if (num < (60 * 1000)) {
            return parseInt(num / 1000) + "秒前";
        } else if (num < (60 * 60 * 1000)) {
            return parseInt(num / 1000 / 60) + "分种前";
        } else if (num < (24 * 60 * 60 * 1000)) {
            return parseInt(num / 1000 / 60 / 60) + "小时前";
        } else if (num < (48 * 60 * 60 * 1000)) {
            return "昨天" + this.dateFmt(date, "HH:mm");
        } else if (yNew == y) {
            return this.dateFmt(date, "M月d日");
        } else {
            return this.dateFmt(date, "yyyy-MM-dd");
        }
    }
    ,
    tableSum: function (param, filedlList) {
        var filedList = filedList.split(',');
        const {
            columns,
            data,
        } = param;
        const sums = [];
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = '合计';
                return
            }
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value)) && (filedList.indexOf(column.property) > -1)) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr
                    } else {
                        return prev
                    }
                }, 0);
                sums[index] = sums[index].toFixed(2)
            } else {
                sums[index] = ''
            }
        });
        return sums
    }
    ,
    removeTagData: function (key) {
        localStorage.removeItem('tagData' + key)
    }
    ,
    findTagData: function (to) {
        var $this = this;
        // Object.assign(this.$data, this.$options.data())
        var $dataJson = $this.getTagData(to.fullPath);
        if ($dataJson == null) {
            return
        }
        for (var key in $dataJson) {
            try {
                var keyval = $dataJson[key];
                if (keyval != null && typeof keyval == 'object') {
                    keyval.tt$ = new Date()
                }
                $this.$set($this.$data, key, keyval)
            } catch (e) {
            }
        }
        setTimeout(function () {
            if ($this.$refs['obj'] != undefined) {
                $this.$refs['obj'].clearValidate()
            }
            try {
                $this.getRules()
            } catch (e) {
            }
        }, 50)
    }

    ,
    clearValidate: function (modelName) {
        try {
            if (this.$refs[modelName] != null) {
                setTimeout(() => {
                    this.$refs[modelName].clearValidate()
                }, 1)
            }
        } catch (e) {
        }
    }
    ,
    /*
        * 格式化数字显示方式
        * 用法
        * formatNumber(12345.999,'#,##0.00');
        * formatNumber(12345.999,'#,##0.##');
        * formatNumber(123,'000000');
        * @param num
        * @param pattern
        */
    numberFmt: (num, pattern) => {
        var fmtarr = pattern ? pattern.split('.') : [''];           // 格式化字串转为数组
        // 四舍五入处理
        var decimals = (fmtarr.length > 1 ? fmtarr[1].length : 0);  // 小数位
        num = Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
        var strarr = num ? num.toString().split('.') : ['0'];       // 值->字符串->数组
        var retstr = '';
        // 整数部分
        var str = strarr[0];
        var fmt = fmtarr[0];
        var i = str.length - 1;
        var comma = false;
        for (var f = fmt.length - 1; f >= 0; f--) {
            switch (fmt.substr(f, 1)) {
                case '#':
                    if (i >= 0) {
                        retstr = str.substr(i--, 1) + retstr;       // 加当前字符
                    }
                    break;
                case '0':
                    if (i >= 0) {
                        retstr = str.substr(i--, 1) + retstr;       // 加当前字符
                    } else {
                        retstr = '0' + retstr;                      // 补0
                    }
                    break;
                case ',':
                    comma = true;                                   // 有千位符
                    retstr = ',' + retstr;                          // 直接加上逗号（，）
                    break;
            }
        }
        // 整数字符串替换格式后剩余字符串处理
        if (i >= 0) {
            if (comma) {
                var l = str.length;
                for (; i >= 0; i--) {
                    retstr = str.substr(i, 1) + retstr;
                    if (i > 0 && ((l - i) % 3) == 0) {
                        retstr = ',' + retstr;                      // 加上千位符
                    }
                }
            } else {
                retstr = str.substr(0, i + 1) + retstr;             // 无千位符直接加在前面
            }
        }
        retstr = retstr + '.';
        // 处理小数部分
        str = strarr.length > 1 ? strarr[1] : '';
        fmt = fmtarr.length > 1 ? fmtarr[1] : '';
        i = 0;
        for (let f = 0; f < fmt.length; f++) {
            switch (fmt.substr(f, 1)) {
                case '#':
                    if (i < str.length) {
                        retstr += str.substr(i++, 1);               // 加当前字符
                    }
                    break;
                case '0':
                    if (i < str.length) {
                        retstr += str.substr(i++, 1);               // 加当前字符
                    } else {
                        retstr += '0';                              // 补0
                    }
                    break;
            }
        }
        // 返回
        // 1.去掉开头的逗号（，）和加号（+）；2.结尾的小数点（.)
        return retstr.replace(/^,+/, '').replace(/\.$/, '');
    },
    /**日期时间格式化函数*/
    dateFmt: function (date, fmt) {
        if (!date) {
            return date;
        }
        if (typeof date == 'string') {
            if (date.length >= 6 && date.length <= 7) {
                date = date + '-01'
            }
            date = new Date(date.replace(/-/g, '/'))
        } else if (typeof date == 'number') {
            date = new Date(date)
        }
        if (!fmt) {
            fmt = 'yyyy-MM-dd hh:mm:ss'
        } else {
            fmt = fmt.replace("YYYY", "yyyy").replace("DD", "dd")
        }
        var o = {
            'M+': date.getMonth() + 1, //月份
            'd+': date.getDate(), //日
            'h+': date.getHours(), //小时
            'H+': date.getHours(), //小时
            'm+': date.getMinutes(), //分
            's+': date.getSeconds(), //秒
            'q+': Math.floor((date.getMonth() + 3) / 3), //季度
            'S': date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
            }
        }
        return fmt
    }

    ,
    routeTagData: function (now, old, vueUrl) {
        if (old.path == vueUrl) {
            // alert("saveTagData" + old.fullPath)
            this.saveTagData(old)
        }
        if (now.path == vueUrl) {
            if (this.getTagData(now.fullPath) != null) {
                // alert("findTagData" + now.fullPath)
                // this.initData();
                this.findTagData(now)
            } else {
                // alert("initTagData" + now.fullPath)
                this.initTagData()
            }
        }

    }

    /* hasValInArrayObj: function (arr,key,val) {
         for (let i = 0;i<arr.length;i++){
             if(arr[i][key] == val)
                 return i;
         }
         return -1;
     }*/

    ,
    filedShow: function (fname, defVal) {
        var flatConfigFieldMap = {};
        if (this.tableName) {
            var flatConfigMap = this.flatConfig[this.tableName];
            if (flatConfigMap) {
                flatConfigFieldMap = this.listJsonToMap(flatConfigMap.fieldList, "field", "find")
            }
        }
        if (this.fieldConfig == null) {
            return defVal == null ? (flatConfigFieldMap[fname] == null ? true : flatConfigFieldMap[fname]) : defVal
        }
        var config = this.fieldConfig[fname];
        if (config == null) {
            console.error(fname + "不存在");
            return false
        }

        return config.$show
    }
    ,
    filedReadonly: function (fname, defVal) {
        if (this.formConfigItemMap) {
            var config = this.formConfigItemMap[fname];
            if (config) {
                //required,hide
                return config.readonly;
            }
            return false;
        }
        if (this.xhtmlUrl == true) {
            return false;
        }
        if (this.fieldConfig == null) {
            return defVal == null ? !this.addEditStatus() : defVal
        }
        var config = this.fieldConfig[fname];
        if (config == null) {
            return false
        }
        return config.$readonly == true
    }
    ,
    filedRequiredAll: function () {
        var $this = this;
        for (var k in $this.fieldConfig) {
            var r = $this.filedRequired(k);
            if ($this.rules[k] == null) {
                $this.$set($this.rules, k, [])
            }
            if (r) {
                $this.delArrItem($this.rules[k], $this.vRequired);
                $this.rules[k].push($this.vRequired)
            } else {
                $this.delArrItem($this.rules[k], $this.vRequired)
            }
        }
        // $this.$set($this, "rules", rules);
        setTimeout(function () {
            if ($this.$refs['obj'])
                $this.$refs['obj'].clearValidate()
        }, 10)
    }
    ,
    filedRequired: function (fname, defVal) {
        if (this.filedReadonly(fname) == true) {
            return defVal == null ? false : defVal
        }
        if (this.fieldConfig == null) {
            return true
        }
        var config = this.fieldConfig[fname];
        if (config == null) {
            return false
        }
        return config.$required
    }

    ,
    findVueConfig: function () {
        /*this.axiosPost("/sysVueConfig/findList.do", {}, function (res) {
          var list = res.list;
          var vueConfigMap = this.listJsonToMap(list, "vueKey");
          this.setSession("vueConfigMap", vueConfigMap);
        })*/
    }


    /**
     * 冒泡排序
     * @param list
     * @param key
     */
    ,
    bubblingSort: function (list, key) {
        var list2 = [];
        for (let i = 0; i < list.length - 1; i++) {
            for (let j = 0; j < list.length - 1 - i; j++) {
                if (list[j][key] > list[j + 1][key]) {
                    let temp = this.copyObj(list[j]);
                    list[j] = this.copyObj(list[j + 1]);
                    list[j + 1] = temp;
                }
            }
        }
        return list;
    }
    ,
    JsonFormat: function (json) {
        if (typeof json != 'string') {
            json = JSON.stringify(json, undefined, 2);
        }
        json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
//根据数字返回数字对应的拼音（A,B,C,D）
    ,
    indexToChar: function (index) {
        let charCode;
        return index.toString(26).split("").map(function (item, i) {
            charCode = item.charCodeAt(0);
            charCode += (charCode >= 97 ? 10 : 49);
            return String.fromCharCode(charCode)
        }).join("").toUpperCase();
    }
    ,
// 去除所有空格
    removeSpaces: function (str) {
        if (str != null) {
            return str.toString().replace(/\s/g, "");
        } else {
            return null;
        }
    }
}
