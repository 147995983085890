<template>
  <el-button :size="size" :icon="icon" :title="title" :disabled="disabled" :loading="loading" :plain="plain"></el-button>
</template>
<script>


  export default {
    name: "etBtn",
    components: { },
    data() {
      return {}
    },
    props: {
      size: {default: "small"},
      icon: {default: "small"},
      title: {default: "按钮"},
      fontColor: {default: ""},
      disabled: Boolean,
      loading: Boolean,
      plain: Boolean,
    },
    methods: {}, mounted: function () {
    }, watch: {}
  }
</script>
