export default {

  /**
   *
   * @param txt 消息内容
   * @param type 消息类型:success,warning,error
   */
  showConfirmHtml : function (title, content, fn, catchFn) {
    this.$confirm("<div style='color:red;text-align: left;font-size: 14px'>"+content+"</div>", title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true,
      dangerouslyUseHTMLString:true,
      confirmButtonClass: 'confirmBtnOk',
    }).then((e) => {
      this.runCallbackFn(fn);
    }).catch(() => {
      this.runCallbackFn(catchFn);
    })
  }
  ,
  showConfirm : function (title, content, fn, catchFn) {
    this.$confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true,
      dangerouslyUseHTMLString:true,
      confirmButtonClass: 'confirmBtnOk',
    }).then((e) => {
      this.runCallbackFn(fn);
    }).catch(() => {
      this.runCallbackFn(catchFn);
    })
  }
  ,showMsg : function (txt, type, duration, className) {
    if (!type) {
      type = "success";
    }
    if (!duration) {
      duration = 2000;
    }
    if (this.$vant != undefined) {
      this.$dialog.alert({
        title: txt,
        className: type
      }).then(() => {
      });
    } else {
      // this.$notify({
      //   // title: '标题名称',
      //   showClose: true,//显示关闭按钮
      //   duration: duration,//关闭时间
      //   dangerouslyUseHTMLString: true,
      //   type: type,//消息类型
      //   message: txt ? this.toHtmlBr(txt) : "",
      //   position: "top-right",
      //   customClass: className,
      //   offset: 50
      // });
      this.$message({
        dangerouslyUseHTMLString: true,
        message: txt ? this.toHtmlBr(txt) : "",//显示消息
        type: type,//消息类型
        duration: duration,//关闭时间
        showClose: true,//显示关闭按钮
      });
    }
  }
  /**
   * 成功消息
   */
  ,showMsgSuccess : function (txt, times) {
    if (times == undefined) {
      times = 1000;
    }
    this.showMsg(txt, "success", times);
  }
  /**警告消息*/
  ,showMsgWarn : function (txt, times) {
    if (times == undefined) {
      times = 1000 * 3;
    }
    this.showMsg(txt, "warning", times);
  }
  /**错误消息*/
  ,showMsgError : function (txt, times, wait) {
    var $this = this;
    if (times == undefined) {
      times = 1000 * 6;
    }
    if (wait != null) {

      setTimeout(function () {
        $this.showMsg(txt, "error", times);
      }, wait);
    } else {
      this.showMsg(txt, "error", times);
    }

  }
  /**异常提示*/
  ,showMsgExcept : function (ex) {
    console.error(ex);
    var duration = 1000 * 60 * 3;
    if ((ex + "") == 'Error: Network Error') {
      duration = 1000 * 10;
    }
    // this.$message({
    //   showClose: true,
    //   duration: duration,
    //   type: 'error',
    //   dangerouslyUseHTMLString: true,
    //   message: this.toHtmlBr(ex + "")
    // });
    // alert(1)
    this.showMsg(this.toHtmlBr(ex + ""), "error", duration, "msgExcept");
    this.closeLoad();
  }
  ,closeLoad : function () {
    if (this.loading) {
      try{
        this.loading=false
      }catch (e){
        console.log(e)
      }
    }
  }
  ,showNotify : function (msg, type, duration) {
    if (!duration) {
      duration = 0;
    }
    this.$notify({
      message: msg,
      duration: duration,
      type: type
    });
  }
  ,showNotifyWarning : function (msg, duration) {
    this.showNotify(msg, 'warning', duration);
  }
  ,showNotifyError : function (msg, duration) {
    this.showNotify(msg, 'error', duration);
  }
  ,showNotifyInfo : function (msg, duration) {
    this.showNotify(msg, 'info', duration);
  }
}