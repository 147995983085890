import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
        {path: adminPath + "/staffSurvey", component: () => import("./staffSurvey_index.vue")},
        {path: adminPath + "/staffQuit2", component: () => import("./staffQuit_index2.vue")},
        {path: adminPath + "/staffQuit", component: () => import("./staffQuit_index.vue")},
        {path: adminPath + "/staffInfo", component: () => import("./staffInfo_index.vue")},
        {path: adminPath + "/staffWage", component: () => import("./staffWage_index.vue")},
        {path: adminPath + "/staffWageDetail", component: () => import("./staffWageDetail_index.vue")},
        {path: adminPath + "/staffStarType", component: () => import("./staffStarType_index.vue")},
        {path: adminPath + "/yearMoneyStatement", component: () => import("./yearMoneyStatement_index.vue")},
    ],
    pages: []
}
