<script>
  import etBtnDelMini from '../../ydVue/elTemp/button/etBtnDelMini.vue'

  export default {
    name: "etBtnCancel",
    extends: etBtnDelMini,
    props: {
      isCancel: {default: 0},
      title: {
        default: function () {
          return ["点击作废", "已作废"][this.isCancel];
        }
      }, fontColor: {
        default: function () {
          return ["#666", "red"][this.isCancel];
        }
      },
    }
  }
</script>
