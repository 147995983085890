<template>
   <span v-if="value>=0" :title="value">
     <span v-if="value>(1024*1024*1000)" style="color: darkred">
       {{ (value / (1024 * 1024 * 1024)).toFixed(fixed) }} G/s
     </span>
            <span v-else-if="value>(1024*1000)" style="color: red">
              {{ (value / (1024 * 1024)).toFixed(fixed) }} M/s
            </span>
            <span v-else-if="value>1000" style="color: cornflowerblue">
              {{ (value / 1024).toFixed(fixed) }} K/s
            </span>
            <span v-else>
              {{ (value).toFixed(0) }} b/s
            </span>
   </span>
</template>
<script>
export default {
  name: 'etBytes',
  componentName: 'etBytes',
  data() {
    return {};
  },
  props: {
    fixed: {default: 1},
    value: {},
  },


  computed: {},

};
</script>
