<style lang="less"></style>
<template>
  <!--    <component :is="dialogComponents" ref="findDialog" @refresh="refresh"/>-->
  <div v-if="design" :class="{showDesign:design.show_type=='table'}">
    <component :is="dialogComponents" ref="findDialog" @refresh="refresh"/>
    <etBreadcrumb></etBreadcrumb>
    <etSearch ref="etSearch">
      <etDesignSearch :design="design" @refresh="refresh" :search="search" :loading="loading" :p$data="$data"
                      :sysMap="sysMap" @findPage="findPage" @findObj="findObj" @del="del" @whereRight="whereRight"
                      @setVal="setVal">
        <template #keystr1>自定义插槽代码示例</template>
        <template #default>
        </template>
      </etDesignSearch>
    </etSearch>
    <div class="mainContent " v-if="design&&design.table">
      <etDesignTable @setVal="setVal" @refresh="refresh" :page="page" :loading="loading" :design="design" :tHeight="tHeight" :sysMap="sysMap"
                     @selectionChange="selectionChange" @rowDblClick="rowDblClick" @colRight="colRight" :p$data="$data"
                     @headerDragend="headerDragend" @saveRow="saveRow"
                     @sortChange="sortChange" @findObj="findObj">
        <template #name1="o">
          自定义显示：{{ o.row.name }}
        </template>
        <template #keystr1="o">自定义插槽代码示例</template>
        <template #column-create_time>
          <!-- 自定义[列]插槽代码示例-->
          <el-table-column label="时间1" prop="create_time"></el-table-column>
        </template>
        <template #default>
          <el-table-column label="时间2" prop="create_time"></el-table-column>
        </template>
      </etDesignTable>
    </div>
    <etDesign ref="etDesign2" @setVal="setVal" :table_name="design.table_name" v-model="design" :dataList="page.list"
              :p$data="$data"
              show_type="table" class="indexTableDesign" :sysMap="sysMap" @findPageDesign="findPageDesign"
              @selectionChange="selectionChange"
              @rowDblClick="rowDblClick"
              @sortChange="sortChange"/>
    <!--    <sysConfigDialog :title="getMenuName()" ref="findDialog" @refresh="refresh"/>-->
  </div>

</template>
<script>
// import sysConfigDialog from '@/views/sys/sysConfigDialog.vue';
import designAdmin from '@/ydVue/design/designAdmin'
import {
  watch,
  reactive,
  ref,
  shallowReactive,
  onActivated,
  defineAsyncComponent,
} from 'vue'

const componentName = ref('') //保存需要加载的的组件名称
// const sysMenuFind=defineAsyncComponent(() => import("@/views/sys/sysTemp/etSysDict.vue"));
export default {
  name: "etDesignIndexPage",
  extends: designAdmin.indexPage,
  data() {
    return {
      dialogComponents: null,
      sysMenuFind: null,
    }
  },
  props: {
    aaaa: null,
  },

  methods: {
    init() {
      // var vueName = this.$route.query.t;
      // this.serach.table_name=this.$route.query.table_name
      var table_name = this.$route.query.table_name
      var design_code = "auto_" + table_name;
      var design_table_name = table_name;
      this.findPageUrl = "/sysDevDesign/findPageTable.do";
      this.dialogComponents = defineAsyncComponent(() => import("@/ydVue/design/etDesignDialog.vue"));
      this.axiosPost("/sysDevDesign/findDesignJs.dev", {code: design_code, table_name: design_table_name}, (res) => {
        // let requireObj = require('@/views/sys/' + table_name + '.js')
        if (res.design && res.design.design) {
          // alert(1)
          this.design = res.design.design;
        } else {
          this.design = {
            "code": "auto_" + table_name,
            "table_name": table_name,
            "table": {},
            "oper": {},
            "tableCols": [],
            "formCols": [],
            colMap: {},
            wheres: [],
            "colsList": [],
            "form": {},
            "show_type": null,
            "auto_sort": true
          }
        }
      })

    }
  },
  watch: {},
  components: {},
  mounted() {/*页面加载初始化*/

  }
}
</script>

