<style lang="less">
.setOauth {
  .el-form-item.transparent .el-form-item__content {
    background: transparent !important;
  }
}
</style>
<template>
  <el-button v-if="result.auth==false||dev$" @click="dialogVisible = true">许可授权</el-button>
  <el-dialog title="授权信息" v-model="dialogVisible" width="650" :show-close="true" :close-on-click-modal="false"
             :destroy-on-close="false" :close-on-press-escape="true">
    <el-form :model="obj" :rules="rules" ref="obj" label-suffix="" label-width="120px" size="small"
             class="formBox setOauth" style="margin: 0px;">
      <el-form-item prop="host_code" label="机器码" :disabled="true">
        <el-input v-model="obj.host_code" readonly/>
      </el-form-item>
      <el-form-item prop="public_code" label="请求码"  >
        <el-input type="textarea" autosize v-model="obj.public_code" disabled/>
        <div style="border-top: 1px #CCCCCC solid;width: 100%;text-align: right;" class="txt">
          <el-button type="primary" @click="copyText(obj.public_code)" style="margin: 10px">复制</el-button>
        </div>
      </el-form-item>
      <el-form-item prop="code" label="授权码">
        <el-input type="textarea" autosize v-model="obj.code" placeholder="请输入授权代码"/>
        <div style="border-top: 1px #CCCCCC solid;width: 100%">
          <el-button type="primary" @click="validate" style="margin: 10px">验证授权码</el-button>
        </div>
      </el-form-item>
      <el-form-item prop="authTime" class="transparent" label="许可证有效期" :style="{backgroundColor:result.auth?'#00ff00':'#ffd400'}">
        <div style="padding:0 10px;font-weight: bold;font-size: 16px ">
          <div v-if="result.auth">{{ result.authTime }}</div>
          <div v-else>{{ result.error }}</div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()" style="margin: 10px" type="info">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "setOauth",
  data() {
    return {
      obj: {},
      result: {},
      dialogVisible: false,
      rules: {
        code: [this.vRequired],
      }
    }
  },
  methods: {
    init() {

      this.axiosPost("/sysOauth/findSysOauth.do", {}, function (res) {
        this.$set(this, "obj", res.obj);
        this.$set(this.obj, "authTime", res.authTime);
        if (this.obj&&this.obj.code) {
          this.submit()
        }
      })
    },
    validate() {
      this.$refs['obj'].validate((valid) => {
        if (valid) {
          this.submit(true);
        } else {
          this.showMsgError("请输入验证码！")
          return false;
        }
      });
    },
    submit(tip) {
      this.axiosPost("/sysOauth/oauthValidate.do", this.obj, function (res) {
        this.$set(this, "result", res);

        if (res.auth && tip) {
          // this.showMsgSuccess("授权成功！有效期：" + res.authTime, 1000 * 60);
        }
      }, (res) => {
        this.$set(this, "result", res);
        // console.log(this.result)
      })
    },
    closeDialog() {
      this.$set(this, "dialogVisible", false);
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped>

</style>
