import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
        {path: adminPath + "/couponInfo", component: () => import("./couponInfo_index.vue")},
        {path: adminPath + "/couponUser", component: () => import("./couponUser_index.vue")},
    ]
}
