<template>
  <el-button link type="success" :style="{color:color}" :title="title" :disabled="disabled">
    <!--    <span v-if="type=='icon'">-->
    <template v-if="type=='icon'" #icon>
      <el-icon class="iconfont-fuzhi"/>
    </template>
    <!--    </span>-->
    <template v-if="type!='icon'">
      <slot v-if="this.$slots.default"></slot>
      <span v-else-if="title">{{ title }}</span>
    </template>
  </el-button>
</template>
<script>
export default {
  name: "etBtnEditMini",
  props: {
    type: {default: "text"},
    color: {default: ""},
    title: {default: "复制"}, disabled: Boolean,
  }
}
</script>
