<style lang="less">
.etDesignBtn {
  .btn {
    width: 100%;

    &.el-button + .el-button {
      margin-left: 0;
    }

    margin-bottom: 8px;
  }
}
</style>
<template>
<!--  <el-popover popper-class="etDesignBtn"   placement="top-start" :width="80" trigger="hover">-->
<!--    <div style="text-align:  center">-->
<!--      <el-button class="btn" @click="saveDesignJs()" style="color: green">保存</el-button>-->
<!--      <el-button class="btn" @click="design.tableList.push({col:'field'+design.tableList.length,name:'字段'+design.tableList.length,show:true,status:0,del:0})" style="color:red;">新增字段</el-button>-->

<!--      <el-button class="btn"-->
<!--                 @click="design.wheres.push({id:new Date().getTime(),key: 'keystr'+design.wheres.length, label: '关键字'+design.wheres.length,changeFind:true, type:'input',width:'160px',placeholder:'【更改描述】', sqlwhere: '自定义SQL'})">-->
<!--        新增查询-->
<!--      </el-button>-->
<!--      <el-button class="btn" @click="syncCol"><span >同步字段</span></el-button>-->
<!--    </div>-->
<!--    <template #reference>-->
      <el-button   @click="click">设计器</el-button>
<!--    </template>-->
<!--  </el-popover>-->
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etDesignBtn",
  components: {},
  data() {
    return {
      sysMap: {},
      search: {},
      showColDraggable: false,
      showTableConfig: false,
      element: {},
      where: {},
      contentmenuX: "",
      contentmenuY: "",
      contextmenuFlag: false,
      contextmenuWhere: false,
      loading: false,
      drag: false,
      showDesign: false,
      show_table_cols: [],
      // design: {
      //   cols: [],
      //   oper: {name:"操作",width:"120",show:true,edit:true,del:true},
      // },
      cols: [],
    };
  },
  props: {
    modelValue: null,
    table_name: {},
    obj: {},
    dataList: {},
    p$data: {},
  },
  setup(props, {emit}) {
    const design = ref(props.modelValue); // 初始化数据
    watch(
        () => design.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      design: design,
    }
  },
  methods: {
    click(){
      this.$emit("click");
    },
    saveDesignJs() {
      this.axiosPost("/sysDevDesign/saveDesignJs.do", {
        table_name: this.design.table_name,
        jsFileName: this.design.jsFileName,
        designJsonFile: this.design.designJsonFile,
        jsCode: JSON.stringify(this.design)
      }, () => {
        this.showMsgSuccess("保存成功")
      })
    },
    syncCol() {
      this.axiosPost("/sysDevDbTableCol/syncCol.do", {table_name: this.table_name}, (res) => {
        this.showMsgSuccess("同步完成", 10000)
        this.findCols();
      })
    },
  }, mounted: function () {
  }, watch: { //值改变动作 valObj(val){}

  }
}
</script>

