<style lang="less">

</style>
<template>
    <div>
        <el-table :data="orderServeItemList" row-key="id" border stripe empty-text="暂无数据" v-loading="loading">
            <el-table-column label="工单单号" align="center" width="120" prop="id"/>
<!--            <el-table-column prop="goods_server_item_code" label="服务商品项名称">-->

<!--            </el-table-column>-->
<!--            <el-table-column prop="status" label="状态" width="100"/>-->
            <el-table-column prop="server_num" label="服务次数" width="100"/>
<!--            <el-table-column prop="subsidy_money" label="补款金额" width="180"/>-->
        </el-table>
    </div>
</template>
<script>

    export default {
        name: "orderServeItemTmp",
        data() {
            return {
                orderServeItemList: [],
                loading: false,
            }
        },
        props: {
            orderServeId: {type: Number, default: 0},
        },
        created() {

        },
        methods: {
            findServeItemByOrderServeId() {
                this.orderServeItemList = [];
                if (this.orderServeId) {
                    this.loading = true;
                    this.axiosPost("/orderServeItem/findServeItemByOrderServeId.do", {orderServeId: this.orderServeId}, (res) => {
                        this.orderServeItemList = res.list;
                        this.loading = false;
                    })
                }
            }
        },
        watch: {
            orderServeId(newVal, oldVal) {
                this.findServeItemByOrderServeId();
            }
        },
        components: {},
        mounted: function () {/*页面加载初始化*/
            this.findServeItemByOrderServeId();
        }
    }
</script>
