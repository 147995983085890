<!--Breadcrumb 面包屑 -->
<template>
  <div class="etBreadcrumb">

    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="showTemp&&menuListTemp.length!=0">
      <!--<el-breadcrumb-item>
          <i  class="itemIcon iconfont-shouye-copy" style="font-size: 20px"></i><span class="itemName">首页</span>
      </el-breadcrumb-item>-->
      <el-breadcrumb-item><i class="iconfont-shijianzhou" style="color: #4d556b"></i>当前位置：</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(menu,index) in  menuListTemp" :key="index">
        <!--<i :class="'itemIcon '+menu.icon"></i>--><span class="itemName">{{ menu.name }}</span>
      </el-breadcrumb-item>
      <!--<span style="float: right">{{getSysConfig().copyright}}</span>-->
    </el-breadcrumb>

  </div>
</template>
<script>
export default {
  name: "etBreadcrumb",
  props: {
    showTip:{default:true},
    menuList: {
      type: Array,
      default: function () {
        return [];//或者是return {}
      }
    },
    show: {
      default: true
    }
  },
  data() {
    return {
      menuListTemp: this.menuList,
      showTemp: this.show
    };
  },
  methods: {
    init() {
      this.axiosPost("/sysAdmin/findBreadcrumb.do", {menu_fullPath: this.$route.fullPath}, (resData) => {
        if (resData.menuList != null) {
          this.$set(this, "menuListTemp", resData.menuList);
          window.document.title = this.menuListTemp[this.menuListTemp.length - 1].name + ' - ' + this.getSysConfig().sysName;
        } else if (this.menuListTemp.length == 0) {
          this.$set(this, "showTemp", false);
        }
      });
    }
  }, mounted: function () {
    // this.init();
  }, watch: { //值改变动作 valObj(val){}
//            '$route'(to, from) {
//                this.init();
//            }
  }
}
</script>

