<template>
  <span>
  <el-radio-group v-model="value2" :size="size" :disabled="disabled" @change="change">
    <el-radio-button v-for="(o,k) in list" :key="k" :style="{backgroundColor: o.color,borderColor:  o.color}" :label="o.val"
                     :class="[o.class,{customColor:o.color}]">{{o.name}}</el-radio-button>
  </el-radio-group>
    </span>
</template>
<script>
  export default {
    name: 'etStatusSubmit',
    props: {
      value: {},
      list: {type: Array, default: () => [{val: 1, name: '成功', color: 'green'}, {val: 0, name: '失败', color: 'red'}]},
      size: {default: "mini"},
      disabled: {default: false},
    },
    data() {
      return {
        value2: this.value,
      }
    }, methods: {
      change() {
        this.$emit('change');
      }
    }, watch: {
      value() {
        this.$set(this, "value2",this.value) ;
      },
      value2() {
        this.$emit('input', this.value2);
      }
    }
  }
</script>

