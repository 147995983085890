<template>
<!--  <el-button link  :style="{color:color}"   :disabled="disabled">-->
<!--    <slot v-if="this.$slots.default"></slot>-->
<!--    <template v-else-if="title">{{ title }}</template>-->
<!--  </el-button>-->
  <el-button link  :style="{color:color}"  :title="title" :disabled="disabled">
    <!--    <span v-if="type=='icon'">-->
    <template  v-if="type=='icon'" #icon>
      <el-icon class="iconfont-shanchusuoxuan"/>
    </template>
    <!--    </span>-->
    <template v-if="type!='icon'">
      <slot v-if="this.$slots.default"></slot>
      <span v-else-if="title">{{ title }}</span>
    </template>
  </el-button>
</template>
<script>

  export default {
    name: "etBtnDelMini",
    props: {
      type: {default: "text"},
      color: {default: "red"},
      title: {default: "删除"},
      disabled: Boolean,
    }
  }
</script>

