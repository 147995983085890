<!--Breadcrumb 面包屑 -->
<style lang="less">
.useDrawer{
  z-index: 9999 !important;
  //position: absolute !important;
  inset:auto !important;
  top: 0 !important;;
  right: 0 !important;;
  height: 100vh;
  width: 400px;

}
.remark.el-drawer {
  .el-drawer__header {
    margin: 8px 16px 0px 16px;

    .el-drawer__title {
      color: #333333;
    }
  }

  .el-drawer__body {
    padding: 8px 0;
  }

  .remarkContent {
    //border: 1px #DDDDDD solid;
    padding: 16px;
    line-height: initial;

    p {
      margin: initial;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
<template>
  <template v-if="design">

    <etBtnAdd @click="findObj({id:0})"  v-if="addEditStatus()&&addBtnShow()">
      <!--              this.$route.query.typeCode!=unit-->
      新建
    </etBtnAdd>
    <etBtnDel @click="del(selectionVals)" v-if="design&&design.table&&design.table.selectDel"/>
    <searchOption v-if="design.wheres&&design.colMap" :disabled="loading"
                  :exportBtn="design.table.export&&exportStatus()" :design="design"
                  :search="search" @exportTable="exportTable" @findPage="findPage">
      <slot name="default_left"></slot>
      <template v-for="c in design.wheres">
        <etSearchItem
            v-if="showWhere&&!(noShow&&noShow[c.keyName]==true || c.module_type=='firmAuth'&&getLoginUser().firm_my==1 || !showWhereItem(c))"
            :keyName="c.keyName=c.key||c.col"
            :label="c.label||(design.colMap[c.col]?design.colMap[c.col].name:'')"
            :class="{selected:design.selectWhere&&design.selectWhere.id==c.id,customItem:$slots[c.keyName]&&c.close_solt_where!=true}"
            @contextmenu.native="whereRight($event,c)">
          <!--      {{search[c.keyName]}}-->
          <template v-if="$slots[c.keyName]&&c.close_solt_where!=true">
            <slot :name="c.keyName"></slot>
          </template>
          <template v-else>
            <template
                v-if="c.module_type&&c.module_type.indexOf('_dict')>0||design.colMap[c.col]&&design.colMap[c.col].dict_code||c.dict_code">
                  <span v-if="!(c.dict_code||(design.colMap[c.col]&&design.colMap[c.col].dict_code)||c.dict_code)"
                        style="color:red;">
                    先选择字典编码
                  </span>
              <template v-else>
                <!--                <div1 v-model= 2">{{search[c.keyName]}}=</div1>-->
                <etSysDict :sysMap="sysMap"
                           :dict_code="c.dict_code||design.colMap[c.col].dict_code"
                           :dict_no_code="c.dict_code?c.dict_no_code:design.colMap[c.col].dict_no_code"
                           :placeholder="c.placeholder" :radioTitle="c.radioTitle||'全部'" v-model="search[c.keyName]"
                           :width="c.width||'160px'" @handleChange="searchValChange(c)"
                           :radio="dictTypeMap[c.module_type]&&dictTypeMap[c.module_type].radio"
                           :tree="dictTypeMap[c.module_type]&&dictTypeMap[c.module_type].tree"
                           :select="dictTypeMap[c.module_type]&&dictTypeMap[c.module_type].select"
                           :multiple="dictTypeMap[c.module_type]&&dictTypeMap[c.module_type].multiple"
                />
              </template>
            </template>
            <el-input-number v-else-if="c.module_type&&c.module_type=='inputNumber'" :controls="false"
                             :precision="c.precision"
                             :style="{width:c.width||'160px'}"
                             v-model="search[c.keyName]" @change="searchValChange(c)"/>
            <el-date-picker v-else-if="c.module_type&&c.module_type.indexOf('date')>-1" :placeholder="c.placeholder"
                            v-model="search[c.keyName]"
                            :type="c.module_type" :format="c.timeFormat||timeFormatMap[c.module_type]"
                            :value-format="c.timeFormat||timeFormatMap[c.module_type]" @change="searchValChange(c)"
                            clearable
                            :style="{width:c.width||'160px'}"/>
            <el-date-picker v-else-if="c.module_type&&c.module_type=='month'" :placeholder="c.placeholder"
                            v-model="search[c.keyName]"
                            :type="c.module_type" :format="c.timeFormat||timeFormatMap[c.module_type]"
                            :value-format="c.timeFormat||timeFormatMap[c.module_type]" @change="searchValChange(c)"
                            clearable
                            :style="{width:c.width||'160px'}"/>
            <template v-else-if="c.module_type=='tree'">
              <!--          {{selectList[design.colMap[c].col]}}-->
              <el-cascader v-if="selectList[c.col]&&selectList[c.col].length>0"
                           :change-on-select="true" :options="selectList[c.col]"
                           @change="searchValChange(c)"
                           expand-trigger="hover" filterable clearable
                           :disabled="formItemRead(c)"
                           v-model="search[c.col]"
                           separator=">" :style="[{width:c.width||'120px'}]"
                           :props="{checkStrictly:true,multiple:false,emitPath:false,label:'name'}"/>
            </template>
            <template
                v-else-if="selectMap[c.keyName]&&selectMap[c.keyName].api$">
              <selectApiTemp :sysMap="sysMap" :selectMap="selectMap" :design="design" @changeFun="searchValChange(c)"
                             type="search" @remoteMethod="remoteMethod" :selectList="selectList" v-model="search"
                             :placeholder="c.placeholder"  :col="c" :colKey="c.keyName"/>
              <!--              <el-cascader v-if="selectMap[c.keyName].api$.type=='cascader'"
                                         :change-on-select="true" :options="selectList[c.keyName]"
                                         expand-trigger="hover" filterable
                                         v-model="search[c.col]" clearable
                                         separator=">" :style="{width:c.width||'160px'}"
                                         :props="{checkStrictly:true,multiple:false,emitPath:false}" @change="searchValChange(c)"/>

                            <el-select
                                v-else
                                v-model="search[c.keyName]" filterable clearable @change="searchValChange(c)" :max-collapse-tags="1"
                                :multiple="selectMap[c.keyName].api$.module_type=='select_multiple'"
                                :collapse-tags="selectMap[c.keyName].api$.module_type=='select_multiple'"
                                :style="{width:c.width||'160px'}"
                            >
                              <el-option
                                  :style="{minWidth: selectMap[c.keyName].api$?((selectMap[c.keyName].api$.width||320)+30)+'px':'320px'}"
                                  class="selectApi" disabled
                                  key="#1" label=""
                                  v-if="selectMap[c.keyName]&&selectMap[c.keyName].api$&&selectMap[c.keyName].api$.headTitle"
                                  value="#">
                                <el-row class="header-panel">
                                  <el-col
                                      :style="{maxWidth:selectMap[c.keyName].api$.labelWidthLv[ki]||((selectMap[c.keyName].api$.labelWidth[ki]||120)+'px')}"
                                      v-for="(k,ki) in selectMap[c.keyName].api$.headTitle">
                                    {{ k }}

                                  </el-col>
                                </el-row>
                              </el-option>
                              <el-option v-if="selectList[c.keyName].length==0" :value="0" disabled>
                                <div style="text-align: center;color: #999999">
                                  无数据
                                </div>
                              </el-option>
                              <el-option v-for="item in selectList[c.keyName]" :key="item.value" :label="item.label"
                                         :value="item.value"
                                         class="selectApi "
                                         :style="{minWidth: selectMap[c.keyName].api$?((selectMap[c.keyName].api$.width||320)+30)+'px':'320px'}">
                                <el-row v-if="selectMap[c.keyName].api$">
                                  <el-col
                                      :style="{maxWidth:selectMap[c.keyName].api$.labelWidthLv[ki]||((selectMap[c.keyName].api$.labelWidth[ki]||120)+'px')}"
                                      class="apiSelectItem" v-for="(k,ki) in  selectMap[c.keyName].api$.labels">
                                    <template v-if="selectMap[c.keyName].api$.labelDict&&selectMap[c.keyName].api$.labelDict[k]">
                                      <etSysDict :sysMap="sysMap" v-model="item[k]" text
                                                 :dict_code="selectMap[c.keyName].api$.labelDict[k]"></etSysDict>
                                    </template>
                                    <span v-else :title="objKeyVal(item,k)"> {{ objKeyVal(item, k) }}  </span>

                                  </el-col>
                                </el-row>
                              </el-option>
                            </el-select>-->
            </template>
            <el-input v-else-if="!c.module_type||c.module_type=='input'" :placeholder="c.placeholder+''" size="small"
                      :style="{width:c.width||'320px'}"
                      v-model="search[c.keyName]" @change="searchValChange(c)"
                      @keyup.enter.native="searchValChange(c)" clearable/>
            <template v-else-if="c.module_type=='firmAuth'">
              <firm_temp :sysMap="sysMap" v-model="search[c.keyName]" @change="searchValChange(c)"
                         :style="{width:c.width||'240px'}"/>
            </template>
          </template>
        </etSearchItem>
      </template>

      <slot name="default"></slot>
    </searchOption>
    <etBtnAdd @click="updateTreeFullName()" icon=""
              v-if="design.table.tree_update_full_name">
      更新全称
    </etBtnAdd>

    <a style="position:absolute;     top: -20px;    right: 0px;cursor: pointer;color: cornflowerblue"
       v-if="design.table.show_use_remark!=false" @click="findRemark(false)">
      <i class="iconfont-b71" style="font-size: 18px"/>
    </a>
    <el-drawer modal-class="useDrawer" class="remark" v-model="drawer" :modal="false"    size="400px" title="功能使用说明" :direction="'rtl'">
      <div v-if="showTipDialog">
        <div style="border:1px #ccc solid">
          <etRichTextBox col="content" :height=" '700px'" v-model:modelValue="remark"></etRichTextBox>
        </div>
        <div style="text-align: center;margin-top: 16px;">
          <el-button type="success" @click="saveRemark()">保存</el-button>
          <el-button @click="showTipDialog=false">关闭</el-button>
          <sysDataUpdateListDialog :tableName="remarkDesign.table_name"
                                   :design="remarkDesign" :objId="remark.id"></sysDataUpdateListDialog>
        </div>
      </div>
      <div v-else>
        <div class="remarkContent" v-html="remark.content"></div>

      </div>
      <template #footer>
        <div v-if="!showTipDialog&&getLoginUser().oper_use_remark==1" style="margin-top: 16px;text-align: center">
          <el-button @click="findRemark(true)" type="success">编辑</el-button>
        </div>
      </template>
    </el-drawer>
    <el-dialog v-if="false" v-model="showTipDialog" title="功能使用说明" width="600px" top="10vh" :show-close="true">

      <div style="border:1px #ccc solid" v-if="showTipDialog">
        <etRichTextBox col="content" :height=" '600px'" v-model:modelValue="remark"></etRichTextBox>
      </div>
      <div style="text-align: center">
        <el-button type="success" @click="saveRemark()">保存</el-button>
        <el-button @click="showTipDialog=false">关闭</el-button>
      </div>
    </el-dialog>
  </template>
</template>
<script>
import {ref, watch} from 'vue'
import sysUseRemark from '../../views/sys/sysUseRemark.js';
export default {
  name: "etDesignSearch",
  components: {},
  data() {
    return {
      drawer: false,
      showTipDialog: false,
      remark: {},
      remarkDesign: sysUseRemark.design,
      selectList: {},
      selectMap: {},
    };
  },
  emits: ["findPage", "findObj", "del", "whereRight", "exportTable", "setVal", "refresh"],
  props: {
    modelValue: null,
    noShow: {},
    showWhere: {default:true},
    loading: {},
    tHeight: {},
    design: {},
    showDesign: {},
    search: {},
    page: {},
    row: {},
    col: {},
    c: {},
    sysMap: {},
    p$data: {},

  },
  setup(props, {emit}) {
    const design = ref(props.design); // 初始化数据
    const search = ref(props.search); // 初始化数据
    watch(
        () => search.value,
        (data) => emit('update:modelValue', data)
    );
    watch(
        () => design.value,
        (data) => emit('update:design', data)
    );
    return {
      search: search,
      design: design,
    }
  },
  methods: {
    addBtnShow() {
      if (this.design && this.design.table && this.design.table.addBtn) {
        var loginSys = this.getLoginUser();
        var $rq = this.$route.query;
        if (this.design.table.add_no_show_rule) {
          return !eval(this.design.table.add_no_show_rule)
        }
        return true
      }
      return false;
    },
    searchValChange(c) {
      if (c.changeFind) {
        this.findPage()
      }
    },
    updateTreeFullName(c) {
      this.search.updateTreeFullName = true;
      this.findPage()
    },
    findPage(type) {
      this.$emit("findPage", type)
    },
    exportTable() {
      this.$emit("exportTable")
    },
    whereRight($event, c) {
      this.$emit("whereRight", $event, c)
    },
    del(ids) {
      this.$emit("del", ids)
    },
    findObj(val) {
      this.$emit("findObj", val)
    },
    findRemark(show) {
      this.axiosPost("/sysUseRemark/findByUrl.do", {url: this.$route.path}, res => {
        this.remark = res.obj
        if (show) {
          this.showTipDialog = true
        }else{
          this.drawer=true;
        }
      })
    },
    saveRemark() {
      this.axiosPost("/sysUseRemark/save.do", {obj: this.remark}, res => {
        this.showMsgSuccess("保存成功")
        this.showTipDialog = false
      })
    },

  }, mounted: function () {
    // this.initDefaultVal();
    // this.findRemark(false);
    this.initSelect('search');
  }, watch: { //值改变动作 valObj(val){}
  }
}
</script>
