import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
        {
            path: adminPath + "/serverEvalStaffScoreType",
            component: () => import("./serverEvalStaffScoreType_index.vue")
        },
    ],
    pages: []
}
