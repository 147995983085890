<style lang="less">
.ydFromTemp {

}
</style>
<template>
  <table>
<!--    <slot></slot>-->
    <tr v-if="slotsDefault" v-for="(item,i) in  slotsDefault">
      <td><slot :item="item"></slot>={{i}}</td>
    </tr>
  </table>
</template>
<script>

export default {
  name: "ydFromTemp",
  data() {
    return {
      slotsDefault:[],
      value2: this.value,
    }
  },
  props: {
    value:{},
    rules:{},
  },
  created() {
  },
  methods: {},
  watch: {
    value2() {
    },
    value() {
    }

  }
  ,
  components: {}
  ,
  mounted: function () {/*页面加载初始化*/
    this.$set(this,"slotsDefault",this.$slots.default)
    console.log("slotsDefault",this.slotsDefault)
  }
}
</script>
