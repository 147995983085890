/**
 *   分页页面
 */
import data from "@/data";

const indexPage = {
    data() {
        return {
            vueName: null,//参数获取
            design: null,
            indexPageName$: "indexPage",
            scrollMap$: {},
            fullPath$: "",
            sysMap: {},
            sysUserList: [],
            findPageUrl: null,//重定向分页查询接口
            funType: 'page',//tree为树形结构
            tHeight: 130,
            dHeight: 650,
            tHeight2: null,
            title: '功能标题',
            treeList: [{children: []}],
            author: {},//权限
            loading: false,//加载效果
            baseUrl: null,//默认访问地址,必须继承修改,如【/sys_User】
            findObjDialog: '继承修改',//查询单个对象组件名，如【sysUserFind】
            page: {pageNumber: 1, pageSize: 20, list: []},//分页数据集，包含页码、页大小
            sort: {prop: 'create_time', order: 'descending'},//排序数据集
            search: {isDel: 0},//查询参数
            selectionVals: [],//tabled多选项值
        }
    },
    props: {//扩展，重写
        doUrl: {default: false},
    },
    methods: {
        headerDragend(newWidth, oldWidth, column, event) {
            // console.log(1)
            if (newWidth) this.design.colMap[column.property].width = parseInt(newWidth)
        }, whereRight($event, c) {
            $event.preventDefault();
            $event.stopPropagation();
            setTimeout(() => {
                this.design.show_type = "table";
                this.design.showWhere = false;

                if (!this.design.selectWhere || this.design.selectWhere.col != c.col) {
                    this.design.showWhere = true;
                    this.design.selectWhere = c;
                    this.design.tabs = "showWhere";
                } else {
                    this.design.selectWhere = null;
                    this.design.tabs = "default";
                }

            }, 10)
        }, colRight(column, event) {
            if (this.dev$) {
                event.preventDefault();
                event.stopPropagation();
                setTimeout(() => {
                    this.design.show_type = "table";
                    this.design.showTableCol = false;
                    var col = column.property;
                    if (!this.design.selectTableCol || this.design.selectTableCol.col != col) {
                        this.design.showTableCol = true;
                        if (!this.design.colMap[col].export) {
                            this.design.colMap[col].export = false
                        }
                        this.design.selectTableCol = this.design.colMap[col];

                        this.design.tabs = "showTableCol";
                    } else {
                        this.design.tabs = "default";
                        this.design.selectTableCol = null;
                    }

                }, 10)
            }
        }, getSummaries(param) {
            var $this = this;
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                if ($this.page && $this.page.result && $this.page.result.total) {
                    if ($this.page.result.total[column.property]) {
                        var val = $this.page.result.total[column.property];
                        if (typeof val == 'number') {
                            val = this.numFmt(val)
                        }
                        sums[index] = val;
                    } else {
                        sums[index] = "";
                    }
                }
            });
            return sums;
        }, uploadExcelJson(jsonList, fn, erFn) {

            this.axiosBatchImport({list: jsonList}, fn, erFn)

        }, setVal(key, val) {
            this.baseSetVal(key, val)
        }, sortChange(sort) { /*排序变化操作*/
            this.psortChange(sort)
        }, psortChange(sort) { /*排序变化操作*/
            this.$set(this, 'sort', sort);
            this.findPage('search')
        }, updateCell(param, callbackFn, showResultMsg, cancelCallback) {//隐藏数据栏
            this.axiosUpdateCell(param, callbackFn, null, null, cancelCallback);
        }, init() {
        }, autoSQL() {
            if (this.design) {
                var auto$ = [];
                var wheresMap = this.listJsonToMap(this.design.wheres, "key");
                for (var k in this.search) {
                    var v = wheresMap[k];
                    if (v) {
                        auto$.push({key: k, where: v.sqlwhere, field: v.sql_field})
                    }
                }
                this.search.auto$ = auto$;
                console.log("auto$", auto$)
            }
        }, findPageDesign(search) {
            this.search = search;
            this.findPage();
        }, findPage(type) {


            var findTable = false;
            this.design.wheres.forEach(o => {
                if (this.search[o.keyName] != null && (this.search[o.keyName] + '') != '') {
                    findTable = true;
                }
            });
            // console.log(findTable);
            // this.$route.query.forEach(o=>{
            //     console.log(o)
            // })
            // if (this.dev$) {
            //     this.search.designJson$ = this.design;
            // }
            if (this.design.table.tree && !findTable) {
                this.search.findPageTree = this.design.table.tree;
                this.search.pid = this.$route.query.pid || eval(this.design.table.tree_id) || 1;
                // console.log( this.search.pid)
            } else {
                this.page.list = [];
                this.search.findPageTree = false;
            }
            this.pfindPage(type, (resData) => {
                //通过回调数据处理
                delete this.search.updateTreeFullName
            });
        }, initSearchDefVal() {
            // console.log(this.design)
            for (var k in this.$route.query) {
                this.search[k] = this.$route.query[k];
            }
            if (this.design) {

                this.page.pageSize = this.design.table.pageSize || 20;
                this.design.wheres.forEach(c => {
                    var newDate = this.dateFmt(new Date(), "yyyy-MM-dd");
                    var newDates = [newDate, newDate];
                    var newTime = this.dateFmt(new Date(), "yyyy-MM-dd HH:mm:ss");
                    var newTimes = [newDate + " 00:00:00", newDate + " 23:59:59"];
                    if (c.default_value) {
                        try {
                            this.search[c.keyName] = eval(c.default_value)
                        } catch (e) {
                            console.error(e, c.default_value);
                            this.search[c.keyName] = eval('"' + c.default_value + '"')
                        }
                    }
                })
            }
        }, exportTable() {
            var reqData = this.copyObj(this.search);
            var colMap = this.design.colMap;
            var dictList = [];//字典数组
            var translateList = [];//翻译数组
            var apiList = [];//API翻译数组
            var exportKeyList = [];//导出字段数组
            var exportTitleList = [];//导出字段标题数组
            var mapperList = [];
            if (!this.design.exportList) {
                alert("设计器没有包含:exportList");
                return;
            }
            this.design.exportList.forEach(o => {
                for (var k in colMap) {
                    if (o == k) {
                        //勾选导出
                        if (colMap[k].export) {
                            //api接口
                            var api = colMap[k].table_api || colMap[k].api;
                            if (api) {
                                var obj = {};
                                var obj1 = {};
                                obj1.label$ = this.listJsonToStr(this.designApiType[api].label, ",");
                                obj1.value$ = this.designApiType[api].value;
                                obj1.remote = true;
                                obj1.data_field = k;
                                // obj1.default_param$ = this.designApiType[api].default_param;
                                obj1.url$ = (this.designApiType[api].url).replace(".do", ".local");
                                obj[colMap[k].col] = (this.designApiType[api].url).replace(".do", ".local");
                                obj[colMap[k].col] = obj1;
                                apiList.push(obj);
                            }
                            //把字段和标题放入
                            exportKeyList.push(colMap[k].col);
                            exportTitleList.push(colMap[k].export_name || colMap[k].show_name);
                            //如果是字段
                            //放入字典和翻译
                            if (colMap[k].dict_code) {
                                dictList.push(colMap[k].dict_code);
                                var obj = {};
                                obj[colMap[k].col] = colMap[k].dict_code + "Map";
                                translateList.push(obj);
                            }
                            //映射字段
                            if (colMap[k].col && colMap[k].col_data) {
                                var obj = {};
                                obj[colMap[k].col] = colMap[k].col_data;
                                mapperList.push(obj);
                            }
                        }
                    }
                }
            });
            //翻译字段
            reqData.translateList = translateList;
            //字段apiList
            reqData.apiList = apiList;
            //导出字段
            reqData.exportKeyList = exportKeyList;
            //导出标题
            reqData.exportTitleList = exportTitleList;
            //排序
            if (this.sort) {
                reqData.prop = this.sort.prop;
                reqData.order = this.sort.order;
            }
            //分页
            if (this.page) {
                reqData.pageNumber = 1;
                reqData.pageSize = 10000;
            }
            //文件名称
            reqData.fileName = this.design.export_file_name || this.design.table_name;
            //action路径 下划线转驼峰
            var actionName = this.toCamel(this.design.table_name);
            //字典
            var dictArray = [];
            dictList.forEach(o => {
                var key = o + "Map";
                var obj = {};
                obj[key] = this.sysMap.sysDict[o];
                dictArray.push(obj);
            });
            reqData.dictArray = dictArray;
            //映射字段
            reqData.mapperList = mapperList;
            this.axiosPost("/" + actionName + "/exportTable.action", reqData, res => {
                window.open(res.url)
            });
        },

        pfindPage(type, callbackFn, showResultMsg) {/*查询分页*/
            if (type == 'search') {//判断是否查询
                this.page.pageNumber = 1;
            }
            var reqData = this.copyObj(this.search);
            if (this.config && this.config.searchMap) {
                for (var k in reqData) {
                    if (this.config.searchMap[k] == false) {
                        // reqData[k]=null
                        delete reqData[k];
                    }
                }
            }
            if (this.isScrollTable == true) {
                if (this.loading == true || this.page.pageNumber == -1) {
                    return
                }
                // this.search.isCount$ = false
                if (type == 'search') {
                    this.$set(this, 'pageList', [])
                } else {
                    this.page.pageNumber = this.page.pageNumber + 1
                }
            }

            if (this.sort) {
                reqData.prop = this.sort.prop;
                reqData.order = this.sort.order
            }
            if (this.page) {
                reqData.pageNumber = this.page.pageNumber;
                reqData.pageSize = this.page.pageSize || 50
            }
            if (this.config && this.config.searchList) {
                reqData.searchList = this.config.searchList;
            }
            // alert(this)
            reqData.noCountRow = this.design.table.noCountRow
            if (this.funType == 'tree') {
                this.axiosPost(this.baseUrl + '/findTree.action', reqData, (resData) => {
                    if (showResultMsg == true) {
                        this.$message({message: resData.$message, type: 'success'})
                    }
                    this.$set(this, 'treeList', resData.treeList);
                    this.runCallbackFn(callbackFn, resData);
                    this.loading = false;
                    this.initTableHeight();
                    this.jsHeight();
                    this.page.$times$=new Date().getTime()
                })
            } else {
                // var page = this.copyObj(this.page);

                this.axiosPage(reqData, function (resData) {
                    if (this.isScrollTable == true) {
                        // if (resData.page.totalRow >= 0) {
                        //     this.totalRow = this.page.totalRow
                        // } else {
                        //     this.page.totalRow = page.totalRow || this.totalRow
                        // }
                        // if (this.page.list.length != this.page.pageSize) {
                        //     this.page.pageNumber = -1;
                        // }
                        // this.page.list.forEach(o => {
                        //     this.page.list.push(o)
                        // })
                    } else {
                        this.page.list = resData.page.list;
                        // console.log(this.page,this.page.pageNumber)
                        this.page.totalRow = resData.page.totalRow
                        if (resData.page.totalRow > 0) {
                            this.page.totalRow = resData.page.totalRow
                        } else {
                            // this.$set(this.page, "totalRow", page.totalRow);
                        }
                    }


                    // this.loading = false
                    this.loading = false;
                    this.page.$times$=new Date().getTime()
                    this.runCallbackFn(callbackFn, resData);
                    this.initTableHeight();
                    this.jsHeight();
                }, showResultMsg)
            }
        }, pexportPage(callbackFn) {/*导出数据*/
            var reqData = this.search;
            reqData.prop = this.sort.prop;
            reqData.order = this.sort.order;
            reqData.pageNumber = 1;
            reqData.pageSize = 10000;
            var url = this.baseUrl + '/exportXls.do';
            this.axiosPost(url, reqData, callbackFn)
        }, refresh(type) {/*刷新*/
            this.findPage(type)
        },initRowEdit(row) {//初始化行编辑其他值
        }, findObj(row, copy, test) { /*查询表单对象obj*/
            if (!test) {
                test = row;
            }
            if (this.design.table.edit_table) {

                if ((row.id + "").indexOf("#") == 0) {
                    this.delArrItem(this.page.list, row)
                }
                if (!row.id) {
                    row.id = "#" + new Date().getTime();
                    this.page.list.unshift(row)
                }
                this.initRowEdit(row);//初始化其他值
                row.edit$ = row.edit$ ? false : true

            } else {
                row.edit$ = false;

                setTimeout(() => {
                    this.$refs["findDialog"].findObj(row.id, copy, test);
                }, 10);
            }
        }, findObjId(id, copy, test) { /*查询表单对象obj*/


            setTimeout(() => {
                this.$refs["findDialog"].findObj(id, copy, test);
            }, 10);
        }, saveRow(row) { /*查询表单对象obj*/
            var obj = this.copyObj(row);
            if ((obj.id + "").indexOf("#") == 0) {
                delete obj.id;
            }
            this.axiosAddEditRow({obj: obj}, (res) => {
                this.showMsgSuccess("保存成功");
                for (var k in res.obj) {
                    row[k] = res.obj[k]
                }
                this.findObj(row)
            })
        }, selectionChange(val) { /*多选操作*/
            this.pselectionChange(val)
        }, pselectionChange(val) { /*多选操作*/
            this.$set(this, 'selectionVals', val)
        }, /*删除数据*/
        del(ids) {
            this.pdel(ids)
        }, /*删除数据*/
        pdel(ids, key, code) {
            if (ids == undefined) {
                ids = this.selectionVals
            }
            console.log("ids", ids)
            this.axiosDel(ids, key, code, function () {
                this.refresh()
            })
        }, /*多选框是否可操作判断*/
        selectable(row, index) {
            var a = this.operDelShow(row);
            console.log(a, row);
            return this.operDelShow(row)
        }, /*双击行编辑*/
        rowDblClick(row) {
            if (this.design.table.edit_table) {

            } else {
                row.edit$ = false;
                this.findObj(row)
            }
        },

        /*初始化表高度*/
        initTableHeight() {
            var $this = this;
            if (!$this.tHeight2){
                $this.tHeight2 = $this.tHeight;
            }
            if ($this.$refs.etSearch && $this.$refs.etSearch.$el.clientHeight > 0) {
                $this.$set($this, "tHeight", $this.tHeight2 + $this.$refs.etSearch.$el.clientHeight);
            }
        }, runFun(funName, datas) {
            this.thisRunFun({funName: funName, datas: datas})
        }
    }, watch: { //值改变动作 valObj(val){}
    }, mounted() {/*页面加载初始化*/
        this.initSearchDefVal();
        this.runFun("init");
        // this.init()
        // this.refresh()
        this.configCompare();
        this.axiosAuthor();//权限验证
        this.scrollMonitor("table");
        this.findSysMap();
        var $this = this;
        setTimeout(() => {
            this.runFun("refresh");
            this.initTableHeight();//初始化表高度
            this.jsHeight();//计算表高度
        }, 10)
    }, created() {
        //页面刚进入时
    }, destroyed: function () {
        //页面销毁时
    }, beforeRouteLeave(to, from, next) {
        //Vue离开
        next()
    }, activated() {
        //初始化及激活时，
        this.initScrollMonitor("table");
    }
};


/**
 *  查询单个对象-对话框
 */
const formPage = {
    data() {
        return {
            save_colse: true,
            objLoad: true,
            tHeight: 170,
            top: "10vh",
            addFirmId: false,
            tableName: '',
            formConfigItemMap: null,
            formItemRead: false,
            sysMap: {},
            useVMap: {},
            dataMap: {},
            showNameMap: {},
            copy$: false,
            ptreeList: [],
            sysUserList: [],
            sysUserMap: [],
            userAllMap: {},
            sysTypeAll: [],
            sysTypeMap: {},
            pidTree: [],//上级类别
            author: {},//权限
            dialogVisible: false,//是否显示对话框
            loading: false,//是否显示加载效果
            baseUrl: null,//默认访问地址
            obj: {},//表单值
            oldObj$: {},//表单值
            rules: {},//表单校验
            // login_id: localStorage.getItem('login_id'),//当前登录用户ID
            showResultMsg: true,//新增修改成功后是否提示
        }
    }, props: {//扩展，重写
        title: String, doUrl: {default: false}, xhtmlUrl: {default: false}, fullPath: {default: ''},
    }, methods: {
        changeFun(fun_name, val) {
            console.log(fun_name, val);
            this.thisRunFun({funName: fun_name, datas: val})
        }, formItemRight($event, c) {
            this.design.showTableCol = false;
            this.design.selectTableCol = null;
            event.preventDefault();
            event.stopPropagation();
            setTimeout(() => {
                if (this.design.selectTableCol && this.design.selectTableCol.col == c.col) {
                    this.design.tabs = "default";
                } else {
                    this.design.show_type = "form";
                    this.design.showTableCol = true;
                    if (!this.design.colMap[c.col].export) {
                        this.design.colMap[c.col].export = false
                    }
                    this.design.selectTableCol = this.design.colMap[c.col];

                    this.design.tabs = "showTableCol";
                }

            }, 10)
            // this.$emit('whereRight', selectW, c)
        }, setVal(key, val) {
            this.baseSetVal(key, val)
        }, close() {//关闭对话框
            this.dialogVisible = false
        }, refresh(obj) {//刷新父组件
            this.$emit('refresh', obj);
        }, findObj(id, copy, test) { /*初始化-查询表单对象obj*/

            this.axiosFind({id: id}, (resData) => {
                this.copyFormObj(copy, "name");
                this.initObjDefault();
                this.objLoad = false;
                this.findPTreeList();
                this.findObjCallbackFn(resData);
            });

        }, findObjCallbackFn(resData) {
            //需要回调时重写
        }, findPTreeList() {
            var param = {};
            param.findPageTree = this.design.table.tree;
            if (param.findPageTree) {
                param.pid = this.$route.query.pid || eval(this.design.table.tree_id) || 1;
                param.noId = this.obj.id || "";
                this.axiosPost(this.baseUrl + '/findPage.action', param, (res) => {
                    this.ptreeList = res.page.list;
                    // console.log(this.ptreeList)
                })
            }
        }, initObjDefault() { /*初始化-查询表单对象obj*/
            var newDate = this.dateFmt(new Date(), "yyyy-MM-dd");
            var newTime = this.dateFmt(new Date(), "yyyy-MM-dd HH:mm:ss");
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            if (this.design) {
                for (var k in this.design.colMap) {
                    var col = this.design.colMap[k];
                    // console.log(col,k,this.obj[k],col.form_default)
                    if (this.obj[k] == null && col && col.form_default) {
                        try {
                            this.obj[k] = eval(col.form_default)
                        } catch (e) {
                            this.obj[k] = eval('"' + col.form_default + '"')
                        }
                    }
                }
            }
        },

        addEditBeforeFn(obj) {
            return true;
        }, addEditEndFn(res) {
        }, addEdit() {/*新增修改*/

            var obj = this.copyObj(this.obj);
            if (!obj.firm_id && this.addFirmId) {
                obj.firm_id = this.getLoginUser().firm_id;
            }
            for (var k in obj) {
                if (obj[k] == null) {
                    obj[k] = ''
                }
            }
            if (this.addEditBeforeFn(obj)) {
                this.axiosAddEdit({obj: obj}, function (res) {
                    this.addEditEndFn(res);
                    if(this.save_colse) {
                        this.closeDialog();
                    }else{
                        this.findObj(res.obj.id)
                    }
                    this.refresh(res.obj)
                })
            }
        }, closeDialog() {
            this.resetFieldsBase("obj");//清除obj对象的残留验证
            this.$emit("closeDialog");
        }, validateForm() {
            this.$refs["obj"].validate((valid) => {
                this.$emit("validateForm", valid);
            })
        }, runFun(funName, datas) {
            this.thisRunFun({funName: funName, datas: datas})
        },
    }, watch: { //值改变动作 valObj(val){}
    }, mounted() {/*页面加载初始化*/
        this.axiosAuthor()
    }, created() {
        //页面刚进入时
    }, destroyed: function () {
        //页面销毁时
    }, beforeRouteLeave(to, from, next) {
        //Vue离开
        next()
    }, activated() {
        //初始化及激活时
    }
};
export default {
    indexPage: indexPage,
    formPage: formPage,
}

