<template>
  <span style="color: red;font-weight: bold;">停用</span>
<!--  <span v-if="value2&&code&&value2[code]||val">-->
<!--    <span class="sysMapNull" v-if="(sysMap==null||sysMap.sysUserMap==null) &&devModel()"> sysMap is null={{ sysMap }}</span>-->
<!--    <span v-else-if="val&&sysMap.sysUserMap[val]!=null">{{ sysMap.sysUserMap[val].name }}</span>-->
<!--    <span v-else-if="value2&&sysMap.sysUserMap[value2[code]]!=null">{{ sysMap.sysUserMap[value2[code]].name }}</span>-->
<!--    <span v-else-if="value2">{{ value2[code] }}</span>-->
<!--  </span>-->
</template>
<script>

export default {
  name: "sysUserShowTemp",
  data() {
    return {
      value2: this.value,
    }
  },
  props: {
    code: {default: ""},
    value: {},
    val: {},
    sysMap: {
      default: () => {
      }
    }
  },
  methods: {
    change() {
//        this.$emit('change');
    }
  }, watch: {
    value2() {
      this.$emit('input', this.value2);
      this.$emit('change');
    }, value() {
      this.$set(this, "value2", this.value);
      this.$emit('change');
    }
  }, components: {}, mounted: function () {/*页面加载初始化*/
  }
}
</script>
