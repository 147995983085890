<style lang="less">

</style>
<template>
  <div>
    <el-table class="sonTable" :data="goodsServerItemList" row-key="id" border stripe v-loading="loading"
              empty-text="暂无数据">
      <!--            <el-table-column label="编号" align="center" width="80" prop="code"  />-->
      <el-table-column prop="full_name" label="商品服务名称" width="200"/>
      <el-table-column prop="status" label="状态" width="40" align="center">
        <template #default="o">
          <etSysDict :sysMap="sysMap" dict_code="status" text
                     v-model="o.row.status"></etSysDict>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="单价" width="120" align="right">
        <template #default="o">
<!--          <div>{{ o.row.price }}元</div>-->
          <span v-if="o.row.price">{{ o.row.price.toFixed(2) }}</span>
          <span v-if="o.row.max_price!=o.row.price">～{{ (o.row.max_price||0).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="gs_price" label="工时费" width="120" align="right">
        <template #default="o">
          <span v-if="o.row.gs_price">{{ o.row.gs_price.toFixed(2) }}</span>
          <span v-if="o.row.max_gs_price!=o.row.gs_price">～{{ (o.row.max_gs_price||0).toFixed(2) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单位" width="60" align="center"/>
      <el-table-column prop="serve_num" label="服务次数" width="70" align="right"/>
      <el-table-column prop="area_count" label="商品项类型" width="80" align="center">
        <template #default="o">
          <etSysDict :sysMap="sysMap" dict_code="area_count" text
                     v-model="o.row.area_count"></etSysDict>
        </template>
      </el-table-column>
      <el-table-column prop="server_pay" label="服务完付款" width="80" align="center">
        <template #default="o">
          <etSysDict :sysMap="sysMap" dict_code="sf" text
                     v-model="o.row.server_pay"></etSysDict>
        </template>
      </el-table-column>
      <el-table-column prop="server_pay" label="服务项目" width="160" align="right">
        <template #default="o">
        <tempalte v-if="o.row.item_list">
          <div v-for="item in o.row.item_list">
            <etSysDict :sys-map="sysMap" dict_code="server_item_name" text
                       v-model="item.code"></etSysDict>
            <span style="color: #8800ff;font-weight: bold;"><i class="iconfont-chenghao"/>{{ item.server_num }}</span>
          </div>
        </tempalte>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>

export default {
  name: "goodsServerItemTmp",
  data() {
    return {
      goodsServerItemList: [],
      loading: false,
      sysMap: {},

    }
  },
  props: {
    goodsId: {type: Number, default: 0},
  },
  created() {

  },
  methods: {
    findByGoodsId() {
      this.goodsServerItemList = [];
      if (this.goodsId) {
        this.loading = true;
        this.axiosPost("/goodsServerItem/findByGoodsId.do", {goodsId: this.goodsId}, (res) => {
          this.goodsServerItemList = res.list;
          this.loading = false;
        })
      }
    }
  },
  watch: {
    goodsId(newVal, oldVal) {
      this.findByGoodsId();
    }
  },
  components: {},
  mounted: function () {/*页面加载初始化*/
    this.findSysMap(r => {
    });
    this.findByGoodsId();
  }
}
</script>
