import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
        {path: adminPath + "/schoolInfo",  component: ()=>import("./schoolInfo_index.vue")},
    ],
    pages: []
}
