import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
{path: adminPath + "/firmArea", component: () => import("./firmArea_index.vue")},
{path: adminPath + "/firmPlaceBrokerage", component: () => import("./firmPlaceBrokerage_index.vue")},
        {path: adminPath + "/firmPlacePrestore", component: () => import("./firmPlacePrestore_index.vue")},
        {path: adminPath + "/firmPlaceBalance", component: () => import("./firmPlaceBalance_index.vue")},
        {path: adminPath + "/firmPlace", component: () => import("./firmPlace_index.vue")},
        {path: adminPath + "/firmManager", component: () => import("./firmManager_index.vue")},
        {path: adminPath + "/firmInfo", component: () => import("./firmInfo_index.vue")},
        {path: adminPath + "/firmPlaceBrokerageStatistics", component: () => import("./firmPlaceBrokerageStatistics.vue")},
    ],
    pages: []
}
