import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
        {
            path: adminPath + '/goodsServerItem',
            component: () => import("./goodsServerItem_index.vue")
        },
        {path: adminPath + '/goodsInfo', component: () => import("./goodsInfo_index.vue")},
        {path: adminPath + '/goodsConvert', component: () => import("./goodsConvert_index.vue")}
    ]
}
