<template>
  <span class="etStatusQTIcon">
<!--    <i v-if="value==1" class="el-icon-success" style="color: green;font-size: 16px"></i>-->
    <el-icon>
      <SuccessFilled style="color: green;width: 14px;height: 14px" v-if="value==1" />
      <CircleCloseFilled style="color: red;width: 14px;height: 14px" v-else />
    </el-icon>
<!--    <i v-else class="el-icon-error" style="color: red;font-size: 16px"></i>-->
  </span>
</template>
<script>

    export default {
        name: "etStatusQTIcon",
        data() {
            return {};
        },
        props: {
            value: {default: 0},
        }
    }
</script>

