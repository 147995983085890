<template>
  <el-select popper-class="mini" v-model="value2" @change="change()" :clearable="clearable" :disabled="disabled"
             :placeholder="placeholder" :remote="true" :filter-method="filterList" @visible-change="filterList('')"
             filterable style="width: 100%">
    <el-option v-if="list2.length<=(maxLnegth*2)" v-for="(o,key) in list2" :key="key" :loading="filterDataMap$.loading"
               :label="o[label]" :value="o[idKey]" :disabled="o.selectStatus==true">

    </el-option>
  </el-select>
</template>
<script>

  export default {
    name: "selectSearchTemp",
    data() {
      return {
        filterDataMap$: {},
        list2: [],
        value2: this.value,
      }
    },
    props: {
      clearable:{default:false},
      disabled:{default:false},
      placeholder:{default:"请选择"},
      showField: {},
      list: {},
      maxLnegth: {default: 20},
      selectList: {},
      idKey: {default: "id"},
      label: {default: "name"},
      search: {default: "name"},
      value: {}
    }, methods: {
      change() {
        var o = this.listJsonToMap(this.list, "id")[this.value2];
        this.$emit('change', o);
      }, filterList(key) {
        var selectList = this.copyObj(this.selectList);
        if (!selectList) {
          selectList = []
        }
        selectList.push(this.value2);
        this.filterMethodBase(key, this.list2, this.search, this.list, this.maxLnegth, selectList);
      },
    }, watch: {
      value2() {
        this.$emit('input', this.value2);
      }, value() {
        this.$set(this, "value2", this.value);
      }, list() {
      }
    }, mounted: function () {/*页面加载初始化*/
      var $this = this;
      $this.filterList("");
    }, components: {},
  }
</script>
