import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
  index: [
    {path: adminPath + "/wxTempMsg",  component: ()=>import("./wxTempMsg_index.vue")},
    {path: adminPath + "/wxTempMsgType",  component: ()=>import("./wxTempMsgType_index.vue")},
    {path: adminPath + "/wxUser",  component: ()=>import("./wxUser_index.vue")},
    {path: adminPath + "/wxPayRefund",  component: ()=>import("./wxPayRefund_index.vue")},
    {path: adminPath + "/wxPayResult",  component: ()=>import("./wxPayResult_index.vue")},
  ]
}
