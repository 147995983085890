<template>
  <etSearchItem v-if="isSaasAdmin()" :label="label">
    <sysTenantTemp v-model="value2"/>
  </etSearchItem>



</template>
<script>

  export default {
    name: "sysTenantSearch",
    data() {
      return {
        value2: this.value,
      }
    },
    props: {
      type: {default: "Search"},
      label: {default: "租户"},
      value: {},
      sysMap: {
        default: () => {
        }
      },
    },
    created() {
    },
    methods: {

      change() {

      }
    }, mounted() {

    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, components: {}
  }
</script>
