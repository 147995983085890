<template>
  <span>
  <el-radio-group v-model="val" :size="size" :disabled="disabled" @change="change">
    <el-radio-button v-for="(o,k) in list" :key="k"
                     :label="o.val"
                     :class="[o.class,{customColor:o.color}]">{{ o.name }}</el-radio-button>
  </el-radio-group>
    </span>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: 'etStatusEdit',
  props: {
    modelValue: Number,
    list: {type: Array, default: () => [{val: 1, name: '正常', color: 'green'}, {val: 0, name: '停用', color: 'red'}]},
    size: {default: ""},
    disabled: {default: false},
  },
  setup(props, {emit}) {
    const val = ref(props.modelValue) // 初始化数据
    watch(
        () => val.value,
        (data) => emit('update:modelValue', data)
    ) // 监听输入值变动
    return {
      val: val,
    }
  },
  data() {
    return {
      // value2: this.modelValue,
    }
  }, methods: {
    change() {
      // this.$emit('change');
    }
  }, watch: {
    modelValue() {
      this.$set(this, "val", this.modelValue);
    }
  }
}
</script>

