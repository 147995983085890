<template>
    <div style="width: 100%">
        <el-select :clearable="clearable" :disabled="disabled" :multiple="multiple"
                   :placeholder="placeholder" :remote-method="remoteStaff" :style="{width: width}"
                   filterable remote
                   v-if="selectType=='staff'" v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="staffLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in staffList">
                <div style="float: left;margin-right: 30px;width: 100px" v-if="item">姓名：{{ item.name}}</div>
                <div style="float: left;margin-right: 30px;width: 150px" v-if="item">电话：{{ item.phone }}</div>
                <div style="float: left;" v-if="item">工号：{{ item.number }}</div>
            </el-option>
        </el-select>
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
                   :remote-method="remoteManager" :style="{width: width}" filterable
                   remote
                   v-if="selectType=='manager'" v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="managerLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in managerList">
                <div style="float: left;margin-right: 30px;width: 100px" v-if="item">姓名：{{ item.name }}</div>
                <div style="float: left;margin-right: 30px;width: 150px" v-if="item">电话：{{ item.phone }}</div>
                <div style="float: left" v-if="item">加盟商：{{ item.firm_name }}</div>
            </el-option>
        </el-select>
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
                   :remote-method="remoteOrder" :style="{width: width}" filterable
                   remote @change="orderChange"
                   v-if="selectType=='order'" v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="orderLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in orderList">
                <div style="float: left;" v-if="item">{{ orderLabel(item) }}</div>
            </el-option>
        </el-select>
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
                   :remote-method="remoteNxUser" :style="{width: width}" filterable
                   remote
                   v-if="selectType=='nxUser'"
                   v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="nxUserLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in nxUserList">
                <div style="float: left" v-if="item">姓名：{{ item.name }}</div>
                <div style=" float: right;" v-if="item">电话：{{ item.phone }}</div>
            </el-option>
        </el-select>
        <!--        加盟商-->
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder" :remote-method="remoteFirm"
                   :style="{width: width}"
                   filterable no-data-text="暂无" remote
                   v-if="selectType=='firm'"
                   v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="firmLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in firmList">
            </el-option>
        </el-select>
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder" :remote-method="remoteFirm"
                   :style="{width: width}"
                   filterable no-data-text="暂无" remote
                   v-if="selectType=='firmPlace'"
                   v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="firmLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in firmPlaceList">
            </el-option>
        </el-select>
        <!--        优惠券-->
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
                   :remote-method="remoteNxUserCoupon"
                   :style="{width: width}"
                   filterable no-data-text="暂无选项" remote v-if="selectType=='coupon'"
                   v-model="value2"
                   value-key="id">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="couponLabel(item)"
                    :value="item"
                    v-for="(item,index) in couponList">
            </el-option>
        </el-select>
        <!--        客户地址-->
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
                   :style="{width: width}" @change="addrChange" filterable
                   remote v-if="selectType=='nxUserAddr'"
                   v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="nxUserAddrLabel(item)"
                    :value="item.id"
                    v-for="(item,index) in nxUserAddrList">
                <el-row>
                    <el-col :span="4">{{item.name}}</el-col>
                    <el-col :span="8">{{item.phone}}</el-col>
                    <el-col :span="2">{{item.server_area}}㎡</el-col>
                    <el-col :span="10">{{item.area_id && this.areaMap[item.area_id] ?
                        this.areaMap[item.area_id].full_name:""}}
                        {{item.address}}
                    </el-col>
                </el-row>
            </el-option>
        </el-select>
        <el-select :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
                   :remote-method="remoteSysUser" :style="{width: width}" filterable
                   remote
                   v-if="selectType=='sysUser'" v-model="value2">
            <el-option
                    :disabled="item?item.disabled:''"
                    :key="index"
                    :label="sysUserLabel(item)"
                    :value="item?item.id:''"
                    v-for="(item,index) in sysUserList">
                <div style="float: left;margin-right: 30px;width: 100px" v-if="item">姓名：{{ item.name }}</div>
                <div style="float: left;margin-right: 30px;width: 150px" v-if="item">电话：{{ item.phone }}</div>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    import {ref, watch} from 'vue'

    export default {
        name: "select_temp",
        data() {
            return {
                value2: '',
                nxUserList: [],
                staffList: [],
                managerList: [],
                orderList: [],
                orderListMap: {},
                nxUserAddrList: [],
                sysUserList: [],
                firmList: [],
                firmPlaceList: [],
                couponList: [],
                areaMap: [],
            }
        },
        props: {
            modelValue: {type: String, default: ''},
            selectType: {type: String, default: ''},//staff=员工，manager=经理，order=订单，nxUser=客户，firm=加盟商，coupon=优惠券，nxUserAddr=用户地址（必传用户nxUserId）
            width: {type: String, default: '100%'},
            placeholder: {type: String, default: '请选择'},
            clearable: {type: Boolean, default: true},
            nxUserId: {type: Number, default: 0},//地址优惠券选项时必传，用户id
            firmInfoId: {type: Number, default: null},//加盟商id，经理选择时选择传参
            addrFormat: {type: String, default: null},//county区县street街道community社区address详细地址，自定义组合传参格式化返回值，不传参默认返回id
            disabled: {type: Boolean, default: false},
            multiple: {type: Boolean, default: false},//员工多选
            // managerId: {type: Number, default: null},//经理id，选择员工时选择传参
            fAreaId: {type: Number, default: null},//加盟商负责区域，选择员工时选择传参
            staffEmptyInit: {type: Boolean, default: false},//员工选择是否初始化空选项
        },
        setup(props, {emit}) {
            const value2 = ref(props.modelValue);
            watch(
                () => value2.value,
                (data) => {
                    emit('update:modelValue', data)
                }
            );
            return {
                value2: value2,
            }
        },
        watch: {
            modelValue() {
                this.$set(this, "value2", this.modelValue);
                this.$emit('handleChange', this.value2);
                this.orderChange(this.value2);
                if (this.selectType == 'order') {
                    this.remoteOrder(this.value2);
                }
            },
            nxUserId() {
                this.remoteNxUserAddr();
                this.remoteNxUserCoupon();
            },
            firmInfoId() {
                this.remoteManager();
            },
            managerId() {
                this.remoteStaff();
            },
            fAreaId() {
                this.remoteStaff();
            }
        },
        created() {
            if (this.selectType == 'staff') {
                this.remoteStaff();
            }
            if (this.selectType == 'nxUser') {
                this.remoteNxUser();
            }
            if (this.selectType == 'nxUserAddr') {
                this.getAreaTree({dataKey: "areaTree", pid: 1, showPid: true, level: 3});
                this.remoteNxUserAddr();
            }
            if (this.selectType == 'order') {
                this.remoteOrder(this.value2);
            }
            if (this.selectType == 'manager') {
                this.remoteManager();
            }
            if (this.selectType == 'firm') {
                this.remoteFirm();
            }
            if (this.selectType == 'firmPlace') {
                this.remoteFirmPlace();
            }
            if (this.selectType == 'coupon') {
                this.remoteFirm();
            }
            if (this.selectType == 'sysUser') {
                this.remoteSysUser();
            }
        },
        methods: {
            staffLabel(val) {
                if (val) {
                    return (val.name ? val.name + ' ' : '暂无') + (val.phone ? val.phone + ' ' : '暂无') + (val.number ? val.number : '暂无');
                }
            },
            managerLabel(val) {
                if (val) {
                    return (val.name ? val.name + ' ' : '暂无') + (val.phone ? val.phone + ' ' : '暂无') + (val.firm_name ? val.firm_name : '暂无');
                }
            },
            sysUserLabel(val) {
                if (val) {
                    return (val.name ? val.name + ' ' : '暂无') + (val.phone ? val.phone : '暂无');
                }
            },
            orderLabel(val) {
                if (val) {
                    return val.id + "◆" + val.c_name + "◆" + val.c_phone + "◆" + val.c_area + "◆" + (val.goods_server_item_json ? val.goods_server_item_json.full_name : "");
                }
            },
            orderChange(e) {
                if (this.selectType == 'order') {
                    // console.log(e)
                    // console.log(this.orderListMap[e])
                    this.$emit('handleChange', e, this.orderListMap[e]);
                }

            },
            nxUserLabel(val) {
                if (val) {
                    return (val.name ? val.name + ' ' : '暂无') + (val.phone ? val.phone : '暂无');
                }
            },
            firmLabel(val) {
                if (val) {
                    return (val.name ? val.name : '暂无')
                        + '-' + (val.id ? ('ID:' + val.id) : '暂无');
                }
            },
            couponLabel(val) {
                if (val && val.coupon_info) {
                    return val.coupon_info.coupon_name ? (val.coupon_info.coupon_name + '，有效期：' + val.deadline) : ''
                }
            },
            nxUserAddrLabel(val) {
                if (val) {
                    val.city_name = (val.area_id && this.areaMap[val.area_id] ? this.areaMap[val.area_id].full_name : '');
                    return val.name + "-" + val.phone + "-" + val.server_area + "㎡-" + (val.city_name) + " " + val.address;
                }
            },
            remoteNxUser(val) {
                this.axiosPost("/nxUser/findNxUserList.do", {
                    key: val,
                    id: this.value2
                }, function (data) {
                    this.$set(this, "nxUserList", data.list);
                });
            },
            remoteNxUserAddr(val) {
                this.axiosPost("/nxUserAddr/findNxUserAddrList.do", {
                    nx_user_id: this.nxUserId || null
                }, function (data) {
                    this.$set(this, "nxUserAddrList", data.list);
                });
            },
            remoteNxUserCoupon(val) {
                this.axiosPost("/couponUser/findCouponByUser.do", {
                    nx_user_id: this.nxUserId
                }, function (data) {
                    this.$set(this, "couponList", data.list);
                });
            },
            remoteStaff(val) {
                let param = {
                    key: val,
                    id: this.value2
                };
                if (this.multiple) {
                    param.ids = this.value2;
                }
                if (this.staffEmptyInit && !this.fAreaId) {
                    param.firm_area_id = -1;
                } else {
                    param.firm_area_id = this.fAreaId;
                }
                this.axiosPost("/staffInfo/findStaffInfoList.do", param, function (data) {
                    this.$set(this, "staffList", data.list);
                });
            },
            remoteManager(val) {
                this.axiosPost("/firmManager/findSelectListMy.do", {
                    keystr: val,
                    value: this.value2,
                    selectField: "o.name,o.phone,fi.name",
                    label: "name,phone,firm_name",
                    table_label: "name",
                    labelLv: [6, 6, 12],
                    headTitle: "姓名,电话,加盟商".split(","),
                }, function (data) {
                    this.$set(this, "managerList", data.list);
                });
            },
            remoteSysUser(val) {
                this.axiosPost("/sysUsers/findSelectList.do", {
                    keystr: val,
                    value: this.value2,
                    selectField: "o.name,o.phone",
                    label: "name",
                    table_label: "name",
                    remote: false,
                    labelLv: [24],
                    headTitle: "账号".split(","),
                }, function (data) {
                    this.$set(this, "sysUserList", data.list);
                });
            },
            remoteOrder(val) {
                this.axiosPost("/orderInfo/findOrderInfoList.do", {
                    key: val,
                    id: this.value2
                }, function (data) {
                    this.$set(this, "orderList", data.list);
                    this.$set(this, "orderListMap", this.listJsonToMap(data.list, "id"));
                    this.orderChange(this.value2);
                });
            },
            remoteFirm(val) {
                this.axiosPost("/firmInfo/findList.do", {
                    key: val,
                    id: this.value2
                }, function (data) {
                    this.$set(this, "firmList", data.list);
                });
            },
            remoteFirmPlace(val) {
                this.axiosPost("/firmPlace/findSelectList.do", {
                    key: val,
                    id: this.value2
                }, function (data) {
                    this.$set(this, "firmPlaceList", data.list);
                });
            },
            addrValFormat(val) {//格式化地址输出
                let str = this.addrFormat;
                if (str && val) {
                    str = str.replace(new RegExp("county", "gm"), this.areaMap[val.area_county_id] ? this.areaMap[val.area_county_id].name : '');
                    str = str.replace(new RegExp("street", "gm"), this.areaMap[val.area_street_id].name);
                    str = str.replace(new RegExp("community", "gm"), this.areaMap[val.area_community_id].name);
                    str = str.replace(new RegExp("address", "gm"), val.address);
                    return str;
                } else {
                    return "";
                }
            },
            addrChange(val) {
                if (this.value2) {
                    let listMap = this.listJsonToMap(this.nxUserAddrList, "id");
                    var valObj = listMap[this.value2];
                    // valObj.addrStr = this.addrFormat ? this.addrValFormat(listMap[val.id]) : val.id;
                    this.$emit("change", this.value2, listMap[this.value2]);
                }

            }
        }
    }
</script>

<style scoped>

</style>
