<template>
  <el-button  :type="type"  :style="{color:color}"   :disabled="disabled">
    <slot v-if="this.$slots.default"></slot>
    <template v-else-if="title">{{ title }}</template>
  </el-button>
</template>
<script>

export default {
  name: "etBtnDelMini",
  props: {
    type: {default: "danger"},
    title: {default: "删除"},
    disabled: Boolean,
  }
}
</script>

