const defaultObj = {}
import baseUtils from './baseUtils.js';

for (var k in baseUtils) {
    defaultObj[k] = baseUtils[k]
}

import design from './design/_index.js';

for (var k in design.object) {
    defaultObj[k] = design.object[k]
}

import baseAxios from './baseAxios.js';

for (var k in baseAxios) {
    defaultObj[k] = baseAxios[k]
}
import baseMsg from './baseMsg.js';

for (var k in baseMsg) {
    defaultObj[k] = baseMsg[k]
}
import elTableInputValidate from './elTableInputValidate.js';

for (var k in elTableInputValidate) {
    defaultObj[k] = elTableInputValidate[k]
}
import elValidate from './elValidate.js';
import designApiType from './design/designApiType.js';
defaultObj.designApiType=designApiType.designApiType
for (var k in elValidate) {
    defaultObj[k] = elValidate[k]
}
// import baseAdmin from './baseAdmin'
//
// defaultObj.baseAdmin = baseAdmin
export default defaultObj
