<template>
  <div class="jsonFormat" id="jsonFormat">
    <pre v-html="this.JsonFormat(value)"></pre>
  </div>
</template>

<script>
/*json美化显示*/
export default {

  name: "etJsonShow",
  props: {
    value: {
      default: () => {
        return {};
      }
    }
  },
  methods:{

  },
  mounted(){
  },
}
</script>

<style lang="less">
.jsonFormat {
  pre {
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
  }

  .string {
    color: green;
  }

  .number {
    color: darkorange;
  }

  .boolean {
    color: blue;
  }

  .null {
    color: magenta;
  }

  .key {
    color: red;
  }
}
</style>
