import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
//#自动adminPath#
        {path: adminPath + "/creditGoods", component: () => import("./creditGoods_index.vue")},
        {path: adminPath + "/creditOrderInfo", component: () => import("./creditOrderInfo_index.vue")},
        {path: adminPath + "/creditStatement", component: () => import("./creditOrderInfoStatement_index.vue")},
    ],
    pages: []
}
