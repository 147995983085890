<!--分页通用模板，通过父组件page对象取值，给父组件传值@setVal,刷新函数@refresh -->
<style lang="less">

</style>
<template>
  <!--  <el-pagination v-if="page"-->
  <!--      v-model:current-page="page.pageNumber"-->
  <!--      v-model:page-size="page.pageSize"-->
  <!--      :page-sizes="pageSizes"-->
  <!--      :layout="layout"-->
  <!--      :total="page.totalRow"-->
  <!--      :pager-count="pagerCount"-->
  <!--      @size-change="pageSizeChange"-->
  <!--      @current-change="pageNumberChange"-->
  <!--  />-->
  <el-pagination v-if="page"
                 v-model:current-page="page.pageNumber"
                 v-model:page-size="page.pageSize"
                 :page-sizes="pageSizes"
                 :layout="layout"
                 :total="page.totalRow||0"
                 @size-change="pageSizeChange"
                 @current-change="pageNumberChange"
  />
</template>

<script>
export default {
  name: "etPage",
  data() {
    return {totalRow:0};
  },
  props: {
    layout: {default: "total, sizes, prev, pager, next, jumper"},
    page: {default: {}},
    // pagerCount: {type: Number, default: 9},
    disabled: {type: Boolean, default: false},
    scroll: {type: Boolean, default: false},
    pageSizes: {
      Array,
      default: () => {
        return [10, 20, 50, 100, 200, 500];
      }
    }
  },watch: { //值改变动作 valObj(val){}
    "page.totalRow"() {
      if(this.page.totalRow>0){
        this.totalRow=this.page.totalRow
      }else{
        // console.log(this.page.totalRow,this.page)
        setTimeout(()=>{
          this.totalRow=0
        },100)
      }
    }
  },
  methods: {
    pageSizeChange(val) {/*分页大小变化操作*/
      this.$emit('update:page.pageSize', val);
      this.refresh();
    },
    pageNumberChange(val) { /*页码变化操作*/
      this.$emit('update:page.pageNumber', val);
      this.refresh();
    },
    refresh() {//刷新父组件数据
      this.$emit('refresh');
    }
  }
}
</script>

