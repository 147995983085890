<template>
  <el-tag size="medium" :type="type[value]">{{data[value]}}</el-tag>
</template>
<script>
  export default {
    name: 'etStatusTag',
    props: {
      value: {default: 0},
      data: {
        type: Array,
        default: () => {
          return ["否", "是"]
        }
      },
      type: {
        default: () => {
          return ["warning", "success"]
        }
      },
    },
    data() {
      return {}
    },
  }
</script>

