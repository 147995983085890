import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        {path: adminPath + "/baseOrgan", component: () => import("./baseOrgan_index.vue")},
        {path: adminPath + "/baseLink", component: () => import("./baseLink_index.vue")},
        {path: adminPath + "/baseVipOrder", component: () => import("./baseVipOrder_index.vue")},
        {path: adminPath + "/baseVipInfo", component: () => import("./baseVipInfo_index.vue")},
        {path: adminPath + "/baseCouponType", component: () => import("./baseCouponType_index.vue")},
        {path: adminPath + "/baseCouponInfo", component: () => import("./baseCouponInfo_index.vue")},
    ],
    pages: []
}
