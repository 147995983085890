<template>
  <span v-if="type=='input'">
    <el-radio-group ref="sex" v-model="value2" :size="size" :disabled="disabled">
      <el-radio v-if="showAll">全部</el-radio>
      <el-radio :label="0">男</el-radio>
      <el-radio :label="1">女</el-radio>
    </el-radio-group>
  </span>
  <span v-else-if="val==0">男</span>
  <span v-else-if="val==1">男</span>
  <span v-else></span>
</template>
<script>
  export default {
    name: 'etRadioSex',
    props: {
      value: {},
      val: {},
      size: {default: "mini"},
      type: {default: "input"},
      disabled: {default: false},
      showAll: Boolean,
    },
    data() {
      return {
        value2: "",
      }
    }, methods: {}, watch: {
      value() {
        if (this.value2 != this.value) {
          this.value2 = this.value;
        }
      },
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }
    }
  }
</script>

