import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        //#自动adminPath#
        {path: adminPath + "/expensesInfo", component: () => import("./expensesInfo_index.vue")},
    ],
    pages: []
}
