<template>
  <div>
    <json-viewer :copyable="copyable" :expand-depth="expandDepth" :sort="sort"
                 style="line-height: 18px;" :value="revert(modelValue)"></json-viewer>
  </div>
</template>
<style lang="less">
</style>
<script>

export default {
  name: "etJsonViewer",
  props: {
    modelValue: {
      type: Object, default: () => {
      }
    },
    expandDepth: {type: Number, default: 2},
    copyable: {type: Boolean, default: true},
    sort: {type: Boolean, default: true},
  },
  methods: {
    revert(val) {
      if (val) {
        if (typeof (val) == 'string') {
          return JSON.parse(val)
        } else if (typeof (val) == 'object') {
          return val
        }
      }
    }
  }
}
</script>
<style type="less">
.jv-container .jv-code {
  padding: 12px !important;
}
</style>
