<template>
  <span class="etSearchItem" v-if="this.parent$.type=='etSearch'">
    <span class="label" :title="keyName" v-if="label" :key-name="keyName">{{label}}：</span><slot></slot>
  </span>
  <div class="etSearchItem searchOption" v-else>
    <label v-if="label" class="label"  :title="keyName"  :key-name="keyName" style="text-align:right;display:inline-block;"
           :style="{width:this.parent$.$parent.$parent.labelWidth}">{{label}}：</label>
<!--    <div class="content"   :style="{'margin-left':this.parent$.$parent.$parent.labelWidth}">-->
      <slot></slot>
<!--    </div>-->

  </div>
</template>

<script>
export default {
  name: "etSearchItem",
  computed: {
    parent$() {
      let parent = this.$parent;
      return parent;
    },
  },
  data() {
    return {}
  }, props: {
    label: {default: ""},
    keyName: {default: ""},
  }, mounted() {
  }
}
</script>
