var adminPages = [];
var adminOa = [];
var adminUser = [];
var pages = [];
// import Vue from 'vue'
// import viewTemp from './viewTemp'
// Vue.use(viewTemp);
import admin from '../views/admin/_router'
pushArray(admin);
import school from '../views/school/_router'
pushArray(school);
import sys from '../views/sys/_router'
pushArray(sys);
import nx from '../views/nx/_router'
pushArray(nx);
import goods from '../views/goods/_router'
pushArray(goods);
import staff from '../views/staff/_router'
pushArray(staff);
import ali from '../views/ali/_router'
pushArray(ali);
import order from '../views/order/_router'
pushArray(order);
import complain from '../views/complain/_router'
pushArray(complain);
import coupon from '../views/coupon/_router'
import firm from '../views/firm/_router'
pushArray(firm);
pushArray(coupon);
import statistics from '../views/statistics/_router'
pushArray(statistics);
import wx from '../views/wx/_router'
pushArray(wx);
import server from '../views/server/_router'
pushArray(server);
import base from '../views/base/_router'
pushArray(base);
import expenses from '../views/expenses/_router'
pushArray(expenses);
import credit from '../views/credit/_router'
pushArray(credit);
export default {
    adminPages: adminPages,
    adminOa: adminOa,
    adminUser: adminUser,
    pages: pages
}

function pushArray(_router) {
    if (_router.index) {
        _router.index.forEach(function (o) {
            adminPages.push(o);
        })
    }
    if (_router.adminPages) {
        _router.adminPages.forEach(function (o) {
            adminPages.push(o);
        })
    }
    if (_router.adminOa) {
        _router.adminOa.forEach(function (o) {
            adminOa.push(o);
        })
    }
    if (_router.adminUser) {
        _router.adminUser.forEach(function (o) {
            adminOa.push(o);
        })
    }
    if (_router.pages) {
        _router.pages.forEach(function (o) {
            pages.push(o);
        })
    }

}
