<style lang="less">

</style>
<template>
    <div>
        <el-table :data="orderServeList" row-key="id" border stripe v-loading="loading" empty-text="暂无数据">
            <el-table-column type="expand" v-if="showItem">
                <template #default="o">
                    <order-serve-item-tmp :order-serve-id="o.row.id"></order-serve-item-tmp>
                </template>
            </el-table-column>
            <el-table-column label="服务单号" align="center" width="120" prop="id"/>
            <el-table-column prop="server_time" label="服务时间" width="90"/>
<!--            <el-table-column prop="status" label="状态" width="100">-->
<!--                <template #default="o">-->
<!--                    <et-sys-dict v-model="o.row.status" :sys-map="this.$parent.sysMap" text dict_code="order_serve_status"></et-sys-dict>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="firm_id" label="加盟商" width="180"/>-->
<!--            <el-table-column prop="firm_manager_id" label="经理" width="100"/>-->
            <el-table-column prop="c_name" label="联系人" width="100"/>
            <el-table-column prop="c_phone" label="联系电话" width="120"/>
            <el-table-column prop="c_area" label="服务地址" show-overflow-tooltip/>
            <el-table-column prop="server_area" label="服务面积" width="100"/>
        </el-table>
    </div>
</template>
<script>

    import OrderServeItemTmp from "@/views/_temp/orderServeItemTmp";
    import EtSysDict from "@/views/sys/sysTemp/etSysDict";
    export default {
        name: "orderServeTmp",
        data() {
            return {
                orderServeList: [],
                loading: false,
            }
        },
        props: {
            orderId: {type: Number, default: 0},
            showItem: {type: Boolean, default: true},
        },
        created() {

        },
        methods: {
            findServeByOrderId() {
                this.orderServeList = [];
                if (this.orderId) {
                    this.loading = true;
                    this.axiosPost("/orderServe/findServeByOrderId.do", {orderId: this.orderId}, (res) => {
                        this.orderServeList = res.list;
                        this.loading = false;
                    })
                }
            }
        },
        watch: {
            orderId(newVal, oldVal) {
                this.findServeByOrderId();
            }
        },
        components: {EtSysDict, OrderServeItemTmp},
        mounted: function () {/*页面加载初始化*/
            this.findServeByOrderId();
        }
    }
</script>
