<style lang="less">
.nxUserShow {

}
</style>
<template>
  <!--  用户信息显示组件-->
  <span class="nxUserShow">
    {{ name }}-{{ phone }}
  </span>
</template>
<script>

export default {
  name: "nxUserShow",
  data() {
    return {
      obj:{},
    }
  },
  props: {
    name: {default: "name"},
    phone: {default: "phone"},
    id: {},
  },
  created() {
  },
  methods: {
    findById(){
      this.axiosPost("/nxUser/findById.do", {id: this.id}, res => {
        this.$set(this,"obj",res.obj)
        console.log(JSON.stringify(this.obj))
      });
    },
  },
  watch: {
    value2() {
    },
    value() {
    }

  }
  ,
  components: {}
  ,
  mounted: function () {/*页面加载初始化*/
    // this.findById();
  }
}
</script>
