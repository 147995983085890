import {createApp} from 'vue'
import App from './App.vue'

const app = createApp(App);
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import vueRouter from './config/vueRouter'
import vueConfig from './config/vueConfig'
import ydVue from './ydVue/ydVue'
import data from './data'
import flatConfig from './flatConfig'
import ydComponent from './ydVue/ydComponent'

import JsonViewer from 'vue-json-viewer'
// import {vueJsonp} from 'vue-jsonp'


import axios from 'axios'

for (var k in ydVue) {
    app.config.globalProperties[k] = ydVue[k]
}
for (var k in data) {
    app.config.globalProperties[k] = data[k]
}
for (var k in flatConfig) {
    app.config.globalProperties[k] = flatConfig[k]
}
for (var k in ydComponent) {
    app.component(k, ydComponent[k])
}
// import ydFilter from './ydVue/ydFilter'
// for (var k in ydFilter) {
//     app.filter(k, ydFilter[k])
// }
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
import './assets/css/main.less'
import './assets/css/tableInput.less'
import './assets/css/formBox.less'
// import './ydVue/elTemp/elTemp.css'
// import ydVue from './ydVue/_baseEl'
import md5 from 'js-md5'
// import moment from 'vue-moment'
// app.use(require('vue-moment')) //日期时间格式化
app.config.globalProperties.vueConfig = vueConfig;
app.config.globalProperties.$md5 = md5;
//
app.config.globalProperties.dev$ = process.env.NODE_ENV != 'production';
// app.config.globalProperties.dev$ = false;
app.config.globalProperties.designStatus =false;
// app.config.globalProperties.NODE_ENV = process.env.NODE_ENV
axios.defaults.timeout = 1000 * 60 * 10;
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
axios.interceptors.request.use(config => {    // 这里的config包含每次请求的内容
    config.withCredentials = true;// 允许携带cookie
    // console.log("document", document.location)
    // var url = document.location.origin + "/api"
    var url = "/api";
    // if (vueConfig.apiUrl > 0) {
    //   url = url + ':' + vueConfig.apiUrl+"/api"
    // } else {
    //   url = vueConfig.apiUrl+"/api"
    // }
    // config.headers.common['Referer']=window.location.href;
    // alert(process.env.NODE_ENV )
    config.baseURL = url; //设置axios默认地址
    return config
}, err => {
    return Promise.reject(err)
});
// axios响应拦截器
axios.interceptors.response.use(
    function (response) {
        // NProgress.done() // 设置加载进度条(结束..)
        return response
    },
    function (error) {
        return Promise.reject(error)
    }
);
app.config.globalProperties.axios = axios;

import directive from './ydVue/directive.js';//自定义指令扩展

for (var k in directive) {
    app.directive(k,directive[k])
}
// console.log(Vue)
app.use(ElementPlus, {
    locale: zhCn,
});
app.use(JsonViewer);
// app.use(vueJsonp);
app.use(vueRouter);
app.mount('#app');
