<style lang="less">
.alarmIcon {

  i {
    font-size: 16px;
    font-weight: normal;
    vertical-align: center;
  }

  .txt {
    //height: 16px;
    //line-height: 16px;
    font-size: 14px;
    margin-left: 2px;
  }
}
</style>
<template>
  <span class="alarmIcon">
    <span v-if="is_monitor==0" style="color: #999999;">
      <i class="iconfont-yulanguanbi4" :style="{fontSize:iconSize}"/>
      <span v-if="showIcon" class="txt" style="font-weight: bold">未监控</span>
    </span>
    <span v-else-if="down>0" style="color: red;">
      <i class="iconfont-suspend" :style="{fontSize:iconSize}"/>
      <span v-if="showIcon" class="txt" style="font-weight: bold">已停机</span>
    </span>
    <span v-else-if="alarm==2" style="color: #ff5700">
      <i class="iconfont-chacha1" :style="{fontSize:iconSize}"/>
      <span v-if="showIcon" class="txt">严重故障</span>
    </span>
    <span v-else-if="alarm==1" style="color: orange">
      <i class="iconfont-a-gaojingguzhang-m" :style="{fontSize:iconSize}"/>
      <span v-if="showIcon" class="txt">异常提醒</span>
    </span>
    <span v-else style="color: #13ce66">
      <i class="iconfont-a24gf-playCircle" :style="{fontSize:iconSize}"/>
      <span v-if="showIcon" class="txt">运行正常</span>
    </span>
  </span>
</template>
<script>

export default {
  name: "alarmIcon",
  data() {
    return {
      value2: this.value,
    }
  },
  props: {
    iconSize: {default: "16px"},
    showIcon: {default: true},
    is_monitor: {},
    down: {},
    alarm: {},
  },
  created() {
  },
  methods: {},
  watch: {
    value2() {
    },
    value() {
    }

  }
  ,
  components: {}
  ,
  mounted: function () {/*页面加载初始化*/

  }
}
</script>
