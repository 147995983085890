import sysUserTemp from './sysUserTemp.vue'
import sysUserShowTemp from './sysUserShowTemp.vue'
import sysAreaCascaderTemp from './sysAreaCascaderTemp.vue'
import treeCascaderTemp from './treeCascaderTemp.vue'
import treeShowTemp from './treeShowTemp.vue'
import showTableFieldTemp from './showTableFieldTemp.vue'
import sysDataUpdateList from './sysDataUpdateList.vue'
import sysDataUpdateListDialog from './sysDataUpdateListDialog.vue'
import sysTenantTemp from './sysTenantTemp.vue'
import sysTenantSearch from './sysTenantSearch.vue'
import sysTenantTCol from './sysTenantTCol.vue'
import sysTenantForm from './sysTenantForm.vue'
import etSysDict from './etSysDict.vue'
import etTable from './etTable.vue'

export default {
    "etTable": etTable,
    "etSysDict": etSysDict,
    "sysTenantForm": sysTenantForm,
    "sysTenantTCol": sysTenantTCol,
    "sysTenantTemp": sysTenantTemp,
    "sysTenantSearch": sysTenantSearch,
    "sysUserTemp": sysUserTemp,
    "sysUserShowTemp": sysUserShowTemp,
    "sysAreaCascaderTemp": sysAreaCascaderTemp,
    "treeCascaderTemp": treeCascaderTemp,
    "treeShowTemp": treeShowTemp,
    "showTableFieldTemp": showTableFieldTemp,
    "sysDataUpdateList": sysDataUpdateList,
    "sysDataUpdateListDialog": sysDataUpdateListDialog
}
