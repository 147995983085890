import vueConfig from '../../config/vueConfig'

var adminPath = vueConfig.adminUrl;
export default {
    adminPages: [
        {path: adminPath + "/complainInfo",  component: ()=>import("./complainInfo_index.vue")},
        {path: adminPath + "/complainHandle",  component: ()=>import("./complainHandle_index.vue")},
    ],
    pages: []
}
