<template>
  <span class="etStatusQT">
     <span v-if="value==1" class="status1">启用</span>
     <span v-else class="status0" >停用</span>
  </span>
</template>
<script>

  export default {
    data() {
      return {};
    },
    props: {
      value: {default: 0},
    }
  }
</script>

