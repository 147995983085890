<template v-for="c in config.fieldListTable" v-if="c.tc&&c.tc.show!=false">
    <el-table-column v-if="c.field=='sex'" :prop="c.field" :label="c.name" :align="c.tc.align" :sortable="false"
                     :width="c.tc.width" show-overflow-tooltip/>
    <el-table-column v-else :prop="c.field" :label="c.name" :align="c.tc.align" sortable="custom"
                     :width="c.tc.width" show-overflow-tooltip/>
</template>
<script>

export default {
  name: "etTable",
  data() {
    return {}
  },
  props: {
    config: {},
  },
  created() {
  },
  methods: {},
  watch: {

    value() {
      this.$set(this, "value2", this.value);

    }
    ,
    sysMap() {
      this.$set(this, "value2", this.value);
    }
  }
  ,
  components: {}
  ,
  mounted: function () {/*页面加载初始化*/

  }
}
</script>
