<!--npm install -S file-saver xlsx（这里其实安装了2个依赖）-->
<!--npm install -D script-loader-->
<style>
.etUploadExcelReadTable .el-upload--text {
  width: auto;
  height: auto;
  border: 0;
}

.uertSpan {
  color: #cccccc;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.etUploadExcelReadTable .el-upload-list {
  display: none;
}

.webAdmin .el-table th .el-select.excel_title_select .el-input--suffix .el-input__inner {
  font-weight: bold;
  /*color: red !important;*/
  /*background-color: red;*/
}

.webAdmin .el-table th .el-select.excel_title_select .el-input--suffix .el-input__inner::-webkit-input-placeholder {
  color: #ebe9e9;
  font-weight: normal;
  /*color: red !important;*/
  /*background-color: red;*/
}
</style>
<template>
  <span>
    <el-button :size="btnSize" type="primary" :loading="loading" icon="iconfont-daoru" @click="open()"
               style="margin-left: 10px">
              {{ btnName}}
            </el-button>
  <el-dialog class="etUploadExcelReadTable" :title="title" width="1200px"
             :top="top" append-to-body :close-on-click-modal="false"
             @close="closeDialog"
             v-model="show">
    <slot></slot>
    <div>
      <el-form class="formBox" label-width="160px" size="small" style="width: 99%;margin-top: 12px;">
              <el-form-item label="必须导入列" v-if="requiredUploadCols">
                <div style="color:red;">
                  <span v-for="c in requiredUploadCols.split(',')" v-if="filedMap[c]">
                    {{ filedMap[c] }}、
                  </span>
                </div>
              </el-form-item>
              <el-form-item label="数据列不能为空" v-if="requiredFields">
                <div style="color:red;">
                  <span v-for="c in requiredFields.split(',')" v-if="filedMap[c]">
                    {{ filedMap[c] }}、
                  </span>
                </div>
              </el-form-item>

            </el-form>
    </div>
    <div v-if="pageIndexOpen">
       第<el-input-number v-model="rowPageIndex" :disabled="loading" style="width:60px;text-align: center"
                         :controls="false"
                         :precision="0"/>页。
      根据分页获取Excel的<el-input-number v-model="rowPageSize" :disabled="loading" style="width:80px;text-align: center"
                                   :controls="false"
                                   :precision="0"/>条。
      <el-button v-if="file_path" @click="readExcel()" :disabled="loading">获取</el-button>
      <el-button v-if="file_path" @click="readExcel(true)" :disabled="loading">删除缓存</el-button>
    </div>
    <div v-loading="loading">
      <el-upload style="float: left;margin-right: 20px"
                 action=""
                 :on-change="handleChange"
                 :on-remove="handleRemove"
                 :file-list="fileListUpload"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                 :auto-upload="false">
        <etBtnAdd type="primary" icon="iconfont-shangchuan3">上传Excel文件</etBtnAdd>

      </el-upload>
      <etBtnAdd @click="submit({dataFormat:false})" type="success" :loading="loading"
                v-if="importBtn" icon="iconfont-daoru">开始导入</etBtnAdd>
      <etBtnAdd v-if="dataFormatShow" @click="submit({dataFormat:true})" type="success"
                :loading="loading" icon="iconfont-daoru">数据格式化</etBtnAdd>
      <span style="color: red;line-height: 24px;" v-if="importBtn">{{ importTip }}</span>
    </div>
    <el-table v-loading="loading" height="350" v-tablewidth="'99%'" :data="outJson"
              :class="colType=='input'?'tableInput':''"
              style="margin-top: 10px" border>

      <el-table-column label="" width="60"  align="center">
        <template #default="o">
          <etBtnDelMini @click="del(o.row)"/>
        </template>
      </el-table-column>
      <el-table-column label="#" class="index"  align="center" width="80">
        <template #default="o">
            <span>{{ o.row.i }}</span>
        </template>
      </el-table-column>
      <el-table-column v-for="(head,k) in headList" :key="k" align="center" width="120"
                       :show-overflow-tooltip="!isNullStr(selectFiled[head])" show-overflow-tooltip>
        <template   #header >
<!--          filedMap&#45;&#45;{{filedMap}}-->
<!--          head&#45;&#45;{{head}}-->
<!--          selectFiled&#45;&#45;{{selectFiled}}-->
<!--          selectFiled[head]&#45;&#45;{{selectFiled[head]}}-->
          <el-select v-model="selectFiled[head]" class="excel_title_select" style="color: #409EFF" placeholder="请选择"
                     clearable>
            <el-option v-for="(item,key) in  filedMap" :key="key"
                       :label="filedKey==null?item:item[filedKey]" :value="key"
                       :disabled="mapValToKey(selectFiled)[key]!=null||key.indexOf('$read')>-1"
            >
            </el-option>
          </el-select>
<!--          <div v-if="colType=='input'">-->
<!--             <el-input v-if="isNullStr(selectFiled[head])" maxlength="200" style="width: 100%;" :controls="false"-->
<!--                       size="mini"-->
<!--                       v-model="o.row[head]"/>-->
<!--          <span v-else class="uertSpan">{{ o.row[head] }}</span>-->
<!--          </div>-->
<!--          <div v-else>{{ o.row[head] }}</div>-->
        </template>
        <template #default="o">
            {{ o.row[head] }}

        </template>
      </el-table-column>
    </el-table>

     <el-pagination @current-change="current"
                    :total="dataList.length"
                    :page-size="pageSize"
                    layout="slot,prev, pager, next">
<span v-if="dataList!=null" style="font-weight: bold">{{ dataList.length }}行</span>
          </el-pagination>

  </el-dialog>
     </span>
</template>
<script>

import ElButton from "../button/elButtonTemp";

export default {
  name: "etUploadExcelReadTable",
  data() {
    return {
      file_path: null,
      fileTemp: null,
      pageIndex: 1,
      rowPageIndex: 0,
      rowPageSize: 50000,
      show: false,
      loading: false,
      fileListUpload: [],
      dataList: [],
      outJson: [],
      outFiledList: [],
      outJsonFiled: [],
      outFiledLength: 0,
      selectFiled: {},
      headList: {},
      price: {
        txt: "张飞"
      },
      top: '10vh'
    };
  },
  props: {
    isCoupon:{default: false},
    filedKey: {default: null},
    pageSize: {default: 10},
    // pageIndex: {default: 0},
    pageIndexOpen: {default: false},
    colType: {default: "text"},
    requiredUploadCols: {default: ""},//必须上传的列，逗号分隔
    requiredFields: {default: ""},//必填列值，逗号分隔，无值将自动过滤掉
    dataFormatShow: {default: false},
    importBtn: {default: true},
    disabled: {default: false},
    btnSize: {default: "small"},
    btnName: {default: "数据导入"},
    importTip: {default: "数据导入从第1行开始。表头识别完后，删除第1行"},
    title: {default: "数据导入"},
    filedMap: {
      default: () => {
        return {};
      }
    },
    filedValueDefaultMap: {
      default: () => {
        return {};
      }
    },
  },
  computed: {},
  components: {
    ElButton
    /*加载组件*/
  },
  mounted() {/*页面加载初始化*/
    if(this.isCoupon){
      this.getcoupon_infoList();
    }
  },
  methods: {

    getcoupon_infoList(){
      this.axiosPost("/couponInfo/getCoupomInfo.do", {}, res => {
        this.$set(this,"coupon_infoList",res.list)
        // console.log(JSON.stringify(this.coupon_infoList))
      });
    },
    del(row) {
      this.current(this.pageIndex)
      this.delArrItem(this.outJson, row);
      this.delArrItem(this.dataList, row);
    },
    open() {
      this.$set(this, "show", true);
    },
    closeDialog() {
      this.$set(this, "show", false);
      Object.assign(this.$data, this.$options.data());
    },
    getDataList() {
      var selectFiled = this.mapValToKey(this.selectFiled);

      var list = []
      this.dataList.forEach(o => {
        var map = {}
        for (var key in selectFiled) {

          var val = o[selectFiled[key]]
          if (val != null && (val + "").trim().length > 0 && (val + "").toLocaleUpperCase() != 'NULL') {
            map[key] = val;
          } else {
            map[key] = "";
          }
        }
        for (var key in this.filedValueDefaultMap) {
          if (!this.isNullStr(map[key])) {
            map[key] = this.filedValueDefaultMap[key];
          }
        }
        list.push(map);
      });
      return list;
    },
    submit({dataFormat = false}) {
      var $this = this;
      if (this.outJson.length == 0) {
        this.showMsgError("请上传Excel");
        return;
      }
      if (this.mapLength(this.selectFiled) == 0) {
        this.showMsgError("请选择对应数据列");
        return;
      }
      var selectFiled2 = this.mapValToKey(this.selectFiled)
      var errMsg = "";
      if (this.requiredUploadCols)
        this.requiredUploadCols.split(",").forEach(c => {
          if (!selectFiled2[c]) {
            if (!errMsg) {
              errMsg = "必须导入的列：\n"
            }
            errMsg += this.filedMap[c] + "\n";
          }
        })
      if (errMsg) {
        this.showMsgError(errMsg)
        return;
      }
      // var dataList = this.getDataList();
      // console.log("dataList", dataList);
      // var dataList2 = []
      // dataList.forEach(o => {
      //
      // })
      var selectFiled = this.mapValToKey(this.selectFiled)

      if (dataFormat) {

        this.$set(this, "loading", true);
        this.$emit('dataFormat', this.getDataList(), function (list) {

          // console.log("dataFormat-fn", list)
// $this.current(1)
          // $this.$set($this, "headList", $this.mapKeyToList(list[0]));
          $this.$set($this, "selectFiled", {});
          $this.importfxx({
            dataList: list,
            headList: $this.mapKeyToList(list[0])
          })
          setTimeout(() => {
            $this.importfxx({
              dataList: list,
              headList: $this.mapKeyToList(list[0])
            })
            $this.$set($this, "loading", false);
          }, 100)

        }, function () {
          $this.$set($this, "loading", false);
        });
      } else {
        this.$confirm("确定批量导入当前数据吗？", '批量导入提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning",
          center: true,
          confirmButtonClass: "confirmBtnOk"
        }).then((e) => {
          this.$set(this, "loading", true);
          this.$emit('uploadExcelJson', this.getDataList(),function () {
            if (!$this.pageIndexOpen) {
              $this.$set($this, "show", false);
            }
            $this.$emit('refresh');
            $this.$set($this, "loading", false);
          }, function () {
            $this.$emit('refresh');
            $this.$set($this, "loading", false);
          });
//          this.loading=false
        });
      }
    },
    // excel表上传
    readExcel(del) {
      // 判断上传文件格式
      var param = {
        rowPageIndex: this.rowPageIndex,
        rowPageSize: this.rowPageSize,
        file: this.file_path,
      }
      if (del) {
        param.rowPageIndex = 0;
      }
      this.axiosPost("/sysFile/readExcelJson.do", param,

        function (resData) {
          if (resData.excelObj)
            this.importfxx(resData.excelObj)
          // this.axiosPost("/sysFile/readExcelJson.do", {file: resData.obj.file_path}, function (resData2) {
          //   this.$set(this, "loading", true);
          //   this.importfxx(resData2.obj)
          //   this.importfxx(resData.excelObj)
          //   ;this.$set(this, "loading", false)
          // });
        }
      )

    },
    handleChange(file, fileList) {
      this.fileTemp = file.raw
      // 判断上传文件格式
      if (this.fileTemp) {
        if ((this.fileTemp.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (this.fileTemp.type == 'application/vnd.ms-excel')) {
//            this.importfxx(this.fileTemp)
          this.axiosUploadFile('/sysFile/uploadFile.do?readExcel=true&rowPageIndex=' + this.rowPageIndex + "&rowPageSize=" + this.rowPageSize, this.fileTemp, function (resData) {
            this.$set(this, "file_path", resData.obj.file_path);
            this.importfxx(resData.excelObj)
            // this.axiosPost("/sysFile/readExcelJson.do", {file: resData.obj.file_path}, function (resData2) {
            //   this.$set(this, "loading", true);
            //   this.importfxx(resData2.obj)
            //   this.importfxx(resData.excelObj)
            //   ;this.$set(this, "loading", false)
            // });
          })
        } else {
          this.$message({
            type: 'warning',
            message: '附件格式错误，请删除后重新上传！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请上传附件！'
        })
      }
    }
    ,
// 移除excel表
    handleRemove(file, fileList) {
      this.fileTemp = null
    }
    ,
    current(i) {
      this.$set(this, "pageIndex", i)
      this.$set(this, "outJson", this.dataList.slice((i - 1) * this.pageSize, i * this.pageSize));
    }
    ,
    importfxx(obj) {
      // console.log("上传文件完毕后的obj"+JSON.stringify(obj))
      let $this = this;
      $this.$set($this, "selectFiled", {});
      var dataList = obj.dataList;
      var headList = obj.headList;
      for (var k in this.filedMap) {
        if (k.indexOf("$read") > -1 && headList.indexOf(k) == -1) {
          headList.unshift(k)
          if (dataList.length > 0)
            dataList[0][k] = this.filedMap[k];
        }
      }
      this.$set(this, "headList", headList);
      var fmap = {};
      headList.forEach(h => {
        fmap[h] = dataList[0][h];
      })
      // console.log("fmap==="+JSON.stringify(fmap))
      $this.$set($this, "dataList", dataList);
      $this.$set($this, "outFiledLength", headList.length);
      $this.$set($this, "outJsonFiled", this.listToMap(headList));
      $this.$set($this, "selectFiled", {});
      // console.log("fmap=="+JSON.stringify(fmap));
      for (var k in fmap) {
        var v = (fmap[k] || '').trim();
        // console.log("v=="+v);
        // console.log("filedMap=="+JSON.stringify($this.filedMap));
        for (var fk in $this.filedMap) {
          var fv = ($this.filedMap[fk].name || '').trim();
          k = (k || '').trim();
          fk = (fk || '').trim();
          var fvList = fv.split(",");
          // console.log("fvList=="+fvList);
          // console.log("k=="+k);
          // console.log("fv=="+fv);
          if (fvList.indexOf(k) > -1 || k == fv || fvList.indexOf(v) > -1 || v == fv) {
            $this.$set($this.selectFiled, k, fk);
          }
        }
      }
      // console.log("selectFiled", JSON.stringify($this.selectFiled))
      this.current(1);
    }
    ,
  },
  watch: { //值改变动作 valObj(val){}
    selectFiled: {
      handler(newObj, oldObj) {
      }
      ,
      deep: true
    }

  }
  ,
}
;
</script>
