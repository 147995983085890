<template>
  <div>
    <el-select v-model="value2"
               filterable
               allow-create
               :placeholder="placeholder"
               :disabled="disabled"
               style="width: 100%">
      <span v-for="(item,index) in list" :key="index">
         <el-option  :key="item.key" :label="item.val" :value="item.key"/>
      </span>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "etSysTypeSelect",
  data(){
    return{
      list:[],
      // value2:this.value
    }
  },
  props:{
    modelValue:null,
    placeholder:{
      default:""
    },
    disabled:{
      default: false
    },
    code:{
      default:'all'
    }
  },setup(props, {emit}) {
    const value2 = ref(props.modelValue) // 初始化数据
    watch(
        () => value2.value,
        (data) => emit('update:modelValue', data)
    ) // 监听输入值变动
    return {
      value2: value2,
    }
  },
  methods:{
    findList(){
      this.axiosPost("/sysType/findDDList.do", {code: this.code}, res => {
        this.$set(this, "list", res.list);
      });
    }
  },
  watch: {
    modelValue() {
      this.$set(this, "value2",this.value) ;
    },
    value2() {
      // this.$emit('input', this.value2);
    }
  },
  mounted(){
    this.findList();
  }
}
</script>

<style >

</style>
