<style lang="less">
.sysDataUpdateList {
  .img {
    height: 60px;
    margin-right: 10px;
    border: 1px #cccccc solid;
  }

  .el-table td.change_list {
    padding: 0;

    .cell {
      padding: 0;

      .changeItem {
        font-size: 14px;
        color: #000000;
        border-bottom: 1px #cccccc dotted;
        padding: 2px 12px;
        //line-height: 24px;
        .icon-right {
          margin: 0 2px;
          color: red;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
  }

  .operType {
    color: dodgerblue;
  }
  .operType, .operName,.operTime{
    margin-right: 12px;
  }
}
</style>
<template>
  <div>

    <div class="mainContent sysDataUpdateList ">
      字段：
      <el-select v-model="search.findCol" placeholder="" filterable clearable allow-create @change="findPage()"
                 style="width: 200px;display: inline-block"
      >
        <el-option v-for="(c) in design.colMap" :key="c.col" :label="c.name" :value="c.col"/>
      </el-select>
      <!--<el-table default-expand-all :data="treeList[0].children" :height="getHeight(tHeight)" style="width: 100%; " -->
      <!-- row-key="id" border :tree-props="{children: 'children'}"  @row-dblclick="rowDblClick">-->
      <el-table ref="table" :height="getHeight(tHeight-20)" :data="page.list2" border stripe
                @selection-change="selectionChange" @row-dblclick="rowDblClick"
                @sort-change="sortChange" :default-sort="sort" v-loading="loading"
                style="width: 100%">
        <!--<el-table-column type="index" align="center" :width="indexWidth()"/>-->
        <!--<el-table-column  v-if="delStatus()" type="selection" align="center" width="40" :selectable="selectable"></el-table-column>-->


        <el-table-column v-if="false" label="类型/人员" :prop="'type'" align="center" width="100">
          <template #default="o">
            <div style="line-height: 20px;background-color: #D1D1D1;border-bottom: 1px #cccccc dashed;padding:8px">
              <div style="font-size: 12px;color: #333" v-if="o.row.a_name">
                <div class="operType muser">[超管-{{ sysDataUpdateType[o.row.type] }}]</div>
                <div class="operName">{{ o.row.a_name }}</div>
                <div class="operName">{{ o.row.a_phone }}</div>
              </div>
              <div style="font-size: 12px;color: #333" v-else-if="o.row.m_name">
                <div class="operType muser">[经理-{{ sysDataUpdateType[o.row.type] }}]</div>
                <div class="operName">{{ o.row.m_name }}</div>
                <div class="operName">{{ o.row.m_num }}</div>
              </div>
              <div style="font-size: 12px;color: #333" v-else-if="o.row.s_name">
                <div class="operType suser"> [员工-{{ sysDataUpdateType[o.row.type] }}]</div>
                <div class="operName">{{ o.row.s_name }}</div>
                <div class="operName">{{ o.row.s_num }}</div>
              </div>
              <div style="font-size: 12px;color: #333" v-else-if="o.row.n_name">
                <div class="operType nuser">[会员-{{ sysDataUpdateType[o.row.type] }}]</div>
                <div class="operName">{{ o.row.n_name }}</div>
                <div class="operName">{{ o.row.n_phone }}</div>
              </div>
              <div style="font-size: 12px;color: #333" v-else-if="o.row.u_name">
                <div class="operType sysUser">[后台-{{ sysDataUpdateType[o.row.type] }}]</div>
                <div class="operName">{{ o.row.u_name }}</div>
                <div class="operName">{{ o.row.u_username }}</div>
              </div>
            </div>
            <div style="line-height: 12px;text-align: center;font-size: 12px;">
              <div>{{ dateFmt(o.row.create_time,"yyyy-MM-dd") }}</div>
              <div>{{ dateFmt(o.row.create_time,"HH:mm:ss") }}</div>
            </div>
          </template>
        </el-table-column>

        <!--<el-table-column label="操作人员" :prop="'sysUserId'" align="center" width="80"-->
        <!--show-overflow-tooltip/>-->
        <el-table-column label="操作记录" class-name="change_list" :prop="'change_list'" align="left">
          <template #default="o">
            <div style="line-height: 20px;background-color: #EEEEEE;border: 1px #666666 dashed;padding:4px 12px;">
              <span class="operTime" style="line-height: 12px;text-align: center; ">
                <span>{{ dateFmt(o.row.create_time,"yyyy-MM-dd HH:mm:ss") }}</span>
<!--                <span>{{ dateFmt(o.row.create_time,"HH:mm:ss") }}</span>-->
              </span>
              <span style="color: #333" v-if="o.row.a_name">
                <span class="operType muser" style="color: rebeccapurple">[超管-{{ sysDataUpdateType[o.row.type] }}] </span>
                <span class="operName">{{ o.row.a_name }} </span>
                <span class="operName">{{ o.row.a_phone }} </span>
              </span>
              <span style="color: #333" v-else-if="o.row.m_name">
                <span class="operType muser">[经理-{{ sysDataUpdateType[o.row.type] }}] </span>
                <span class="operName">{{ o.row.m_name }} </span>
                <span class="operName">{{ o.row.m_num }} </span>
              </span>
              <span style="color: #333" v-else-if="o.row.s_name">
                <span class="operType suser"> [员工-{{ sysDataUpdateType[o.row.type] }}] </span>
                <span class="operName">{{ o.row.s_name }} </span>
                <span class="operName">{{ o.row.s_num }} </span>
              </span>
              <span style="color: #333" v-else-if="o.row.n_name">
                <span class="operType nuser">[会员-{{ sysDataUpdateType[o.row.type] }}] </span>
                <span class="operName">{{ o.row.n_name }} </span>
                <span class="operName">{{ o.row.n_phone }} </span>
              </span>
              <span style="color: #333" v-else-if="o.row.u_name">
                <span class="operType sysUser" style="color: red">[后台-{{ sysDataUpdateType[o.row.type] }}] </span>
                <span class="operName">{{ o.row.u_name }} </span>
                <span class="operName">{{ o.row.u_username }} </span>
              </span>


            </div>
            <template v-for="(f,c) in o.row.change_list">
              <div class="changeItem" v-if="!search.findCol||search.findCol==c">
                <!--              <el-row>-->
                <!--                <el-col :span="6">-->
                <span style="font-weight: normal;width: 100px;   display: inline-block;color:#016ad1;">
                  {{ design.colMap[c] ? design.colMap[c].name : c }}:
                </span>
                <!--                </el-col>-->
                <!--                <el-col :span="18">-->
                <template v-if="design.colMap[c]">
                  <span v-if="design.colMap[c].data_update_raw">{{f.oldrow[c]}}</span>
                  <etDesignColVal v-else :row="f.oldrow" :c="design.colMap[c]" :design="design" type="sysDataUpdate"
                                  :selectMap="selectMap" :sysMap="sysMap"></etDesignColVal>
                  <span style="color: red;margin: 0 8px; "><i class="iconfont-iconfontkuaijin"/></span>
                  <span v-if="design.colMap[c].data_update_raw">{{f.row[c]}}</span>
                  <etDesignColVal v-else :row="f.row" :c="design.colMap[c]" :design="design"  type="sysDataUpdate"
                                  :selectMap="selectMap" :sysMap="sysMap"></etDesignColVal>
                </template>
                <template v-else>
                  {{ f.oldVal }} <span style="color: red;margin: 0 8px; "><i
                    class="iconfont-iconfontkuaijin"/></span>{{ f.newVal }}
                </template>
                <!--                </el-col>-->

                <!--              </el-row>-->
              </div>
            </template>
          </template>
        </el-table-column>
<!--        <el-table-column label="操作时间" :prop="'create_time'" width="85" align="center">-->
<!--          <template #default="o">-->
<!--            &lt;!&ndash;<i class="el-icon-time"></i>&ndash;&gt;-->
<!--            <div style="line-height: 16px;text-align: center">-->
<!--              <div>{{ dateFmt(o.row.create_time,"yyyy-MM-dd") }}</div>-->
<!--              <div>{{ dateFmt(o.row.create_time,"HH:mm:ss") }}</div>-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <!--<el-table-column label="操作" align="left">-->
        <!--<template #default="o">-->
        <!--<etBtnEditMini v-if="addEditStatus()" @click="findObj(o.row.id,true)">复制</etBtnEditMini>-->
        <!--<etBtnEditMini v-if="addEditStatus()" @click="findObj(o.row.id)"/>-->
        <!--<etBtnEditMini v-else @click="findObj(o.row.id)">查看</etBtnEditMini>-->
        <!--<etBtnDelMini v-if="delStatus()" @click="del(o.row.id)"/>-->
        <!--</template>-->
        <!--</el-table-column>-->
      </el-table>
      <etPage :page="page" @refresh="refresh" @setVal="setVal"/>
    </div>
  </div>
</template>
<script>
import baseAdmin from '../../../ydVue/baseAdmin'

/*
//复制到flatConfig.js

*/
export default {
  name: "sysDataUpdateList",
  extends: baseAdmin.page,
  data() {
    return {
      selectList: {},
      selectMap: {},
      slots: {},
      sysDataUpdateType: {add: "新增", update: "修改"},
      tableName: "sysDataUpdate",
      config: {
        vueKey: 'sysDataUpdateIndex',
        showSelectField: false,
        searchMap: {
          /*更多查询字段*/
          //sex: true,
        },
        fieldListTable: [],
        fieldListExport: [],
      },
      list: [],
      funType: "page",//tree为树形结构
//                funType: "tree",//tree为树形结构
      baseUrl: "/sysDataUpdate",//默认访问地址,必须继承修改,如【/sys_User】
      findPageUrl: "/sysDataUpdate/findPage.do",
      page: {},//分页数据集，包含页码、页大小
      sort: {prop: 'id', order: 'descending'},//排序数据集
      search: {isDel: 0},//查询参数
      selectionVals: [],//table选项值
      login_id: localStorage.getItem('login_id'),//当前登录用户ID
      loading: false,//加载效果
      fieldMap: {},
      sysMap: {},
      epAreaTree: [],
    }
  },
  props: {doUrl: {default: true}, design: {}},
  methods: {
    findPage(type) {/*查询分页*/
      var table_name = this.search.table_name
      this.findSysMap(r => {
        this.getAreaTree({dataKey: "epAreaTree", pid: this.getLoginUser().ep_area_id, showPid: true})
      });


      this.$set(this, "loading", true);
      this.pfindPage(type, (resData) => {
        // if (this.flatConfig[table_name]) {
        //   var fieldMap = this.listJsonToMap(this.flatConfig[table_name].fieldList, "field");
        //   this.$set(this, "fieldMap", fieldMap);
        // }
        //通过回调数据处理
        // console.log("resData",resData)
        // this.page=resData.page
        var list2 = []
        this.page.list.forEach(o => {
          var o2 = this.copyObj(o);
          for (var k in o.change_list) {
            o2.change_list[k].row = {}
            o2.change_list[k].oldrow = {}
            o2.change_list[k].row[k] = o.change_list[k].newVal
            o2.change_list[k].oldrow[k] = o.change_list[k].oldVal

            var ids = []
            if (o.change_list[k].oldVal instanceof Array) {
              o.change_list[k].oldVal.forEach(o1 => {
                ids.push(o1);
              })
            } else {
              ids.push(o.change_list[k].oldVal);
            }
            if (o.change_list[k].newVal instanceof Array) {
              o.change_list[k].newVal.forEach(o1 => {
                if (o1!=null)
                  ids.push(o1);
              })
            } else if (o.change_list[k].newVal) {
              ids.push(o.change_list[k].newVal);
            }
            o[k] = ids
          }
          list2.push(o2);
          // o.change_list.forEach(item => {
          //   if (item.field.indexOf("$") > -1) {
          //     o.change_list.forEach(item2 => {
          //       if (item.field == item2.field + "$") {
          //         this.delArrItem(o.change_list, item2)
          //       }
          //
          //     });
          //   }
          //
          // })
        })
        this.page.list2 = list2;
        // console.log("9999")
        this.initSelect('table');
      });
    },
    initFind(tableName, id) {
      this.$set(this, "page", {pageNumber: 1, pageSize: 10});
      this.$set(this.search, "table_name", tableName);
      this.$set(this.search, "obj_id", id);
      this.findPage();
//        this.axiosPost(this.baseUrl + "/findPage.do", {tableName: tableName, objId: id}, function (resData) {
//          this.$set(this, "list", resData.list);
//          this.loading=false
//        });
    },
    findObj(id, copy) { /*查询表单对象obj*/

    },
    del(ids) {/*删除数据*/
    },
    selectionChange(val) { /*多选操作*/
    },
    rowDblClick(row) {/*双击行编辑*/
    },
    selectable(row, index) { /*多选框是否可操作判断*/
    },
  },
  watch: {
    /*值改变动作 valObj(val){}*/
  },
  components: {},
  mounted() {/*页面加载初始化*/
    this.$set(this, "slots", this.$slots);
  }
}
</script>

