<style lang="less">
.dialogDesign {
  .etDesign .el-tabs--border-card > .el-tabs__content {
    //max-height: calc(100vh - 420px) !important;
    height: calc(100vh - 280px);
  }
}

.etDesign {
  .el-form-item__label {
    height: 28px;
    line-height: 28px;

  }

  .el-input__inner {
    height: 24px;
  }


  .el-input {
    --el-input-height: 24px;
  }

  .title {
    height: 28px;
    line-height: 28px;
    background: #0073ff;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }

  .col2Selectd {
    color: #cccccc;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    &.isActive {
      color: red;
    }
  }

  .el-radio-group {
    .el-radio-button__inner {
      height: 24px;
      padding: 4px 8px;
    }
  }

  .el-button {
    height: 24px !important;
    padding: 4px 8px !important;
  }


  .el-checkbox, .el-radio {
    //color: #ccc;
    margin-right: 16px;
    min-width: 0px;
    height: 20px;
  }

  .btnDiv {
    margin-top: 8px;
    &.el-button + .el-button {
      margin-left: 0;
    }

    .el-button {
      padding: 4px 12px;
    }
  }

  .el-tabs__item, .el-tabs__item:nth-child(2) {
    padding: 0 6px !important;
  }

  .designBtn {
    font-size: 24px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .el-tabs{
    --el-tabs-header-height:32px;
    .el-tabs__nav-next, .el-tabs__nav-prev{
      line-height:32px;
    }
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 8px 2px;
    height: calc(100vh - 256px);
    overflow-y: auto;
  }

  .el-form-item {
    margin-bottom: 2px;

    .el-input {
      color: inherit;
      font-weight: inherit;
    }

    .el-form-item__label {
      padding: 0 4px 0 0;
    }
  }

  /*被拖拽对象的样式*/

  .item {
    cursor: move;
    //float: left;
    border: 0px #f2f2f2 solid;
    margin: 0 0px 1px 0;
    padding: 0px 5px;
    background: #ecf5ff;

    .is-guttered {
      //height: 24px;
      line-height: 16px;
    }

    .el-checkbox {
      height: 20px;
    }
  }

  /*选中样式*/

  .chosen {
    background: #96ff7fb0;
  }

  .btnList {
    .el-button {
      margin-right: 12px;

      i {
        margin-right: 2px;
      }
    }
  }
}


</style>
<template>
  <!--  <span v-if="show_type=='form'" @click="design.showUseRemark=!design.showUseRemark"
          style="    position: absolute;right: 16px;    top: 45px; cursor: pointer;color: cornflowerblue">
      <i class="iconfont-b71" style="font-size: 18px"/>
    </span>
    <div v-if="design&&design.showUseRemark">

      &lt;!&ndash;    <el-drawer class="remark" v-model="drawer" :modal="false" :append-to-body="true"  size="500px" title="功能使用说明" :direction="'rtl'">&ndash;&gt;
      <show-tip-dialog/>
      &lt;!&ndash;    </el-drawer>&ndash;&gt;
    </div>-->
  <div class="etDesign" v-if="design&&dev$">

    <el-button v-if="dev$" class="openDesignBtn" circle @click="open()"
               :type="show_type==design.show_type?'success':''">
      <i class="iconfont-sheji"/>
      <template v-if="designNew">
        <etSysDict :title="designNew.dev_content" :sysMap="sysMap" placeholder="开发状态" dict_code="dev_status" text
                   v-model="designNew.dev_status">
          -{{ designNew.dev_staff }}
        </etSysDict>

      </template>
      <!--      {{show_type}}={{design.show_type}}-->
    </el-button>

    <template v-if=" show_type==design.show_type">
      <div style="padding: 8px;font-weight: bold;background-color: #ff760000;color: #ffffff;border:1px #ccc solid;margin-top: 0px">
        <el-row :gutter="20">
          <!--        <el-col :span="6">设计器</el-col>-->
          <el-col :span="24" style="text-align: center" class="btnList">
            <el-button class="btn" type="success" @click="saveDesignJs()">保存</el-button>
            <el-button class="btn" type="primary" @click="addWhere">
              <i class="iconfont-jiahao1"/>查询
            </el-button>
            <el-button class="btn" type="danger" @click="addField"><i class="iconfont-jiahao1"/>字段</el-button>
          </el-col>
        </el-row>
      </div>
      <!--      {{getDevAuthor()}}1-->
      <el-tabs type="border-card" v-model="design.tabs">

        <el-tab-pane :label="design.show_type=='form'?'表单顺序':'表格顺序'" name="default">
          <div style="text-align: center;margin-bottom: 8px;">
            <el-checkbox v-model="design.auto_sort">所有顺序一致</el-checkbox>
          </div>
          <draggable v-model="colsList" style=" " item-key="col" @start="drag=true"
                     chosen-class="chosen" @end="drag=false" :force-fallback="false">
            <template #item="{element}">
              <div class="item" v-if="design.colMap[element]">
                <el-row :gutter="20" style="width: 100%">
                  <el-col :span="2">
                    <el-checkbox v-if="design.show_type=='form'" v-model="design.colMap[element].show_form"


                                 style="float: left"/>
                    <el-checkbox v-else v-model="design.colMap[element].show"
                                 style="float: left"/>
                  </el-col>
                  <template v-if="design.colMap[element].status==1">
                    <el-col :span="10">
                      <template v-if="design.show_type=='form'||design.auto_sort">
                         <span
                             style="cursor: pointer" class="col2Selectd"
                             :class="{isActive: design.colMap[element].col2==1}"
                             @click.stop="col2Change(design.colMap[element])">‖</span>
                        {{ design.colMap[element].name_form || design.colMap[element].name }}
                      </template>
                      <template v-else>
                        {{ design.colMap[element].name_table || design.colMap[element].name }}
                      </template>

                    </el-col>
                    <el-col :span="10">
                      <span style="font-weight: bold;">
                        {{ design.colMap[element].col }}
                       <i v-if="design.colMap[element].col_index_name" class="iconfont-jiantou"
                          style="color:orangered; ;font-size: 10px" title="索引"/>
                      </span>
                    </el-col>
                  </template>
                  <template v-else>
                    <el-col :span="10" style="color:red">
                       <span
                           style="cursor: pointer" class="col2Selectd"
                           :class="{isActive: design.colMap[element].col2==1}"
                           @click.stop="col2Change(design.colMap[element])">‖</span>
                      {{ design.colMap[element].name }}
                    </el-col>
                    <el-col :span="10">
                        <span
                            v-if="design.colMap[element].module_type_form=='card'||design.colMap[element].module_type_form=='tabs'"
                            style="font-weight: bold;color:#a900ff;">【{{
                            design.colMap[element].module_type_form
                          }}】</span>
                      <span v-else>
                          <span style="color:red">{{ design.colMap[element].col }}</span>
                        <span style="color:red;font-weight: bold;margin-left: 6px;">
                         【非字段】

                        </span>
                        </span>
                    </el-col>
                  </template>
                  <el-col :span="2">
                    <el-icon class="iconfont-bianji" style="font-size: 14px;cursor: pointer;padding-top: 4px"
                             @click.stop="editCol(design.colMap[element])"></el-icon>
                  </el-col>

                </el-row>
              </div>
            </template>
          </draggable>
        </el-tab-pane>

        <el-tab-pane label="表格属性" name="table">
          <el-form v-if="design.table" label-position="right" label-width="100">
            <el-form-item label="表格宽度">
              <el-input v-model="design.table.width" style="width: 100px"/>
              <el-checkbox v-model="design.table.border">边框</el-checkbox>
              <el-checkbox v-model="design.table.verticalAlignTop">垂直顶部靠齐</el-checkbox>
            </el-form-item>
            <el-form-item label="树形表格">
              <el-checkbox v-model="design.table.tree">树表</el-checkbox>
              <el-checkbox v-if="design.table.tree" v-model="design.table.treeExpandAll"
              >默认展开
              </el-checkbox>
              <el-input v-if="design.table.tree" v-model="design.table.tree_id" placeholder="pid值" style="width: 100%"/>
              <el-checkbox v-if="design.table.tree" v-model="design.table.tree_update_full_name"
              >更新全称按钮
              </el-checkbox>
            </el-form-item>
            <el-form-item label="功能使用说明">
              <el-checkbox v-model="design.table.show_use_remark">显示
              </el-checkbox>
              <!--              <el-input v-model="design.table.edit" style="width: 100px"/>-->
            </el-form-item>
            <el-form-item label="编辑表格">
              <el-checkbox v-model="design.table.edit_table">开启数据编辑，使用表单配置
              </el-checkbox>
              <!--              <el-input v-model="design.table.edit" style="width: 100px"/>-->
            </el-form-item>
            <el-form-item label="默认排序字段">
              <el-select v-model="design.table.sort_col" clearable filterable>
                <el-option v-for="c in design.colMap" :label="c.name+' '+c.col" :value="c.col"
                           :disabled="c.status==0"/>
              </el-select>
            </el-form-item>
            <el-form-item label="排序方向">
              <el-radio-group v-model="design.table.sort_order">
                <el-radio label="ascending">升序</el-radio>
                <el-radio label="descending">降序</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="不统计行">
              <el-checkbox v-model="design.table.noCountRow">除第1页统计，其他页不统计
              </el-checkbox>
              <!--              <el-input v-model="design.table.edit" style="width: 100px"/>-->
            </el-form-item>

            <el-form-item label="分页栏">
              <el-checkbox v-model="design.table.noShowPage">不显示
              </el-checkbox>
              <!--              <el-input v-model="design.table.edit" style="width: 100px"/>-->
            </el-form-item>
            <el-form-item label="默认条数">
              <el-input-number v-model="design.table.pageSize" style="width: 100%"/>
            </el-form-item>
            <el-form-item label="条数选择">
              <el-input v-model="design.table.pageSizes" style="width: 100%"/>
            </el-form-item>
            <el-form-item label="表格序号">
              <el-checkbox v-model="design.table.indexShow">显示</el-checkbox>
              <el-input v-model="design.table.index_label" placeholder="序号名称" style="width: 100px"/>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.table.addBtn">新增按钮</el-checkbox>
            </el-form-item>

            <el-form-item label="新增不显示" v-if="design.table.addBtn">
              <el-input style="width: 100%" type="textarea" v-model="design.table.add_no_show_rule2"
                        placeholder="loginSys,$rq"
                        @change="design.table.add_no_show_rule=design.table.add_no_show_rule2"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.table.selectDel">批量删除按钮</el-checkbox>
            </el-form-item>
            <el-form-item label="导入">
              <el-checkbox v-model="design.table.import">导入
              </el-checkbox>
              <el-input style="width: 100%" v-if="design.table.import" placeholder="模板"
                        v-model="design.import_temp_url"></el-input>
            </el-form-item>
            <el-form-item label="导出">
              <el-checkbox v-model="design.table.export">导出 exportTable.action
              </el-checkbox>
              <el-input style="width: 100%" v-if="design.table.export" placeholder="导出文件名"
                        v-model="design.export_file_name"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.table.expand">展开行(#expand$="o")
              </el-checkbox>
            </el-form-item>
            <el-form-item label="合计">
              <el-checkbox v-model="design.table.showSummary">
                显示
              </el-checkbox>
              <el-input style="width: 100%" placeholder="合计函数名"
                        v-model="design.table.summaryMethod"></el-input>
            </el-form-item>
            <div
                style="background-color: #23aba4;height: 32px;line-height: 32px;text-align: center;color:#fff;font-weight: bold;margin: 8px 0;">
              【操作】表格列-配置
            </div>
            <el-form-item label="名称">
              <el-input v-model="design.oper.name" style="width: 100%"/>
            </el-form-item>
            <el-form-item label="列宽">
              <el-input v-model="design.oper.width" style="width: 50px"/>
              px
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.oper.show">显示操作列</el-checkbox>
            </el-form-item>
            <el-form-item label="排列">
              <el-button-group>
                <template v-for="(item,k) in alignMap">
                  <el-button @click="design.oper.align=k" :type="design.oper.align==k?'primary':''">
                    {{ item.name }}
                  </el-button>
                </template>
              </el-button-group>
            </el-form-item>
            <el-form-item label="左右固定">
              <el-button-group>
                <template v-for="(item,k) in fixedMap">
                  <el-button @click="design.oper.fixed=k" :type="design.oper.fixed==k?'primary':''">
                    {{ item.name }}
                  </el-button>
                </template>
              </el-button-group>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.oper.edit">编辑按钮</el-checkbox>
              <el-checkbox v-model="design.oper.del">删除按钮</el-checkbox>
              <el-checkbox v-model="design.oper.copy">复制按钮</el-checkbox>
            </el-form-item>
            <el-form-item label="不删除规则">
              <el-input type="textarea" v-model="design.oper.del_no_rule2" style="width: 100%"
                        placeholder="row,$rq,loginSys"
                        @change="design.oper.del_no_rule=design.oper.del_no_rule2"/>
            </el-form-item>
            <el-form-item label="不编辑规则">
              <el-input type="textarea" v-model="design.oper.edit_no_rule2" style="width: 100%"
                        placeholder="row,$rq,loginSys"
                        @change="design.oper.edit_no_rule=design.oper.edit_no_rule2"/>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="'导出顺序'" v-if="design.table.export==true" name="exportList">
          <div class="exportList">

            <draggable v-model="design.exportList" style=" " item-key="col" @start="drag=true"
                       chosen-class="chosen" @end="drag=false" :force-fallback="false">
              <template #item="{element}">
                <div class="item" v-if="design.colMap[element]">
                  <el-row>
                    <el-col :span="2">
                      <el-checkbox v-model="design.colMap[element].export"


                                   style="float: left"/>
                    </el-col>
                    <template v-if="design.colMap[element].status==1">
                      <el-col :span="10">
                        <el-input v-model="design.colMap[element].export_name" style="width: 100%;display: contents;"
                                  :placeholder="''+design.colMap[element].show_name"/>
                      </el-col>
                      <el-col :span="10">
                      <span style="font-weight: bold;">
                        {{ design.colMap[element].col }}
                      </span>
                      </el-col>
                    </template>
                    <template v-else>
                      <el-col :span="12" style="color:red">
                        <el-input v-model="design.colMap[element].export_name" style="width: 100%;display: contents;"
                                  :placeholder="''+design.colMap[element].show_name"/>
                        <!--                        {{ design.colMap[element].export_name || design.colMap[element].show_name }}-->
                      </el-col>
                      <el-col :span="8">
                        <span v-if="design.colMap[element].module_type_form=='card'"
                              style="font-weight: bold;color:red;">【卡片】</span>
                        <span v-else>
                          <span style="color:red">{{ design.colMap[element].col }}</span>
                        <span style="color:red;font-weight: bold;margin-left: 6px;">
                         【非字段】

                        </span>
                        </span>
                      </el-col>
                    </template>
                    <el-col :span="2">
                      <el-icon class="iconfont-bianji" style="font-size: 14px;cursor: pointer;padding-top: 4px"
                               @click.stop="editCol(design.colMap[element])"></el-icon>
                    </el-col>

                  </el-row>
                </div>
              </template>
            </draggable>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="'导入'" v-if="design.table.import==true" name="importList">
          <div class="exportList">
            <draggable v-model="design.importList" style=" " item-key="col" @start="drag=true"
                       chosen-class="chosen" @end="drag=false" :force-fallback="false">
              <template #item="{element}">
                <div class="item" v-if="design.colMap[element]">
                  <el-row>
                    <el-col :span="2">
                      <el-checkbox v-model="design.colMap[element].import"


                                   style="float: left"/>
                    </el-col>
                    <template v-if="design.colMap[element].status==1">
                      <el-col :span="10">
                        <el-input v-model="design.colMap[element].import_name" style="width: 100%;display: contents;"
                                  :placeholder="''+design.colMap[element].show_name"/>
                      </el-col>
                      <el-col :span="10">
                      <span style="font-weight: bold;">
                        {{ design.colMap[element].col }}
                      </span>
                      </el-col>
                    </template>
                    <template v-else>
                      <el-col :span="12" style="color:red">
                        <el-input v-model="design.colMap[element].import_name" style="width: 100%;display: contents;"
                                  :placeholder="''+design.colMap[element].show_name"/>
                        <!--                        {{ design.colMap[element].export_name || design.colMap[element].show_name }}-->
                      </el-col>
                      <el-col :span="8">
                        <span v-if="design.colMap[element].module_type_form=='card'"
                              style="font-weight: bold;color:red;">【卡片】</span>
                        <span v-else>
                          <span style="color:red">{{ design.colMap[element].col }}</span>
                        <span style="color:red;font-weight: bold;margin-left: 6px;">
                         【非字段】

                        </span>
                        </span>
                      </el-col>
                    </template>
                    <el-col :span="2">
                      <el-icon class="iconfont-bianji" style="font-size: 14px;cursor: pointer;padding-top: 4px"
                               @click.stop="editCol(design.colMap[element])"></el-icon>
                    </el-col>

                  </el-row>
                </div>
              </template>
            </draggable>
          </div>
        </el-tab-pane>
        <el-tab-pane label="表单属性" v-if="design.form" name="form">
          <el-form label-position="right" label-width="100">

            <el-form-item label="弹窗宽度">
              <el-input v-model="design.form.dialog_width" placeholder="默认1000px,只能是px" style="width: 100%"/>
            </el-form-item>
            <el-form-item label="label宽度">
              <el-input v-model="design.form.width" style="width: 100%"/>
            </el-form-item>
            <el-form-item label="label后缀">
              <el-input v-model="design.form.suffix" style="width: 100%"/>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.form.read">表单只读</el-checkbox>
              <el-checkbox v-model="design.form.save_btn">保存按钮</el-checkbox>
              <el-checkbox v-model="design.form.upchange_btn">修改记录按钮
              </el-checkbox>
            </el-form-item>


            <el-form-item label="只读规则">
              <el-input :placeholder=" 'loginSys,obj,oldObj'" style="width: 100%"
                        type="textarea"
                        v-model="design.form.saveBtnRule2"
                        @change="design.form.saveBtnRule=design.form.saveBtnRule2"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="design.form.tabs_group">tabs分组</el-checkbox>
              <!--              <el-button-->
              <!--                  @click="design.form.tabs_group_list.push({id:new Date().getTime(),name:'',order_num:1,cols:[]})">添加-->
              <!--              </el-button>-->
              <!--              <el-button @click="tabs_group_listInit()">初始化-->
              <!--              </el-button>-->
            </el-form-item>
            <!--            <div v-for="item in design.form.tabs_group_list">
                          <el-row>
                            <el-col :span="6">
                              <el-input v-model="item.key" placeholder="" style="width: 100%" clearable/>
                            </el-col>
                            <el-col :span="12">
                              <el-input v-model="item.name" placeholder="" style="width: 100%" clearable/>
                            </el-col>
                            <el-col :span="4">
                              <el-input-number v-model="item.order_num" placeholder="" style="width: 100%" clearable
                                               :controls="false" :precision="0"/>
                            </el-col>
                            <el-col :span="2">
                              <span @click="delArrItem(design.form.tabs_group_list,item)">删除</span>
                            </el-col>
                          </el-row>

                        </div>-->

          </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="design.showWhere" name="showWhere" label="查询项">
          <span style="display: none">{{ where = design.selectWhere }}</span>
          <el-form v-if="where" label-position="right" label-width="80">

            <el-form-item label="顺序">
              <el-button @click="whereIndexChange(where,-1)">-</el-button>
              <el-button @click="whereIndexChange(where,1)">+</el-button>
              <!--              <el-input-number clearable  v-model="where.index2" style="width: 100%;" @change="whereIndexChange(where)"-->
              <!--                               placeholder=""/>-->
            </el-form-item>
            <el-form-item label="绑定字段">
              <el-select v-model="where.col" clearable filterable style="width: 100%">
                <el-option v-for="c in design.colMap" :label="c.col+ ' ' +c.name" :value="c.col">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="KEY">
              <el-input v-model="where.key" style="width: 100%;" placeholder="默认=字段名"/>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="where.label" style="width: 100%;display: contents;" placeholder="默认=字段名称"/>
            </el-form-item>
            <el-form-item label="显示规则">
              <el-input style="width: 100%" type="textarea" v-model="where.show_rule2"
                        @change="where.show_rule=where.show_rule2"></el-input>
            </el-form-item>
            <el-form-item label="组件">

              <el-select v-model="where.module_type" filterable clearable style="width: 100%" placeholder="默认input">
                <template v-for="(o,k) in moduleTypeMap">
                  <el-option v-if="o.search" :value="k" :label="o.name+' '+k"/>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="API接口"
                          v-if="moduleTypeMap[where.module_type]&&moduleTypeMap[where.module_type].api">
              <el-select v-model="where.api" filterable clearable allow-create placeholder=""
                         style="width: 100%">
                <template v-for="(o,k) in designApiType">
                  <el-option :value="k" :label="o.name+' '+k"></el-option>
                </template>
              </el-select>

            </el-form-item>
            <template v-if="moduleTypeMap[where.module_type]&&moduleTypeMap[where.module_type].api">
              <el-form-item label="API默认参">
                <el-input v-model="where.api_default_param" @change="isJson(where.api_default_param)"
                          :placeholder="'{}'"
                          style="width: 100%"></el-input>
                <div v-if="designApiType[where.api]&&designApiType[where.api].default_param">
                  {{ designApiType[where.api].default_param }}
                </div>
              </el-form-item>
              <el-form-item label="A项只读">
                <el-input v-model="where.api_read" placeholder="o.status==0" style="width: 100%"></el-input>
              </el-form-item>
              <el-form-item label="A项不显示">
                <el-input v-model="where.api_item_no_show" placeholder="o.status==0" style="width: 100%"></el-input>
              </el-form-item>
              <el-form-item label="A加盟商">
                <el-checkbox v-model="where.api_my_firm">自己的数据</el-checkbox>
                <el-checkbox v-model="where.api_is_firm">加盟商权限</el-checkbox>
              </el-form-item>
            </template>
            <el-form-item v-if="where&&where.type.indexOf('select')>=0" label="多选">
              <el-checkbox v-model="where.multiple"/>
            </el-form-item>
            <el-form-item v-if="where&&where.type.indexOf('inputNumber')>=0" label="小数点位">
              <el-input-number v-model="where.precision" :controls="false" :precision="0" style="width: 60px"/>
            </el-form-item>
            <el-form-item v-if="where&&where.type.indexOf('dict')>=0" label="单选按钮名称">
              <el-input v-model="where.radioTitle" style="width: 60px"/>
            </el-form-item>
            <el-form-item v-if="where&&where.type.indexOf('time')>=0" label="时间格式">
              <el-input placeholder="YYYY-MM-DD HH:mm:ss" v-model="where.timeFormat" style="width: 240px"/>
            </el-form-item>
            <!--            <el-form-item label="SQL模式">
                          <el-select v-model="where.sqlwhere" filterable clearable style="width: 100%">
                            <el-option v-for="(o,k) in designSqlMap" :value="k" :label="k+' '+o.name"/>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="SQL字段">
                          <el-input v-model="where.sql_field" style="width: 100%" placeholder="默认=字段名"/>
                        </el-form-item>-->
            <el-form-item label="字典">
              <el-select v-model="where.dict_code" filterable clearable style="width: 100%"
                         :placeholder="design.colMap[where.col]?'覆盖字段字典':''">
                <el-option v-for="(o,k) in  sysMap.sysDict" :value="k" :label="o.name+' '+k"/>
              </el-select>
            </el-form-item>
            <div v-if="sysMap.sysDict[where.dict_code]" style="line-height: 16px">
              <span style="margin-right: 12px;font-weight:bold;color:blue;">{{ where.dict_code }}</span>
              <span v-for="item in sysMap.sysDict[where.dict_code].item_list">
                    {{ item.key }}={{ item.value }}、
                  </span>
            </div>
            <el-form-item label="不显示字典" v-if="sysMap.sysDict[where.dict_code]">

              <el-input v-model="where.dict_no_code" style="width: 100%;"
                        :placeholder="'key不显示，逗号分离'"/>
            </el-form-item>
            <el-form-item label="字段字典" v-if="design.colMap[where.col]">
              <el-select v-model="design.colMap[where.col].dict_code" placeholder="优先选择字段字典" filterable clearable
                         style="width: 100%">
                <el-option v-for="(o,k) in  sysMap.sysDict" :value="k" :label="o.name+' '+k"/>
              </el-select>
            </el-form-item>
            <el-form-item label="宽度">
              <el-input v-model="where.width" style="width: 80px"/>
              <span style="color: red;margin-left: 4px;">单位：px|%|*</span>
            </el-form-item>
            <el-form-item label="文本框提示">
              <el-input v-model="where.placeholder" style="width: 100%;display: contents;"/>
            </el-form-item>

            <el-form-item label="默认值">
              <el-input v-model="where.default_value" placeholder=""
                        style="width: 100%"></el-input>

            </el-form-item>
            <div style="line-height:16px">newDates,newTimes,newDate,newTime,表达式,字符串,数字,{},[]</div>
            <el-form-item label="变更查询">
              <el-checkbox v-model="where.changeFind"/>
            </el-form-item>

            <el-form-item label="查询插槽">
              <el-checkbox v-model="where.close_solt_where">关闭查询插槽</el-checkbox>
            </el-form-item>
            <div style="text-align: center;margin-top: 32px;">
              <el-button type="warning" @click="delWhere(where)">删除当前【查询项】</el-button>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane v-if="design.showTableCol" name="showTableCol" :label="design.show_type=='form'?'表单项':'表格列'">
          <span style="display: none">{{ element = design.selectTableCol }}</span>
          <el-form v-if="element" label-position="right" label-width="80">
            <div>
              <div class="title">字段信息</div>
              <el-form-item label="字段编码">
                <el-input v-model="element.col" style="width: 100% " :style="{color:element.status==0?'red':''}"
                          :readonly="element.status==1" @change="colChange(element)"/>
                <div v-if="element.status==0" style="color:red;font-weight: bold">【非字段】</div>
                <div style="line-height: 1.5;text-align: left">{{ element.key$ }}</div>
              </el-form-item>

              <el-form-item label="字段名称">
                <el-input v-model="element.name" style="width: 100%;display: contents;"/>
              </el-form-item>
              <el-form-item label="映射字段">
                <el-input v-model="element.col_data" style="width: 100%; "/>
              </el-form-item>
              <el-form-item label="字段字典">
                <el-select v-model="element.dict_code" filterable clearable style="width: 100%">
                  <el-option v-for="(o,k) in  sysMap.sysDict" :value="k" :label="o.name+' '+k"/>
                </el-select>

              </el-form-item>
              <div v-if="sysMap.sysDict[element.dict_code]" style="line-height: 16px">
                <span style="margin-right: 12px;font-weight:bold;color:blue;">{{ element.dict_code }}</span>
                <span v-for="item in sysMap.sysDict[element.dict_code].item_list">
                    {{ item.key }}={{ item.value }}、
                  </span>
              </div>
              <el-form-item label="不显示字典" v-if="sysMap.sysDict[element.dict_code]">

                <el-input v-model="element.dict_no_code" style="width: 100%;"
                          :placeholder="'key不显示，逗号分离'"/>
              </el-form-item>
              <div>
                <el-checkbox v-model="element.show">显示表格列</el-checkbox>
                <el-checkbox v-model="element.show_form">显示表单项</el-checkbox>
                <el-checkbox v-model="element.export">导出字段</el-checkbox>

                <el-input v-model="element.export_name" v-if="element.export" style="width: 100%;display: contents;"
                          :placeholder="''+element.show_name"/>
              </div>
              <!--              <el-form-item label-width="16">-->
              <!--                <el-checkbox-group @change="changeChecked($event,element)"-->
              <!--                                   v-if="$route.query&&Object.keys($route.query).length>0"-->
              <!--                                   v-model="element.show_form_checked">-->
              <!--                  <el-checkbox :key="i" :label="val"-->
              <!--                               v-for="(val,key,i) in $route.query">表单路由{{ key }}={{ val }}不显示-->
              <!--                  </el-checkbox>-->
              <!--                </el-checkbox-group>-->
              <!--                <el-checkbox-group @change="changeChecked1($event,element)"-->
              <!--                                   v-if="$route.query&&Object.keys($route.query).length>0"-->
              <!--                                   v-model="element.show_checked">-->
              <!--                  <el-checkbox :key="i" :label="val"-->
              <!--                               v-for="(val,key,i) in $route.query">表格路由{{ key }}={{ val }}不显示-->
              <!--                  </el-checkbox>-->
              <!--                </el-checkbox-group>-->

              <!--              </el-form-item>-->
            </div>
            <template v-for="ti in design.show_type=='form'?[1,2]:[2,1]">
              <div v-if="ti==1">
                <div class="title">表单</div>
                <el-form-item label="Label名称" title="name_form">
                  <el-input v-model="element.name_form" style="width: 100%;display: contents;"
                            :placeholder="''+element.name"/>
                </el-form-item>

                <el-form-item label="Label样式" title="form_label_style">
                  <el-input v-model="element.form_label_style" type="textarea" style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="Label隐藏" title="form_label_hide">
                  <el-checkbox v-model="element.form_label_hide">
                  </el-checkbox>
                </el-form-item>
                <el-form-item label="显示规则" title="form_show_rule">
                  <el-input :placeholder="element.table_show_rule_sync?element.table_show_rule2:''" style="width: 100%"
                            type="textarea"
                            v-model="element.form_show_rule2"
                            @change="element.form_show_rule=element.form_show_rule2"></el-input>
                  <el-checkbox v-model="element.form_show_rule_sync" true-label="custom"
                  >
                    同步（表格规则为空有效）
                  </el-checkbox>
                </el-form-item>
                <el-form-item label="只读规则" title="form_read_rule">
                  <el-input :placeholder=" 'loginSys,obj,oldObj'" style="width: 100%"
                            type="textarea"
                            v-model="element.form_read_rule2"
                            @change="element.form_read_rule=element.form_read_rule2"></el-input>
                </el-form-item>

                <el-form-item label="文本表达式" title="eval_form">
                  <el-input clearable v-model="element.eval_form2"
                            @change="element.eval_form=element.eval_form2"
                            style="width: 100%;display: contents;" type="textarea"
                            :placeholder="'obj.name +obj.id、loginSys'"/>
                </el-form-item>
                <el-form-item label="表单组件" title="module_type_form">

                  <el-select v-model="element.module_type_form" filterable clearable placeholder="默认-input"
                             style="width: 100%">
                    <template v-for="(o,k) in moduleTypeMap">
                      <el-option v-if="o.form" :value="k" :label="o.name+' '+k"/>
                    </template>
                  </el-select>

                  <el-input v-model="element.imgCompressWidth" type="number"
                            v-if="element.module_type_form=='imgUpload'"
                            placeholder="图片压缩宽度像素"
                            style="width: 300px"/>
                  <el-input v-model="element.height"
                            v-if="element.module_type_form=='rich_text_box'||element.module_type_form=='textarea'"
                            placeholder="高度"
                            style="width: 100px"></el-input>
                  <el-checkbox v-if="element.module_type_form=='inputNumber'" v-model="element.number_controls"
                  >计步器
                  </el-checkbox>
                </el-form-item>

                <el-form-item label="API接口" title="api">
                  <!--                  {{designApiType}}-->
                  <el-select v-model="element.api" filterable clearable allow-create placeholder=""
                             style="width: 100%">
                    <template v-for="(o,k) in designApiType">
                      <el-option :value="k" :label="o.name+' '+k"></el-option>
                    </template>
                  </el-select>

                  <!--                  <el-input type="textarea" v-model="element.api_param_str" style="width: 100%"-->
                  <!--                            :placeholder="api_param_default"-->
                  <!--                            @change="api_param_change(element)"></el-input>-->

                </el-form-item>
                <template v-if="moduleTypeMap[element.module_type_form]&&moduleTypeMap[element.module_type_form].api">
                  <et-json-viewer v-if="designApiType[element.api]" :expand-depth=0
                                  v-model="designApiType[element.api]"/>
                  <el-form-item label="API默认参" title="api_default_param">
                    <el-input v-model="element.api_default_param" @change="isJson(element.api_default_param)"
                              :placeholder="'api：'"
                              style="width: 100%"></el-input>
                    <div v-if="designApiType[element.api]&&designApiType[element.api].default_param">
                      {{ designApiType[element.api].default_param }}
                    </div>
                  </el-form-item>

                  <el-form-item v-if="designApiType[element.api]" label="API显示表" title="api_form_table">
                    <el-checkbox v-model="element.api_form_table">
                    </el-checkbox>
                    <el-checkbox v-model="element.api_form_hide">不显示选项
                    </el-checkbox>
                  </el-form-item>

                  <el-form-item label="项只读" title="api_read">
                    <el-input v-model="element.api_read" placeholder="o.status==0" style="width: 100%"></el-input>
                  </el-form-item>
                  <el-form-item label="项不显示" title="api_item_no_show">
                    <el-input v-model="element.api_item_no_show" placeholder="o.status==0"
                              style="width: 100%"></el-input>
                  </el-form-item>

                  <el-form-item label="A加盟商" title="api_my_firm">
                    <el-checkbox v-model="element.api_my_firm">自己的数据</el-checkbox>
                    <el-checkbox v-model="element.api_is_firm">加盟商权限</el-checkbox>
                  </el-form-item>
                  <el-form-item label="数据字段" title="api_field">
                    <el-input v-model="element.api_field" placeholder="数据对应value字段" style="width: 100%"></el-input>
                  </el-form-item>
                  <el-form-item label="表显示项" title="api_show_field">
                    <el-input v-model="element.api_show_field" placeholder="name,phone" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
                <el-form-item label="回调函数" title="callback_fun">
                  <el-input v-model="element.callback_fun" placeholder="@change回调函数名称" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="必填表达式" title="required_rule">
                  <el-input clearable placeholder="" style="width: 100%" type="textarea" autosize
                            v-model="element.required_rule2"
                            @change="element.required_rule=element.required_rule2"/>
                </el-form-item>
                <el-form-item label="表单验证" title="rules">
                  <el-select v-model="element.rules" filterable clearable multiple style="width: 100%">
                    <el-option v-for="(o,k) in rulesMap" :value="k" :label="o.name+' '+k"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="表单默认值" title="form_default">
                  <el-input v-model="element.form_default" placeholder="表达式,字符串,数字,{},[]"
                            style="width: 100%"></el-input>

                </el-form-item>
                <el-form-item label="">
                  <el-checkbox v-model="element.col2">双列显示</el-checkbox>
                  <el-checkbox v-model="element.read_form">只读</el-checkbox>
                </el-form-item>
                <el-form-item label="输入框宽度" title="width_form">
                  <el-input v-model="element.width_form" style="width: 80px"></el-input>
                  （注意单位：px,%）
                </el-form-item>
                <el-form-item label="样式" title="form_style">
                  <el-input v-model="element.form_style" type="textarea" style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="输入框提示" title="placeholder">
                  <el-input v-model="element.placeholder" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="最大长度" title="maxlength">
                  <el-input-number v-model="element.maxlength" style="width: 100%"/>
                </el-form-item>
                <el-form-item label="数字区间" v-if="element.module_type_form=='inputNumber'">
                  <el-input-number v-model="element.min_val" style="width: 60px;" placeholder="" :controls="false"
                                   :precision="0"/>
                  -
                  <el-input-number v-model="element.max_val" style="width: 60px;" placeholder="" :controls="false"
                                   :precision="0"/>
                  精度
                  <el-input-number v-model="element.precision" :controls="false" :precision="0" style="width: 40px;"
                                   placeholder=""/>
                </el-form-item>
                <!--                <el-form-item label="前后缀">-->
                <!--                  <el-input v-model="element.left_suffix_form" style="width: 100px;" placeholder=""/>-->
                <!--                  <el-input v-model="element.right_suffix_form" style="width: 100px;" placeholder=""/>-->
                <!--                </el-form-item>-->
                <el-form-item label="前缀" title="left_suffix_form">
                  <el-input clearable v-model="element.left_suffix_form" style="width: 100px;" placeholder=""/>
                </el-form-item>
                <el-form-item label="前缀样式" title="left_suffix_form_style">
                  <el-input clearable v-model="element.left_suffix_form_style" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="后缀" title="right_suffix_form">
                  <el-input clearable v-model="element.right_suffix_form" style="width: 100px;" placeholder=""/>
                </el-form-item>

                <el-form-item label="后缀样式" title="right_suffix_form_style">
                  <el-input clearable v-model="element.right_suffix_form_style" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="表单项插槽" title="close_solt_form">
                  <el-checkbox v-model="element.close_solt_form">关闭表单项插槽
                  </el-checkbox>
                </el-form-item>
              </div>

              <div v-if="ti==2">
                <div class="title">表格</div>
                <el-form-item label="显示名称">
                  <el-input type="textarea" clearable v-model="element.name_table"
                            style="width: 100%;display: contents;"
                            :placeholder="''+element.name"/>
                </el-form-item>
                <el-form-item label="显示表达式">
                  <el-input type="textarea" clearable v-model="element.name_table_eval"
                            style="width: 100%;display: contents;"
                            :placeholder="''+element.name"/>
                </el-form-item>
                <el-form-item label="显示规则">
                  <el-input clearable v-model="element.table_show_rule2"
                            @change="element.table_show_rule=element.table_show_rule2"
                            style="width: 100%;display: contents;" type="textarea"
                            :placeholder="element.table_show_rule?element.form_show_rule2:''"/>
                  <el-checkbox v-model="element.table_show_rule_sync" true-label="custom"
                  >
                    同步（表单规则为空有效）
                  </el-checkbox>
                </el-form-item>

                <el-form-item label="修改记录">
                  <el-checkbox v-model="element.data_update_raw" true-label="custom"
                  >
                    原值
                  </el-checkbox>
                </el-form-item>
                <el-form-item label="表格组件">
                  <el-select v-model="element.module_type_table" filterable clearable style="width: 100%"
                             placeholder="">
                    <template v-for="(o,k) in moduleTypeMap">
                      <el-option v-if="o.table" :value="k" :label="o.name+' '+k"/>
                    </template>
                  </el-select>
                </el-form-item>
                <el-form-item label="API接口"
                              v-if="moduleTypeMap[element.module_type_table]&&moduleTypeMap[element.module_type_table].api">
                  <el-select v-model="element.table_api" filterable clearable allow-create placeholder=""
                             style="width: 100%">
                    <template v-for="(o,k) in designApiType">
                      <el-option :value="k" :label="o.name+' '+k"></el-option>
                    </template>
                  </el-select>

                </el-form-item>
                <template
                    v-if="designApiType[element.table_api]&&moduleTypeMap[element.module_type_table]&&moduleTypeMap[element.module_type_table].api">
                  <el-form-item label="API默认参">
                    <el-input v-model="element.api_default_param_table" @change="isJson(element.api_default_param)"
                              :placeholder="'{}'"
                              style="width: 100%"></el-input>
                    <div v-if="designApiType[element.table_api]&&designApiType[element.table_api].default_param">
                      {{ designApiType[element.table_api].default_param }}
                    </div>
                  </el-form-item>
                  <el-form-item label="表显示项">
                    <el-input v-model="element.api_show_field" placeholder="name,phone" style="width: 100%"></el-input>
                  </el-form-item>
                </template>
                <el-form-item label="表达式">
                  <el-input clearable v-model="element.eval2"
                            @change="element.eval=element.eval2"
                            style="width: 100%;display: contents;" type="textarea"
                            :placeholder="'row.name +row.id、loginSys'"/>
                </el-form-item>
                <el-form-item v-if="design.show_type!='form'" label="表格排列">
                  <el-button-group>
                    <template v-for="(item,k) in alignMap">
                      <el-button @click="element.align=k" :type="element.align==k?'primary':''">
                        {{ item.name }}
                      </el-button>
                    </template>
                  </el-button-group>
                </el-form-item>

                <el-form-item v-if="design.show_type!='form'" label="左右固定">
                  <el-button-group>
                    <template v-for="(item,k) in fixedMap">
                      <el-button @click="element.operFixed=k" :type="element.operFixed==k?'primary':''">
                        {{ item.name }}
                      </el-button>
                    </template>
                  </el-button-group>
                </el-form-item>
                <el-form-item v-if="design.show_type!='form'" label="列宽">
                  <el-input clearable v-model="element.width" style="width: 80px"></el-input>
                  （注意单位：px,%）
                </el-form-item>
                <el-form-item v-if="design.show_type!='form'" label="字号">
                  <el-input-number clearable v-model="element.fontSize" style="width: 60px" :controls="false"
                                   :precision="0"/>
                  px
                  <el-color-picker v-model="element.color" size="mini"></el-color-picker>
                  <el-checkbox v-if="design.show_type!='form'" v-model="element.fontWeight" true-label="bold"
                               false-label="normal">
                    加粗
                  </el-checkbox>
                </el-form-item>
                <el-form-item label="换行排列">
                  <el-checkbox v-model="element.line_show"/>
                  <el-input-number v-model="element.lineHeight" placeholder="行间距" style="width: 80px" clearable
                                   :controls="false" :precision="0"/>
                  px
                </el-form-item>
                <el-form-item v-if="design.show_type!='form'" label="样式Map">
                  <el-input clearable v-model="element.style_map2" @change="element.style_map=element.style_map2"
                            style="width: 100%"></el-input>

                </el-form-item>
                <el-form-item v-else label="内容排列">
                  <el-button-group>
                    <template v-for="(item,k) in alignMap">
                      <el-button @click="element.align_form=k" :type="element.align_form==k?'primary':''">
                        {{ item.name }}
                      </el-button>
                    </template>
                  </el-button-group>
                </el-form-item>
                <el-form-item label="">
                  <el-checkbox v-if="design.show_type!='form'" v-model="element.sortable" true-label="custom"
                  >
                    排序
                  </el-checkbox>
                  <el-checkbox v-if="design.show_type!='form'" v-model="element.tooltip" :true-label="false"
                               :false-label="true">
                    溢出隐藏
                  </el-checkbox>
                </el-form-item>
                <el-form-item v-if="design.show_type!='form'" label="时间格式化">
                  <el-input clearable v-model="element.date_format" style="width: 100%;display: contents;"
                            placeholder="YYYY-MM-DD HH:mm:ss"/>
                </el-form-item>

                <el-form-item label="">
                  <el-checkbox v-model="element.date_time">日期时间换行
                  </el-checkbox>
                </el-form-item>
                <el-form-item v-if="design.show_type!='form'" label="数字格式化">
                  <el-input clearable v-model="element.num_format" style="width: 100%;display: contents;"
                            placeholder="#.##,0.##,0.00,#,###.##"/>
                </el-form-item>
                <el-form-item label="前缀">
                  <el-input clearable v-model="element.left_suffix" style="width: 100px;" placeholder=""/>
                </el-form-item>
                <el-form-item label="前缀样式">
                  <el-input clearable v-model="element.leftStyle" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="后缀">
                  <el-input clearable v-model="element.right_suffix" style="width: 100px;" placeholder=""/>
                </el-form-item>


                <el-form-item label="后缀样式">
                  <el-input clearable v-model="element.rightStyle" style="width: 100%"></el-input>
                </el-form-item>
                <el-form-item label="列插槽">
                  <el-checkbox v-model="element.close_solt_col">关闭列插槽
                  </el-checkbox>
                </el-form-item>
              </div>
            </template>
            <div>
              <div class="title">数据库</div>
              <el-form-item label="新-字段">
                <el-input clearable v-model="element.col_new" style="width: 100px; "/>

                <!--                <el-button style="margin-left: 16px" type="danger" v-if="!element.col_new=element.col_new"-->
                <!--                           @click="createCol(element)">更新-->
                <!--                </el-button>-->
              </el-form-item>
              <el-form-item label="DB描述">
                <el-input clearable v-model="element.col_desc" style="width: 100%;display: contents;"/>
              </el-form-item>
              <el-form-item label="DB类型">
                <el-select v-model="element.col_type" clearable filterable style="width:100%; ">
                  <el-option v-for="(o,k) in  dbColTypeMap" :label="k+' '+o.name" :value="k"/>
                </el-select>
                <!--            <el-input  clearable v-model="element.col_type" />-->
              </el-form-item>
              <el-form-item label="DB长度"
                            v-if="dbColTypeMap[element.col_type]&&dbColTypeMap[element.col_type].lengthReq">
                <el-input clearable v-model="element.col_length" style="width: 120px;" placeholder="*必填*"/>
                <!--            <template v-if="element.col.indexOf('f_')!=0">-->
                <!--            </template>-->
              </el-form-item>

              <el-form-item label="默认值">
                <el-input clearable v-model="element.col_default" style="width: 100%;" placeholder="字符串使用‘’"/>
              </el-form-item>
              <el-form-item label="DB精度"
                            v-if="dbColTypeMap[element.col_type]&&dbColTypeMap[element.col_type].decimalReq">
                <el-input clearable v-model="element.col_decimal" style="width: 100%;" placeholder="*必填*"/>
              </el-form-item>
              <el-form-item label="DB索引" v-if="element.status==1">
                <el-button v-if="element.col_index_name" type="danger" @click="delDbIndex(element)">删除索引</el-button>
                <el-button v-else type="success" @click="createDbIndex(element)">创建索引
                  删除
                </el-button>
              </el-form-item>
              <div style="text-align: center">
                <el-button style="margin-left: 16px" type="danger" v-if="element.status==0&&element.col_type"
                           @click="createCol(element)">创建字段
                </el-button>

                <el-button style="margin-left: 16px" type="warning" v-else-if="element.col_type"
                           @click="updateColType(element)">更新类型
                </el-button>
                <el-button v-if="element.status==1" type="danger" @click="delDbCol(element)">物理删除</el-button>
                <el-button v-if="element.status==0" type="warning" @click="delTableItem(element)">
                  删除
                </el-button>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="开发" name="dev_status" v-if="element">
          <!--          <el-button @click="edsignSource">还原</el-button>-->
          <el-form label-position="right" label-width="80">
            <!--            <el-form-item label="">-->
            <el-input clearable v-model="design.name" style="width: 100%;margin-bottom: 4px;" placeholder="功能名称"/>
            <etSysDict :sysMap="sysMap" placeholder="开发状态" dict_code="dev_status" radio v-model="design.dev_status"/>
            <!--            </el-form-item>-->

            <!--            <el-form-item label="">-->
            <el-input clearable v-model="design.dev_staff" placeholder="开发人员" style="width: 100%;margin: 16px 0 ">
              <template #prepend>开发人员</template>
            </el-input>
            <!--            </el-form-item>-->
            <!--            <el-form-item label="开发说明：">-->
            <!--            </el-form-item>-->
            <el-input clearable type="textarea" v-model="design.dev_content" placeholder="开发说明"
                      :autosize="{ minRows: 2, maxRows: 50}"></el-input>
            <el-form-item label="核实需求：">
            </el-form-item>
            <el-input clearable type="textarea" v-model="design.verify_content" placeholder="核实需求"
                      :autosize="{ minRows: 2, maxRows: 50}"></el-input>
            <!--            <div>-->
            <!--              <el-button >保存</el-button>-->
            <!--            </div>-->
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="历史记录" name="logs" v-if="logsPage">
          <!--          <el-button @click="edsignSource">还原</el-button>-->
          <div>
            <etSysDict :sysMap="sysMap" placeholder="选择操作人" dict_code="dev_host" select v-model="logs_host"
                       @change="findLogs"/>
          </div>
          <el-row style="margin-bottom: 6px;" v-for="(item,itemIndex) in logsPage.list">
            <el-col :span="12">{{ item.create_time }}</el-col>
            <el-col :span="12">
              <el-button style="height: 20px" @click="cutLog(item)">切换
                <etSysDict :sysMap="sysMap" dict_code="dev_host" text v-model="item.host_name"/>
              </el-button>
            </el-col>
          </el-row>
          <etPage :page="logsPage" layout="total,   prev, pager, next" :pagerCount="3" @refresh="findLogs"/>
        </el-tab-pane>
      </el-tabs>
      <div class="btnDiv" style="text-align:  center">

        <el-button class="btn" type="primary" @click="syncCol" :loading="syncLoad"><span>同步字段</span></el-button>
      </div>
    </template>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import {ref, watch} from 'vue'
import ShowTipDialog from "@/ydVue/design/showTipDialog";

var api_param_default = JSON.stringify({"type_code": "u.type_code",});
export default {
  name: "et_design",
  components: {
    ShowTipDialog,
    draggable
  },
  data() {
    return {
      designNew: null,
      logs_host: null,
      edsignSource: null,
      api_param_default: api_param_default,
      form_show_rule_str: "",
      syncLoad: false,
      search: {},
      colsList: [],
      logsPage: {pageNumber: 1, pageSize: 15, list: []},
      showColDraggable: false,
      showTableConfig: false,
      element: {},
      where: null,
      contentmenuX: "",
      contentmenuY: "",
      contextmenuFlag: false,
      contextmenuWhere: false,
      loading: false,
      drag: false,

      show_table_cols: [],
      dbCols: null,
    };
  },
  props: {

    modelValue: null,
    table_name: {},
    show_type: {},
    sysMap: {},
    runFun: {Function, default: null},
    obj: {},
    dataList: {},
    p$data: {},
  },
  setup(props, {emit}) {
    const design = ref(props.modelValue); // 初始化数据
    watch(
        () => design.value,
        (data) => emit('update:modelValue', data)
    );
    return {
      design: design,
    }
  },
  methods: {
    // isJson(text) {
    //   try {
    //     JSON.parse(text)
    //   } catch (e) {
    //     console.error(e)
    //     this.showMsgError(text + "，JSON验证失败")
    //   }
    // },
    isJson(text) {
      try {
        if (text.indexOf('?') > -1) {
          JSON.parse(JSON.stringify(eval(text)))
        } else {
          JSON.parse(text)
        }
      } catch (e) {
        console.error(e);
        this.showMsgError(text + "，JSON验证失败")
      }
    },
    tabs_group_listInit() {
      var tabs_group_list = this.design.form.tabs_group_list;
      this.design.form.tabs_group_list.forEach(o => {
        if (o.cols == null) {
          o.cols = []
        }
        o.cols.push({key: "_" + o.key})
      });
      var list = "";
      if (tabs_group_list.length > 0) {

        var listCol = this.listJsonToKeyList(tabs_group_list, "cols");
        for (var k in this.design.colMap) {
          if (listCol.indexOf(k) == -1) {
            if (this.design.form.tabs_group_list[0].cols == null) {
              this.design.form.tabs_group_list[0].cols = []
            }
            this.design.form.tabs_group_list[0].cols.push(k)
          }
        }
      }
      console.log("this.design.form.tabs_group_list", this.design.form.tabs_group_list)
    },
    cutLog(log) {
      var edsignSource = this.copyObj(this.design);
      // if (!this.edsignSource) {
      //   this.edsignSource = this.copyObj(this.design);
      // }
      this.design = log.design;
      this.design.tabs = edsignSource.tabs;
      this.design.show_type = edsignSource.show_type;
      this.design.showWhere = edsignSource.showWhere;
      this.design.showTableCol = edsignSource.showTableCol;
      this.design.selectWhere = edsignSource.selectWhere;
      this.design.selectTableCol = edsignSource.selectTableCol;
    },
    // changeChecked(val, o) {
    //     o.show_form_query = '';
    //     if (val && val instanceof Array) {
    //         val.forEach(o1 => {
    //             o.show_form_query += o1;
    //         })
    //     }
    // },
    // changeChecked1(val, o) {
    //     o.show_query = '';
    //     if (val && val instanceof Array) {
    //         val.forEach(o1 => {
    //             o.show_query += o1;
    //         })
    //     }
    // },
    // changeQuery(val,o,where) {
    //     console.log(o)
    //     console.log(where)
    //     console.log(this.design)
    //     // o.show_form_query = val;
    //     // console.log(val)
    //     // if (col.show_form_checked && col.show_form_checked instanceof Array) {
    //     //     col.show_form_checked.forEach(o => {
    //     //         for (var k in query) {
    //     //             if (o == query[k]) {
    //     //                 col.show_form_query = true;
    //     //             }
    //     //         }
    //     //     });
    //     // }
    // },
    whereIndexChange(where, num) {
      // console.log(where,where.index,where.index2)
      // if (where.index > where.index2) {
      //   where.index = where.index - 1;
      // } else {
      where.index += num + (num > 0 ? 1 : -1);
      // }

      this.bubblingSort(this.design.wheres, "index");
      var id = where.id;
      this.design.showWhere = false;
      this.design.selectWhere = null;
      this.design.wheres.forEach((o, oi) => {
        o.index = oi + 1;
        o.index2 = oi + 1;
        if (id == o.id) {
          // setTimeout(() => {
          this.design.selectWhere = o;
          this.design.showWhere = true
          // }, 10)
        }
      })
      // console.log(list)
      // console.log(this.design.wheres)
    },
    api_param_change(c) {
      // console.log(c.api_param_str)
      try {
        if (c.api_param_str) {
          var json = this.strToJson(c.api_param_str);
          c.api_param_json = json;
        } else {
          c.api_param_json = null;
        }
      } catch (e) {
        console.error(e);
        this.showMsgError("JSON格式数据")
      }
      // console.log(eval('(' + c.api_param_str + ')'))
    },
    col2Change(c) {
      if (c.col2 == 1) {
        c.col2 = 0;
      } else {
        c.col2 = 1
      }
    },
    colChange(c) {
      var key$ = c.key$;
      var col = c.col;
      if (key$ != col) {
        if (this.design.colMap[col]) {
          this.showMsgError("字段已存在");
          c.col = key$;
          return
        } else {
          c.key$ != col;
          this.design.colMap[col] = this.copyObj(c);
          delete this.design.colMap[key$];
          this.editCol(this.design.colMap[col]);
          if (this.design.tableCols.indexOf(col) == -1) {
            this.design.tableCols.push(col);
          }
          if (this.design.formCols.indexOf(col) == -1) {
            this.design.formCols.push(col);
          }
          this.delArrItem(this.design.tableCols, key$);
          this.delArrItem(this.design.formCols, key$);
        }
        // this.design.colMap
        console.log("this.design.colMap", this.design.colMap);
        console.log("col", col)
      }
    },
    createDbIndex(o) {
      this.showConfirm("确定创建索引", "创建字段索引：" + o.col, () => {
        this.axiosPost("/sysDevDbTableCol/createDbIndex.dev", {
          table_name: this.design.table_name,
          col_name: o.col,
          col_index_name: o.col_index_name,
        }, () => {
          this.showMsgSuccess("创建索引成功");
          this.syncCol()
        })

      }, () => {
      })

    },
    updateColType(o) {
      this.showConfirmHtml("确定更新字段类型",
          "<div style='font-size: 16px;' >字段：" + o.col + "<br/>新字段：" + o.col_new + "<br/>类型：" + o.col_type + "<br/>长度：" + o.col_length + "<br/>默认值：" + o.col_default + "<br/>描述：" + o.col_desc + "</div>", () => {
            this.axiosPost("/sysDevDbTableCol/updateColType.dev", {
              table_name: this.design.table_name,
              col_name: o.col,
              col_new: o.col_new,
              col_default: o.col_default,
              col_type: o.col_type,
              col_length: o.col_length,
              col_decimal: o.col_decimal,
              col_desc: o.col_desc,
            }, () => {
              if (o.col_new) {
                o.col = o.col_new;
                this.colChange(o)
              }
              this.showMsgSuccess("字段类型更新成功");
              this.syncCol()
            })

          }, () => {
          })

    }, createCol(o) {
      this.showConfirmHtml("确定【创建】字段", "<div style='font-size: 16px;' >字段：" + o.col + "<br/>类型：" + o.col_type + "<br/>长度：" + o.col_length + "<br/>默认值：" + o.col_default + "</div>", () => {
        this.axiosPost("/sysDevDbTableCol/createCol.dev", {
          table_name: this.design.table_name,
          col_name: o.col,
          col_type: o.col_type,
          col_default: o.col_default,
          col_length: o.col_length,
          col_decimal: o.col_decimal,
          col_desc: o.col_desc || o.name,
        }, () => {
          this.showMsgSuccess("【创建】字段成功");
          this.design.showTableCol = false;
          this.design.selectTableCol = null;
          this.design.tabs = "default";
          this.syncCol()
        })

      }, () => {
      })

    },
    delDbIndex(o) {
      // alert("未开发")
      // DROP INDEX "public"."sys_dev_db_create_time_idx_copy1_copy1";
      this.showConfirmHtml("确定删除", "<div style='font-size: 16px;' >删除字段索引！<br/>字段：" + o.col + "<br/>索引：" + o.col_index_name + "</div>", () => {
        this.axiosPost("/sysDevDbTableCol/delDbIndex.dev", {
          table_name: this.design.table_name,
          col_name: o.col,
          col_index_name: o.col_index_name,
        }, () => {

          this.showMsgSuccess("删除字段索引成功");
          this.syncCol()
        })

      }, () => {
      })

    },
    delDbCol(o) {
      this.showConfirm("确定删除", "确定删除字段：" + o.col, () => {

        this.delTableItem(o)
      }, () => {
      })

    },

    editCol(o) {
      this.design.showTableCol = false;
      this.design.selectTableCol = null;
      this.design.tabs = "default";
      setTimeout(() => {

        this.design.showTableCol = true;
        if (!o.export) {
          o.export = false
        }
        this.design.selectTableCol = o;
        this.design.tabs = "showTableCol";
      }, 100)

    }, delTableItem(o) {
      // this.delArrItem(this.design.tableCols, o.col);
      // // delete this.design.colMap[o.col];
      // this.design.showTableCol = false;
      // this.design.selectTableCol = null;
      // this.design.tabs = "default";
      this.axiosPost("/sysDevDbTableCol/delDbCol.dev", {
        table_name: this.design.table_name,
        col_name: o.col,
      }, () => {
        // console.log(this.design.tableCols,o.col)
        console.log(o);
        delete this.design.colMap[o.col];
        delete this.design.colMap[o.key$];
        this.delArrItem(this.design.tableCols, o.col);
        // console.log(this.design.tableCols,o.col)
        this.delArrItem(this.design.formCols, o.col);
        // console.log(this.design.formCols,o.col)
        this.delArrItem(this.colsList, o.col);
        console.log("this.colsList", this.colsList);
        console.log("this.tableCols", this.design.tableCols);
        console.log("this.formCols", this.design.formCols);

        // this.delTableItem(o);
        this.showMsgSuccess("删除成功");
        this.design.showTableCol = false;
        this.design.selectTableCol = null;
        this.design.tabs = "default";
      })
    },
    addWhere() {
      var len = this.design.wheres.length + 1;
      var id = new Date().getTime();
      var where = {
        id: id,
        index: len,
        key: 'keystr' + len,
        label: '关键字' + len,
        changeFind: true,
        type: 'input',
        width: '160px',
        placeholder: '',
        sqlwhere: ''
      };
      this.design.wheres.push(where);
      this.design.wheres.forEach(o => {
        if (o.id == where.id) {
          this.design.showWhere = true;
          this.design.selectWhere = o;
          this.design.tabs = "showWhere";
        }
      })

    },
    addField() {
      var len = this.design.tableCols.length + 1;
      var ele = {
        col: 'f_' + new Date().getTime(),
        name: '字段' + len,
        show: true,
        show_form: true,
        // show_form_checked: [],
        show_checked: [],
        status: 0,
        api_param_str: null,//默认API示例
        del: 0,
        rules: ["vRequired"],
      };
      ele.key$ = ele.col;
      this.design.tableCols.push(ele.col);
      this.design.formCols.push(ele.col);
      this.initColsList();
      this.design.colMap[ele.col] = ele;
      // this.design.tableCols.forEach(o => {
      //   if (o.col == ele.col) {
      this.design.showTableCol = true;
      this.design.selectTableCol = this.design.colMap[ele.col];
      this.design.tabs = "showTableCol";
      //   }
      // })

    },
    delWhere(where) {
      this.delArrItem(this.design.wheres, where);
      this.contextmenuWhere = false
    },
    saveDesignJs() {

      this.design.tabs = "default";
      delete this.design.show_type;
      delete this.design.showWhere;
      delete this.design.showTableCol;
      delete this.design.selectWhere;
      delete this.design.selectTableCol;
      // console.log((this.edsignSource), (this.design))
      if (this.edsignSource) {
        delete this.edsignSource.show_type
      }
      // if (this.jsonToStr(this.edsignSource) == this.jsonToStr(this.design)) {
      //   this.showMsgError("未发生变化,无需保存")
      // } else {
      this.axiosPost("/sysDevDesign/saveDesignJs.dev", {
        table_name: this.design.table_name,
        jsFileName: this.design.jsFileName,
        designJsonFile: this.design.designJsonFile,
        design: this.design
      }, () => {
        this.showMsgSuccess("保存成功")
      })
      // }
    },
    handleContextmenuWhere(event, c) {
      // this.design.wheres.forEach(o => {
      //   if (o.col == c.col) {
      this.where = c;
      //     console.log(this.element)
      //   }
      // })
      // console.log(event)
      let target = event.target;
      // 解决 https://github.com/d2-projects/d2-admin/issues/54
      let flag = true;
      if (flag) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.contextmenuWhere = true;
      }
    },
    open() {
      if (this.design.show_type == this.show_type) {
        this.design.show_type = ''
      } else {
        this.design.show_type = this.show_type
      }


      if (!this.design.tableCols || this.design.tableCols.length == 0) {
        this.design.tableCols = [];
        this.design.tabs = "default";
        this.syncCol();
      } else {
        this.findCols();
      }
    },
    headerContextmenu(column, event) {
      let target = event.target;

      this.design.tableCols.forEach(o => {
        if (o.col == column.property) {
          this.element = o;
          console.log(this.element)
        }
      });
      if (true) {
        event.preventDefault();
        event.stopPropagation();
        this.contentmenuX = event.clientX;
        this.contentmenuY = event.clientY;
        this.contextmenuFlag = true;
      }
    },

    headerDragend(newWidth, oldWidth, column, event) {
      this.design.tableCols.forEach(o => {
        if (o.col == column.property) {
          o.width = parseInt(newWidth)
        }
      });
      console.log(newWidth, oldWidth, column, event)
    },
    syncCol() {
      this.syncLoad = true;
      this.axiosPost("/sysDevDbTableCol/syncCol.dev", {table_name: this.table_name}, (res) => {
        this.dbCols = res.list;
        this.showMsgSuccess("同步完成", 10000);
        this.findCols();
        this.syncLoad = false;
      })
    },

    findLogs() {
      this.axiosPost("/sysDevDesignLog/selectList.dev", {
        code: this.design.code,
        table_name: this.design.table_name,
        host_name: this.logs_host,
        pageNumber: this.logsPage.pageNumber,
        pageSize: this.logsPage.pageSize,
      }, (res) => {
        this.logsPage = res.page;
      })
    },
    findDev() {
      if (!this.dev$) {
        return
      }
      var devAuthor = this.getDevAuthor();
      if (devAuthor) {
        this.design.sys_menu_id = devAuthor.id
      }
      this.axiosPost("/sysDevDesign/findByCode.dev", {
        code: this.design.code,
        table_name: this.design.table_name,
      }, (res) => {
        if (res.design) {
          this.designNew = res.design;
          this.design.name = res.design.name;
          this.design.dev_content = res.design.dev_content;
          this.design.verify_content = res.design.verify_content;
        }
      })
    },
    findCols() {
      if (!this.edsignSource) {
        this.edsignSource = this.copyObj(this.design)
      }
      this.findDev();

      this.findLogs();
      var noShow = {
        is_del: false,
        update_time: false,
        del_time: false,
        del_user_id: false,
        sys_tenant_code: false,
        id: false
      };
      var colName = {
        is_del: "删除标识",
        update_time: "更新时间",
        del_time: "删除时间",
        del_user_id: "删除人",
        sys_tenant_code: "租户",
        id: "ID"
      };
      var widthMap = {
        create_time: "150px",
        update_time: "150px",
      };
      var colAlign = {
        create_time: "center",
        status: "center",
      };
      if (!this.design.wheres) {
        this.design.wheres = []
      }
      if (!this.design.form.tabs_group_list) {
        this.design.form.tabs_group_list = []
      }
      if (!this.design.form || !this.design.form.width) {
        this.design.form = {width: "160px", save_btn: true}
      }
      if (!this.design.table || this.design.table.indexShow == null) {
        this.design.table = {
          width: "100%",
          addBtn: true,
          selectDel: false,
          indexShow: true,
          sort_col: "create_time",
          sort_order: "descending"
        }
      }
      if (!this.design.oper || !this.design.oper.name) {
        this.design.oper = {name: "操作", width: "90", align: "center", show: true, edit: true, del: true}
      }
      if (!this.design.exportList) {
        this.design.exportList = []
      }
      if (!this.design.importList) {
        this.design.importList = []
      }
      var order_num = this.design.tableCols.length + 1;
      if (this.dbCols) {
        this.dbCols.forEach(o => {
          if (!this.design.colMap[o.col_name]) {
            // alert(1)
            this.design.colMap[o.col_name] = ({
              col: o.col_name,//字段
              key$: o.col_name,//字段
              name: colName[o.col_name] || o.cn_name || o.col_name,//字段名
              show: noShow[o.col_name] == null ? true : false,//表格显示
              show_form: noShow[o.col_name] == null ? true : false,//表单显示
              sortable: "custom",//表格是否排序
              // index: order_num++,
              // form_index: order_num++,
              tooltip: true,//溢出提示
              rules: ["vRequired"],//表单验证
              align: colAlign[o.col_name] || "left",//排列
              width: widthMap[o.col] || 160,//宽度
              status: o.status,//是否是真是字段
              api_param_str: api_param_default,//默认API示例
            });
          }
        });
        var dbColMap = this.listJsonToMap(this.dbCols, "col_name");
        for (var k in this.design.colMap) {
          var dbCol = dbColMap[k];
          var o = this.design.colMap[k];
          if (k == '') {
            k = o.col;
          }
          // console.log(o.col, k);
          if (dbCol) {

            o.col_new = "";
            o.col_desc = dbCol.cn_name;
            o.status = dbCol.status;
            o.col_type = dbCol.col_type;
            o.col_default = dbCol.col_default;
            o.col_length = dbCol.col_length;
            o.col_decimal = dbCol.decimal;
            o.col_index_name = dbCol.col_index_name;
          } else {
            o.status = 0;
          }
          if (this.design.tableCols.indexOf(k) == -1) {
            this.design.tableCols.push(k)
          }
          if (this.design.formCols.indexOf(k) == -1) {
            this.design.formCols.push(k)
          }


        }

        // this.design.tableCols.forEach(o => {
        //
        // })
      }
      for (var k in this.design.colMap) {
        var o = this.design.colMap[k];
        if (k == '') {
          k = o.col;
        }
        o.col = k;
        o.key$ = k;
        o.table_show_rule2 = o.table_show_rule;
        o.form_show_rule2 = o.form_show_rule;
        if (this.design.exportList.indexOf(k) == -1) {
          this.design.exportList.push(k)
        }
        if (this.design.importList.indexOf(k) == -1) {
          this.design.importList.push(k)
        }
      }
      this.initColsList();
      // })
    }
    , initColsList() {
      var colsList = this.design.tableCols;
      if (this.design.show_type == 'form') {
        colsList = this.design.formCols
      }
      this.colsList = colsList;
      this.colsList = Array.from(new Set(colsList))
    }
  }, mounted: function () {
    this.findDev();
  }, watch: { //值改变动作 valObj(val){}
    "colsList": {
      handler(newName, oldName) {
        var list = Array.from(new Set(this.colsList));
        if (this.design.show_type == 'form' || this.design.auto_sort == true) {
          this.design.formCols = list
        }
        if (this.design.show_type == 'table' || this.design.auto_sort == true) {
          this.design.tableCols = list
        }
        // console.log("colsList-", this.colsList)
        // console.log("tableCols", this.design.tableCols)
        // console.log("formCols-", this.design.formCols)
      },
      deep: true,
    },
    "wheres": {
      handler(newName, oldName) {

        this.design.wheres.forEach(o => {
          o.keyName = o.key || o.col
        })
      },
      deep: true,
    },
    "design.colMap": {
      handler(newName, oldName) {
        var col2Index = 0;
        for (var k in this.design.colMap) {
          var col = this.design.colMap[k];
          col.show_name = col.name_table || col.name;
          // col.export_name = col.export_name || col.show_name;
          col.show_form_name = col.name_form || col.name;
        }
        var card = null;
        var cardList = [];
        this.design.formCols.forEach((c, ci) => {
          var col = this.design.colMap[c];
          if (col) {
            if (ci == 0 && col.module_type_form == 'card') {
              card = c
            } else if (card && card != c && col.module_type_form == 'card') {
              card = c
            }
            // if(!card){
            //   cardList.push(card);
            // }
            col.card_key = card;
            // console.log(col.card,card)
            if(col.show_form){
              if ( col.col2) {
                col.col2Right = (++col2Index) % 2 == 1;
                // console.log(col)
              } else {
                col2Index = 0;
                col.col2Right = false;
              }
            }

          }
        })

      },
      deep: true,
    },
    // where: {
    //   handler(newName, oldName) {
    //     console.log(this.where.index, this.design.selectWhere.index)
    //     if (oldName)
    //       console.log(newName.index + '=' + oldName.index);
    //   },
    //   deep: true,
    // },
    "design.tabs": {
      handler(newName, oldName) {
        if (this.design.tabs == "default") {
          this.initColsList();
        }
      },
      deep: true,
    },


  }
}
</script>

