<template>
  <div>
    <textarea :id="textareaName" :name="textareaName" :ref="textareaName" style="display:none;"
              :style="{width:width,height:height}" ></textarea>
  </div>
</template>
<style lang="less">
</style>
<script>
import {ref, watch} from 'vue';

export default {
  name: "etRichTextBox",
  data() {
    return {
      textareaName: "content" + new Date().getTime(),
      // value2: '',
      editor: null,
      value2_text: ''
    };
  },
  props: {
    modelValue: {
      type: Object, default: () => {
      }
    },
    textModel: {},
    col: {},
    width: {default: "100%"},
    height: {default: "600px"},
    // customStyle: {type: Object, default: {width: '100%', height: '600px', display: 'none'}},
  },
  setup(props, {emit}) {
    const value2 = ref(props.modelValue);

    watch(
        () => value2.value,
        (data) => emit('update:modelValue', data)
    );
    return {
      value2: value2,
    }
  },
  methods: {
    init() {
      this.initEditor(this.value2[this.col], "value2", this.col, this.getLoginUserId());
    }
  }, created() {
    this.init()
  },
  watch: {
    modelValue: {
      handler(newName, oldName) {
        this.$set(this, "value2", this.modelValue);
        if (this.editor&&this.modelValue[this.col] !=this.editor.html()) {
          this.editor.html(this.modelValue[this.col] || "")
        }
      }, deep: true
    },
    value2: {
      handler(newName, oldName) {
        // console.log("this.value2", this.value2)
      }, deep: true
    },
    // modelValue(val) {
    //   console.log(this.modelValue)
    //   // this.textModel=this.value2_text
    //   // this.$emit('update:textModel', this.value2_text)
    //   console.log(this.textModel)
    //   this.$set(this, "value2", val);
    //   if (this.editor&&this.editor.html() != val) {
    //     this.editor.html(val || "")
    //   }
    // },deep:true
  },

}
</script>
