<!--Breadcrumb 面包屑 -->
<template>
  <template v-if="design&&design.formCols">
    <template v-if="tabsList.length>0">
      <el-tabs type="border-card">
        <el-tab-pane v-for="(c,ci) in tabsList" :label="c.name">
          <div class="etDesignFormItemTabs">
            <div v-if="c.cardCodeNullList.length>0" class="cardCodeNullList">
              <template v-for="(c,ci) in c.cardCodeNullList">
                <et-design-form-item-content :design="design" :sysMap="sysMap"  :oldObj$="oldObj$" v-model="obj" :c="c" :selectList="selectList"
                                            :root$="root$" @changeFun="changeFun" @remoteMethod="remoteMethod" :ptreeList="ptreeList"
                                             @formItemRight="formItemRight" :selectMap="selectMap">
                  <template #content v-if="$slots[design.colMap[c].col]">
                    <slot :name="design.colMap[c].col"></slot>
                  </template>
                  <template #item v-if="$slots['item-'+design.colMap[c].col]">
                    <slot :name="'item-'+design.colMap[c].col"></slot>
                  </template>
                </et-design-form-item-content>
              </template>
            </div>
            <template v-for="(ca,cai) in c.cardList">
              <el-card style="margin: 8px">
                <template #header>
                  <div class="card-header">
                    <span>{{ ca.name }}</span>
                  </div>
                </template>
                <template v-for="(c,ci) in ca.colList">
                  <et-design-form-item-content :design="design" :sysMap="sysMap"  :oldObj$="oldObj$" v-model="obj" :c="c" :selectList="selectList"
                                            :root$="root$"   @changeFun="changeFun" @remoteMethod="remoteMethod" :ptreeList="ptreeList"
                                               @formItemRight="formItemRight" :selectMap="selectMap">
                    <template #content v-if="$slots[design.colMap[c].col]">
                      <slot :name="design.colMap[c].col"></slot>
                    </template>
                    <template #item v-if="$slots['item-'+design.colMap[c].col]">
                      <slot :name="'item-'+design.colMap[c].col"></slot>
                    </template>
                  </et-design-form-item-content>
                </template>
              </el-card>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
     <template v-else>
       <div v-if="cardCodeNullList.length>0" class="cardCodeNullList">
         <template v-for="(c,ci) in cardCodeNullList">
           <et-design-form-item-content :sunTbale="sunTbale" :design="design" :oldObj$="oldObj$" :sysMap="sysMap" v-model="obj" :c="c" :selectList="selectList"
                                       :root$="root$" @changeFun="changeFun" @remoteMethod="remoteMethod" :ptreeList="ptreeList"
                                        @formItemRight="formItemRight" :selectMap="selectMap">
             <template #content v-if="$slots[design.colMap[c].col]">
               <slot :name="design.colMap[c].col"></slot>
             </template>
             <template #item v-if="$slots['item-'+design.colMap[c].col]">
               <slot :name="'item-'+design.colMap[c].col"></slot>
             </template>
           </et-design-form-item-content>
         </template>
       </div>
       <template v-for="(ca,cai) in cardList">
         <el-card style="margin: 8px">
           <template #header>
             <div class="card-header">
               <span>{{ ca.name }}</span>
             </div>
           </template>
           <template v-for="(c,ci) in ca.colList">
             <et-design-form-item-content  :sunTbale="sunTbale" :design="design" :sysMap="sysMap"  :oldObj$="oldObj$" v-model="obj" :c="c" :selectList="selectList"
                                        :root$="root$"  @changeFun="changeFun" @remoteMethod="remoteMethod" :ptreeList="ptreeList"
                                          @formItemRight="formItemRight" :selectMap="selectMap">
               <template #content v-if="$slots[design.colMap[c].col]">
                 <slot :name="design.colMap[c].col"></slot>
               </template>
               <template #item v-if="$slots['item-'+design.colMap[c].col]">
                 <slot :name="'item-'+design.colMap[c].col"></slot>
               </template>
             </et-design-form-item-content>
           </template>
         </el-card>
       </template>
     </template>

  </template>
</template>
<script>
import {ref, watch} from 'vue'
import EtDesignFormItemContent from "@/ydVue/design/etDesignFormItemContent";
import EtDesignFormItemCard from "@/ydVue/design/etDesignFormItemCard";
const dd = () => {
  return 'hre'
}
export default {
  name: "etDesignFormItem",
  components: {EtDesignFormItemCard, EtDesignFormItemContent},
  data() {
    return {
      dynamicSlotName:"a",
      cardCodeNullList: [],
      tabsList: [],
      cardList: [],
      selectMap: {},
      selectList: {},
    };
  },
  emits: "remoteMethod,formItemRight,changeFun,update:modelValue".split(","),
  props: {
    modelValue: null,
    col: {},
    sunTbale: {},
    root$: {},

    // c: {},
    design: {},
    oldObj$: {},
    sysMap: {},
    ptreeList: {},
  },
  setup(props, {emit}) {
    const obj = ref(props.modelValue); // 初始化数据
    const design = ref(props.design); // 初始化数据
    watch(
        () => obj.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      obj: obj,
      design: design,
    }
  },
  methods: {

    sk(sk2){
      return "sk"+sk2+new Date().getTime()
    },
    changeFun(fun_name, val) {
      this.$emit("changeFun", fun_name, val)
    },
    // show(col) {
    //     var flag = true;
    //     //显示条件
    //     if (col && col.form_show_rule) {
    //         if (col.form_show_rule.indexOf("{") == 0 && col.form_show_rule.lastIndexOf("}") == col.form_show_rule.length - 1) {
    //             var obj = JSON.parse(col.form_show_rule);
    //             if (obj instanceof Object) {
    //                 for (var k in obj) {
    //                     if (k && k.indexOf('obj.') > -1) {
    //                         var kv = k.replace("obj.", "this.obj.");
    //                         if (!eval(kv + obj[k])) {
    //                             return false;
    //                         }
    //                     }
    //                     if (k && k.indexOf('url.') > -1) {
    //                         var kv = k.replace("url.", "this.$route.query.");
    //                         if (!eval(kv + obj[k])) {
    //                             return false;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     return flag;
    // },
    remoteMethod($event, c) {
      console.log("remoteMethod",c)
      this.initSelectR('form', c, $event)
    },
    formItemRight($event, c) {
      this.$emit('formItemRight', $event, c)
    },
    jsCard(colList) {

      var cardList = [];
      var cardCode;
      var cardCodeList = [];
      var cardCodeNullList = [];
      colList.forEach(o => {
        var col = this.design.colMap[o];
        if (col && col.show_form && col.module_type_form != 'tabs') {
          if (col.module_type_form == 'card' && cardCode != o) {
            if (cardCode != null) {
              cardList.push({
                name: this.design.colMap[cardCode].name,
                key: cardCode,
                colList: this.copyObj(cardCodeList)
              })
              cardCodeList = []
            }
            cardCode = o;
          } else if (cardCode) {
            cardCodeList.push(o)
          } else {
            cardCodeNullList.push(o)
          }
        }
      })
      if (cardCode != null) {
        cardList.push({name: this.design.colMap[cardCode].name, key: cardCode, colList: cardCodeList})
      }
      // console.log("cardList,", cardList)
      // console.log("cardCodeNullList,", cardCodeNullList)
      return {cardCodeNullList: cardCodeNullList, cardList: cardList}
    },
    jsTabs() {
      if (this.design.form.tabs_group) {
        var code;
        var cardList = [];
        var colList = [];
        var tabsList = [];
        this.design.formCols.forEach(o => {
          var col = this.design.colMap[o];
          if (col && col.show_form) {
            if (col.module_type_form == 'tabs' && code != o) {
              // console.log(code, o)
              if (code != null) {
                var result = this.jsCard(colList);
                tabsList.push({
                  name: this.design.colMap[code].name, key: code, colList: colList
                  , cardCodeNullList: result.cardCodeNullList
                  , cardList: result.cardList
                })
                colList = []
              }
              code = o;
            } else if (code) {
              colList.push(o)
            }
          }
        })

        if (code != null) {
          var result = this.jsCard(colList);
          tabsList.push({
            name: this.design.colMap[code].name, key: code, colList: colList
            , cardCodeNullList: result.cardCodeNullList
            , cardList: result.cardList
          })
        }
        this.tabsList = tabsList;
        // console.log(tabsList)
      } else {
        var result = this.jsCard(this.design.formCols);
        this.cardCodeNullList = result.cardCodeNullList
        this.cardList = result.cardList
      }

    },
  }, mounted: function () {
    this.jsTabs();
  }, watch: { //值改变动作 valObj(val){}
    "modelValue.$times$": {
      handler(newName, oldName) {


        if(newName&&newName!=oldName){
          // console.log('modelValue.$times$',newName,oldName)
          this.initSelect('form');
        }
        // if(this.modelValue.$times$&&this.modelValue.$times$!=this.obj.$times$) {
        //   console.log(this.modelValue.$times$
        //       , oldName.$times$
        //   )
        // }
        // this.obj=this.modelValue
        // this.$set(this, "obj", this.objVal);
        // this.initSelect('form');
      }, deep: true
    },
    modelValue() {
      this.$set(this, "obj", this.modelValue);
      // console.log("99999")
      // this.initSelect('form');
    },
    design: {
      handler(newName, oldName) {
        this.jsTabs();
      }, deep: true
    },

    "modelValue.id"() {
      this.$set(this, "obj", this.modelValue);

    },
  }
}
</script>
<style lang="less">
.el-select-dropdown__list {
  margin: 0 !important;
}

.etDesignFormItemTabs {
  .cardCodeNullList {
    border: 1px #ccc solid;
    margin: 8px;
    display: flow-root;
    border-bottom: 0;
  }
}
</style>
