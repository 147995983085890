<!--图片预览-->
<template>
  <span v-if="list">
    <el-image v-for="(item,index) in list"
              style="width: inherit;    height: inherit;margin:inherit"
              :src="item[urlKey]"
              :zoom-rate="1.2"
              :preview-teleported="true"
              :preview-src-list="getImgList()"
              :initial-index="index"
              fit="cover"
    />
  </span>
  <!--      :preview-src-list="listJsonToList(scope.row.back_image,'url')"-->

</template>
<script>
export default {
  name: "etImgsView",
  data() {
    return {}
  }, props: {
    wh: {default: "36px"},
    marginRight: {default: "4px"},
    list: Array,//图片数组对象
    urlKey: {default: "url"},//图片对象的url标识
  }, methods: {
    getImgList() {

      var list = this.listJsonToList(this.list, this.urlKey)
      // console.log("  listJsonToList(list,'url')", list)
      return list;
    }
  }, mounted() {
  }
  // setup(props, {emit}) {
  //   const list = ref(props.list) // 初始化数据
  //   const urlKey = ref(props.urlKey) // 初始化数据
  //
  //   return {
  //     list2: list,
  //   }
  // },
}
</script>
<style lang="less">
.el-image {
  .el-image__error {
    background-color: #efeff0;
    font-size: 12px;
  }
}
</style>