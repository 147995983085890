<!--Breadcrumb 面包屑 -->
<template>
  <template v-if="selectMap[c.col]&& selectMap[c.col]">
    <template
        v-if="selectMap[c.col].api$.showTable&&selectMap[c.col].api$&&selectMap[c.col].api$.table_label&&selectMap[c.col].api$.table_label.length>1">
      <template v-if="selectMap[c.col][row[c.api_field || c.col]]">
        <div v-if="type=='descriptions'" style="overflow: auto">
          <el-descriptions class="etDesignApiShowDescriptions" style="width: 100%"
                           :column="selectMap[c.col].api$.col_num"
                           border>
            <div v-for="(item,itemIndex) in selectMap[c.col].api$.label_json">
              <div v-if="itemIndex<1">
                <template v-for="j in selectMap[c.col].api$.label_json">
                  <el-descriptions-item :width="(j.title.length*20)+'px'" :label="j.title"
                                        :span="j.form_item_span"
                                        v-if="!selectMap[c.col].api$.form_label||selectMap[c.col].api$.form_label.indexOf(j.label)>=0">
                    <template #label>
                      <div :style="{minWidth: (j.title.length*20)+'px'}"  > {{ j.title }}</div>
                    </template>
                    <template v-slot:default>
                      <div :style="{minWidth:(j.width||120)+'px'}">

                    <span v-if="selectMap[c.col].api$.labelDict&&selectMap[c.col].api$.labelDict[j.label]">
                        <etSysDict :sysMap="sysMap"
                                   :modelValue="objKeyVal(selectMap[c.col][row[c.api_field || c.col]],j.label)" text
                                   :dict_code="selectMap[c.col].api$.labelDict[j.label]"></etSysDict>
                      </span>
                    <span v-else>
                      <span v-if="j.custom_style" :style="JSON.parse(j.custom_style)">
                        {{ objKeyVal(selectMap[c.col][row[c.api_field || c.col]], j.label) }}
                      </span>
                      <span v-else>
                        {{ objKeyVal(selectMap[c.col][row[c.api_field || c.col]], j.label) }}
                      </span>
                  </span>
                      </div>
                    </template>
                    <!--                      {{item[j.label]}}-->
                  </el-descriptions-item>
                </template>

              </div>
            </div>
          </el-descriptions>
        </div>
        <table v-else class="selectTable" border="1" cellspacing="0" cellpadding="0">
          <!--        <template v-for="(item,itemIndex) in selectMap[c.col].api$.headTitle">-->
          <!--          <tr v-if="selectMap[c.col].api$.table_label.indexOf(selectMap[c.col].api$.label[itemIndex])>-1">-->
          <!--            <td class="head">{{ item }}</td>-->
          <!--            <td>{{ selectMap[c.col][row[c.api_field || c.col]][selectMap[c.col].api$.label[itemIndex]] }}</td>-->
          <!--          </tr>-->
          <!--        </template>-->
          <template :key="itemIndex" v-for="(j,itemIndex) in selectMap[c.col].api$.label_json">
            <tr v-if="j.table_item">
              <td class="head">{{ j.title }}：</td>
              <td>
                <template v-if="selectMap[c.col].api$.labelDict&&selectMap[c.col].api$.labelDict[j.label]">
                  <etSysDict :sysMap="sysMap"
                             :modelValue="objKeyVal(selectMap[c.col][row[c.api_field || c.col]],j.label)"
                             text
                             :dict_code="selectMap[c.col].api$.labelDict[j.label]"></etSysDict>
                </template>
                <template v-else>
                  {{ objKeyVal(selectMap[c.col][row[c.api_field || c.col]], j.label) }}
                </template>
              </td>
            </tr>
          </template>
        </table>
      </template>
      <span v-else-if="showText" style="color: #ff8d00;">{{ row[c.api_field || c.col] }}</span>
    </template>
    <template v-else>
      <template v-if="row[c.api_field||c.col] instanceof  Array">
          <span v-for="(item,itemIndex) in row[c.api_field||c.col]" :style="{marginRight: c.line_show!=1?'4px':''}">
            <span :class="{line:c.line_show}" style="display: inline-block" v-if="selectMap[c.api_field||c.col][item]">
              {{ selectMap[c.api_field || c.col][item].table_label }}
            </span>
            <span  v-else  :class="{line:c.line_show}" style="color: #ff8d00;display: inline-block;">{{ item }}</span>
          </span>
      </template>
      <template v-else-if="selectMap[c.col][row[c.api_field||c.col]]">
        <template v-if="selectMap[c.col].api$&&selectMap[c.col].api$.options">
            <span v-for="(k,kIndex) in selectMap[c.col].api$.options">
              <span v-if="kIndex>=1">#</span>
              <img v-if="k&k.indexOf('img#')==0" :src="selectMap[c.col][row[c.api_field||c.col]][k.replace('img#','')]"
                   style="height: 32px;vertical-align: middle"/>
              <span v-else>
                {{ selectMap[c.col][row[c.api_field || c.col]][k] }}
              </span>
              <!--              {{// selectMap[c.col][row[c.api_field||c.col]][k]}}-->
            </span>
        </template>
        <template v-else>
          <span v-html="getTextHtml()" :title="getHeadText()"></span>
        </template>
      </template>
      <span v-else-if="showText" style="color: #ff8d00;">{{ row[c.api_field || c.col] }}</span>
    </template>
  </template>
  <span v-else>{{row[c.api_field||c.col]}}</span>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etDesignApiShow",
  data() {
    return {showText:false};
  },
  emits: "remoteMethod,formItemRight,changeFun,update:modelValue".split(","),
  props: {
    selectMap: {},
    c: {},
    row: {},
    type: {},
    sysMap: {},
  },
  setup(props, {emit}) {
    const obj = ref(props.modelValue); // 初始化数据
    const design = ref(props.design); // 初始化数据
    watch(
        () => obj.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动

    return {
      obj: obj,
      design: design,
    }
  },
  methods: {
    getTextHtml() {
      var text = this.getText();
      var seq = this.selectMap[this.c.col].api$.seq_text || this.selectSeqText
      if (text)
        text = this.replaceAll(text, seq, "<span style='color:#666666;font-size:12px;'>" + seq + "</span>")
      return text;
    },getText() {
      var text = this.selectMap[this.c.col][this.row[this.c.api_field || this.c.col]].table_label
      return text;
    },getHeadText() {
     var table_label=this.selectMap[this.c.col].api$.table_label;
     var headTitle=this.selectMap[this.c.col].api$.headTitle;
      // console.log(table_label,headTitle)
      var text="";
      table_label.forEach((o,oi)=>{
        var val=this.selectMap[this.c.col][this.row[this.c.api_field || this.c.col]][o]||''
        text+=headTitle[oi]+"："+val+"\n";
        // console.log(this.selectMap[this.c.col][this.row[this.c.api_field || this.c.col]])
      })
      // console.log(text)
      // var text = this.selectMap[this.c.col][this.row[this.c.api_field || this.c.col]].table_label
      return text;
    }
  }, created() {

  },mounted(){
    setTimeout(()=>{
      this.showText=true
    },1000)
  }
}
</script>

<style lang="less">


</style>
