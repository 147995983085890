<template>
  <span class="treeShowTemp" v-if="!load&&treeList&&treeList.length>0" style="white-space: pre-line">
    <span class="sysMapNull" v-if="!treeList&&devModel()">treeList is null</span>
    <span v-if="objList&&objList.length>0">
      <span v-for="(o,i) in objList" :key="i">
        <i v-if="i>startIndex&&iconClass" :class="iconClass"></i>
        <span v-else-if="i>startIndex" v-html="sign"></span>
       <span v-if="i>=startIndex">{{ o[valKey] }}</span>
      </span>
    </span>
    <span v-else-if="id" style="color: #999999">{{ id }}</span>
  </span>
  <!--  <span v-else>{{ id }}</span>-->
</template>
<script>
export default {
  name: "treeShowTemp",
  data() {
    return {
      load: true,
      objList: [],
      valList: "",
      nowDate: new Date().getTime(),
      value2: this.value,
    }
  }, props: {
    treeList: {},
    startIndex: {default: 0},
    idKey: {default: "id"},
    valKey: {default: "name"},//default: "name";指定valKey就查找获取字符串并赋值给value2
    // valKeyOnly: {default: "name"},//default: "name";指定valKey就查找获取字符串并赋值给value2
    sign: {default: ">"},
    value: {},
    id: {},
    iconClass: {
      default: "el-icon-arrow-right"
    },
  }, methods: {
    find() {
      var $this = this;
      if (this.treeList && this.id) {
        this.objList = this.treeFindListObj(this.id, this.treeList, this.idKey);
        if (this.valKey) {
          this.value2 = this.treeFindVal(this.id, this.treeList, this.idKey, this.valKey, this.sign);
        }
        this.$set(this, "load", false);
//          this.objList.forEach((o, i) => {
//            if (i > 0) {
//              this.value2 += $this.sign;
//            }
//            this.value2 += o[$this.valKey]
//          })
      }
    },
  }, mounted() {
    var $this = this;
    setTimeout(function () {
      $this.find();
    }, 10)
  }, watch: {
//      value: {
//        handler(newValue, oldValue) {
//          this.find();
//        }, deep: true
//      }, sysMap: {
//        handler(newValue, oldValue) {
//          this.find();
//        }, deep: true
//      },
    id() {
      this.find();
    }, treeList() {
      this.find();
    }, value2() {
      this.$emit('input', this.value2);
    }, value() {
      this.$set(this, "value2", this.value);
    }
  }
}
</script>
