<!--Breadcrumb 面包屑 -->
<template>
  <div v-if="cardCodeNullList.length>0" class="cardCodeNullList">
    <template v-for="(c,ci) in cardCodeNullList">
      <et-design-form-item-content :design="design" :sysMap="sysMap" v-model="obj" :c="c" :selectList="selectList"
                                   @changeFun="changeFun" @remoteMethod="remoteMethod"
                                   @formItemRight="formItemRight" :selectMap="selectMap">
        <template #content v-if="$slots[design.colMap[c].col]">
          <slot :name="design.colMap[c].col"></slot>
        </template>
        <template #item v-if="$slots['item-'+design.colMap[c].col]">
          <slot :name="'item-'+design.colMap[c].col"></slot>
        </template>
      </et-design-form-item-content>
    </template>
  </div>
  <template v-for="(ca,cai) in cardList">
    <el-card style="margin: 8px">
      <template #header>
        <div class="card-header">
          <span>{{ ca.name }}</span>
        </div>
      </template>
      <template v-for="(c,ci) in ca.colList">
        <et-design-form-item-content :design="design" :sysMap="sysMap" v-model="obj" :c="c" :selectList="selectList"
                                     @changeFun="changeFun" @remoteMethod="remoteMethod"
                                     @formItemRight="formItemRight" :selectMap="selectMap">
          <template #content v-if="$slots[design.colMap[c].col]">
            <slot :name="design.colMap[c].col"></slot>
          </template>
          <template #item v-if="$slots['item-'+design.colMap[c].col]">
            <slot :name="'item-'+design.colMap[c].col"></slot>
          </template>
        </et-design-form-item-content>
      </template>
    </el-card>
  </template>

</template>
<script>
import {ref, watch} from 'vue'
import EtDesignFormItemContent from "@/ydVue/design/etDesignFormItemContent";

export default {
  name: "etDesignFormItemCard",
  components: {EtDesignFormItemContent},
  data() {
    return {

      tabsList: [],


    };
  },
  emits: "remoteMethod,formItemRight,changeFun,update:modelValue".split(","),
  props: {
    selectMap: {},
    selectList: {},
    cardCodeNullList: [],
    cardList: [],
    modelValue: null,
    col: {},
    // c: {},
    design: {},
    oldObj$: {},
    sysMap: {},
    ptreeList: {},
  },
  setup(props, {emit}) {
    const obj = ref(props.modelValue); // 初始化数据
    const design = ref(props.design); // 初始化数据
    watch(
        () => obj.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      obj: obj,
      design: design,
    }
  },
  methods: {
    changeFun(fun_name, val) {
      this.$emit("changeFun", fun_name, val)
    },

    remoteMethod($event, c) {
      this.initSelectR('form', c, $event)
    },
    formItemRight($event, c) {
      this.$emit('formItemRight', $event, c)
    },

  }, mounted: function () {
    // this.jsTabs();
    console.log("etDesignFormItemCard.$slots",this.$slots)
  }, watch: { //值改变动作 valObj(val){}
    modelValue() {
      this.$set(this, "obj", this.modelValue);
    }, design: {
      handler(newName, oldName) {
        // this.jsTabs();
      }, deep: true
    },

    "modelValue.id"() {
      this.$set(this, "obj", this.modelValue);
      // this.initSelect('form');
    },
  }
}
</script>
<style>
.el-select-dropdown__list {
  margin: 0 !important;
}
</style>
