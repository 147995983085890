<style lang="less"></style>
<template>
  <span v-if="objId">
     <el-button @click="open" v-if="tableName" style="margin-left: 32px;">修改记录</el-button>
    <el-drawer title="操作记录" size="50%" append-to-body v-model="dialogVisible" direction="rtl"
               :modal="true" :close-on-press-escape="true" :wrapperClosable="false">
      <!--<span class="title" v-if="slots.zf" ><slot name="zf"></slot></span>-->

      <sysDataUpdateList ref="sysDataUpdateList" :design="design">

<!--        <template v-for="(s,sk) in slots" :slot="sk" slot-scope="{data}" ><slot :name="sk" :data="data"></slot></template>-->
        <!--<template v-for="(s,sk) in slots"><slot :name="sk" :data="123"></slot></template>-->
        <!--<template v-for="(s,sk) in slots"><span :slot="sk" slot-scope="{data}"><slot :name="sk" :data="data"></slot></span></template>-->
      </sysDataUpdateList>
    </el-drawer>
    <!--<el-dialog :title="title" width="800px" :close-on-click-modal="false" -->
    <!--@close="dialogVisible=false" :top="top" append-to-body v-el-drag-dialog destroy-on-close-->
    <!--:visible="dialogVisible">-->
    <!--<sysDataUpdateList ref="sysDataUpdateList"></sysDataUpdateList>-->
    <!--</el-dialog>-->
    </span>
</template>
<script>

  export default {
    name: "sysDataUpdateListDialog",
    data() {
      return {
        dialogVisible: false,
        slots: {},
      }
    }, props: {
      title: {default: "修改记录"},
      objId: {},
      design: {},
      tableName: {},
    },
    methods: {
      open() { /*查询表单对象obj*/
        this.dialogVisible = true;
        var $this = this;
        setTimeout(function () {
          $this.$refs["sysDataUpdateList"].initFind($this.tableName, $this.objId);
        }, 1);
      }
    },
    watch: { //值改变动作 valObj(val){}

    },
    components: {},
    mounted: function () {/*页面加载初始化*/
      this.$set(this, "slots", this.$scopedSlots);
    }
  }
</script>

