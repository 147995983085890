<style lang="less">
.statusIcon01 {

  i {
    font-size: 16px;
    font-weight: normal;
    vertical-align: center;
  }

  .txt {
    //height: 16px;
    //line-height: 16px;
    font-size: 14px;
    margin-left: 2px;
  }
}
</style>
<template>
  <span class="alarmIcon">
    <span v-if="value==1" style="color: #13ce66">
        <i class="iconfont-a24gf-playCircle"/>
      </span>
    <span v-else style="color: red;">
      <i class="iconfont-suspend"/>
    </span>
  </span>
</template>
<script>

export default {
  name: "statusIcon01",
  data() {
    return {}
  },
  props: {
    showIcon: {default: true},
    value: {},
  },
  created() {
  },
  methods: {},
  watch: {

    value() {
    }

  }
  ,
  components: {}
  ,
  mounted: function () {/*页面加载初始化*/

  }
}
</script>
