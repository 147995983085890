<template>
  <view class="list">
    <ct-commonality title="列表" devCode="list" ref="commonality"></ct-commonality>
    <ct-copyright ref="copyright"></ct-copyright>
    <view class="list-btn margin-right-16 margin-top-16">
      <tui-button @click="toUrl('/ptest/formValidation')">新增</tui-button>
    </view>
    <tui-card style="margin-top: 32rpx" :title="{text:item.id}" :tag="{text: item.create_time}"
              v-for="(item,index) in page.list" :key="index"
              @click="toUrl('/ptest/formValidation?id='+item.id)">
      <template v-slot:body>
        <view class="tui-default" style="font-size: 28rpx">
          投诉内容：{{ item.content }}
        </view>
      </template>
      <template v-slot:footer>
        <view class="tui-default list-btn">
          <tui-button height="52rpx" @click="del(item.id)">删除</tui-button>
        </view>
      </template>
    </tui-card>
    <tui-pagination :page="page" @change="findPage"></tui-pagination>
  </view>
</template>

<script>

import baseAdmin from "../ydUni/baseAdmin"

export default {
  name: "list",
  extends: baseAdmin.listPage,
  components: {},
  data() {
    return {
      parentOnShow: true,//默认后执行listPage.onShow()
      parentOnLoad: true,//默认后执行listPage.onLoad()
      urlParam: {},
      baseUrl: "/complainInfo",//
      urlType: "app",//用户类型，app,m,s,xhtml,
      search: {},//查询条件
      page: {pageSize: 10}//分页配置
    }
  },
  onLoad(param) {
     
  },
  onShow() {

  },
  methods: {},
  watch: {},
}
</script>

<style lang="less">
@import "../css/param";

.list {

}
</style>
