module.exports = {
    'trule': {

        // 注意： 在每个 函数中，第一个参数，永远是 el ，表示 被绑定了指令的那个元素，这个 el 参数，是一个原生的JS对象
        inserted: function (el, binding, vnode) {
            trule(el, binding, vnode)
        },
        update: function (el, binding, vnode) {
            trule(el, binding, vnode)

        },
    },
    'tablewidth': {
        //更新tablewidt调整样式正常
        componentUpdated: function (el, binding, vnode) {
            el.style.width = 'auto'  //操作style所有样式
            el.style.width = binding.value  //操作style所有样式
        },
    }

};