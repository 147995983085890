<template>
  <span v-if="imgList!=null">
    <viewer :id="id" :images="listJsonToList(imgList,'url')">
	   <img v-for="o in imgList" lazy v-if="o!=null&&o.file_type&&listFind(imageType,o.file_type)" :onerror="'javascript:this.src=\''+noImg+'\''" :alt="o.file_name"

          style="margin-right: 5px" :src="o.url" width="32" height="32"/>
       <a v-if="!listFind(imageType,o.file_type)" v-for="o in imgList" :title="o.file_type"
          style="margin-right: 5px" :href="o.url" target="_blank">
      <i class="el-icon-document" style="font-size: 32px;color: #999999"></i>
    </a>
	  </viewer>

    <!--<img v-for="(img,index) in imgList"-->
    <!--:key="index"-->
    <!--preview-title-enable="true"-->
    <!--preview-nav-enable="true" v-preview="img.url" :src="img.url" width="40" height="40"-->
    <!--style="margin-right: 5px"/>-->
  </span>
</template>
<script>
import noImg from "./img_load_erro.png"
//  import Viewer from 'viewerjs';
//  import 'viewerjs/dist/viewer.css';

export default {
  name: "etImgView",
  data() {
    return {
      id: "viewer_" + new Date().getTime(),
      noImg: noImg,
      imageType: "jpg,jpeg,png,bmp,gif",
    };
  },
  props: {
    imgList: null,
    name: null,
  }, mounted() {

  }, methods: {
    onerror(o) {
      o.url = this.noImg;

    }
  }, components: {}
}
</script>
