<style lang="less">

</style>
<template>
  <span style="  " v-if="sysMap.firmList">
    <template v-if="!text">
      <span v-if="firm_my==1" style="display: none">{{ value2 = firm_id }}</span>
      <!--      <el-radio-group v-model="value2">-->
      <!--        <el-radio-button v-for="item in sysMap.firmList" :key="item.id" :label="item.id">{{item.name}}</el-radio-button>-->
      <!--      </el-radio-group>-->
      <el-select v-model="value2" placeholder="" :disabled="firm_my==1||disabled" filterable clearable :multiple="multiple" style="width: 100%">
        <el-option v-for="item in sysMap.firmList" :key="item.id" :label="item.a_name+'-'+item.name" :value="item.id"/>
      </el-select>
    </template>
    <span v-else-if="sysMap.firmListMap[value2]">
      [{{ sysMap.firmListMap[value2].a_name }}]{{ sysMap.firmListMap[value2].name }}
    </span>
  </span>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "firm_temp",
  data() {
    return {
      firm_my: this.getLoginUser().firm_my,
      firm_id: this.getLoginUser().firm_id,

    }
  },
  props: {
    modelValue: null,
    multiple: {type: Boolean, default: false},
    showAll: {type: Boolean, default: false},
    sysMap: {},
    disabled: {type: Boolean, default: false},
    text: {type: Boolean, default: false},
  },
  created() {
  },
  methods: {},
  setup(props, {attrs, emit}) {
    const value = ref(props.modelValue); // 初始化数据
    watch(
        () => value.value,
        (data) => {
          emit('update:modelValue', data)
        }
    ) // 监听输入值变动
    return {
      value2: value,
    }
  },
  watch: {
    modelValue() {
      if (this.modelValue != this.value2 && this.firm_my != 1) {
        this.$set(this, "value2", this.modelValue);
      } else if (this.modelValue != this.value2) {
        this.$emit("update:modelValue", this.firm_id)
      }
    },
    value2(val) {
      this.$emit('change', val);
      this.$emit("update:modelValue", this.value2)
    },
  },
  components: {},
  mounted: function () {/*页面加载初始化*/

  }
}
</script>
