import {createRouter, createWebHistory} from 'vue-router'

// Vue.use(Router)
import rootRouter from '../views/_router'
//   import funIndex from '../views/admin/funIndex.vue'
// console.log("rootRouter.adminPages",rootRouter.adminPages)
rootRouter.adminPages.push( {path: "/admin/adminIndex", component: () => import("@/views/adminIndex.vue"),},)
var routes = [
    {path: "/login", component: () => import("@/views/admin/loginIndex.vue"),},
    {path: "/funIndex", component: () => import("@/views/admin/funIndex.vue"),},

    {
        // path: '/adminMain',
        component: () => import('@/views/admin/adminFrame.vue'),
        children: rootRouter.adminPages
    },
];

rootRouter.pages.forEach(o => {
    o.meta={keepAlive:true}
    routes.push(o)
})
const router = createRouter({
    mode: 'history',
    history: createWebHistory(),//去除#号。
    routes: routes
})
export default router
