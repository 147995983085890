/**
 *   分页页面
 */
const listPage = {
    data() {
        return {
            parentOnShow: true,
            parentOnLoad: true,
            sysMap: {},
            urlParam: {},
            baseUrl: "/apiURL",//
            urlType: "app",//用户类型，app,m,s,xhtml,
            search: {},//查询条件

            bottomFindPage: false,
            isScrollPage: false,
            page: {pageSize: 10},//分页配置
        }
    }, props: {},
    onLoad(param) {
        this.getSession("invite_id", param.invite_id);
        this.urlParam = param;
        /*if (this.parentOnLoad) {
            // this.findPage();
        } else {
            console.log("不执行 onLoad baseAdmin.listPage")
        }*/
    },
    onShow() {
        this.wxAppUpdateVersion()
        this.findSysMap();

        if (this.parentOnShow) {
            // console.log("onShow baseAdmin.listPage");

            this.updateFindPage();
        } else {
            console.log("不执行 onShow baseAdmin.listPage")
        }
    },
    onReachBottom() {
        this.reachBottom();
    },

    methods: {
        reachBottom() {
            if (this.isScrollPage) {
                if (this.page.totalRow > this.page.list.length) {
                    this.page.pageNumber++;
                    this.findPage();
                }
            }
        },
        pageBottom() {
            if (!this.isScrollPage) {
                return this.isScrollPage;
            }
            return this.page.totalRow <= (this.page.list ? this.page.list.length : 0)
        },
        findPage(refresh) {
            // console.log(this.page)
            if (refresh) {
                this.page.pageNumber = 1
            }
            this.findPageUni({});
        },
        del(id, param) {
            this.showModal("删除确认", "确认删除", () => {
                this.delUni({
                    id: id, param: param, fn: () => {
                        this.showMsgSuccess("删除成功！");
                        this.findPage();
                    }
                });
            });
        }
    }, watch: { //值改变动作 valObj(val){}
    }, mounted() {/*页面加载初始化*/

    }, created() {
    },
    destroyed: function () {
    }, beforeRouteLeave(to, from, next) {
    },
    activated() {
    }
};
const formPage = {
    data() {
        return {

            table_name: "",
            isBackPage: true,
            parentOnShow: true,
            parentOnLoad: true,
            urlParam: {},
            sysMap: {},
            baseUrl: "/complainInfo",
            urlType: "app",//app,m,s,xhtml,
            obj: {},
            oldObj$: {},

        }
    }, props: {},
    onLoad(param) {
        this.getSession("invite_id", param.invite_id);
        this.urlParam = param;
        if (param.urlType) {
            this.urlType = param.urlType
        }
        if (this.parentOnLoad) {
            this.findObj();
        } else {
            console.log("不执行 onLoad baseAdmin.formPage")
        }
    },
    onShow() {
        this.wxAppUpdateVersion()
        if (this.parentOnShow) {
            this.findSysMap();
        } else {
            console.log("不执行 onShow baseAdmin.formPage")
        }
    },
    methods: {
        findObj() {
            this.findObjUni({});
        },
        addEdit() {
            this.addEditUni({})
        },
        del(id, param) {
            this.showModal("删除确认", "确认删除", () => {
                this.delUni({
                    id: id, param: param, fn: () => {
                        this.showMsgSuccess("删除成功！");
                        this.backPage();
                    }
                });
            });
        }
    }, watch: { //值改变动作 valObj(val){}
    }, mounted() {/*页面加载初始化*/

    }, created() {
    },
    destroyed: function () {
    }, beforeRouteLeave(to, from, next) {
    },
    activated() {
    }
};


export default {
    listPage: listPage,
    formPage: formPage,
}

