<template>
  <el-button  link type="primary" :style="{color:color}" :class="{btnIcon:type=='icon'}" :title="title" :disabled="disabled">
    <template v-if="type=='icon'" #icon>
      <el-icon class="iconfont-bi"/>
    </template>
    <template v-if="type!='icon'">
      <slot v-if="this.$slots.default"></slot>
      <span v-else-if="title">{{ title }}</span>
    </template>
  </el-button>
</template>
<script>

export default {
  name: "etBtnEditMini",
  props: {
    type: {default: "text"},
    color: {default: ""},
    title: {default: "编辑"}, disabled: Boolean,
  }
}
</script>
<style lang="less">

</style>
