import etBtn from './etBtn.vue'
import etBtnDel from './etBtnDel.vue'
import etBtnAdd from './etBtnAdd.vue'
import etBtnRefresh from './etBtnRefresh.vue'
import etBtnFind from './etBtnFind.vue'
import etBtnDelMini from './etBtnDelMini.vue'
import etBtnEditMini from './etBtnEditMini.vue'
import etBtnFindMini from './etBtnFindMini.vue'
import etBtnMini from './etBtnMini.vue'
import etInputAddBtn from './etInputAddBtn.vue'
import etBtnBatchImport from './etBtnBatchImport.vue'
import etBtnCopyMini from './etBtnCopyMini.vue'

export default  {
  "etBtn":etBtn,
  "etBtnDel":etBtnDel,
  "etBtnAdd":etBtnAdd,
  "etBtnRefresh":etBtnRefresh,
  "etBtnFind":etBtnFind,
  "etBtnDelMini":etBtnDelMini,
  "etBtnEditMini":etBtnEditMini,
  "etBtnFindMini":etBtnFindMini,
  "etBtnMini":etBtnMini,
  "etInputAddBtn":etInputAddBtn,
  "etBtnBatchImport":etBtnBatchImport,
  "etBtnCopyMini":etBtnCopyMini,
}
