<template>
  <span class="sysMapNull" v-if="(sysMap==null) &&devModel()"> sysMap is null=sysMap</span>
  <span class="sysMapNull" v-else-if="(sysMap.sysTenant==null) &&devModel()"> sysMap.sysTenant is null={{sysMap.sysTenant}}</span>
  <el-select v-else-if="showName==false" v-model="value2" :placeholder="name" :clearable="clearable" @change="change" :multiple="multiple "
             style="width: 160px"   :disabled="disabled" filterable>
    <el-option v-for="(item,key) in sysMap.sysTenant" :key="item.id"
               :label="item.code+'-'+item.name"
               :value="item.code" :disabled="item.status==0&&optionDisabled">
      <span :style="item.status==0?'color:red;':''"> {{item.code}}-{{item.name}}</span>
    </el-option>
  </el-select>
  <span v-else-if="showName==true&&sysMap.sysTenantCodeMap[value]">{{value}}-{{sysMap.sysTenantCodeMap[value].name}}</span>
</template>
<script>

  export default {
    name: "sysTenantTemp",
    data() {

      return {
        sysMap:this.getSession('sysMap'),
        value2: this.value,
      }
    },
    props: {
      showName: {type: Boolean, default: false},
      optionDisabled: {default: true},
      disabled: {default: false},
      multiple: {default: false},
      clearable: {default: true},
      name: {default: "选择租户"},
      value: {},
//      sysMap: {
//        default: () => {
//
//        }
//      },
      list: {type: Array, default: () => []},
    },
    created() {
    },
    methods: {
      change() {

      }
    }, mounted() {

    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, components: {}, mounted: function () {/*页面加载初始化*/
    }
  }
</script>
