<template>
  <div>
    <el-upload :action="uploadUrl"
               :before-upload="beforeUpload"
               :on-progress="uploadProcess"
               :on-success="handleSuccess"
               :show-file-list="false"
               :with-credentials="true"
               accept="video/*"
               class="avatar-uploader"
               list-type="picture-card">
      <el-button size="small" type="primary">{{value.url===''?'选取文件':'重新上传'}}</el-button>
      <el-button @click.stop="videoDel" size="small" type="danger" v-if="value.url!==''">
        删除
      </el-button>
      <video :src="value.url+'?'+new Date().getTime()"
             class="avatar video-avatar"
             controls="controls"
             height="200"
             id="video"
             v-if="value.url !==''&& !uploadProgress"
             width="100%">
        您的浏览器不支持视频播放
      </video>

      <el-progress :percentage="uploadProgressPercent"
                   style="margin-top:7px;"
                   type="circle"
                   v-if="uploadProgress"></el-progress>
    </el-upload>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        uploadProgress: false,
        uploadProgressPercent: 0
      }
    },
    computed: {
      uploadUrl() {
        return this.axiosUrl(this.vueConfig.apiUrl) + "/sysFile/uploadFile.do";
      }
    },
    props: {
      fileSize: {default: 1024 * 1024 * 20},
      value: {
        default: function () {
          return {url: ""}
        }
      }
    }, methods: {
      //上传前回调
      beforeUpload(file) {
        //上传大小
        if (file.size > this.fileSize) {
          this.$message.error("视频大小不能超过" + (this.fileSize / 1024 / 1024) + "MB");
          return false;
        }
        if (file.type.indexOf("mp4") === -1 && file.type.indexOf("MP4") === -1) {
          this.$message.error("请上传mp4视频文件");
          return false;
        }
      },
      //进度条
      uploadProcess(event, file) {
        this.uploadProgress = true;
        this.uploadProgressPercent = file.percentage.toFixed(0) * 1;
      },
      //上传成功回调
      handleSuccess(resData) {
        this.uploadProgress = false;
        this.uploadProgressPercent = 0;
        if (resData.$code === 200) {
          this.$message.success('上传成功');
          this.$emit('input', {url: resData.url});
        } else {
          this.$message.error('上传失败，请重试');
        }
      },
      videoDel() {
        this.$emit('input', {url: ''});
      },
      videoStop() {
        let video = document.getElementById("video");
        if (video != null) {
          video.pause();
        }
      }
    }
  }
</script>

<style scoped>
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }

  .avatar {
    width: 100%;
    height: 200px;
    display: block;
  }

  /deep/ .el-upload--picture-card {
    border: none;
    width: 100%;
    height: auto;
    vertical-align: top;

  }

  /deep/ .el-progress {
    margin-top: 37px !important;
    width: 100%;
  }

  /deep/ .el-progress-circle {
    margin: 0 auto;
  }
</style>
