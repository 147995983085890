<style lang="less">

.searchOptionForm {
  .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 4px;
  }

  .el-form-item__label {
    padding-right: 0px;
  }
}

.el-popper {
  .searchContent {
    /*border-bottom: 1px #c3c3c3 dashed;*/
    /*padding-bottom: 10px;*/
    margin-bottom: 16px;
    /*> div {*/
    /*margin-bottom: 10px;*/
    /*}*/
  }
}
</style>
<template>
  <!--<div style="float: left">-->
  <div class="default" style="display: contents">
    <template v-if="config&&config.searchList">
      <template v-for="(c,ci) in config.searchList" :key="ci">
        <template v-if="c.show!=false">
          <etSearchItem :label="c.label" :key="c.key" :slot="c.more?'more':null">
            <slot v-if="slotsValList.indexOf(c.field)>-1" :name="c.field+'$'" :c="c"/>
            <etSysDict v-else-if="c.type=='sysDict'" :sysMap="sysMap" radioTitle="全部" :dict_code="c.sysDictCode" radio
                       v-model="search[c.field]"
                       @change="c.changeFind!=false?findPage('search'):null"/>
            <el-date-picker v-else-if="c.type=='datePicker'" size="small" v-model="search[c.field]" type="daterange"
                            value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" style="width: 220px"
                            @change="c.changeFind!=false?findPage('search'):null"/>
            <el-input v-else :placeholder="c.placeholder" size="small" style="width: 200px"
                      v-model="search[c.field]" clearable
                      @keyup.enter.native="c.enterFind?findPage('search'):null"
                      @change="c.changeFind!=false?findPage('search'):null"/>
          </etSearchItem>
        </template>
      </template>
    </template>
    <slot name="default"></slot>
    <!--    <el-button-group class="floatLeft" :class="{showMulti:mapLength(searchMap)>0}">-->

    <el-button type="primary" :disabled="disabled" @click.stop="findPage">
      <i class="iconfont-sousuoxiao" style="font-size: 16px;"></i>
    </el-button>

    <etBtnAdd @click="updateTreeFullName()" icon="" v-if="design&&design.table.import">导入</etBtnAdd>
    <el-button :disabled="disabled" @click.stop="exportTable" title="导出" type="primary" v-if="exportBtn">
      <i class="iconfont-export" style="font-size: 16px;margin-right: 4px"></i>导出
    </el-button>
    <!--        {{ findBtnName }}-->
    <!--      </etBtnAdd>-->
    <!--      <el-button type="primary">{{ findBtnName }}</el-button>-->
    <!--      <el-popover class="popover" v-if="mapLength(searchMap)>0" placement="bottom" :width="width" @hide="hide()"
                      @show="show()" v-model="showD"
                      trigger="manual">
            <div class="searchContent">
              &lt;!&ndash;<el-form label-suffix="：" :label-width="labelWidth" size="small" class="searchOptionForm" style="width: 99%">&ndash;&gt;
              <slot name="more"></slot>
              &lt;!&ndash;</el-form>&ndash;&gt;
            </div>
            <div style="text-align: center">
              <el-button type="primary" @click="findPage">{{ findBtnName }}</el-button>
              <el-button type="info" @click="reset">重置</el-button>
              <el-button type="info" @click="showD=!showD">关闭</el-button>
            </div>
            <el-badge :value="checkCount>0?checkCount:''" slot="reference">
              &lt;!&ndash;<el-button >{{btnName}}</el-button>&ndash;&gt;
              <el-button :disabled="disabled" @click="showD=!showD" style="padding: 7px 2px" class="show-btn-gd"
                         :type="checkCount>0?'primary':'primary'"
              ><i class="el-icon-arrow-down"></i>
              </el-button>
            </el-badge>
          </el-popover>
        </el-button-group>-->
  </div>

  <!--</div>-->
</template>
<script>
export default {
  name: 'searchOption',

  data() {
    return {
      checkCount: 0,
      showD: false,
      type: "searchOption",
      searchMap: {},
      slotsValList: [],
      slotsList: [],
    }
  },
  props: {
    labelWidth: {default: "120px"},
    design: {},
    width: {default: 320},
    disabled: {default: false},
    exportStatus: {default: false},
    exportBtn: {default: false},
    btnName: {default: "更多查询"},
    findBtnName: {default: "查询"},
    config: {type: Object},
    sysMap: {type: Object},
    search: {type: Object}
  },
  methods: {
    show() {

    },
    hide() {

    },
    reset() {
      if (this._events.reset) {
        this.$emit('reset')
      } else {
        for (let key in this.searchMap) {
          this.search[key] = null;
        }
      }
      this.findPage();
    },
    findPage: function () {
      this.$emit('findPage');
      this.$set(this, "showD", false);
    },
    exportTable() {
      this.$emit('exportTable');
      this.$set(this, "showD", false);
    },
    checkCountMethod: function (val) {

      let tempCount = 0;
      for (let key in this.searchMap) {
        var sval = this.search[key];
        if ((this.isNullStr(sval))) {
          tempCount++;
        }
      }
      this.$set(this, "checkCount", tempCount);
    },
    initSearchMap() {
      var slots = this.$slots.more;
      if (slots)
        slots.forEach(o => {
//            this.searchMap[o.key] = true;
        })
    }
  },
  watch: { //值改变动作 valObj(val){}
//      config: {
//        handler(oldVal, newVal) {
//          this.checkCountMethod(newVal);
//        }, deep: true
//      },
    search: {
      handler(oldVal, newVal) {
        this.checkCountMethod()
      }, deep: true
    }
  },
  components: {
    /*加载组件*/
  },
  mounted() {/*页面加载初始化*/
    var scopedSlots = this.mapKeyToList(this.$scopedSlots);
    var slotsList = [];
    var slotsValList = [];
    scopedSlots.forEach(k => {
      if (k.indexOf("$") > -1) {
        slotsValList.push(k.replace("$", ""))
      } else {
        slotsList.push(k)
      }
    });
    this.$set(this, "slotsValList", slotsValList);
    this.$set(this, "slotsList", slotsList);
    this.initSearchMap();
    this.checkCountMethod();
  },
}
</script>

