<template>
  <template
       v-if="sysMap&&sysMap.sysDict&&sysMap.sysDict[dict_code]&&sysMap.sysDict[dict_code].item_list">
    <template v-if="text">
      <template v-if="value2!=null">
        <template v-if="tree&&treeList">
          <span class="txt" style="display: inline-block;">  {{ treeFindVal(value2, treeList, "key", "value", ">") }}</span>
        </template>
        <template v-else-if="value2 instanceof Array">
          <!--          <template v-else-if="multipleText">-->
          <span v-for="(o,index) in value2">
               <span v-if="index>0">;</span>
            <span v-if="valueMap[o]" :class="{line:line_show}"
                  style="display: inline-block;margin-right: 4px" :style="{color:showColor?valueMap[o].color:''}">{{valueMap[o].value}}</span>
            <span v-else style="color: mediumvioletred;">{{o}}</span>

<!--               <template v-for="(o1,key) in sysMap.sysDict[dict_code].item_list" :key="o1.key">
                  <span v-if="o==o1.key" :class="{line:line_show}" style="display: inline-block;margin-right: 4px" :style="{color:showColor?o.color:''}">
                    {{ o1.value }}
                  </span>

               </template>-->
            </span>
          <!--          </template>-->
        </template>
        <template v-else-if="valueMap[value2]">
          <template v-if="valueMap[value2].icon">


          <i  :class="valueMap[value2].icon" style="font-size: 14px;"
             :style="{color:showColor?valueMap[value2].color:''}">
            <slot name="default"></slot>
          </i>
            <span v-if="valueMap[value2].icon_text==1"    style="margin-left: 2px"
                  :style="{color:showColor?valueMap[value2].color:''}">{{ valueMap[value2].value }}<slot
                name="default"></slot>
               </span>
          </template>
          <el-tag v-else-if="valueMap[value2].tag_type" :type="valueMap[value2].tag_type=='default'?'':valueMap[value2].tag_type">
            {{ valueMap[value2].value }}
            <slot name="default"></slot>
          </el-tag>
          <span v-else
                :style="{color:showColor?valueMap[value2].color:''}">{{ valueMap[value2].value }}<slot
              name="default"></slot>
          </span>
        </template>
        <span v-else style="color: mediumvioletred;" title="字典不存在">{{ value2 }}</span>
      </template>
    </template>

    <el-cascader v-else-if="tree" :change-on-select="true" :disabled="disabled" :options="treeList"
                 :props="{label:'value',value:'key',checkStrictly: checkStrictly}"
                 :clearable="clearable" expand-trigger="hover" filterable
                 separator=">" size="small"  :style="{width: width||'inherit'}"
                 v-model="value2">
      <template #default="{ node, data }">
        <span>{{ data.value }}</span>
        <!--        <span v-if="data.children&&data.children.length >0"> ({{ data.children.length }}) </span>-->
      </template>
    </el-cascader>
    <el-radio-group  v-else-if="radio||sysMap.sysDict[dict_code].module_type=='radio'&&!select"
                    v-model="value2" :disabled="disabled">
      <el-radio-button v-if="radioTitle">{{ radioTitle }}</el-radio-button>
      <template v-for="(o,key) in sysMap.sysDict[dict_code].item_list">
        <el-radio-button :key="o.key" :label="o.key"
                         v-if="!dict_no_code||dict_no_code.split(',').indexOf(o.key+'')==-1">
          <span>{{ o.value }}</span>
        </el-radio-button>
      </template>
    </el-radio-group>
    <el-select v-else :clearable="clearable" :disabled="disabled" :multiple="multiple" collapse-tags
               filterable :placeholder="placeholder"
                v-model="value2" :style="{width: width||'inherit'}" @change="">
      <template v-for="(o,key) in sysMap.sysDict[dict_code].item_list">
        <el-option :key="o.key" :label="o[nameKey]" :value="o.key"
                   v-if="!dict_no_code||dict_no_code.split(',').indexOf(o.key+'')==-1"/>
      </template>
    </el-select>
  </template>
  <div v-else-if="!sysMap" style="color:red">sysMap</div>
  <div v-else style="color:red">{{ dict_code }}字典不存在</div>
</template>
<script>
import {ref, watch} from 'vue'
import {computed, reactive} from 'vue'

export default {
  name: "etSysDict",
  data() {
    return {
      valueMap: {},
    }
  },
  props: {
    dict_no_code: {default: ""},
    width: {default: ""},
    placeholder: {default: "请选择"},
    radioTitle: {default: ""},
    disabled: {default: false},//显示颜色
    line_show: {default: false},//显示颜色
    showColor: {default: true},//显示颜色
    select: {type: Boolean, default: false},//默认
    radio: {type: Boolean, default: false},
    tree: {type: Boolean, default: false},
    text: {type: Boolean, default: false},
    clearable: {type: Boolean, default: true},
    multiple: {type: Boolean, default: false},
    nameKey: {type: String, default: "value"},
    // multipleText: {type: Boolean, default: false},
    checkStrictly: false,
    dict_code: {},
    treeList: {
      type: Array,
      default: () => {
        return []
      }
    },
    sysMap: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modelValue: null,
  }, setup(props, {attrs, emit}) {
    const value2 = ref(props.modelValue); // 初始化数据
    // const multiple = ref(props.multiple) // 初始化数据
    // if(multiple.value==true){
    //   if(value2.value==null){
    //     value2.value=[1]
    //   }
    //   console.log("value2",value2)
    // }
    // console.log("multiple",multiple)
    watch(
        () => value2.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      value2: value2,
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.sysMap && this.sysMap.sysDict && this.sysMap.sysDict[this.dict_code]) {
        // console.log("this.sysMap.sysDict[this.dict_code].item_list",this.sysMap.sysDict[this.dict_code].item_list)
        this.valueMap = this.listJsonToMap(this.sysMap.sysDict[this.dict_code].item_list, "key")
      }
    }
  },
  watch: {
    dict_code(val) {
      this.init();
    },
    value2(val) {
      // if(this.radio||this.sysMap.sysDict[this.dict_code].module_type=='radio'&&!this.select){
      //     console.log(1)
      //
      // }else{
        this.$emit('handleChange', val);
      // }

    },
    modelValue() {
      if (this.modelValue != this.value2) {
        this.value2=this.modelValue
      }
    },
    sysMap() {
      this.value2=this.modelValue
      this.init();
    }
  },
  components: {},
  mounted: function () {/*页面加载初始化*/

  }
}
</script>
