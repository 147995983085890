<template>
  <el-cascader v-if="treeList" size="small" style="width: 100%;" v-model="value2" @change="change"
               expand-trigger="hover" :clearable="clearable" :disabled="disabled" :placeholder="placeholder"
               separator=" > " :props="props" :debounce="0"
               :options="getTreeList()" filterable :change-on-select="true"></el-cascader>
</template>
<script>

  export default {
    name: "treeCascaderTemp",
    data() {
      return {
        value2: this.value,
        treeList2: [],
        sysTypeMap: {},
      }
    },
    props: {
      placeholder: {default: ""},
      value: {},
      showIdMap: {
        default: () => {
          return {};
        }
      },
      showIdLevel: {default: 0},
      props: {
        default: () => {
          return {emitPath: false};
        }
      },
      treeList: {},
      clearable: {default: true},
      disabled: {default: false},
    },
    methods: {
      change() {

      },
      getTreeList() {
        var treeList = this.treeList;
        treeList = this.treeShow(treeList, 1);
        return treeList;
      },
      treeShow(list, n) {
        if (this.showIdLevel == 0) {
          n == 0;
        }
        if (this.mapLength(this.showIdMap) == 0) {
          return list;
        }
        var list2 = []
        list.forEach(o => {
          if (!this.showIdMap[o.id] && n == this.showIdLevel) {
          } else if (o.children) {
            var children = this.treeShow(o.children, n + 1)
            o.children = o.children;
            list2.push(o);
          }
        })
        return list2;
      }
    }, watch: {
      value2() {
        this.$emit('input', this.value2);
        this.$emit('change');
      }, value() {
        this.$set(this, "value2", this.value);
        this.$emit('change');
      }
    }, mounted: function () {/*页面加载初始化*/
//      this.findSysTypeTree(this.typeCode);
    }, components: {},
  }
</script>
