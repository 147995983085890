<template>
  <el-button   :type="type" :style="{color:color}" :disabled="disabled" :loading="loading">
    <slot v-if="this.$slots.default"></slot>
    <template v-else-if="title">{{ title }}</template>
  </el-button>
</template>
<script>
  import etBtn from './etBtn.vue'

  export default {
    name: "etBtnFind",
    extends: etBtn,
    props: {

      type: {default: "primary"},
      title: {default: "查询"},
      loading: {default: false},
      disbaled: {default: false},
      plain: {default:false},
    }
  }
</script>
