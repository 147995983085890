<!--Breadcrumb 面包屑 -->
<template>
  <template v-if="design ">
    <template v-if="obj">
      <span :style="leftStyle(c)" v-if="design.colMap[c].left_suffix_form">
        {{ design.colMap[c].left_suffix_form }}
      </span>
      <template
          v-else-if="design.colMap[c].module_type_form&&design.colMap[c].module_type_form.indexOf('_dict')>0||design.colMap[c].dict_code">
        <span v-if="!(design.colMap[c].dict_code||(design.colMap[c].dict_code))" style="color:red;"
              :style="contentStyle(c)">
                  先选择字典编码
                </span>
        <etSysDict v-else :sysMap="sysMap"
                   @handleChange="changeFun($event,design.colMap[c])"
                   :dict_no_code="design.colMap[c].dict_no_code||design.colMap[design.colMap[c].col].dict_no_code"
                   :dict_code="design.colMap[c].dict_code||design.colMap[design.colMap[c].col].dict_code"
                   :radioTitle="design.colMap[c].radioTitle"
                   v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
                   :placeholder="design.colMap[c].placeholder||''"
                   :disabled="formItemRead(design.colMap[c])"
                   :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
                   :radio="dictTypeMap[design.colMap[c].module_type_form]&&dictTypeMap[design.colMap[c].module_type_form].radio"
                   :tree="dictTypeMap[design.colMap[c].module_type_form]&&dictTypeMap[design.colMap[c].module_type_form].tree"
                   :select="dictTypeMap[design.colMap[c].module_type_form]&&dictTypeMap[design.colMap[c].module_type_form].select"
                   :text="dictTypeMap[design.colMap[c].module_type_form]&&dictTypeMap[design.colMap[c].module_type_form].text"
                   :multiple="dictTypeMap[design.colMap[c].module_type_form]&&dictTypeMap[design.colMap[c].module_type_form].multiple"
        />
      </template>
      <el-input
          v-else-if="!design.colMap[c].module_type_form||design.colMap[c].module_type_form=='input'||design.colMap[c].module_type_form=='inputPassword'"
          :placeholder="design.colMap[c].placeholder||''" size="small"
          @change="changeFun($event,design.colMap[c])"
          :maxlength="design.colMap[c].maxlength"
          :type="design.colMap[c].module_type_form=='inputPassword'?'password':''"
          :disabled="formItemRead(design.colMap[c])"
          :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
          v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
          clearable/>
      <el-input v-else-if="!design.colMap[c].module_type_form||design.colMap[c].module_type_form=='textarea'"
                :placeholder="design.colMap[c].placeholder||''" size="small"
                @change="changeFun($event,design.colMap[c])"
                type="textarea" :rows="design.colMap[c].height||3"

                :maxlength="design.colMap[c].maxlength"
                :disabled="formItemRead(design.colMap[c])"

                :style="[{width:design.colMap[c].width_form||'100%' },contentStyle(c)]"
                v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
                clearable/>
      <template
          v-else-if="design.colMap[c].module_type_form&&design.colMap[c].module_type_form.indexOf('area_sales0')==0">
        <nx-area-select
            v-model:modelValue="obj[design.colMap[c].col_data||design.colMap[c].col]" :level="0" :checkStrictly="true"
            :multiple="design.colMap[c].module_type_form=='area_multiple'" :is_sales="1" @handleChange="changeFun($event,design.colMap[c])"
        />
      </template>
      <template
          v-else-if="design.colMap[c].module_type_form&&design.colMap[c].module_type_form.indexOf('area_sales')==0">
        <nx-area-select
            v-model:modelValue="obj[design.colMap[c].col_data||design.colMap[c].col]" :level="3" :checkStrictly="true"
            :multiple="design.colMap[c].module_type_form=='area_multiple'" :is_sales="1" @handleChange="changeFun($event,design.colMap[c])"
        />
      </template>
      <template v-else-if="design.colMap[c].module_type_form&&design.colMap[c].module_type_form.indexOf('area')==0">
        <nx-area-select v-model:modelValue="obj[design.colMap[c].col_data||design.colMap[c].col]"
                      :checkStrictly="true" :level="0"
                        :multiple="design.colMap[c].module_type_form=='area_multiple'" :is_sales="null"/>
      </template>


      <template v-else-if="design.colMap[c].module_type_form">
        <template v-if="design.colMap[c].module_type_form=='firmAuth'">
          <firm_temp :sysMap="sysMap" @change="changeFun($event,design.colMap[c])"
                     v-model:modelValue="obj[design.colMap[c].col_data||design.colMap[c].col]"
                     :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
                     :disabled="formItemRead(design.colMap[c])"/>
        </template>
        <span v-if="design.colMap[c].module_type_form=='text'" :style="contentStyle(c)">
          {{ objEval(obj, design.colMap[c]) }}
        </span>
        <div v-if="design.colMap[c].module_type_form=='imgUpload'">
          <etUploadImgList :imgWidth="design.colMap[c].width_form||120" @change="changeFun($event,design.colMap[c])"
                           v-model="obj[design.colMap[c].col_data||design.colMap[c].col]" :style="contentStyle(c)"
                           :compressWidth="design.colMap[c].imgCompressWidth" :disabled="formItemRead(design.colMap[c])"
                           :limit="design.colMap[c].maxlength"/>
        </div>
        <div style="width: 100%" v-if="design.colMap[c].module_type_form=='fileUpload'">
          <etUploadFileList :limit="design.colMap[c].maxlength" @change="changeFun($event,design.colMap[c])"
                            :disabled="formItemRead(design.colMap[c])" :style="contentStyle(c)"
                            v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"/>
        </div>
        <div style="width: 100%" v-if="design.colMap[c].module_type_form=='rich_text_box'">
          <etRichTextBox :col="[design.colMap[c].col]" :style="contentStyle(c)"
                         :height="design.colMap[c].height||'400px'" @change="changeFun($event,design.colMap[c])"
                         v-model:modelValue="obj"></etRichTextBox>
        </div>
        <div style="width: 100%" v-if="design.colMap[c].module_type_form=='json_viewer'">
          <etJsonViewer v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
                        :style="contentStyle(c)"></etJsonViewer>
        </div>
        <span class="txt" :style="contentStyle(c)" v-if="design.colMap[c].module_type_form=='select_text'">
           <etDesignApiShow :sysMap="sysMap" type="table" :selectMap="selectMap" :c="design.colMap[c]" :row="obj"/>
          </span>
        <div :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
             v-else-if="design.colMap[c].module_type_form=='select'  ||design.colMap[c].module_type_form=='select_multiple'||design.colMap[c].module_type_form=='select_remote'||design.colMap[c].module_type_form=='select_remote_multiple'">
          <selectApiTemp :sysMap="sysMap" :selectMap="selectMap" :design="design"
                         :disabled="formItemRead(design.colMap[c])" @remoteMethod="remoteMethod"
                         @changeFun="changeFun($event,design.colMap[c])" :selectList="selectList" v-model="obj"
                         :col="design.colMap[c]"/>
        </div>
        <div v-if="design.colMap[c].module_type_formmodule_type_form=='radio'">

          <el-radio-group @change="changeFun($event,design.colMap[c])" :style="contentStyle(c)"
                          v-if="selectList&&selectList[design.colMap[c].col]"
                          v-model="obj[design.colMap[c].col_data||design.colMap[c].col]">
            <el-radio :label="item.value" v-for="item in selectList[design.colMap[c].col]">
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="design.colMap[c].module_type_form=='checked'">
          <el-checkbox-group @change="changeFun($event,design.colMap[c])" :style="contentStyle(c)"
                             v-if="selectList&&selectList[design.colMap[c].col]"
                             v-model="obj[design.colMap[c].col_data||design.colMap[c].col]">
            <el-checkbox :label="item.value" v-for="item in selectList[design.colMap[c].col]">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>

        </div>
        <el-input-number v-if="design.colMap[c].module_type_form=='inputNumber'"
                         :controls="design.colMap[c].number_controls==true"

                         @change="changeFun($event,design.colMap[c])"
                         :precision="parseInt(design.colMap[c].precision)" :min="design.colMap[c].min_val"
                         :max="design.colMap[c].max_val"
                         :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
                         :disabled="formItemRead(design.colMap[c])"
                         :placeholder="(design.colMap[c].placeholder||'')+' '+ (design.colMap[c].max_val&&design.colMap[c].min_val?design.colMap[c].min_val+'至'+design.colMap[c].max_val:'')"
                         v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
        />
        <template v-if="design.colMap[c].module_type_form=='ptree'">
          <el-cascader v-if="design.colMap[c].module_type_form=='ptree'&&ptreeList&&ptreeList.length>0"
                       :change-on-select="true" :options="ptreeList"
                       @change="changeFun($event,design.colMap[c])"
                       expand-trigger="hover" filterable clearable
                       :disabled="formItemRead(design.colMap[c])"
                       v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
                       separator=">" :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
                       :props="{checkStrictly:true,multiple:false,emitPath:false}"/>
        </template>
        <template v-if="design.colMap[c].module_type_form=='tree'">
<!--          {{selectList[design.colMap[c].col]}}-->
          <el-cascader v-if="selectList[design.colMap[c].col]&&selectList[design.colMap[c].col].length>0"
                       :change-on-select="true" :options="selectList[design.colMap[c].col]"
                       @change="changeFun($event,design.colMap[c])"
                       expand-trigger="hover" filterable clearable
                       :disabled="formItemRead(design.colMap[c])"
                       v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
                       separator=">" :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"
                       :props="{checkStrictly:true,multiple:false,emitPath:false,label:'name'}"/>
        </template>
        <el-date-picker v-if="design.colMap[c].module_type_form.indexOf('date')>-1"
                        :placeholder="design.colMap[c].placeholder"
                        @change="changeFun($event,design.colMap[c])"
                        v-model="obj[design.colMap[c].col_data||design.colMap[c].col]"
                        :type="design.colMap[c].module_type_form"
                        :format="design.colMap[c].timeFormat||timeFormatMap[design.colMap[c].module_type_form]"
                        :value-format="design.colMap[c].timeFormat||timeFormatMap[design.colMap[c].module_type_form]"
                        :disabled="formItemRead(design.colMap[c])"
                        clearable
                        :style="[{width:design.colMap[c].width_form||'100%'},contentStyle(c)]"/>

        <template v-else-if="design.colMap[c].module_type_form=='textBr' ">
          <div v-html="toHtmlBr(objEval(obj, design.colMap[c]))" :style="contentStyle(c)"></div>
        </template>
        <template v-else-if="design.colMap[c].module_type_form=='byteStr'&& objEval(obj, design.colMap[c])!=null">
          <span :title="objEval(obj, design.colMap[c])">{{ byteStr(objEval(obj, design.colMap[c])) }}</span>
        </template>
        <template v-else-if="design.colMap[c].module_type_form=='alink'&& objEval(obj, design.colMap[c])!=null">
          <a :href="objEval(obj, design.colMap[c])" target="_blank">{{ objEval(obj, design.colMap[c]) }}</a>
        </template>
      </template>
      <span v-if="design.colMap[c].right_suffix_form" :style="rightStyle(c)">
        {{ design.colMap[c].right_suffix_form }}
      </span>
    </template>
  </template>
</template>
<script>
import {ref, watch} from 'vue'
import EtJsonViewer from "../elTemp/etJsonViewer";
import NxAreaSelect from "@/views/_temp/nxAreaSelect";

export default {
  name: "etDesignFormItem",
  components: {NxAreaSelect, EtJsonViewer},
  data() {
    return {};
  },
  emits: "remoteMethod,formItemRight,changeFun,update:modelValue".split(","),
  props: {
    type: {default: "edit"},

    selectMap: {},
    oldObj$: {},
    selectList: null,
    modelValue: {},
    col: {},
    c: {},
    design: {},
    sysMap: {},
    ptreeList: {},
    root$: {},
  },
  setup(props, {emit}) {
    const obj = ref(props.modelValue); // 初始化数据
    const design = ref(props.design); // 初始化数据
    watch(
        () => obj.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动

    return {
      obj: obj,
      design: design,
    }
  },
  methods: {
    getautosize(c) {
      try {
        if (c.height) {
          eval(c.height)
        }
      } catch (e) {

      }
      return {minRows: 2, maxRows: 4};
    },
    objVal(obj, c) {
      // console.log(c, obj)
      return this.objKeyVal(obj, c.col_data || c.api_field || c.col);
    },
    objEval(obj, c) {
      try {
        var eval_text = c.eval_form
        if (!eval_text) {
          return this.objVal(obj, c);
        }
        var $rq = this.$route.query;
        var loginSys = this.getLoginUser();
        var val = eval(eval_text);
        console.log(obj, eval_text, val)
        return val
      } catch (e) {
        console.error(e)
        this.showMsgError(eval_text + " 行列显示表达式错误")
      }
    },
    leftStyle(c) {
      return this.design.colMap[c].left_suffix_form || this.design.colMap[c].form_style
    },
    rightStyle(c) {
      return this.design.colMap[c].right_suffix_form_style || this.design.colMap[c].form_style
    },
    contentStyle(c) {
      return this.design.colMap[c].form_style
    },
    changeFun(val, c) {
      // console.log(val,c)
      if (c.callback_fun) {
        this.$emit("changeFun", c.callback_fun, val)
      }
    },
    selectOptionRead(o, col) {
      if (col.api_read) {
        return eval(col.api_read)
      }
      return false;
    },
    selectOptionShow(o, col) {
      if (col.api_item_no_show) {
        return !eval(col.api_item_no_show)
      }
      return true;
    },
    remoteMethod($event, c) {
      this.$emit("remoteMethod", $event || "", c)
    }
  }, mounted: function () {
  }, watch: { //值改变动作 valObj(val){}
    modelValue() {
      this.$set(this, "obj", this.modelValue);
    },
  }
}
</script>
<style>
.el-select-dropdown__list {
  margin: 0 !important;
}
</style>
