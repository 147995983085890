// import Vue from 'vue'
import moment from 'moment';
import Kindeditor from '../public/kindeditor/kindeditor-all.js'
import '../public/kindeditor/themes/default/default.css'

export default {
    //判断JSON是否为{}
    checkJson(json){
        if(JSON.stringify(json) === "{}"){
            return false;
        } else {
            return true;
        }
    },
    //去除空格
    trimAll(ele){
        if(typeof ele === 'string'){
            return ele.split(/[\t\r\f\n\s]*/g).join('');
        }else{
            console.error(`${typeof ele} 输入字符串数据`)
        }
    },
    syncNxjbDb(api, reqData) {
        var url = '/nxOldDbSync/' + api;
        this.axiosPost(url , reqData || {}, (res) => {
            this.showMsgSuccess(url = +"同步完成：" + res.num, 1000 * 60);
        })
    },
    initLoginData(data) {
        if(!data){
            data = this.getSession('loginData');
        }
        if (data) {
            this.findVueConfig();

            this.$set(this, 'loginUser', data.loginSysUser);
            this.setSession('findAuthor', data.loginSysUser.findAuthor);
            this.setSession('loginAdminUser', data.loginAdminUser);
            this.setSession('loginConfig', data.loginConfig);
            var loginSysUser=this.copyObj(data.loginSysUser);
            if(  loginSysUser.sysRole) {
                loginSysUser.sysRole.menu_ids = []
            }
            loginSysUser.sysRoleList=[]
            loginSysUser.findAuthor=[]
            this.setLoginUser(loginSysUser);
            // this.$set(this, 'items', data.sysMenuList);
            this.items=data.sysMenuList;
            var sysMenuMap = data.sysMenuMap;
            sysMenuMap['/admin'] = '工作台';
            sysMenuMap['/404'] = '404';
            localStorage.setItem('sysMenuMap', JSON.stringify(data.sysMenuMap));
            this.menuLoading = false
        }
    },
    setJSONToStr(json) {
        return JSON.stringify(json);
    },
    dateFormat: function (date, format) {
        return moment(date).format(format);
    },
    findAlarmInfo: function (deviceId, callbackFn) {
        this.axiosPost("/deviceInfo/findAlarmInfo.do", {deviceId: deviceId}, function (data) {
            this.runCallbackFn(callbackFn, data);
        })
    }
    , oracleLine_ljqk: function (div_id, title, times, val0, val1, val2) {
        var myChart = this.$echarts.init(document.getElementById(div_id));
        var option = {
            // title: {
            //   text: title
            // },
            tooltip: {
                trigger: 'axis'
            },
            // legend: {
            //   data: ['死锁数量','连接数', '活跃会话']
            // },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            // toolbox: {
            //   feature: {
            //     saveAsImage: {}
            //   }
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: times
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '死锁数量',
                    type: 'line',
                    stack: 'Total',
                    data: val0
                },
                {
                    name: '连接数',
                    type: 'line',
                    stack: 'Total',
                    data: val1
                },
                {
                    name: '活跃会话',
                    type: 'line',
                    stack: 'Total',
                    data: val2
                }
            ]
        };
        myChart.setOption(option);
    }
    , http_urlWFSJ: function (div_id, title, unit, times, max, min) {
        var myChart = this.$echarts.init(document.getElementById(div_id));
        var option = {
            // title: {
            //   text: title
            // },
            // tooltip: {
            //   trigger: 'axis'
            // },
            legend: {},
            // toolbox: {
            //   show: true,
            //   feature: {
            //     dataZoom: {
            //       yAxisIndex: 'none'
            //     },
            //     dataView: { readOnly: false },
            //     magicType: { type: ['line', 'bar'] },
            //     restore: {},
            //     saveAsImage: {}
            //   }
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: times
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}' + unit
                }
            },
            series: [
                {
                    name: '最大值',
                    type: 'line',
                    data: max,
                    markPoint: {
                        data: [
                            {type: 'max', name: 'Max'},
                            {type: 'min', name: 'Min'}
                        ]
                    },
                    markLine: {
                        data: [{type: 'average', name: 'Avg'}]
                    }
                },
                {
                    name: '最小值',
                    type: 'line',
                    data: min,
                    markPoint: {
                        data: [{name: '周最低', value: -2, xAxis: 1, yAxis: -1.5}]
                    },
                    markLine: {
                        data: [
                            {type: 'average', name: 'Avg'},
                            [
                                {
                                    symbol: 'none',
                                    x: '90%',
                                    yAxis: 'max'
                                },
                                {
                                    symbol: 'circle',
                                    label: {
                                        position: 'start',
                                        formatter: 'Max'
                                    },
                                    type: 'max',
                                    name: '最高点'
                                }
                            ]
                        ]
                    }
                }
            ]
        };
        myChart.setOption(option);
    }

    , http_urlLine2: function (div_id, name, val1, xData, data, calculate, title, colors) {
        // console.log('name'+JSON.stringify(name))
        //   // console.log('name'+JSON.stringify(val1))
        //   // console.log('xData'+JSON.stringify(xData))
        //   // console.log('data'+JSON.stringify(data))
        var unit = val1;
        if (calculate) {
            if (data && data[0] && data[0].length > 0) {
                var max = 0;
                data[0].forEach(o => {
                    if (o > max) {
                        max = o;
                    }
                });
                var newData = [];
                if (max > 0 && max < 1024) {
                    unit = "Byte";
                    data[0].forEach(o => {
                        newData.push(o);
                    });
                }
                if (max > 1024 && max < 1024 * 1024) {
                    unit = "Kb";
                    data[0].forEach(o => {
                        newData.push(o / 1024);
                    });
                }
                if (max > 1024 * 1024 && max < 1024 * 1024 * 1024) {
                    unit = "Mb";
                    data[0].forEach(o => {
                        newData.push(o / (1024 * 1024));
                    });
                }
                if (max > 1024 * 1024 * 1024 && max < 1024 * 1024 * 1024 * 1024) {
                    unit = "Gb";
                    data[0].forEach(o => {
                        newData.push(o / (1024 * 1024 * 1024));
                    });
                }
                unit = unit + val1;
                data[0] = newData;
            }
        }
        var myChart = this.$echarts.init(document.getElementById(div_id));
        var color = colors ? colors : ["#00f7ff", "#99e8ea", "#e32f46", "#7049f0", "#fa704d", "#01babc"];
        var series = [];
        //  "{b}<br />{a0}: {c0}"+val1+"<br />{a1}: {c1}<br />{a2}: {c2}<br />"
        var formatterTex = '{b}<br />';
        for (var i = 0; i < name.length; i++) {
            // formatterTex.concat('{a'+i+'}: {c'+i+'}'+val1+'<br />');
            if (name[i] != '') {
                formatterTex += '{a' + i + '}: {c' + i + '}' + val1 + '<br />';
            } else {
                formatterTex += '{c' + i + '}' + val1 + '<br />';
            }
            series.push({
                name: name[i],
                type: "line",
                symbolSize: 3, //标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10[ default: 4 ]
                symbol: "circle", //标记的图形。ECharts 提供的标记类型包括 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
                smooth: true, //是否平滑曲线显示
                showSymbol: false, //是否显示 symbol, 如果 false 则只有在 tooltip hover 的时候显示
                areaStyle: {
                    normal: {
                        color: new this.$echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                    offset: 0,
                                    color: color[i],
                                },
                                {
                                    offset: 0.8,
                                    color: color[i] + "88",
                                },
                            ],
                            false
                        ),
                        // shadowColor: 'rgba(255,255,255, 0.1)',
                        shadowBlur: 10,
                        opacity: 0.3,
                    },
                },
                itemStyle: {
                    normal: {
                        color: color[i],
                        lineStyle: {
                            width: 1,
                            type: "solid", //'dotted'虚线 'solid'实线
                        },
                        borderColor: color[i], //图形的描边颜色。支持的格式同 color
                        borderWidth: 4, //描边线宽。为 0 时无描边。[ default: 0 ]
                        barBorderRadius: 0,
                        label: {
                            show: false,
                        },
                        opacity: 0.5,
                    },
                },
                data: data[i],
            });
        }
        var option = {
            // backgroundColor: "#141f56",
            legend: {//顶部标题
                top: 0,
                itemGap: 5,
                itemWidth: 5,
                textStyle: {
                    color: "#fff",
                    fontSize: "12",
                },
                show: false,
                // data: name,
            },
            title: {
                text: title,
                show: title,
                left: 'center',
                padding: [0, 0, 0, 20],
                textStyle: {
                    color: "#fff",
                    fontSize: "18",
                    fontWeight: "normal",
                },
                // subtextStyle: {
                //   color: "#90979c",
                //   fontSize: "16",
                // },
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    // 坐标轴指示器，坐标轴触发有效
                    type: "line", // 默认为直线，可选为：'line' | 'shadow'
                    lineStyle: {
                        color: "#57617B",
                    },
                },
                formatter: formatterTex,
                // "{b}<br />{a0}: {c0}"+val1+"<br />{a1}: {c1}<br />{a2}: {c2}<br />",
                backgroundColor: "rgba(0,0,0,0.7)", // 背景
                padding: [8, 10], //内边距
                extraCssText: "box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);color:rgb(200, 200, 200)", //添加阴影
            },
            grid: {//网格
                borderWidth: 0,
                top: 20,
                right: 10,
                bottom: 20,
                textStyle: {
                    color: "#fff",
                },
            },
            xAxis: [
                {
                    type: "category",
                    axisLine: {
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#fff",
                        },
                    },
                    boundaryGap: false, //坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样
                    axisTick: {
                        show: false,
                    },
                    splitArea: {
                        show: false,
                    },
                    axisLabel: {
                        inside: false,
                        textStyle: {
                            color: "#bac0c0",
                            fontWeight: "normal",
                            fontSize: "12",
                        },
                        formatter: function (data) {
                            return data.substring(5);
                        }
                    },
                    data: xData,
                },
            ],
            yAxis: {
                type: "value",
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#fff",
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#fff",
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: "#bac0c0",
                        fontWeight: "normal",
                        fontSize: "12",
                    },
                    formatter: function (val) {
                        return val + unit;
                    },
                },
            },
            series: series,
        };
        if (title) {
            option.title.text = title;
        }
        myChart.setOption(option);
    }

    , http_urlLine: function (div_id, val1, val2, val3) {
        var myChart = this.$echarts.init(document.getElementById(div_id));
        var option = {
            tooltip: {
                trigger: 'axis'
            },
            // legend: {
            //   data: [val1]
            // },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            // toolbox: {
            //   feature: {
            //     saveAsImage: {}
            //   }
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: val2
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: val1,
                    type: 'line',
                    stack: 'Total',
                    data: val3
                },

            ]
        };
        myChart.setOption(option);
    }
    , rateMV: function (div_id, val1, val2) {
        var myChart = this.$echarts.init(document.getElementById(div_id));
        var option = {
            xAxis: {
                type: 'category',
                data: val1
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: val2,
                    type: 'line'
                }
            ]
        };
        myChart.setOption(option);
    }
    , syl_pie2: function (div_id, val1, name1, val2, name2, showLink) {
        var myChart = this.$echarts.init(document.getElementById(div_id));

        var value = 700;
        var color = this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
                offset: 0,
                color: "#58D9F9",
            },
            {
                offset: 1,
                color: "#4992FF",
            },
        ]);
        var option = {
            backgroundColor: "#ffffffd1",
            series: [
                {
                    type: "gauge",
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1000,
                    radius: "60%",
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: [[1, "#999"]],
                            width: 1,
                            opacity: 1,
                        },
                    },
                    title: {
                        show: false,
                    },
                    detail: {
                        backgroundColor: "#ffffffd1",
                        borderColor: "#999",
                        borderWidth: 2,
                        width: "80%",
                        borderRadius: 4,
                        offsetCenter: [0, "50%"],
                        formatter: function (value) {
                            return "{value|" + value.toFixed(0) + "}{unit|km/h}";
                        },
                        rich: {
                            value: {
                                fontSize: 50,
                                fontWeight: "bolder",
                                color: "#777",
                            },
                            unit: {
                                fontSize: 20,
                                color: "#999",
                                padding: [0, 0, 15, 6],
                            },
                        },
                    },

                    axisTick: {
                        length: 10,
                        lineStyle: {
                            color: "#999",
                        },
                    },
                    splitLine: {
                        length: 15,
                        lineStyle: {
                            color: "#999",
                            width: 3,
                        },
                    },
                    axisLabel: {
                        color: "#999",
                    },
                    pointer: {
                        width: 10,
                        length: "70%",
                    },
                    itemStyle: {
                        color: color,
                        shadowColor: "rgba(0,138,255,0.45)",
                        shadowBlur: 10,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                    },
                    markPoint: {
                        data: [
                            {
                                x: "50%",
                                y: "50%",
                                symbol: "circle",
                                symbolSize: 8,
                                itemStyle: {
                                    color: "#ffffffd1",
                                },
                            },
                        ],
                    },
                    data: [
                        {
                            value: value,
                        },
                    ],
                },
                {
                    type: "gauge",
                    radius: "70%",
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 1000,
                    title: {
                        show: false,
                    },
                    detail: {
                        show: false,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 16,
                            color: [
                                [value / 1000, color],
                                [1, "rgba(225,225,225,0.4)"],
                            ],
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    pointer: {
                        show: false,
                    },
                    itemStyle: {
                        normal: {
                            color: "#54F200",
                        },
                    },
                },
            ],
        };


        myChart.setOption(option);
    }
    , syl_pie: function (div_id, val1, name1, val2, name2, showLink) {
        var myChart = this.$echarts.init(document.getElementById(div_id));
        var option = {
            color: ['#D72727', '#00ff00'],
            tooltip: {
                trigger: 'item',
                formatter: " {b} :({d}%)"
            },
            series: [{
                itemStyle: {
                    normal: {
                        label: {
                            position: 'inner',
                            formatter: function (params) {
                                if (showLink) {
                                    return params.name + params.value + '%';
                                } else {
                                    return params.value + '%';
                                }
                            }, textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                                color: '#ffffffd1',
                                fontSize: 12,
                                fontWeight: 'bolder'
                            }
                        },
                        labelLine: {
                            show: false
                        }
                    }

                },
                type: 'pie',
                center: ['50%', '50%'],
                radius: '80%',
                data: [{
                    value: val2, name: name2
                }, {
                    value: val1, name: name1
                }
                ]
            }
            ]
        };
        myChart.setOption(option);
    }
    , ec_gauge: function (div_id, name, val, max, colors) {
        try {
            if (!colors) {
                colors = [[0.4, '#00ff00'], [0.8, '#619aec'], [1, '#D72727']]
            }
            var myChart = this.$echarts.init(document.getElementById(div_id));
            var option = {
                tooltip: {
                    trigger: 'axis'
                },
                series: [{
                    // name: '业务指标',
                    type: 'gauge',
                    // endAngle: -45,
                    // startAngle:180,
                    max: max ? max : 100,
                    splitNumber: 5,       // 分割段数，默认为10
                    axisLine: {            // 坐标轴线
                        // length: 22,        // 属性length控制线长
                        lineStyle: {       // 属性lineStyle控制线条样式
                            color: colors,
                            width: 10
                        }
                    },
                    itemStyle: {              //指针样式
                        color: colors[2][2]     //指针颜色
                    },
                    axisTick: {            // 坐标轴小标记
                        show: false,
                        distance: 10,
                        splitNumber: 1,   // 每份split细分多少段
                        length: 8,        // 属性length控制线长
                        lineStyle: {       // 属性lineStyle控制线条样式
                            color: 'auto',
                            width: 2
                        }
                    },
                    axisLabel: {           // 坐标轴文本标签，详见axis.axisLabel
                        distance: 13,
                        fontSize: 12,
                        textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                            color: 'auto'
                        },
                        lineStyle: {       // 属性lineStyle控制线条样式
                            color: 'auto',
                            width: 1
                        },
                        length: 5
                    },
                    splitLine: {           // 分隔线
                        distance: 0,
                        show: true,        // 默认显示，属性show控制显示与否
                        length: 8,         // 属性length控制线长
                        lineStyle: {       // 属性lineStyle（详见lineStyle）控制线条样式
                            color: 'auto',
                            width: 1
                        }
                    },
                    tooltip: {},
                    pointer: {
                        width: 5
                    },
                    detail: {
                        offsetCenter: [0, "75%"],
                        formatter: '{value}' + '%',
                        textStyle: {       // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                            color: 'auto',
                            fontSize: 18,
                            fontWeight: 'bolder'
                        }
                    },
                    center: ['50%', '55%'],    // 默认全局居中
                    radius: '100%',
                    data: [{value: val, name: name}]
                }
                ]
            };
            myChart.setOption(option);
            return myChart;
        } catch (e) {
            alert(e);
        }
    }
    , idCardHide: function (id_card) {
        if (!id_card) {
            return id_card;
        }
        if (id_card.length == 18) {
            return this.strHide(id_card, 7, 10)
        }
        if (id_card.length == 9) {
            return this.strHide(id_card, 3, 5)
        }
    }
    , findShowNameMap: function () {
        // var sysDict=this.sysMap.sysDict;
        // console.log(sysDict)

        if (this.sysMap && this.sysMap.sysDict && this.sysMap.sysDict.show_name && this.sysMap.sysDict.use_v) {
            // console.log(this.sysMap.sysDict)
            var show_name = this.sysMap.sysDict.show_name.item_list;
            var use_v = this.sysMap.sysDict.use_v.item_list;
            // console.log("show_name", show_name)
            // console.log("use_v", use_v)
            var showNameMap = this.listJsonToMap(show_name, "key", "value");
            this.$set(this, "showNameMap", showNameMap);
            // console.log("showNameMap",showNameMap)
            // console.log("showNameMap",this.showNameMap)
            var useVMap = {};
            use_v.forEach(o => {
                if (o.value == '1') {
                    useVMap[o.key] = '1'
                }
            });
            this.$set(this, "useVMap", useVMap);
            // console.log("useVMap",this.useVMap)
            // }
            // console.log("nmae2",nmae2)
            // console.log(3)
            // return nmae2;

            /* var si = setInterval(() => {
               var map = this.getSession("showNameMap")
               if(map){
                 clearInterval(si);
               }
             console.log(11)
             }, 10);*/
        }
    }
    , getDictTree: function (dict_code) {
        if (this.sysMap && this.sysMap.sysDict && this.sysMap.sysDict[dict_code]) {
            var obj = this.sysMap.sysDict[dict_code];
            var treeList = [];
            obj.item_list.forEach(o => {
                if (o.pkey == '') {
                    var list = this.listToTree({
                        list: obj.item_list,
                        idKey: "key",
                        pid: o.key,
                        pidKey: "pkey",
                        showPid: false,
                        fieldMap: null
                    });
                    o.children = list;
                    treeList.push(o);
                }
            });
            return treeList;
        }
    }
    , user_typeMap: {
        "wjw": {name: "卫健委", class: "orange"},
        "wg": {name: "网格管理员", class: "orange"},
    }
    , official_adminMap: {
        0: {name: "否", class: "orange"},
        1: {name: "是", class: "orange"},
    }
    , user_admin: {
        0: {name: "否", class: "orange"},
        1: {name: "是", class: "orange"},
    }
    , sysOa_typesMap: {
        "official": {name: "公文", color: "red"},
        "person": {name: "个人", color: "green"},
    }


    , id_type: {
        0: {name: "身份证", val: 0},
    }
    , relation_code: {
        0: {name: "父亲", val: 0},
        1: {name: "母亲", val: 1},
        2: {name: "子女", val: 2},
        3: {name: "爱人", val: 3},
        4: {name: "亲属", val: 4},
        98: {name: "其他", val: 98},
        99: {name: "本人", val: 99}
    }
    , sysCode: function (code, dataKey, mapKey) {
        return this.getSession("sysCode");
    }
    , findDDList: function (code, dataKey, mapKey) {
        this.axiosPost("/sysType/findDDList.do", {code: code}, res => {
            if (dataKey) {
                this.$set(this, dataKey, res.list);
            }
            if (mapKey) {
                this.$set(this, mapKey, this.listJsonToMap(res.list, "key", "val"));
            }
        });
    }

    , findEpOrganTree: function (pid, dataKey, exclude, fn) {
        var skey = "ecOrgan_" + (pid ? pid : 1) + "_" + (exclude ? exclude : 0);
        var list = this.getSession(skey);
        if (!fn && list != null && list.length > 0) {
            this.$set(this, dataKey || "epOrganTree", list);
        } else {
            this.axiosPost("/nxArea/findTree.do", {pid: pid}, res => {
                var list = [];
                if (res.treeList.length == 1) {
                    res.treeList[0].children.forEach(o => {
                        if (o.id != exclude) {
                            list.push(o);
                        }
                    })
                } else {
                    list = res.treeList;
                }
                this.$set(this, dataKey || "epOrganTree", list);
                if (fn) {
                    this.runCallbackFn(fn, res.treeList)
                } else {
                    this.setSession(skey, list);
                }
            })
        }
    }
    , nodeInitRules: function (rulesObj) {
        this.$set(this, rulesObj, this.copyObj(this.rules));
        for (var k in this.formConfigItemMap) {
            var v = this.formConfigItemMap[k];
            if (v.required) {
                this.$set(this[rulesObj], k, [this.vRequired]);
            }
//          this.rules[k]=[this.vRequired];
        }
        setTimeout(() => {
            this.$refs["obj"].clearValidate()
        }, 1);
        // this.clearValidate("obj")
    }
    , nodeFiledReadonly: function (field) {
        var config = this.formConfigItemMap[field];
        if (config) {
            //required,hide
            return config.readonly;
        }
        return false;
    }
    , nodeFiledShow: function (field) {
        var config = this.formConfigItemMap[field];
        if (config) {
            //required,hide
            return !config.hide;
        }
        return true;
    }
    , areaTreeFind: function ({list, pid, showPid = false, noId = []}) {
        var result = [];

        for (var i = 0; i < list.length; i++) {
            var o = list[i];
            if (o[pidKey] == pid && showPid == false || showPid == true && o.id == pid) {
                var children = this.listToTree({
                    list: list,
                    pid: o.id,
                    pidKey: pidKey,
                    showPid: false,
                    noId: noId,
                    fieldMap: fieldMap
                });
                o.hasChildren = false;
                if (children.length > 0) {
                    o.hasChildren = true;
                    o.children = children;
                }
                if (fieldMap) {
                    for (var k in fieldMap) {
                        o[fieldMap[k]] = o[k];
                    }
                }
                if (noId.length == 0 || noId.indexOf(o.id) == -1) {
                    result.push(o);
                }
            } else {

            }
        }
        result.sort(function (a, b) {
            return a.order_num - b.order_num
        });

        return result;
    },
    //行政区划递归
    areaRecursion(list, areaList) {
        list.forEach(o => {
            areaList.push(o);
            if (o.children) {
                o.children.forEach(o1 => {
                    areaList.push(o1);
                });
                this.areaRecursion(o.children, areaList);
            }
        });
    },
    getAreaTreeInit: function ({dataKey, showPid, level, only, noId = [], fn, treeList}) {
        var list = [];
        var areaMap = {};
        var areaList = [];
        if (showPid) {
            list = treeList;
            this.areaRecursion(list, areaList);
            areaMap = this.listJsonToMap(areaList, "id");
        } else {
            if (treeList.length == 1) {
                treeList[0].children.forEach(o => {
                    if (noId.indexOf(o.id) == -1) {
                        list.push(o);
                    }
                });
            } else {
                list = treeList
            }
            this.areaRecursion(list, areaList);
            areaMap = this.listJsonToMap(areaList, "id");
            if (level>0 && !only) {
                areaList = [];
                list.forEach(o1 => {
                    if (o1.children && level == 1) {
                        delete o1['children'];
                    } else if (o1.children && level == 2) {
                        o1.children.forEach(o2 => {
                            delete o2['children'];
                        })
                    } else if (o1.children && level == 3) {
                        o1.children.forEach(o2 => {
                            if (o2.children) {
                                o2.children.forEach(o3 => {
                                    delete o3['children'];
                                })
                            }
                        })
                    } else if (o1.children && level == 4) {
                        o1.children.forEach(o2 => {
                            if (o2.children) {
                                o2.children.forEach(o3 => {
                                    if(o3.children) {
                                        o3.children.forEach(o4 => {
                                            delete o4['children'];
                                        })
                                    }
                                })
                            }
                        })
                    }
                });
                this.areaRecursion(list, areaList);
                areaMap = this.listJsonToMap(areaList, "id");
            } else if (level>0 && only) {
                var newList = [];
                list.forEach(o => {
                    if (o.children && level == 1) {
                        delete o['children'];
                        newList.push(o);
                    } else if (o.children && level == 2) {
                        o.children.forEach(o1 => {
                            delete o1['children'];
                            newList.push(o1);
                        })
                    } else if (o.children && level == 3) {
                        o.children.forEach(o1 => {
                            if (o1.children) {
                                o1.children.forEach(o2 => {
                                    delete o2['children'];
                                    newList.push(o2);
                                })
                            }
                        })
                    }
                });
                list = newList;
            }
        }
        // console.log("list",list)
        this.$set(this, dataKey || "epAreaTree", list);
        this.$set(this, "areaMap", areaMap);
        //
        if (fn) {
            this.runCallbackFn(fn, treeList)
        }
    }


    // , getOrderInfo: function () {
    //     this.axiosPost("/orderInfo/findOrderInfoList.do", {}, function (resData) {
    //         var orderInfo = {};
    //         orderInfo.orderInfoList = resData.list;
    //         orderInfo.orderInfoMap = this.listJsonToMap(resData.list, "id");
    //         this.$set(this, "orderInfo", orderInfo);
    //     });
    // }
    // , getFirmManagerInfo: function (fn) {
    //     this.axiosPost("/firmManager/findFirmManagerList.do", {}, function (resData) {
    //         var firmManagerInfo = {};
    //         firmManagerInfo.firmManagerList = resData.list;
    //         firmManagerInfo.firmManagerMap = this.listJsonToMap(resData.list, "id");
    //         this.$set(this, "firmManagerInfo", firmManagerInfo);
    //         if (fn) {
    //             this.runCallbackFn(fn, firmManagerInfo)
    //         }
    //     });
    // }
    // , getNxUserInfo: function (fn) {
    //     this.axiosPost("/nxUser/findNxUserList.do", {}, function (resData) {
    //         var nxUserInfo = {};
    //         nxUserInfo.nxUserList = resData.list;
    //         nxUserInfo.nxUserMap = this.listJsonToMap(resData.list, "id");
    //         this.$set(this, "nxUserInfo", nxUserInfo);
    //     });
    // }
    // , getStaffInfo: function (keystr, id) {
    //     this.axiosPost("/staffInfo/findStaffInfoList.do", {
    //         keystr: keystr,
    //         id: id
    //     }, function (resData) {
    //         var staffInfo = {};
    //         staffInfo.staffInfoList = resData.list;
    //         staffInfo.staffInfoMap = this.listJsonToMap(resData.list, "id");
    //         this.$set(this, "staffInfo", staffInfo);
    //     });
    // }
    , findFirm_place: function () {
        this.axiosPost("/firmPlace/findFirm_place.html", {}, function (resData) {
            this.$set(this, "firm_place", resData.list);
        });
    }
    , getFirmInfo: function () {
        this.axiosPost("/firmInfo/findList.do", {}, function (resData) {
            var firmInfo = {};
            firmInfo.firmInfoList = resData.list;
            firmInfo.firmInfoMap = this.listJsonToMap(resData.list, "id");
            this.$set(this, "firmInfo", firmInfo);
        });
    }
    , getAreaTree: function ({status, is_sales, dataKey, pid, showPid, level=0, only, noId = [], fn}) {

        if (pid instanceof Object && pid.data) {
            pid = pid.data;
        }
        var req = {status: status, is_sales: is_sales, pid: pid || 1,level:level,noId:noId};
        var skey = "getAreaTree_" + JSON.stringify(req);
        var list = this.getSession(skey);
        // console.log(dataKey,skey, list)
        var find = true;
        if (list != null && list.length > 0) {
            find = false;
        }

        req.find = find;
        this.axiosPost("/nxArea/findTree.do", req, res => {
            if (res.treeList) {
                list = res.treeList;
            }
            this.getAreaTreeInit({
                dataKey: dataKey,
                showPid: showPid,
                level: level,
                only: only,
                noId: noId,
                fn: fn,
                treeList: list
            });
            this.setSession(skey, list);
        })
        // if (this.sysMap && this.sysMap.epAreaTree) {
        //   // console.log(this.sysMap.epAreaTree)
        //   // console.log(dataKey, pid, showPid)
        //   var list = this.listToTree({list: this.sysMap.epAreaTree, pid: pid, showPid: showPid, noId: noId});
        //   this.$set(this, dataKey, list);
        //   console.log(dataKey, list)
        //   // console.log("new Date().getTime()", new Date().getTime() - n)
        // } else {
        //   alert("行政区划缓存无数据")
        // }
    },
    showFormItem: function (col) {
        try {
            // console.log(col)
            if (col == null) {
                return false;
            }
            var flag = col.show_form;
            var $rq = this.$route.query;
            var obj = this.obj;
            var sunTbale = this.sunTbale;
            var loginSys = this.getLoginUser();
            var rule = col.form_show_rule;
            if (!rule && col.table_show_rule_sync) {
                rule = col.table_show_rule
            }
            if (rule) {
                if (!eval(rule)) {
                    return false;
                }
            }
            return flag;
        } catch (e) {
            console.error(e);
            this.showMsgError("表单项字段：" + col.col + ",表达式错误");
            return true;
        }

    }, formItemRead: function (col) {
        try {
            if (col.read_form) {
                return true;
            }
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var obj = this.obj;
            var oldObj = this.oldObj$;
            // console.log(oldObj)
            var flag = true;
            var rule = col.form_read_rule;
            if (rule) {
                if (eval(rule)) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            this.showMsgError("表单字段：" + col.col + ",只读规则表达式错误");
        }

    }, formSaveBtnShow: function () {
        var rule;
        try {
            if (this.design.form.read == true) {
                return false;
            }
            if (this.design.form.save_btn == false) {
                return false;
            }
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var obj = this.obj;
            var oldObj = this.oldObj$;
            rule = this.design.form.saveBtnRule;
            if (rule) {
                try {
                    if (eval(rule)) {
                        return false;
                    }
                } catch (e) {
                    this.showMsgError("保存按钮表达式错误：" + rule);
                    console.error(e, rule, this.oldObj$)
                }
            }
            return true;
        } catch (e) {
            this.showMsgError("保存按钮表达式错误：" + rule);
            console.error(e)
        }

    }, operDelShow: function (row) {
        try {
            if (this.design.oper.del == false) {
                return false;
            }
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var rule = this.design.oper.del_no_rule;
            if (rule) {
                if (eval(rule)) {
                    return false;
                }
            }
            return true;
        } catch (e) {
            console.error(e)
        }

    }, tableColLabel: function (c) {
        try {
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var rule = c.name_table_eval;
            if (rule) {
                return eval(rule);
            }
        } catch (e) {
            console.error(e)
        }
        return c.show_name;

    }, formItemLabel: function (c) {
        try {
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            // var rule = c.name_table_eval;
            // if (rule) {
            //     return eval(rule);
            // }
            return (c.name_form || c.name) + (this.design.form.suffix || "");
        } catch (e) {
            console.error(e)
        }

    }, operEditShow: function (row) {
        try {
            if (this.design.oper.edit == false) {
                return false;
            }
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var rule = this.design.oper.edit_no_rule;
            if (rule) {
                if (eval(rule)) {
                    return false;
                }
            }
            return true;
        } catch (e) {
            console.error(e)
        }

    }, showWhereItem: function (col) {
        try {
            var flag = true;
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var rule = col.show_rule;
            if (rule) {
                if (!eval(rule)) {
                    return false;
                }
            }
            return flag;
        } catch (e) {
            this.showMsgError("keyName：" + col.keyName + ",表达式错误");
        }

    }, findTableFixed: function (design) {
        try {
            // console.log(design);
            var operFixed = false;
            design.tableCols.forEach(c => {
                if (operFixed == false && design.colMap[c] && design.colMap[c].operFixed) {
                    operFixed = "left";
                }
            })

        } catch (e) {
            console.error(e)
        }
        return operFixed;

    },
    showTableItem: function (col) {
        try {
            // console.log(col);
            var flag = true;
            var sunTbale = this.sunTbale;
            var $rq = this.$route.query;
            var loginSys = this.getLoginUser();
            var rule = col.table_show_rule;
            if (!rule && col.form_show_rule_sync) {
                rule = col.form_show_rule
            }
            if (rule) {
                if (!eval(rule)) {
                    return false;
                }
            }
            return flag;
        } catch (e) {
            console.error(e);
            this.showMsgError("字段：" + col.col + ",表达式错误");
        }

    }
    , getUserTree: function (dataKey, duty_user_ids) {
        var list = [];
        this.axiosPost("/ecDutyUser/findTree.do", {duty_user_ids: duty_user_ids}, res => {
            this.$set(this, dataKey || "epUserTree", list);
            console.log("DataKey", dataKey);
            console.log("ID", duty_user_ids)
        })
    }
    /**
     * 查询第三方接口数据
     * @param code
     * @param use_type
     */
    , findApiDataDo: function (code, use_type, param_data, fn) {
        this.axiosPost("/sysApi/findData.do", {code: code, use_type: use_type, param_data: param_data}, res => {
            var obj = res.obj;
            if (obj) {
                this.runCallbackFn(fn, res.data);
            } else {
                this.showMsgError(code + " API 不存在")
            }
        });
    }
    , getMinArea: function (id) {
        if (!id) {
            return null;
        }
        var id2 = parseInt(id.toString());
        var id2Str = id2.toString();
        var index = id2Str.indexOf("000");
        if (index > -1) {
            if (index >= 7 && index <= 9) {
                index = 9;
            }
            id2 = parseInt(id2Str.substring(0, index));
        }
        return id2;
    }

    , menuType: {
        1: {key: 1, name: "功能地址", color: "#666"},
        0: {key: 0, name: "目录", color: "#000"},
        2: {key: 2, name: "工作台菜单", color: "blue"},
        4: {key: 4, name: "APP菜单", color: "red"},
        3: {key: 3, name: "账号菜单", color: "green"},

    }
    /*字段统计维度*/
    , spsTotalFiledMap: {
        "whlb": {name: "文号类别", key: "whlb"},
        "wslb": {name: "文书类别", key: "wslb"},
        "kwsdw": {name: "开文书单位", key: "kwsdw"},
        "kwsbm": {name: "开文书部门", key: "kwsbm"},
        "is_del": {name: "删除标记", key: "is_del"},
        "yeartime": {name: "年", key: "yeartime"},
        "monthtime": {name: "月", key: "monthtime"},
        "daytime": {name: "日", key: "daytime"},
    }
    , judgeMap: {
        "=": {name: "等于", key: "whlb"},
        "!=": {name: "不等于", key: "wslb"},
        ">": {name: "大于", key: "kwsdw"},
        ">=": {name: "大于等于", key: "kwsbm"},
        "<": {name: "小于", key: "is_del"},
        "<=": {name: "小于等于", key: "is_del"},
        "notNull": {name: "不为空", key: "yeartime"},
        "isNull": {name: "为空", key: "yeartime"},
        "like": {name: "包含", key: "monthtime"},
    }
    , spsTotalTimeMap: {
        "yeartime": {name: "年", key: "yeartime"},
        "monthtime": {name: "月", key: "monthtime"},
        "daytime": {name: "日", key: "daytime"},
    },
    timeFormatMap: {
        datetime: "YYYY-MM-DD HH:mm:ss",
        datetimerange: "YYYY-MM-DD HH:mm:ss",
        date: "YYYY-MM-DD",
        month: "YYYY-MM",
        daterange: "YYYY-MM-DD",
    }
    ,
    dictTypeMap: {
        default_dict: {},
        select_dict: {select: true},
        select_multiple_dict: {select: true, multiple: true},
        radio_dict: {radio: true},
        tree_dict: {tree: true},
        text_dict: {text: true},
    },
    /* designApiType2: {
         wxTempMsgTypeDo: {
             name: "微信模板类型",
             url: "/wxTempMsgType/findSelectList.do",
             value: "id",
             label: "code,name",
             // selectField: "o.username,o.phone,o.name",
             // table_label: "name",
             labelLv: [12, 12],
             headTitle: "编号,名称".split(","),
             remove: true
         },
         staffWage: {
             name: "工资目录",
             url: "/staffWage/findSelectList.do",
             value: "id",
             selectField: "o.title",
             label: "title",
             labelLv: [24],
             headTitle: "标题".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         aliSmsTempDo: {
             name: "短信模板",
             url: "/aliSmsTemp/findSelectList.do",
             value: "temp_id",
             label: "code,name",
             table_label: "name",
             labelLv: [8, 16],
             headTitle: "编号,名称".split(","),
             remove: true
         },
         sysRole: {
             name: "角色",
             url: "/sysRole/findSelectList.do",
             value: "id",
             label: "full_name",
             table_label: "full_name",
             labelLv: [24],
             // headTitle: "编号,名称".split(","),
             remove: true
         },
         findMyRoleTree: {
             type: "cascader",
             name: "角色",
             url: "/sysRole/findMyRoleTree.do",
             value: "id",
             label: "name",
             table_label: "full_name",
             labelLv: [24],
             // headTitle: "编号,名称".split(","),
             remove: true
         },
         wxUser: {
             name: "微信用户",
             url: "/wxUser/findSelectList.do",
             value: "id",
             label: "id,nickname",
             selectField: "id,nickname",
             options: "id,nickname,img#headimgurl".split(","),
             // table_label: "id,nickname,img#headimgurl",
             labelLv: [6, 12, 6],
             headTitle: "ID,微信昵称,头像".split(","),
             remove: true
         },
         sysUser: {
             name: "系统用户",
             url: "/sysUsers/findSelectList.do",
             value: "id",
             label: "username,name",
             selectField: "o.username,o.phone,o.name",
             // table_label: "name",
             labelLv: [12, 12],
             headTitle: "账号,姓名".split(","),
             remove: true
         },
         couponInfo: {
             name: "优惠劵类型",
             url: "/couponInfo/findSelectList.do",
             value: "coupon_code",
             label: "coupon_code,coupon_name",
             // selectField: "o.username,o.phone,o.name",
             // table_label: "name",
             labelLv: [8, 16],
             headTitle: "编号,名称".split(","),
             remove: true
         },
         nxInviteRuleDo: {
             name: "推荐规则",
             url: "/nxInviteRule/findSelectList.do",
             value: "code",
             label: "code,name",
             table_label: "name",
             labelLv: [8, 16],
             headTitle: "编号,名称".split(","),
             remove: true
         },
         nxCreditRuleDo: {
             name: "积分规则",
             url: "/nxCreditRule/findSelectList.do",
             value: "code",
             label: "code,name",
             table_label: "name",
             labelLv: [12, 12],
             // headTitle: "姓名,电话,工号,".split(","),
             remove: true
         },
         nxUserDo: {
             width: "300px",
             name: "凰巢用户",
             url: "/nxUser/findSelectList.do",
             value: "id",
             label: "name,phone",
             table_label: "name,phone",
             labelLv: [12, 12],
             headTitle: "姓名,电话".split(","),
             // tableShowHead: "姓,电".split(","),
             // tableShowHeadKey: "name,phone".split(","),
             remove: true
         },
         sysHeartbeat: {
             width: "300px",
             name: "系统心跳检测",
             url: "/sysHeartbeat/findList.do",
             value: "id",
             label: "name,server_ip",
             table_label: "name,server_ip",
             labelLv: [12, 12],
             headTitle: "名称,ip".split(","),
             // tableShowHead: "姓,电".split(","),
             // tableShowHeadKey: "name,phone".split(","),
             remove: true
         },
         staffInfo: {
             name: "凰巢员工",
             url: "/staffInfo/findSelectList.do",
             value: "id",
             label: "number,name",
             table_label: "number,name",
             labelLv: [12, 12],

             headTitle: "工号,姓名".split(","),
             // tableShowHead: "工号,'姓名',".split(","),
             remove: true
         },
         firmManager: {
             name: "凰巢经理",
             url: "/firmManager/findSelectList.do",
             value: "id",
             selectField: "o.name,o.phone,fi.name",
             label: "name,phone,firm_name",
             table_label: "name",
             labelLv: [4, 8, 12],

             headTitle: "姓名,电话,加盟商".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         firmInfo: {
             name: "凰巢加盟商",
             url: "/firmInfo/findSelectList.do",
             value: "id",
             selectField: "o.name",
             label: "name,id",
             table_label: "name",
             labelLv: [18, 6],

             headTitle: "加盟商,ID".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         goodsInfo: {
             name: "凰巢商品",
             url: "/goodsInfo/findSelectList.do",
             value: "id",
             selectField: "o.name",
             label: "name",
             labelLv: [24],

             headTitle: "商品名称".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         firmPlace: {
             name: "凰巢分销商",
             url: "/firmPlace/findSelectList.do",
             value: "id",
             selectField: "o.name,o.id",
             label: "name,id",
             labelLv: [18, 6],

             headTitle: "分销商,ID".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         areaCityDo: {
             name: "市级接口",
             url: "/nxArea/areaCityDo.do",
             value: "id",
             selectField: "o.name",
             label: "name",
             labelLv: [24],

             // headTitle: "分销商,ID".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         areaCountyDo: {
             name: "县级名称接口",
             url: "/nxArea/areaCountyDo.do",
             value: "id",
             selectField: "o.name",
             label: "name",
             labelLv: [24],

             // headTitle: "分销商,ID".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         areaStreetDo: {
             name: "县-街道名称接口",
             url: "/nxArea/areaStreetDo.do",
             value: "id",
             selectField: "o.county_name",
             label: "county_name",
             labelLv: [24],

             // headTitle: "分销商,ID".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         areaIdsDo: {
             name: "区域查询接口",
             url: "/nxArea/areaCityDo.do",
             value: "id",
             selectField: "o.city_name",
             label: "city_name",
             labelLv: [24],

             // headTitle: "分销商,ID".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         orderInfo: {
             width: "300px",
             name: "凰巢订单",
             url: "/orderInfo/findSelectList.do",
             value: "id",
             // selectField: "o.no_code",
             selectField: "o.id",
             label: "no_code,uname",
             labelLv: [12, 12],
             headTitle: "订单编号,客户".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         orderServe: {
             width: "300px",
             name: "凰巢服务单",
             url: "/orderServe/findSelectList.do",
             value: "id",
             // selectField: "o.no_code",
             selectField: "o.id",
             label: "no_code,c_name",
             labelLv: [12, 12],
             headTitle: "预约单号,客户".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         orderServeItem: {
             width: "300px",
             name: "凰巢服务单项目",
             url: "/orderServeItem/findSelectList.do",
             value: "id",
             // selectField: "o.no_code",
             selectField: "o.id",
             label: "no_code,c_name,c_phone,finish_time",
             table_label: "no_code,c_name,finish_time",
             labelLv: [6, 4, 4, 10],
             headTitle: "服务单号,客户,电话,完成时间".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },

         rateTagDo: {
             width: "300px",
             name: "评价标签",
             url: "/sysDictItem/rateTagDo.do",
             value: "id",
             selectField: "order_serve_eval_tags",
             label: "id,values",
             labelLv: [12, 12],
             headTitle: "id,值".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         complainInfo: {
             name: "凰巢投诉",
             url: "/complainInfo/findSelectList.do",
             value: "id",
             selectField: "o.no_code,o.content",
             label: "nx_user_name,no_code,content",
             labelLv: [4, 6, 14],

             headTitle: "投诉人,订单,投诉内容".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
         nxVipDo: {
             name: "凰巢会员",
             url: "/nxVipInfo/findSelectList.do",
             value: "id",
             selectField: "o.name",
             label: "name,discount",
             labelLv: [18, 6],
             headTitle: "类型,折扣".split(","),
             // tableShowHead: "姓名,电话".split(","),
             remove: true
         },
     },*/
    moduleTypeMap: {
        card: {name: "card卡片", form: true},
        tabs: {name: "tabs组件", form: true},
        ptree: {name: "上级树", form: true},
        tree: {name: "树", form: true,search:true},

        firmAuth: {name: "加盟商-权限组件", form: true, table: true, search: true},
        input: {name: "文本框", form: true, table: false, search: true, dict: false},
        inputPassword: {name: "密码框", form: true, table: false, search: true, dict: false},
        datetime: {name: "日期时间", form: true, table: false, search: true, dict: false},
        datetimerange: {name: "日期时间范围", form: true, table: false, search: true, dict: false},
        date: {name: "日期", form: true, table: false, search: true, dict: false},
        month: {name: "月份", form: true, table: false, search: true, dict: false},
        daterange: {name: "日期范围", form: true, table: false, search: true, dict: false},
        inputNumber: {name: "数字", form: true, table: false, search: true, dict: false},
        textarea: {name: "文本域", form: true, table: false, search: false, dict: false},
        select: {name: "下拉", form: true, table: true, search: true, api: true, dict: true},
        select_text: {name: "下拉文本", form: true, table: true, search: true, api: true, dict: true},
        select_multiple: {name: "下拉多选", form: true, table: true, search: true, api: true, dict: false},
        radio: {name: "单选", form: true, table: true, search: true, api: true, dict: true},
        checked: {name: "复选框", form: true, table: true, search: true, api: true, dict: true},
        default_dict: {name: "【字典】默认", form: true, table: false, search: true, dict: false},
        select_dict: {name: "【字典】下拉", form: true, table: false, search: true, dict: false},
        select_multiple_dict: {name: "【字典】下拉多选", form: true, table: false, search: true, dict: false},
        radio_dict: {name: "【字典】单选", form: true, table: false, search: true, dict: false},
        tree_dict: {name: "【字典】树形", form: true, table: false, search: true, dict: false},
        text_dict: {name: "【字典】文本", form: true, false: false, search: false, dict: false},
        area: {name: "全部地区组件(单选)", form: true, table: true, search: true, dict: false},
        area_multiple: {name: "全部地区组件(多选)", form: true, table: true, search: true, dict: false},

        area_sales: {name: "销售地区组件(单选)", form: true, table: true, search: true, dict: false},
        area_sales_multiple: {name: "销售地区组件(多选)", form: true, table: true, search: true, dict: false},
        area_sales0: {name: "全级销售地区(单选)", form: true, table: true, search: true, dict: false},
        imgUpload: {name: "【上传】图片", form: true, table: false, search: false, dict: false},
        fileUpload: {name: "【上传】文件", form: true, table: false, search: true, api: true, dict: false},
        // select_remote: {name: "远程搜索", form: true, table: true, search: true, api: true, remote: true, dict: false},
        // select_remote_multiple: {
        //     name: "远程搜索多选",
        //     form: true,
        //     table: true,
        //     search: false,
        //     api: true,
        //     remote: true,
        //     dict: false
        // },
        rich_text_box: {name: "富文本框", form: true, table: false, search: false, api: true, remote: true, dict: false},
        json_viewer: {name: "JSON风格", form: true, table: false, search: false, api: true, remote: true, dict: false},
        text: {name: "文本显示", form: true, table: true, search: false, dict: false},
        html: {name: "HTML显示", form: true, table: true, search: false, dict: false},
        byteStr: {name: "字节", form: true, table: true, search: false, dict: false},
        alink: {name: "超链接", form: true, table: true, search: false, dict: false},
        textBr: {name: "文本换行显示", form: true, table: true, search: false, dict: false},
    }
    , designSqlMap: {
        defined: {name: "自定义"},
        "=": {name: "等于"},
        "in": {name: "包含"},
        like: {name: "模糊查询",},
        likeValHead: {name: "模糊查询Head",},
        betweenTime: {name: "时间范围",},
        jsonListContains: {name: "JSON数组包含单值",},
        jsonListContainsJsonArrayValue: {name: "JSON数组包含数组",},

    }
    , dbColTypeMap: {
        varchar: {name: "字符串", lengthReq: true},
        int8: {name: "长整型"},
        int4: {name: "整型"},
        int2: {name: "短整型"},
        timestamp: {name: "日期时间",},
        text: {name: "text",},
        json: {name: "json",},
        float8: {name: "精度float8",},
        numeric: {name: "精度numeric", lengthReq: true, decimalReq: true},

    }
    , contentTypeMap: {
        "html": {name: "自定义编辑"},
        "word": {name: "word文件"},
        "pdf": {name: "pdf文件"},
    },
    alignMap: {
        "left": {name: "左靠齐"},
        "center": {name: "居中"},
        "right": {name: "右靠齐"},
    },
    fixedMap: {
        "": {name: "默认"},
        "left": {name: "左"},
        "right": {name: "右"},
    },
    findMapValue: function () {
        this.axiosPost('/sysAdmin/findMapValue.xhtml', {}, function (resData) {
            this.setSysConfig(resData.obj);
            this.sysConfig = this.getSysConfig();
            window.document.title = this.sysConfig.sysName
        })
    }
    , initEditor: function (content, objKey, colKey, userDir) {
        var $this = this;
        var textareaName = this.$refs[$this.textareaName];
        if (textareaName != null) {
            this.initEditor2(content, objKey, colKey, userDir);
        } else {
            setTimeout(function () {
                $this.initEditor(content, objKey, colKey, userDir);
            }, 10);
        }
    }
    , initEditor2: function (content, objKey, colKey, userDir) {
        var $this = this;

        var editor = KindEditor.create('textarea[name="' + $this.textareaName + '"]', {
            // allowFileManager: true,
            fileManagerJson: '/api/sysFile/getKeFileList.do?userDir=' + userDir,
            allowFileManager: true,
            uploadJson: '/api/sysFile/uploadKindEditorFile.do?userDir=' + userDir,
            items: [
                'source', '|', 'undo', 'redo', '|', 'preview', 'print', 'template', 'code', 'cut', 'copy', 'paste',
                'plainpaste', 'wordpaste', '|', 'justifyleft', 'justifycenter', 'justifyright',
                'justifyfull', 'insertorderedlist', 'insertunorderedlist', 'indent', 'outdent', 'subscript',
                'superscript', 'clearhtml', 'quickformat', 'selectall', '|', '/',
                'formatblock', 'fontname', 'fontsize', '|', 'forecolor', 'hilitecolor', 'bold',
                'italic', 'underline', 'strikethrough', 'lineheight', 'removeformat', '|', 'image',
                'flash', 'media', 'insertfile', 'table', 'hr', 'emoticons', 'baidumap', 'pagebreak',
                'anchor', 'link', 'unlink', '|', 'about'
            ], afterChange: function () {
                $this[objKey][colKey] = this.html();
                var text = this.text();
                text = text.replace(/<[^>]+>|&[^>]+;/g, "").trim();
                $this[objKey][colKey + "_text"] = text
                // console.log(text,  $this[objKey][colKey],  $this[objKey][colKey + "_text"])
            }
        });
        editor.html("");
        if (content == undefined) {
            content = "";
        }
        editor.html(content);
        $this.$set($this, "editor", editor);
        return editor;
    }
    , isSaasAdmin: function () {
        // if (!this.$isSaas) {
        //     return false;
        // }
        var sysLogin = this.getLoginUser();
        if (!sysLogin || sysLogin.saasAdmin != 1) {
            return false;
        }
        return true;
    }
    , closeEditor: function () {
        var a = KindEditor.remove('textarea[name="' + this.textareaName + '"]');
    }
    , findSysTypeList: function (key, reqJson, fn) {
        this.axiosPost("/sysType/findList.do", reqJson, function (resData) {
            if (resData.treeList && resData.treeList.length > 0) {
                this.$set(this, key, resData.treeList);
            }
            this.runCallbackFn(fn, null);
        });
    }
    , wsNewsStatus: {
        // 0: {name: "驳回"},
        // 1: {name: "未投稿"},
        // 2: {name: "未审核"},
        // 3: {name: "未通过"},
        0: {name: "待发布", style: "color:#999;"},
        5: {name: "已发布", style: "color:green;font-weight:bold;"},
    }

    , qjUserStatus: {
        0: {name: "停用", style: "color:#999;"},
        1: {name: "正常", style: "color:green;font-weight:bold;"},
    }

    , sysTypesMap: {
        news: {name: "新闻"},
        link: {name: "链接"},
        // newsZL: {name: "专栏"},
    }

    , colHeader: function (h, para) {
        // var prop = para.column.property
        // var b=new Date().getTime()
        var config = para._self.config;
        config.fieldListTable.forEach(c => {
            if (c.fieldName == para.column.property) {
                c.name = para.column.label;
                JSON.stringify();

            }
        });
        config.fieldListExport.forEach(c => {
            if (c.fieldName == para.column.property) {
                c.name = para.column.label;

            }
        });
        // console.log(new Date().getTime()-b)
        return (
            h('span', para.column.label)
        )
//          para.column.align = 'is-right'//排序
//     return (
//       h('span', cObj.name ? cObj.name : '')
//     )
    }
    , getLoginPactAreaPermit: function () {
        return this.getLoginUser().pactAreaPermit;
    }
    , getLoginPactAreaPermitMap: function () {
        if (!this.vRolePermit("pactInfoAll")) {
            return this.listToMap(this.getLoginPactAreaPermit());
        }
        return {}
    }
    , getLoginStaffId: function () {
        return this.getLoginUser().staffInfoId;
    }
    , vRolePermit: function (key) {
        var loginUser = this.getLoginUser();
        if (loginUser) {
            var sysRoleList = loginUser.sysRoleList;
            for (var i = 0; i < sysRoleList.length; i++) {
                var sysRole = sysRoleList[i];
                var permitList = this.listAddList(sysRole.dataPermit, sysRole.auditPermit);
                var dataPermitMap = this.listToMap(permitList);
                if (dataPermitMap[key]) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }
    , tableNameMap: {
        sys_users: "账号信息",
    }
    , findGoodsInfoByTypeId: function (typeIdList, param, callbackFn) {
        if (!param) {
            param = {}
        }
        param.typeIdList = typeIdList;
        this.axiosPost("/goodsInfo/findListByTypeId.do", {param: param}, function (resData) {
            this.$set(this, "goodsInfoList", resData.list);
            this.$set(this, "goodsInfoMap", this.listJsonToMap(resData.list, "id"));
            this.runCallbackFn(callbackFn, null);
        });
    }
    , tableHeight: 0

    ,
    initGoodsUnitName: function (list, idList) {
        var goodsUnitMap = this.sysMap.goodsUnitMap;
        var ids = idList.split(",");
        list.forEach((o) => {
            ids.forEach(id => {
                var uo = goodsUnitMap[o[id]];
                if (uo) {
                    // console.log(new Date().getTime())
                    o[id + "$"] = uo.name;
                }
            })
        })
    }
    ,
    getSysMapSession: function (...tableList) {
        var sessionKey = "sysMap" + $this.jsonToStr(tableList);
        var sysMap = $this.getSession(sessionKey);
        if (sysMap) {
            $this.$set($this, "sysMap", sysMap);
        } else {
            sysMap = {}
        }
        return sysMap;
    }
// async function runSysMap($this,callbackFn, ...tableList) {
//   // console.log(new Date().getTime())
//
// }
    ,
    initDictMap: function (code, paraKey) {
        if (this.sysMap && this.sysMap.sysDict && this.sysMap.sysDict[code]) {
            // console.log(this.sysMap.sysDict[code])
            this.$set(this, paraKey, this.listJsonToMap(this.sysMap.sysDict[code].item_list, "key", null));
        }
    }
    ,
    findSysMap: function (callbackFn, ...tableList) {
        var $this = this;
        var sessionKey = "sysMap";
        // if ($this.jsonToStr(tableList) != '[]') {
        //   sessionKey = "sysMap" + $this.jsonToStr(tableList);
        // }
        if (tableList.length > 0) {
            // console.log("noTable", tableList)
        }
        var sysMap = {};
        sysMap = $this.getSession(sessionKey);
        var find = false;
        if (sysMap) {
            $this.$set($this, "sysMap", sysMap);
            $this.findShowNameMap();
        } else {
            find = true;
            sysMap = {}
        }

        // console.log(new Date().getTime())
        $this.axiosPost("/sysAdmin/findSysMap.do", {
            tableList: tableList,
            find: find
        }, function (resData) {
            var $updateStatus = resData.$updateStatus;
            if ($updateStatus) {
                var tableList = "sysUser,epAreaTree,sysDict,firmList".split(",");
                var idMap = $this.listToMap("sysUser,firmList".split(","));
                var codeMap = $this.listToMap("".split(","));
                var mapList = {goodsType: "typeCode"};
                tableList.forEach(t => {
                    var resDataT = resData[t];
                    if (resDataT != null) {
                        $this.$set(sysMap, t, resDataT);
                        if (idMap[t] != null) {
                            $this.$set(sysMap, t + "Map", $this.listJsonToMap(resDataT, "id"));
                        }
                        if (codeMap[t] != null) {
                            $this.$set(sysMap, t + "CodeMap", $this.listJsonToMap(resDataT, "code"));
                        }
                        if (mapList[t] != null) {
                            $this.$set(sysMap, t + "MapList", $this.listJsonToMapList(resDataT, mapList[t]));
                        }
                    }
                });
                $this.setSession(sessionKey, sysMap);
                if (!$this.sysMap || ($this.sysMap && !$this.sysMap.sysDict)) {
                    $this.sysMap = sysMap;
                }
                $this.findShowNameMap();
            }
            // $this.$set(sysMap, "firmManagerList", resData.firmManagerList);
            // $this.$set(sysMap, "firmManagerMap", $this.listJsonToMap(resData.firmManagerList, 'id'));
            $this.runCallbackFn(callbackFn, sysMap);
        });
        // $this.callbackFn = runSysMap
        ////console.log(callbackFn)
        // $this.callbackFn($this,callbackFn,tableList)
        // runSysMap($this,callbackFn,tableList)

    }
    ,
    findTableTask: function (tableName, tableId) {
        this.axiosPost("/flowTask/findListByTableId.do", {tableName: tableName, tableId: tableId}, function (resData) {
            var list = resData.list;
            if (list != null && list.length > 0) {
                var row = list[0];
                this.$router.push({path: "/flowTaskFind?taskId=" + row.id + "&tagName=" + row.title});
            }
        });
    }

    ,
    cancelStatus: function () {
        return this.funStatus(this.baseUrl + "/cancel.action");
    }
    ,
    cancelFn: function (row, name) {
        this.cancelPost("确定" + (row.isCancel == 1 ? "取消" : "") + "作废该" + name + "吗？", row.id);
    }

    ,
    cancelPost: function (msg, id, callbackFn, url, showResultMsg) {
        this.$confirm(msg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
            center: true,
            confirmButtonClass: "confirmBtnOk"
        }).then((e) => {
            if (url == null) {
                url = this.baseUrl + "/cancel.action";
            }
            this.axiosPost(url, {id: id}, (resData) => {
                if (this.judgeStatus(resData)) {
                    var dvt = resData.dvt;
                    if (dvt != null) {
                        var tableName = this.tableNameMap[dvt.tableName];
                        if (tableName == null) {
                            tableName = dvt.tableName;
                        }
                        this.showMsgError("数据被【" + tableName + "】占用，无法作废")
                    } else {
                        if (showResultMsg != false) {
                            this.$message({message: "操作成功", type: 'success'});
                            this.refresh();
                        }
                        this.runCallbackFn(callbackFn, resData);
                    }
                }
            });

        }).catch(() => {
        });
    }
    ,
    findAllPmInfo: function () {
        var loginConfig = this.getSession("loginConfig");
        return loginConfig.pmInfo != null;
    }
    ,
    findAllUserFollow: function () {
        var loginConfig = this.getSession("loginConfig");
        return loginConfig.userFollow != null;
    }
    ,
    findAllBuyerInfo: function () {
        var loginConfig = this.getSession("loginConfig");
        return loginConfig.buyerInfo != null;
    }
    ,
    findAllData: function (tableName) {
        var loginConfig = this.getSession("loginConfig");
        return loginConfig[tableName] != null;
    }
    ,
    loginUserFormRead: function (field) {
        if (!this.formItemRead) {
            this.$set(this, "formItemRead", this.obj[field] != this.getLoginUserId());
        }
    }
    ,
    initObjLoginUser: function (field) {
        if (this.obj[field] == null) {
            this.$set(this.obj, field, this.getLoginUserId());
        }
    }
    ,
    findSysTypeTree: function (code) {
        this.axiosPost("/sysType/findTree.do", {code: code, id: 0}, (resData) => {
            this.$set(this.sysTypeMap, code, resData.treeList);
            if (resData.treeList != null && resData.treeList.length == 1) {
                this.$set(this.sysTypeMap, "$" + code, resData.treeList[0].children);
            }
        });
    }
    ,
    sysTypeName: function (plist, sysTypeList, id, code, seq) {
        var $this = this;
        var str = "";
        sysTypeList.forEach(o => {
            if (str.length > 0) {
                return;
            }
            if (o.id == id) {
                var pstatus = false;
                plist.forEach(p => {
                    if (p.code == code) {
                        pstatus = true
                    } else if (pstatus) {
                        str = str + (p.name) + seq;
                    }
                });
                str = str + o.name;
                return str;
            }
            if (o.children != null) {
                var plistTemp = $this.copyObj(plist);
                plistTemp.push(o);
                str = $this.sysTypeName(plistTemp, o.children, id, code, seq);
                if (str.length > 0) {
                    return str;
                }
            }
        });
        return str;
    }
    ,
    findSysTypeAllTree: function () {
        this.axiosPost("/sysType/findTree.do", {code: "root"}, (resData) => {
            this.$set(this, "sysTypeAll", resData.treeList);
        });
    }
    ,
    dayList: ["日", "一", "二", "三", "四", "五", "六"]
    , isLoginConfig: function (key) {
        var loginUser = this.getLoginUser();
        var config = loginUser.config;
        var status = false;
        config.forEach(o => {
            if (key == o) {
                status = true;

            }
        });

        return status;
    }

    ,
    findSysUserList: function () {
        this.axiosPost("/sysUser/findList.do", {}, (resData) => {
            var list = [];
            resData.list.forEach(o => {
                if (o.status == 0) {
                    o.disabled = true;
                    o.disabledTxt = "账号停用";
                } else {
                    o.disabled = false;
                }
                list.push(o);
            });
            this.$set(this, "sysUserList", list);
            this.$set(this, "sysUserMap", this.listJsonToMap(list, "id"));

        });
    }
    ,
    findClientAll: function () {
        this.axiosPost("/userInfo/findAll.do", {typeCode: "client"}, (resData) => {
            this.$set(this, "clientAllList", resData.list);
        });
    }
    ,
    findPmInfoList: function () {
        this.axiosPost("/pmInfo/findList.do", {}, (resData) => {
            this.$set(this.dataMap, "pmInfoList", resData.list);
            this.$set(this.dataMap, "pmInfoList$", this.listJsonToMap(resData.list, "id"));
        });
    }
    ,
    findPmPactList: function () {
        this.axiosPost("/pmPact/findListAll.do", {}, (resData) => {
            this.$set(this.dataMap, "pmPactList", resData.list);
            this.$set(this.dataMap, "pmPactList$", this.listJsonToMap(resData.list, "id"));
        });
    }
    ,
    findBuyerInfoList: function () {
        this.axiosPost("/buyerInfo/findList.do", {}, (resData) => {
            this.$set(this.dataMap, "buyerInfoList", resData.list);
            this.$set(this.dataMap, "buyerInfoList$", this.listJsonToMap(resData.list, "id"));
        });
    }
    ,
    findSupplierAll: function () {
        this.axiosPost("/userInfo/findAll.do", {typeCode: "supplier"}, (resData) => {
            this.$set(this, "supplierAllList", resData.list);
        });
    }
    ,
    findUserAll: function () {
        this.axiosPost("/userInfo/findAll.do", {typeCode: "client"}, (resData) => {
            this.$set(this, "userList", resData.list);
        });
    }

    ,

    convert: function (size) {
        let kb = 1024;
        let unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i;
        if (size) {
            i = Math.floor(Math.log(size) / Math.log(kb))
            // let unit = size < 1024 ? 'KB' : size < 1024*1024 ? 'MB'
        } else {
            i = 0;
        }
        return (size / Math.pow(kb, i)).toFixed(2) + '' + unit[i];
    }

    ,
    conver: function (limit) {
        var size = "";
        if (limit < 0.1 * 1024) { //如果小于0.1KB转化成B
            size = limit.toFixed(2) + "B";
        } else if (limit < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB
            size = (limit / 1024).toFixed(2) + "KB";
        } else if (limit < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
            size = (limit / (1024 * 1024)).toFixed(2) + "MB";
        } else { //其他转化成GB
            size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
        }
        var sizestr = size + "";
        var len = sizestr.indexOf("\.");
        var dec = sizestr.substr(len + 1, 2);
        if (dec == "00") {//当小数点后为00时 去掉小数部分
            return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
        }
        return sizestr;
    }

    ,
    byteStr: function (num, fixedNum) {
        if (!num) {
            return num;
        }
        if (fixedNum == null) {
            fixedNum = 1;
        }

        if (num > 1000 * 1024 * 1024 * 1024 * 1024) {
            return (num / (1024 * 1024 * 1024 * 1024 * 1024)).toFixed(fixedNum) + "PB";
        }
        if (num > 1000 * 1024 * 1024 * 1024) {
            return (num / (1024 * 1024 * 1024 * 1024)).toFixed(fixedNum) + "TB";
        }
        if (num > 1000 * 1024 * 1024) {
            return (num / (1024 * 1024 * 1024)).toFixed(fixedNum) + "GB";
        }
        if (num > 1000 * 1024) {
            return (num / (1024 * 1024)).toFixed(fixedNum) + "MB";
        }
        if (num > 1000) {
            return (num / 1024).toFixed(fixedNum) + "KB";
        }
        return num.toFixed(0) + "B"
        // let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        // let targetNum = num;
        // if (targetNum&&num>1024) {
        //   if (sourceUnit && targetUnit) {
        //     let sourceLevel = units.indexOf(sourceUnit);
        //     let targetLevel = units.indexOf(targetUnit);
        //     if (targetLevel > sourceLevel) {
        //       targetNum = (num / (1024 ^ (targetLevel - sourceLevel))).toFixed(2);
        //     }
        //   } else {
        //     targetNum = this.convert(num);
        //   }
        // }
        // console.log(num,targetNum,targetUnit)
        // if (addUnit) {
        //   return targetNum + targetUnit||'';
        // } else {
        //   return targetNum||'';
        // }
    }
    ,
    portUnitConvert: function (num, sourceUnit, targetUnit, addUnit) {
        if (!num) {
            return num;
        }
        if (num >= 1000 * 1000 * 1000 * 1000 * 1000) {
            return (num / (1000 * 1000 * 1000 * 1000 * 1000)).toFixed(1) + "PB";
        }
        if (num >= 1000 * 1000 * 1000 * 1000) {
            return (num / (1000 * 1000 * 1000 * 1000)).toFixed(1) + "TB";
        }
        if (num >= 1000 * 1000 * 1000) {
            return (num / (1000 * 1000 * 1000)).toFixed(1) + "GB";
        }
        if (num >= 1000 * 1000) {
            return (num / (1000 * 1000)).toFixed(1) + "MB";
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + "KB";
        }
        return num.toFixed(1) + "B";
        // let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        // let targetNum = num;
        // if (targetNum&&num>1024) {
        //   if (sourceUnit && targetUnit) {
        //     let sourceLevel = units.indexOf(sourceUnit);
        //     let targetLevel = units.indexOf(targetUnit);
        //     if (targetLevel > sourceLevel) {
        //       targetNum = (num / (1024 ^ (targetLevel - sourceLevel))).toFixed(2);
        //     }
        //   } else {
        //     targetNum = this.convert(num);
        //   }
        // }
        // console.log(num,targetNum,targetUnit)
        // if (addUnit) {
        //   return targetNum + targetUnit||'';
        // } else {
        //   return targetNum||'';
        // }
    }

    ,
    getProcessColor: function (percent) {
        if (percent <= 40) {
            return '#00fb08'
        } else if (percent <= 60) {
            return '#0000e2'
        } else if (percent <= 80) {
            return '#ff8949'
        } else {
            return '#ea0000'
        }
    }
//求两个日期之间间隔时间入参是new Date(‘2023-01-13 10:14:30’)这样的格式
    ,
    getDiffTime: function (new_date, old_date) {
        var subtime = (new_date.getTime() - old_date.getTime()) / 1000;    //计算时间差,并将毫秒转化为秒
        // console.log("subtime",new_date, old_date,subtime)
        var days = parseInt(subtime / 86400);  //天  24*60*60*1000
        if (days > 0) {
            return days + "天";
        }
        var hours = parseInt(subtime / 3600) - 24 * days;   //小时  60*60  总小时数-过去小时数=现在小时数
        if (hours > 0) {
            return hours + "小时";
        }
        var mins = parseInt(subtime % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
        if (mins > 0) {
            return mins + "分钟";
        }
        var secs = parseInt(subtime % 60);   //以60秒为一整份  取余  剩下秒数
        if (secs >= 0) {
            return secs + "秒";
        }
        return days + "天 " + hours + "小时 " + mins + "分钟 " + secs + "秒 ";
    }
    /**
     * 根据时间换算天为单位
     * @param sec 时间（秒）
     * @returns {string}
     */
    ,
    getDayTimeForS: function (sec) {
        if (sec) {
            var days = parseInt(sec / 86400);  //天  24*60*60*1000
            var hours = parseInt(sec % 86400 / 3600);   //小时  60*60  总小时数-过去小时数=现在小时数
            var mins = parseInt(sec % 86400 % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
            var secs = parseInt(sec % 86400 % 3600 % 60);   //以60秒为一整份  取余  剩下秒数
            var str = "";
            if (days > 0) {
                str = days + "天 " + hours + "小时 " + mins + "分钟 ";
            } else if (hours > 0) {
                str = hours + "小时 " + mins + "分钟 ";
            } else if (mins > 0) {
                str = mins + "分钟 ";
            }
            return str + secs + "秒 ";
        } else {
            return "";
        }
    }
    ,
    toFixed: function (num, len) {
        if (num) {
            return Number(num).toFixed(len);
        } else {
            return 0;
        }
    }
    ,
    arraytoFixed: function (array, len) {
        if (array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i]) {
                    array[i] = Number(array[i]).toFixed(len);
                } else {
                    array[i] = 0;
                }
            }
            return array;
        }
    }
    ,
    segmentation: function (max, min, seg) {
        let differ = (Number(max) - Number(min)) * seg;
        return (Number(max) - differ).toFixed(2);
    }
}
