<!--Breadcrumb 面包屑 -->
<template>
  <template v-if="row&&c">
    <span :style="[c.leftStyle]" v-if="(rowVal(row, c)||rowVal(row, c)==0)&&c.left_suffix">{{ c.left_suffix }}</span>

    <template v-if="c.dict_code">
      <etSysDict :sysMap="sysMap" :modelValue="rowVal(row, c)" :line_show="c.line_show==1" text
                 :dict_code="c.dict_code"></etSysDict>
    </template>
    <template
        v-else-if="
        c.module_type_form&&(c.module_type_form=='select'||c.module_type_form.indexOf('area')==0 ||c.module_type_form.indexOf('tree')==0 ||c.module_type_form=='select_remote'||c.module_type_form=='select_text'||c.module_type_form=='select_remote_multiple'||c.module_type_form=='select_multiple'||c.module_type_form=='radio'||c.module_type_form=='checked')
       || c.module_type_table&&(c.module_type_table=='select'||c.module_type_table.indexOf('area')==0 ||c.module_type_table.indexOf('tree')==0 ||c.module_type_table=='select_remote'||c.module_type_table=='select_text'||c.module_type_table=='select_remote_multiple'||c.module_type_table=='select_multiple'||c.module_type_table=='radio'||c.module_type_table=='checked')
">
      <etDesignApiShow type="table" :sysMap="sysMap" :selectMap="selectMap" :c="c" :row="row"/>
      <!--      <template v-if="selectMap[c.col]">
              <template
                  v-if="selectMap[c.col].api$.showTable&&selectMap[c.col].api$&&selectMap[c.col].api$.table_label&&selectMap[c.col].api$.table_label.length>1">

              <table class="selectTable" border="1" cellspacing="0"
                     cellpadding="0">
                <template v-for="(item,itemIndex) in selectMap[c.col].api$.headTitle">
                  <tr v-if="selectMap[c.col].api$.table_label.indexOf(selectMap[c.col].api$.label[itemIndex])>-1">
                    <td class="head">{{ item }}</td>
                    <td>{{ selectMap[c.col][row[c.api_field || c.col]][selectMap[c.col].api$.label[itemIndex]] }}</td>
                  </tr>
                </template>
              </table>
                </template>
             <template v-else>
                <template v-if="row[c.api_field||c.col] instanceof  Array">
                <span v-for="(item,itemIndex) in row[c.api_field||c.col]" :style="{marginRight: c.line_show!=1?'4px':''}">
                  <span :class="{line:c.line_show}" style="display: inline-block" v-if="selectMap[c.api_field||c.col][item]">
                    {{ selectMap[c.api_field || c.col][item].table_label }}
                  </span>
                  <span :class="{line:c.line_show}" v-else style="color: #ff8d00;display: inline-block;">{{ item }}</span>
                </span>
              </template>
              <template v-else-if="selectMap[c.col][row[c.api_field||c.col]]">
                <template v-if="selectMap[c.col].api$&&selectMap[c.col].api$.options">
                  <span v-for="(k,kIndex) in selectMap[c.col].api$.options">
                    <span v-if="kIndex>=1">#</span>
                    <img v-if="k&k.indexOf('img#')==0" :src="selectMap[c.col][row[c.api_field||c.col]][k.replace('img#','')]"
                         style="height: 32px;vertical-align: middle"/>
                    <span v-else>{{ selectMap[c.col][row[c.api_field || c.col]][k] }}</span>
                    &lt;!&ndash;              {{// selectMap[c.col][row[c.api_field||c.col]][k]}}&ndash;&gt;
                  </span>
                </template>
                <template v-else> {{ selectMap[c.col][row[c.api_field || c.col]].table_label }}</template>
              </template>
              <span v-else style="color: #ff8d00;">{{ row[c.api_field || c.col] }}</span>
             </template>
            </template>-->
    </template>

<!--    <template v-else-if="c.eval">-->
<!--      <span v-html="rowEval(row, c.eval)"></span>-->
<!--    </template>-->
    <template v-else-if="c.module_type_form=='imgUpload' ">
      <etImgsView v-if="fileVal(row, c)" :list="fileVal(row, c)" urlKey="url"
                  style="margin:0px  2px 0 0px;  "
                  :style="{height:c.fontSize?c.fontSize+'px':'40px' }"/>
    </template>
    <template v-else-if="c.module_type_form=='firmAuth' ">
      <firm_temp :sysMap="sysMap" :modelValue="rowVal(row, c)" text/>
    </template>
    <template v-else-if="c.module_type_table=='textBr' ">
      <span v-html="toHtmlBr(rowVal(row, c))"></span>
    </template>
    <template v-else-if="c.module_type_form=='fileUpload' ">
      <etUploadFileList disabled :readShow="true" :modelValue="rowVal(row, c)"/>
      <!--      <div :key="index" v-for="(item,index) in rowVal(row, c)">-->
      <!--        <a :href="item.domain+item.imgUrl" target="_blank">{{item.name}}</a>-->
      <!--      </div>-->
      <!--      <etImgsView v-if="rowVal(row, c)" :list="rowVal(row, c)" urlKey="url" :style="{width:c.width_form,height:c.width_form}"-->
      <!--                  style="margin-right: 4px;"/>-->
    </template>
    <template v-else-if="c.date_time">
      <template v-if="rowVal(row, c)&&!type">
      <div>{{ dateFmt(rowVal(row, c), "YYYY-MM-DD") }}</div>
      <div>{{ dateFmt(rowVal(row, c), "HH:mm:ss") }}</div>
      </template>
       <template v-else>
       {{ dateFmt(rowVal(row, c), "YYYY-MM-DD HH:mm:ss") }}
      </template>

    </template>

    <template v-else-if="c.module_type_table=='byteStr'&& rowVal(row, c)!=null">
      <span :title="rowVal(row, c)">{{ byteStr(rowVal(row, c)) }}</span>
    </template>
    <template v-else-if="c.module_type_table=='alink'&& rowVal(row, c)!=null">
      <a :href="rowVal(row, c)" target="_blank">{{ rowVal(row, c) }}</a>
    </template>
    <template v-else-if="c.date_format||c.module_type_form&&c.module_type_form.indexOf('date')==0">
      {{ dateFmt(rowVal(row, c), c.date_format || timeFormatMap[c.module_type_form]) }}
    </template>
    <template v-else-if="c.num_format&& rowVal(row, c)!=null">
      {{ numberFmt(rowVal(row, c), c.num_format) }}
    </template>
    <template v-else-if="c.line_show">
      <span v-html="toHtmlBr(rowVal(row, c) )"></span>
    </template>
    <template v-else-if="c.module_type_table=='html'">
      <span v-html="(rowVal(row, c) )"></span>
    </template>
    <template v-else>
      {{rowVal(row, c)}}
    </template>
    <!--    {{!(rowVal(row, c)==undefined||rowVal(row, c)=='')}}-->
    <span :style="[c.rightStyle]" v-if="(rowVal(row, c)||rowVal(row, c)==0)&&c.right_suffix">{{ c.right_suffix }}</span>
  </template>
</template>
<script>
import {ref, watch} from 'vue'

export default {
  name: "etDesignColVal",
  components: {},
  data() {
    return {};
  },
  props: {
    modelValue: null,
    row: {},
    type: {},
    col: {},
    c: {},
    design: {},
    sysMap: {},
    selectMap: {},
  },
  setup(props, {emit}) {
    const search = ref(props.modelValue); // 初始化数据
    watch(
        () => search.value,
        (data) => emit('update:modelValue', data)
    ); // 监听输入值变动
    return {
      search: search,
    }
  },
  created() {
  },
  methods: {
    rowVal(row, c) {
      // if(c.table_show_rule){
      //   return this.rowEval(row, c.table_show_rule)
      // }
      if(c.eval){
        return this.rowEval(row, c.eval)
      }
      return this.objKeyVal(row, c.api_field || c.col_data || c.col);
    }, fileVal(row, c) {
      var files = this.objKeyVal(row, c.api_field || c.col_data || c.col);

      if (typeof files == "string") {
        files = [{url: files}]
      }
      return files;
    },
    rowEval(row, eval_text) {
      try {
        var $rq = this.$route.query;
        var loginSys = this.getLoginUser();
        var a = eval(eval_text);
        return eval(eval_text)
      } catch (e) {
        console.log(eval_text,row)
        console.error(e)
        this.showMsgError(eval_text + " 行列显示表达式错误")
      }
    }
  }, mounted: function () {

  }, watch: { //值改变动作 valObj(val){}
  }
}
</script>