<style lang="less"></style>
<template>
  <div v-if="design" :class="{showDesign:showDesign==true}">
    <etBreadcrumb></etBreadcrumb>
    <etSearch ref="etSearch">
      <etDesignSearch :design="design" @refresh="refresh" :search="search" :showDesign="showDesign" :loading="loading" :p$data="p$data"
                      @findPage="findPage" @findObj="findObj" @del="del"  @whereRight="whereRight" @setVal="setVal">
<!--        <template #keystr1>11231</template>-->
      </etDesignSearch>

    </etSearch>
    <div class="mainContent " v-if="design&&design.table">
      <etDesignTable @setVal="setVal" @refresh="refresh" :page="page" :loading="loading" :design="design" :tHeight="tHeight" :sysMap="sysMap"
                     @selectionChange="selectionChange" @rowDblClick="rowDblClick" @colRight="colRight"
                     @headerDragend="headerDragend"
                     @sortChange="sortChange" @findObj="findObj">
<!--        <template #value="o">-->
<!--          自定义显示：{{ o.row.name }}-->
<!--        </template>-->
<!--        <template #column-create_time>-->
<!--          <el-table-column label="时间" prop="create_time"></el-table-column>-->
<!--        </template>-->
      </etDesignTable>
    </div>


  </div>
</template>
<script>

import {ref, watch} from 'vue'
export default {
  name: "etDesignIndex",
  data() {
    return {
    }
  },
  props: {
    search:{},
    showDesign:{},
    design:{},
    page:{},
    sysMap:{},
    tHeight:{},
    loading:{},
    p$data: {},
    root$: {},
  }, setup(props, {emit}) {

  },
  methods: {
    setVal(key,val) {
      this.$emit("setVal",key,val);

    },
  },
  watch: {},
  components: {

  },
  mounted() {/*页面加载初始化*/
  }
}
</script>

